import { get, isString, isPlainObject, PropertyPath } from 'lodash';
import { REPLACE_SIGN, LTR_UNICODE, OTHER_OPTION, UUID_V4_PATTERN } from 'const';
import { LaborerCaseResponse, LaborerCaseClosingDetails, LaborerCase } from 'modules/laborer-case';
import {
  RegistrarClassification,
  RegistrarClassificationResponse,
  LaborCommitteeClassification,
  LaborCommitteeClassificationResponse,
  RegistrarClassificationType,
  LaborCommitteeClassificationType,
  ReferralType,
  ReferralTypeResponse,
  HostingType,
  HostingTypeResponse,
  NationalityResponseDto,
  AuthorityType,
  AuthorityTypeResponse,
  OccupationType,
  OccupationResponse,
} from './types';

/**
 * Misc
 */

export const toLTR = (value: string) => `${LTR_UNICODE}${value}`;

export const toPx = (value: number): string => `${value}px`;

// rename to isOtherOptionSelected
export const isOtherSelected = (value: string | null) => value === OTHER_OPTION;

export const isArabic = (value: string) => /[\u0621-\u064A\u0660-\u0669]/.test(value);

export const isObject = (value: any): value is Record<string, any> => !!value && value.constructor === Object;

export const isUUID = (value: string): boolean => {
  return UUID_V4_PATTERN.test(value);
};

export const getValue = <T>(object: T, path: PropertyPath) => {
  const value = get(object, path);
  return value || REPLACE_SIGN;
};

export const prepareFormPayload = (formData: Record<string, any>): any => {
  return Object.fromEntries(
    Object.entries(formData).map(([key, value]) => [
      key,
      isString(value) ? (value.length ? value : null) : isPlainObject(value) ? prepareFormPayload(value) : value,
    ])
  );
};

/**
 * Case classification
 */

export const isCaseHostingType = (registrarClassification: string): boolean => {
  return registrarClassification === RegistrarClassification.HOSTING;
};

export const isCaseFinancialClaimsAndDeportationType = (registrarClassification: string): boolean => {
  return registrarClassification === RegistrarClassification.FINANCIAL_CLAIM_AND_DEPORTATION;
};

export const isCaseFinancialClaimsType = (laborCommitteeClassification: string): boolean => {
  return laborCommitteeClassification === LaborCommitteeClassification.FINANCIAL_CLAIM;
};

export const isCaseDeportationType = (laborCommitteeClassification: string): boolean => {
  return laborCommitteeClassification === LaborCommitteeClassification.DEPORTATION;
};

export function getCaseClassificationLabel(
  classifications: RegistrarClassificationResponse[],
  caseClassification: RegistrarClassificationType
): string;
export function getCaseClassificationLabel(
  classifications: LaborCommitteeClassificationResponse[],
  caseClassification: LaborCommitteeClassificationType | null
): string;
export function getCaseClassificationLabel(
  classifications: (RegistrarClassificationResponse | LaborCommitteeClassificationResponse)[],
  caseClassification: RegistrarClassificationType | (LaborCommitteeClassificationType | null)
): string {
  const classfication = classifications.find(c => c.name === caseClassification);
  return classfication ? classfication.arabicName : REPLACE_SIGN;
}
export function getCaseClassificationEnglishLabel(
  classifications: (RegistrarClassificationResponse | LaborCommitteeClassificationResponse)[],
  caseClassification: RegistrarClassificationType | (LaborCommitteeClassificationType | null)
): string {
  const classfication = classifications.find(c => c.name === caseClassification);
  return classfication ? classfication.englishName : REPLACE_SIGN;
}

/**
 * Referral Types
 */

export const getReferredByByLabel = (
  referralTypes: ReferralTypeResponse[],
  caseReferralTypes: [LaborerCaseResponse['referredBy'], LaborerCaseResponse['referredByOther']]
): string => {
  const [referredBy, referredByOther] = caseReferralTypes;

  if (referredBy === ReferralType.OTHER) {
    return referredByOther || REPLACE_SIGN;
  }

  const referralType = referralTypes.find(referral => referral.name === referredBy);
  return referralType ? referralType.arabicName : REPLACE_SIGN;
};

export const getReferredByByEnglishLabel = (
  referralTypes: ReferralTypeResponse[],
  caseReferralTypes: [LaborerCaseResponse['referredBy'], LaborerCaseResponse['referredByOther']]
): string => {
  const [referredBy, referredByOther] = caseReferralTypes;

  if (referredBy === ReferralType.OTHER) {
    return referredByOther || REPLACE_SIGN;
  }

  const referralType = referralTypes.find(referral => referral.name === referredBy);
  return referralType ? referralType.englishName : REPLACE_SIGN;
};

/**
 * Hosting Types
 */

export const getHostingTypeLabel = (
  hostingTypes: HostingTypeResponse[],
  caseHostingTypes: [LaborerCaseResponse['hostingType'], LaborerCaseResponse['hostingTypeOther']]
): string => {
  const [hostingType, hostingTypeOther] = caseHostingTypes;

  if (hostingType === HostingType.OTHER) {
    return hostingTypeOther || REPLACE_SIGN;
  }

  const hosting = hostingTypes.find(type => type.name === hostingType);
  return hosting ? hosting.arabicName : REPLACE_SIGN;
};

export const getHostingTypeEnglishLabel = (
  hostingTypes: HostingTypeResponse[],
  caseHostingTypes: [LaborerCaseResponse['hostingType'], LaborerCaseResponse['hostingTypeOther']]
): string => {
  const [hostingType, hostingTypeOther] = caseHostingTypes;

  if (hostingType === HostingType.OTHER) {
    return hostingTypeOther || REPLACE_SIGN;
  }

  const hosting = hostingTypes.find(type => type.name === hostingType);
  return hosting ? hosting.englishName : REPLACE_SIGN;
};

/**
 * Nationalities
 */

export const getNationalityLabelByName = (
  nationalities: NationalityResponseDto[],
  laborerNationality: LaborerCaseResponse['laborerNationality']
): string => {
  const nationality = nationalities.find(nat => nat.name === laborerNationality);
  return nationality ? nationality.arabicNationalityName : REPLACE_SIGN;
};

export const getNationalityEnglishLabelByName = (
  nationalities: NationalityResponseDto[],
  laborerNationality: LaborerCaseResponse['laborerNationality']
): string => {
  const nationality = nationalities.find(nat => nat.name === laborerNationality);
  return nationality ? nationality.englishNationalityName : REPLACE_SIGN;
};

/**
 * AuthorityTypes
 */

export const getAuthorityTypeLabel = (
  authorityTypes: AuthorityTypeResponse[],
  caseAuthorityTypes: [LaborerCaseClosingDetails['authority'], LaborerCaseClosingDetails['authorityOther']]
): string => {
  const [authority, authorityOther] = caseAuthorityTypes;

  if (authority === AuthorityType.OTHER) {
    return authorityOther || REPLACE_SIGN;
  }

  const authorityType = authorityTypes.find(type => type.name === authority);
  return authorityType ? authorityType.arabicName : REPLACE_SIGN;
};

/**
 * Occupation
 */

export const getOccupationLabelByName = (
  occupations: OccupationResponse[],
  laborerOccupation: LaborerCase['laborerOccupation']
): string => {
  const occupation = occupations.find(o => o.name === laborerOccupation);
  return occupation ? occupation.arabicName : REPLACE_SIGN;
};

export const getOccupationEnglishLabelByName = (
  occupations: OccupationResponse[],
  laborerOccupation: LaborerCase['laborerOccupation']
): string => {
  const occupation = occupations.find(o => o.name === laborerOccupation);
  return occupation ? occupation.englishName : REPLACE_SIGN;
};

export const getOccupationLabelByCode = (
  occupations: OccupationResponse[],
  laborerOccupationCode: string | null
): string => {
  const occupation = occupations.find(o => o.code === laborerOccupationCode);
  return occupation ? occupation.arabicName : REPLACE_SIGN;
};

export const getOccupationNameByCode = (
  occupations: OccupationResponse[],
  laborerOccupationCode: string
): OccupationType => {
  const occupation = occupations.find(o => o.code === laborerOccupationCode);
  return occupation!.name;
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    maxHeight: '225px',
    overflow: 'auto',
  },
  menuItem: {
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

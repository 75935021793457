import { Action } from 'services/store';
import { EwaCenterActionType } from './actions';
import { EwaCenterState } from './state';

export const ewaCenterState: EwaCenterState = {
  ewaCentersList: {
    data: [],
    count: 0,
    status: 'default',
  },
  ewaList: {
    data: [],
    count: 0,
    status: 'default',
  },
  ewaCenterDetails: {
    data: null,
    status: 'default',
  },
  postEwaCenterStatus: 'default',
  updateEwaCenterStatus: 'default',
  selectedEwaCenterId: null,
};

export function ewaCenterReducer(state = ewaCenterState, action: Action): EwaCenterState {
  switch (action.type) {
    /**
     * FetchEwaCenters
     */
    case EwaCenterActionType.FetchEwaCenters:
      return {
        ...state,
        ewaCentersList: {
          ...state.ewaCentersList,
          status: 'pending',
        },
      };

    case EwaCenterActionType.FetchEwaCentersSuccess:
      return {
        ...state,
        ewaCentersList: {
          data: action.payload.ewaCenters.results,
          count: action.payload.ewaCenters.count,
          status: 'success',
        },
      };

    case EwaCenterActionType.FetchEwaCentersFailure:
      return {
        ...state,
        ewaCentersList: {
          ...state.ewaCentersList,
          status: 'failure',
        },
      };

    /**
     * FetchEwaCenter
     */
    case EwaCenterActionType.FetchEwaCenter:
      return {
        ...state,
        ewaCenterDetails: {
          ...state.ewaCenterDetails,
          status: 'pending',
        },
      };

    case EwaCenterActionType.FetchEwaCenterSuccess:
      return {
        ...state,
        ewaCenterDetails: {
          data: action.payload.data,
          status: 'success',
        },
      };

    case EwaCenterActionType.FetchEwaCenterFailure:
      return {
        ...state,
        ewaCenterDetails: {
          ...state.ewaCenterDetails,
          status: 'failure',
        },
      };

    /**
     * PostEwaCenter
     */
    case EwaCenterActionType.PostEwaCenter:
      return {
        ...state,
        postEwaCenterStatus: 'pending',
      };

    case EwaCenterActionType.PostEwaCenterSuccess:
      return {
        ...state,
        postEwaCenterStatus: 'success',
      };

    case EwaCenterActionType.PostEwaCenterFailure:
      return {
        ...state,
        postEwaCenterStatus: 'failure',
      };

    /**
     * UpdateEwaCenter
     */
    case EwaCenterActionType.UpdateEwaCenter:
      return {
        ...state,
        updateEwaCenterStatus: 'pending',
      };

    case EwaCenterActionType.UpdateEwaCenterSuccess:
      return {
        ...state,
        updateEwaCenterStatus: 'success',
      };

    case EwaCenterActionType.UpdateEwaCenterFailure:
      return {
        ...state,
        updateEwaCenterStatus: 'failure',
      };

    /**
     * ResetEwaCenterDetails
     */
    case EwaCenterActionType.ResetEwaCenterDetails:
      return {
        ...state,
        ewaCenterDetails: {
          ...ewaCenterState.ewaCenterDetails,
        },
      };

    /**
     * SelectEwaCenter
     */
    case EwaCenterActionType.SelectEwaCenter:
      return {
        ...state,
        selectedEwaCenterId: action.payload.ewaCenterId,
      };

    default:
      return state;

    /**
     * FetchEwaList
     */
    case EwaCenterActionType.FetchEwaList:
      return {
        ...state,
        ewaList: {
          ...state.ewaList,
          status: 'pending',
        },
      };

    case EwaCenterActionType.FetchEwaListSuccess:
      return {
        ...state,
        ewaList: {
          data: action.payload.ewaList.results,
          count: action.payload.ewaList.count,
          status: 'success',
        },
      };

    case EwaCenterActionType.FetchEwaListFailure:
      return {
        ...state,
        ewaList: {
          ...state.ewaList,
          status: 'failure',
        },
      };
  }
}

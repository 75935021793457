import { useState, useMemo } from 'react';
import { Filter } from 'components';
import { omit } from 'lodash';

// TODO: add generic type support
export const useFilters = () => {
  const [filters, setFilters] = useState<Record<string, Filter['data']>>({});

  const filterParams = useMemo(
    () =>
      Object.entries(filters)
        .filter(([, data]) => (typeof data === 'string' ? data : data.id))
        .reduce((params, [name, data]) => {
          const value = typeof data === 'string' ? data : data.id;

          return { ...params, [name]: value };
        }, {}),
    [filters]
  );

  const filterEntries = useMemo(
    () =>
      Object.entries(filters).map(([name, data]) => {
        const label = typeof data === 'string' ? data : data.name;
        const labelEn = typeof data !== 'string' && data.nameEn ? data.nameEn : '';
        return [name, label, labelEn];
      }),
    [filters]
  );

  const addFilter = ({ name, data }: Filter) => {
    setFilters({ ...filters, [name]: data });
  };

  const removeFilter = (name: string, ...names: string[]) => {
    if (names.length) {
      setFilters(omit(filters, [name, ...names]));
    } else {
      setFilters(omit(filters, [name]));
    }
  };

  return {
    filterParams,
    filterEntries,
    addFilter,
    removeFilter,
  };
};

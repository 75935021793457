import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm, FormProvider, UseFormMethods } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { useStyles } from './Editable.styles';

type EditableProps = {
  children: (props: { editing: boolean; formMethods: UseFormMethods<any> }) => React.ReactNode;
  dataEditTestId?: string;
  dataUpdateTestId?: string;
  dataCancelTestId?: string;
  defaultValues: any;
  isEditable: boolean;
  onSubmit: (value: any) => void;
  validationSchema: yup.Schema<unknown>;
  resetEditing: boolean;
};

export const Editable: React.FC<EditableProps> = ({
  children,
  dataEditTestId,
  dataUpdateTestId,
  dataCancelTestId,
  defaultValues,
  isEditable,
  onSubmit,
  validationSchema,
  resetEditing = true,
}) => {
  const [editing, setEditing] = useState(false);
  const styles = useStyles({ editing });
  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    if (resetEditing) {
      setEditing(false);
    }
  }, [isEditable]);

  useEffect(() => {
    if (editing) {
      formMethods.reset(defaultValues);
    }
  }, [editing]);

  return (
    <FormProvider {...formMethods}>
      <div className={styles.container}>
        {children({ editing, formMethods })}

        {isEditable && !editing && (
          <IconButton
            data-test-id={dataEditTestId}
            className={styles.icon}
            size="small"
            onClick={() => setEditing(true)}
          >
            <EditIcon />
          </IconButton>
        )}

        {isEditable && editing && (
          <Box display="flex" marginTop={1}>
            <IconButton
              data-test-id={dataUpdateTestId}
              className={styles.icon}
              disabled={!formMethods.formState.isValid}
              size="small"
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              <CheckIcon />
            </IconButton>

            <IconButton data-test-id={dataCancelTestId} size="small" onClick={() => setEditing(false)}>
              <RemoveIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </FormProvider>
  );
};

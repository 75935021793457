import { NationalityResponse, NationalityResponseDto } from '../types';

export const toNationalityResponseDto = (nationality: NationalityResponse): NationalityResponseDto => {
  return {
    name: nationality.name,
    arabicNationalityName: nationality.arabicNationalityName,
    englishNationalityName: nationality.englishNationalityName,
    nicCode: nationality.nicCode,
  };
};

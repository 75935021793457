import React, { useState } from 'react';
import { useTranslation } from 'services/translation-client';
import { useSharedManagement, useSharedDictionaries } from 'modules/shared';
import { PrintCaseSummary, PrintExitForm } from 'components';
import { getEwaCenter } from 'modules/ewa-center';
import { getLaborerCase, getLaborerAssessment } from '../api';

const getReactPdf = () => import(/* webpackChunkName: 'react-pdf-module' */ '@react-pdf/renderer');

export type PrintType = 'EXIT_FORM' | 'CASE_SUMMARY';

type PrintData = {
  caseId: string | null;
  generating: boolean;
};

export const usePrintView = () => {
  const { t } = useTranslation();
  const [pdfDocument, setPdfDocument] = useState<PrintData>({ caseId: null, generating: false });
  const { notifyUser } = useSharedManagement();
  const {
    committeeCaseClassifications,
    registrarCaseClassifications,
    referralTypeDictionary,
    nationalities,
    authorityTypeDictionary,
    laborerOccupations,
  } = useSharedDictionaries();

  const createPrintComponent = async (
    caseId: string,
    printType: PrintType,
    reactPdfModule: typeof import('@react-pdf/renderer')
  ): Promise<JSX.Element> => {
    const caseDetails = await getLaborerCase(caseId);

    if (printType === 'EXIT_FORM') {
      const ewaCenterDetails = await getEwaCenter(caseDetails.ewaCenterId);

      return (
        <PrintExitForm
          caseDetails={caseDetails}
          laborerOccupations={laborerOccupations}
          referralTypes={referralTypeDictionary}
          nationalities={nationalities}
          authorityTypes={authorityTypeDictionary}
          ewaCenterName={ewaCenterDetails.name}
          reactPdf={reactPdfModule}
        />
      );
    }

    const caseAssessment = await getLaborerAssessment(caseId);

    return (
      <PrintCaseSummary
        caseDetails={caseDetails}
        caseAssessment={caseAssessment}
        registrarCaseClassifications={registrarCaseClassifications}
        committeeCaseClassifications={committeeCaseClassifications}
        laborerOccupations={laborerOccupations}
        nationalities={nationalities}
        reactPdf={reactPdfModule}
      />
    );
  };

  const handleOpenPrintView = async (caseId: string, printType: PrintType): Promise<void> => {
    setPdfDocument({ caseId, generating: true });

    try {
      const reactPdfModule = await getReactPdf();
      const PrintComponent = await createPrintComponent(caseId, printType, reactPdfModule);
      const blob = await reactPdfModule.pdf(PrintComponent).toBlob();

      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url);

      if (newWindow) {
        newWindow.print();
      }
    } catch {
      notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
    } finally {
      setPdfDocument({ caseId, generating: false });
    }
  };

  return {
    handleOpenPrintView,
    pdfDocument,
  };
};

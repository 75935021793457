import { AuthenticatedHttpClient, BasicRequestConfig, CustomHttpResponse } from '../http-client';

type ApiVersion = 'v1' | 'v2';

// TODO: remove failSilently from this type definition and create proper interface for redux actions
export type RequestConfig = BasicRequestConfig & {
  apiVersion?: ApiVersion;
  failSilently?: boolean;
};

export const DEFAULT_API_VERSION: ApiVersion = 'v1';

interface ApiClientConstructor {
  httpClient: AuthenticatedHttpClient;
}

export class ApiClient {
  httpClient: ApiClientConstructor['httpClient'];

  constructor({ httpClient }: ApiClientConstructor) {
    this.httpClient = httpClient;
  }

  request<T>(path: string, options: RequestConfig): Promise<CustomHttpResponse<T>> {
    const { apiVersion, ...axiosOptions } = options;
    const version = apiVersion || DEFAULT_API_VERSION;

    return this.httpClient.request<T>(`/${version}${path}`, axiosOptions);
  }

  get<T>(path: string, options: RequestConfig = {}): Promise<CustomHttpResponse<T>> {
    return this.request<T>(path, { ...options, method: 'GET' });
  }

  post<T>(path: string, options: RequestConfig = {}): Promise<CustomHttpResponse<T>> {
    return this.request<T>(path, { ...options, method: 'POST' });
  }

  put<T>(path: string, options: RequestConfig = {}): Promise<CustomHttpResponse<T>> {
    return this.request<T>(path, { ...options, method: 'PUT' });
  }

  patch<T>(path: string, options: RequestConfig = {}): Promise<CustomHttpResponse<T>> {
    return this.request<T>(path, { ...options, method: 'PATCH' });
  }

  delete<T>(path: string, options: RequestConfig = {}): Promise<CustomHttpResponse<T>> {
    return this.request<T>(path, { ...options, method: 'DELETE' });
  }
}

import * as yup from 'yup';
import moment from 'moment';
import { i18nClient } from 'services/translation-client';
import { DEFAULT_DATE_RANGE } from 'services/date';
import {
  IQAMA_BORDER_NUMBER_PATTERN,
  BORDER_NUMBER_PATTERN,
  IQAMA_NUMBER_PATTERN,
  PASSPORT_NUMBER_PATTERN,
} from 'const';

export enum SearchByMode {
  BorderOrIqamaNumber = 'borderIqama',
  EmployerId = 'employerId',
}

export type SearchLaborerFormData = {
  searchByMode: SearchByMode;
  searchByValue: string;
  laborerSearchNationality: string;
  checkDate?: Date | null;
  registrarOption: string;
};

export const searchLaborerSchema = () => {
  return yup.object().shape<SearchLaborerFormData>({
    searchByMode: yup
      .string<SearchByMode>()
      .defined()
      .oneOf([SearchByMode.BorderOrIqamaNumber, SearchByMode.EmployerId]),
    searchByValue: yup
      .string()
      .defined()
      .when('registrarOption', {
        is: registrarOption => registrarOption.includes('iqama'),
        then: yup.string().matches(IQAMA_NUMBER_PATTERN, {
          message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:BorderIqamaPassportNo)' }),
          excludeEmptyString: true,
        }),
      })
      .when('registrarOption', {
        is: registrarOption => registrarOption.includes('passport'),
        then: yup.string().matches(PASSPORT_NUMBER_PATTERN, {
          message: i18nClient.t('Validation.Pattern', { field: '$t(Common:PassportNo)' }),
          excludeEmptyString: true,
        }),
      })
      .when('registrarOption', {
        is: registrarOption => registrarOption.includes('border'),
        then: yup.string().matches(BORDER_NUMBER_PATTERN, {
          message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:BorderNo)' }),
          excludeEmptyString: true,
        }),
      }),
    laborerSearchNationality: yup
      .string()
      .nullable() // autocomplete default value
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Nationality)' })),
    checkDate: yup
      .date()
      .nullable()
      .when('searchByMode', {
        is: SearchByMode.EmployerId,
        then: yup
          .date()
          .transform((date, momentDate) => {
            if (momentDate?.toDate) {
              return momentDate.toDate();
            }
            if (momentDate) {
              return momentDate;
            }
            return null;
          })
          .test('maxDate', i18nClient.t('Validation.MaxDateExceeded'), function maxDate(value) {
            const isDateOfBirth = /^1/.test(this.parent.searchByValue);
            const maximalDate = isDateOfBirth ? new Date() : DEFAULT_DATE_RANGE.HIJRI_MAX_DATE;
            const isAfterMaximal = moment(value).isAfter(maximalDate, 'day');
            if (isAfterMaximal && !isDateOfBirth) {
              return this.createError({ path: this.path, message: i18nClient.t('Validation.WrongDateFormat') });
            }
            return !isAfterMaximal;
          })
          .typeError(i18nClient.t('Validation.RequiredField', { field: '$t(CheckDateRegistrationForm)' }))
          .required(i18nClient.t('Validation.RequiredField', { field: '$t(CheckDateRegistrationForm)' })),
      }),
    registrarOption: yup
      .string()
      .defined()
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Laborer:registrarOption)' })),
  });
};

import { createAction } from 'services/store/create-action';
import { Query, Paginated } from 'modules/http';
import {
  EwaCenterShortResponse,
  EwaCenterDetailsResponse,
  CreateEwaCenterRequest,
  EditEwaCenterRequest,
} from './types';
import { EwaCentersQuery } from './query';

export enum EwaCenterActionType {
  FetchEwaCenters = '[EwaCenter]: Fetch ewa centers',
  FetchEwaCentersSuccess = '[EwaCenter]: Fetch ewa centers - success',
  FetchEwaCentersFailure = '[EwaCenter]: Fetch ewa centers - failure',

  FetchEwaCenter = '[EwaCenter]: Fetch ewa center',
  FetchEwaCenterSuccess = '[EwaCenter]: Fetch ewa center - success',
  FetchEwaCenterFailure = '[EwaCenter]: Fetch ewa center - failure',

  PostEwaCenter = '[EwaCenter]: Post ewa center',
  PostEwaCenterSuccess = '[EwaCenter]: Post ewa center - success',
  PostEwaCenterFailure = '[EwaCenter]: Post ewa center - failure',

  UpdateEwaCenter = '[EwaCenter]: Update ewa center',
  UpdateEwaCenterSuccess = '[EwaCenter]: Update ewa center - success',
  UpdateEwaCenterFailure = '[EwaCenter]: Update ewa center - failure',

  ResetEwaCenterDetails = '[EwaCenter]: Reset ewa center details',

  SelectEwaCenter = '[EwaCenter]: MLSD select ewa center',

  FetchEwaList = '[EwaCenter]: Fetch ewa list',
  FetchEwaListSuccess = '[EwaCenter]: Fetch ewa list - success',
  FetchEwaListFailure = '[EwaCenter]: Fetch ewa list - failure',
}

export const EwaCenterAction = {
  fetchEwaCenters: (query?: EwaCentersQuery) => createAction(EwaCenterActionType.FetchEwaCenters, { query }),
  fetchEwaCentersSuccess: (ewaCenters: Paginated<EwaCenterShortResponse>) =>
    createAction(EwaCenterActionType.FetchEwaCentersSuccess, { ewaCenters }),
  fetchEwaCentersFailure: () => createAction(EwaCenterActionType.FetchEwaCentersFailure),

  fetchEwaCenter: (ewaCenterId: string) => createAction(EwaCenterActionType.FetchEwaCenter, { ewaCenterId }),
  fetchEwaCenterSuccess: (data: EwaCenterDetailsResponse) =>
    createAction(EwaCenterActionType.FetchEwaCenterSuccess, { data }),
  fetchEwaCenterFailure: () => createAction(EwaCenterActionType.FetchEwaCenterFailure),

  postEwaCenter: (data: CreateEwaCenterRequest) => createAction(EwaCenterActionType.PostEwaCenter, data),
  postEwaCenterSuccess: () => createAction(EwaCenterActionType.PostEwaCenterSuccess),
  postEwaCenterFailure: () => createAction(EwaCenterActionType.PostEwaCenterFailure),

  updateEwaCenter: (data: EditEwaCenterRequest) => createAction(EwaCenterActionType.UpdateEwaCenter, data),
  updateEwaCenterSuccess: () => createAction(EwaCenterActionType.UpdateEwaCenterSuccess),
  updateEwaCenterFailure: () => createAction(EwaCenterActionType.UpdateEwaCenterFailure),

  resetEwaCenterDetails: () => createAction(EwaCenterActionType.ResetEwaCenterDetails),

  selectEwaCenter: (ewaCenterId: string, skipRedirect = false) =>
    createAction(EwaCenterActionType.SelectEwaCenter, { ewaCenterId, skipRedirect }),

  fetchEwaList: (query?: EwaCentersQuery) => createAction(EwaCenterActionType.FetchEwaList, { query }),
  fetchEwaListSuccess: (ewaList: Paginated<EwaCenterShortResponse>) =>
    createAction(EwaCenterActionType.FetchEwaListSuccess, { ewaList }),
  fetchEwaListFailure: () => createAction(EwaCenterActionType.FetchEwaListFailure),
};

export type EwaCenterActionCreator = typeof EwaCenterAction;

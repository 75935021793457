import React from 'react';
import cn from 'classnames';
import { Box } from '@material-ui/core';
import { useStyles } from './FormRow.styles';

type FormBoxProps = {
  className?: string;
};

export const FormRow: React.FC<FormBoxProps> = ({ children, className }) => {
  const styles = useStyles();

  return (
    <Box className={cn(styles.formRow, className)}>
      {React.Children.map(children, child => (child ? <Box className={styles.child}>{child}</Box> : null))}
    </Box>
  );
};

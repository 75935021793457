import React, { FC } from 'react';
import cn from 'classnames';
import MuiFormControl, { FormControlProps as MuiFormControlProps } from '@material-ui/core/FormControl';
import { InputLabel, InputLabelProps as InternalInputLabelProps } from '../InputLabel';
import { FormHelperText, FormHelperTextProps as InternalFormHelperTextProps } from '../FormHelperText';
import { useStyles } from './FormControl.styles';

export type FormControlProps = MuiFormControlProps & {
  helperText?: string;
  label?: string;
  InputLabelProps?: InternalInputLabelProps;
  FormHelperTextProps?: InternalFormHelperTextProps;
};

export const FormControl: FC<FormControlProps> = props => {
  const styles = useStyles();
  const {
    children,
    classes,
    className,
    color = 'primary',
    disabled = false,
    error = false,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    id,
    InputLabelProps,
    label,
    required = false,
    variant = 'outlined',
    ...other
  } = props;

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  return (
    <MuiFormControl
      className={cn(classes?.root, className)}
      classes={classes}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      required={required}
      color={color}
      variant={variant}
      {...other}
    >
      {label && (
        <InputLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}

      {children}

      <div className={styles.helperTextWrapper}>
        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </div>
    </MuiFormControl>
  );
};

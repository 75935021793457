import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 250,
    padding: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.spacing(1) / 2,
  },
  outline: {
    border: 0,
  },
}));

import { createAction } from 'services/store/create-action';
import { Paginated } from 'modules/http';
import { EditLaborerCaseRequest, LaborerCaseResponse, LaborerCasesQuery, CreateLaborerCaseRequest } from './types';

export interface UpdateLaborerCaseData {
  data: EditLaborerCaseRequest;
  successMessageTranslationKey?: string;
  blockRedirect?: boolean;
  shouldRefetch?: boolean;
}

export enum LaborerActionType {
  FetchLaborerCase = '[LaborerCase]: Fetch laborer case',
  FetchLaborerCaseSuccess = '[LaborerCase]: Fetch laborer case - success',
  FetchLaborerCaseFailure = '[LaborerCase]: Fetch laborer case - failure',

  FetchLaborerCases = '[LaborerCase]: Fetch laborer cases',
  FetchLaborerFilteredCases = '[LaborerCase]: Fetch laborer filtered cases',
  FetchLaborerCasesSuccess = '[LaborerCase]: Fetch laborer cases - success',
  FetchLaborerCasesFailure = '[LaborerCase]: Fetch laborer cases - failure',

  PostLaborerCase = '[LaborerCase]: Post laborer case',
  PostLaborerCaseSuccess = '[LaborerCase]: Post laborer case - success',
  PostLaborerCaseFailure = '[LaborerCase]: Post laborer case - failure',

  UpdateLaborerCase = '[LaborerCase]: Update laborer case',
  UpdateLaborerCaseSuccess = '[LaborerCase]: Update laborer case - success',
  UpdateLaborerCaseFailure = '[LaborerCase]: Update laborer case - failure',

  ResetLaborerCaseDetails = '[LaborerCase]: Reset laborer case details',

  ConnectToReservationSocket = '[LaborerCase]: Connect to reservation socket',
  ConnectToReservationSocketSuccess = '[LaborerCase]: Connect to reservation socket - success',
  ConnectToReservationSocketFailure = '[LaborerCase]: Connect to reservation socket - failure',

  ReserveLaborerCase = '[LaborerCase]: Reserve laborer case',
  ReserveLaborerCaseSuccess = '[LaborerCase]: Reserve laborer case - success',
  ReserveLaborerCaseFailure = '[LaborerCase]: Reserve laborer case - failure',

  ReleaseLaborerCase = '[LaborerCase]: Release laborer case',
  ReleaseLaborerCaseSuccess = '[LaborerCase]: Release laborer case - success',
  ReleaseLaborerCaseFailure = '[LaborerCase]: Release laborer case - failure',

  SaveReservedCaseId = '[LaborerCase]: Save reserved case id',
  ClearReservedCaseId = '[LaborerCase]: Clear reserved case id',

  ReserveLaborerCaseAgain = '[LaborerCase]: Reserve laborer case again',
}

export const LaborerAction = {
  fetchLaborerCase: (laborerCaseId: string) => createAction(LaborerActionType.FetchLaborerCase, { laborerCaseId }),
  fetchLaborerCaseSuccess: (data: LaborerCaseResponse) => createAction(LaborerActionType.FetchLaborerCaseSuccess, data),
  fetchLaborerCaseFailure: () => createAction(LaborerActionType.FetchLaborerCaseFailure),

  fetchLaborerCases: (query?: LaborerCasesQuery) => createAction(LaborerActionType.FetchLaborerCases, { query }),
  fetchLaborerFilteredCases: (query?: LaborerCasesQuery) =>
    createAction(LaborerActionType.FetchLaborerFilteredCases, { query }),
  fetchLaborerCasesSuccess: (cases: Paginated<LaborerCaseResponse>) =>
    createAction(LaborerActionType.FetchLaborerCasesSuccess, { cases }),
  fetchLaborerCasesFailure: () => createAction(LaborerActionType.FetchLaborerCasesFailure),

  postLaborerCase: (data: CreateLaborerCaseRequest) => createAction(LaborerActionType.PostLaborerCase, data),
  postLaborerCaseSuccess: () => createAction(LaborerActionType.PostLaborerCaseSuccess),
  postLaborerCaseFailure: () => createAction(LaborerActionType.PostLaborerCaseFailure),

  updateLaborerCase: ({
    data,
    successMessageTranslationKey = 'GeneralSendSuccessMessage',
    blockRedirect = false,
    shouldRefetch = false,
  }: UpdateLaborerCaseData) =>
    createAction(LaborerActionType.UpdateLaborerCase, {
      data,
      successMessageTranslationKey,
      blockRedirect,
      shouldRefetch,
    }),
  updateLaborerCaseSuccess: (blockRedirect: boolean, shouldRefetch: boolean, laborerCaseId: string) =>
    createAction(LaborerActionType.UpdateLaborerCaseSuccess, { blockRedirect, shouldRefetch, laborerCaseId }),
  updateLaborerCaseFailure: () => createAction(LaborerActionType.UpdateLaborerCaseFailure),

  resetLaborerCaseDetails: () => createAction(LaborerActionType.ResetLaborerCaseDetails),

  connectToReservationSocket: () => createAction(LaborerActionType.ConnectToReservationSocket),
  connectToReservationSocketSuccess: () => createAction(LaborerActionType.ConnectToReservationSocketSuccess),
  connectToReservationSocketFailure: () => createAction(LaborerActionType.ConnectToReservationSocketFailure),

  reserveLaborerCase: (laborerCaseId: string) => createAction(LaborerActionType.ReserveLaborerCase, { laborerCaseId }),
  reserveLaborerCaseSuccess: () => createAction(LaborerActionType.ReserveLaborerCaseSuccess),
  reserveLaborerCaseFailure: () => createAction(LaborerActionType.ReserveLaborerCaseFailure),

  releaseLaborerCase: (laborerCaseId: string) => createAction(LaborerActionType.ReleaseLaborerCase, { laborerCaseId }),
  releaseLaborerCaseSuccess: () => createAction(LaborerActionType.ReleaseLaborerCaseSuccess),
  releaseLaborerCaseFailure: () => createAction(LaborerActionType.ReleaseLaborerCaseFailure),

  reserveLaborerCaseAgain: (laborerCaseId: string) =>
    createAction(LaborerActionType.ReserveLaborerCaseAgain, { laborerCaseId }),

  saveReservedCaseId: (laborerCaseId: string) => createAction(LaborerActionType.SaveReservedCaseId, { laborerCaseId }),
  clearReservedCaseId: () => createAction(LaborerActionType.ClearReservedCaseId),
};

export type LaborerActionCreator = typeof LaborerAction;

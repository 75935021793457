import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { stringify } from 'query-string';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Box, Drawer, List, ListItem, Typography, IconButton, ClickAwayListener, Backdrop } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'services';
import { useUsersManagement, isUserInactive, hasUserRoles, UserRole } from 'modules/user';
import { selectSelectedEwaCenterId, useEwaCenterManagement } from 'modules/ewa-center';
import { routes, version, TestId } from 'const';
import { isStaging } from 'config';
import { EwaCenterIcon, UserIcon, CaseIcon, BundlesIcon } from 'assets/images';
import MusanedLogoWhite from 'assets/images/musaned-logo-white.svg';
import { useStyles } from './NavigationPanel.styles';

export const NavigationPanel: React.FC = () => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { loggedUser } = useUsersManagement();
  const isArabic = i18n.language === 'ar';

  const hasRoles = useSelector(hasUserRoles);
  const selectedEwaCenterId = useSelector(selectSelectedEwaCenterId);

  const { ewaCenter } = useEwaCenterManagement();
  const ewaCenterType = ewaCenter?.ewaCenterType;

  const handleDrawerOpen = (event: React.MouseEvent): void => {
    setOpen(true);
    event.stopPropagation();
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Drawer
        variant="permanent"
        anchor={isArabic ? 'left' : 'right'}
        classes={{
          root: styles.root,
          paper: cn(styles.paper, {
            [styles.drawerOpen]: open,
            [styles.drawerClose]: !open,
          }),
        }}
        onClick={handleDrawerClose}
      >
        {open ? (
          <div className={styles.toolbar}>{<img width="146" height="48" src={MusanedLogoWhite} />}</div>
        ) : (
          <IconButton className={styles.menuButton} aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        )}
        {!(loggedUser && isUserInactive(loggedUser.status)) && (
          <List className={styles.drawerList}>
            {hasRoles([UserRole.Admin]) && (
              <>
                <ListItem
                  data-test-id={TestId.NavigationPanelCentersIcon}
                  className={styles.drawerListItem}
                  button
                  component={NavLink}
                  to={routes.ewaCenter.main.path}
                  activeClassName={styles.drawerItemActive}
                >
                  <EwaCenterIcon />
                  <Typography className={styles.drawerItemText} noWrap>
                    {t(['EwaCenter', 'ListPageTitle'])}
                  </Typography>
                </ListItem>

                <ListItem
                  data-test-id={TestId.NavigationPanelUsersIcon}
                  className={styles.drawerListItem}
                  button
                  component={NavLink}
                  to={routes.users.main.path}
                  activeClassName={styles.drawerItemActive}
                >
                  <UserIcon />
                  <Typography className={styles.drawerItemText} noWrap>
                    {t(['Users', 'ListPageTitle'])}
                  </Typography>
                </ListItem>
              </>
            )}
            {hasRoles([
              UserRole.Registrar,
              UserRole.LaborCommittee,
              UserRole.SocialWorker,
              UserRole.EwaCenterManager,
              UserRole.Admin,
            ]) && (
              <ListItem
                data-test-id={TestId.NavigationPanelLaborerCasesIcon}
                className={styles.drawerListItem}
                button
                component={NavLink}
                to={routes.laborerCases.main.path}
                activeClassName={styles.drawerItemActive}
              >
                <CaseIcon />
                <Typography className={styles.drawerItemText} noWrap>
                  {t(['Common', 'LaborersList'])}
                </Typography>
              </ListItem>
            )}
            {hasRoles([UserRole.EwaCenterManager]) && (
              <ListItem
                data-test-id={TestId.NavigationPanelUsersIcon}
                className={styles.drawerListItem}
                button
                component={NavLink}
                to={routes.users.main.path}
                activeClassName={styles.drawerItemActive}
              >
                <UserIcon />
                <Typography className={styles.drawerItemText} noWrap>
                  {t(['Users', 'ListPageTitle'])}
                </Typography>
              </ListItem>
            )}
            {hasRoles([UserRole.MLSD]) && (
              <ListItem
                className={styles.drawerListItem}
                button
                component={NavLink}
                to={{
                  pathname: routes.laborerCases.main.path,
                  search: stringify({ ewaCenterId: selectedEwaCenterId }, { skipNull: true }),
                }}
                activeClassName={styles.drawerItemActive}
              >
                <CaseIcon />
                <Typography className={styles.drawerItemText} noWrap>
                  {t(['Common', 'LaborersList'])}
                </Typography>
              </ListItem>
            )}
            {isStaging() && hasRoles([UserRole.EwaCenterManager]) && ewaCenterType?.toString() === '4' && (
              <ListItem
                className={styles.drawerListItem}
                button
                component={NavLink}
                to={routes.bundles.main.path}
                activeClassName={styles.drawerItemActive}
              >
                <BundlesIcon />
                <Typography className={styles.drawerItemText} noWrap>
                  {t(['Common', 'Bundles'])}
                </Typography>
              </ListItem>
            )}
          </List>
        )}
        <Backdrop open={open} classes={{ root: styles.backdrop }} />
      </Drawer>
    </ClickAwayListener>
  );
};

import React from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'services/translation-client';
import { useSharedManagement } from 'modules/shared';
import { getReportFileManager, getReportFileAdmin } from '../api';

export const useReportFileManager = () => {
  const { t } = useTranslation();

  const { notifyUser } = useSharedManagement();

  const downloadManagerReport = async (startDate: string, endDate: string): Promise<void> => {
    try {
      const formattedStartDate = moment(startDate).hours(3).minutes(0).seconds(0).milliseconds(0).toISOString();
      const formattedEndDate = moment(endDate).hours(26).minutes(59).seconds(59).milliseconds(0).toISOString();
      const file = await getReportFileManager(formattedStartDate, formattedEndDate);
      saveAs(
        file,
        `قائمة العاملات من تاريخ ${moment(startDate).format('YYYY-MM-DD')} حتى تاريخ ${moment(endDate).format(
          'YYYY-MM-DD'
        )}.xlsx`
      );
    } catch (e) {
      if (e.status === 404) {
        notifyUser(t(['Laborer', 'NoLaborerRecordError']), 'error');
      } else {
        notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
      }
    }
  };

  const downloadAdminReport = async (startDate: string, endDate: string): Promise<void> => {
    try {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
      const file = await getReportFileAdmin(formattedStartDate, formattedEndDate);
      saveAs(
        file,
        `قائمة العاملات من تاريخ ${moment(startDate).format('YYYY-MM-DD')} حتى تاريخ ${moment(endDate).format(
          'YYYY-MM-DD'
        )}.xlsx`
      );
    } catch (e) {
      if (e.status === 404) {
        notifyUser(t(['Laborer', 'NoLaborerRecordError']), 'error');
      } else {
        notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
      }
    }
  };

  return {
    downloadManagerReport,
    downloadAdminReport,
  };
};

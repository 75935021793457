import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as yup from 'yup';
import moment from 'moment';
import { i18nClient, fullFormat, useTranslation } from 'services';
import {
  useLaborerCaseManagement,
  useLaborerCaseReservation,
  getBadgeStatusLevel,
  getCaseStatusTranslationKey,
  isReservationEvent,
  buildUpdateLaborerCaseDto,
} from 'modules/laborer-case';
import { useFilesManagement, stripTimestampFromFileName } from 'modules/files';
import { selectUserId, hasUserRoles, UserRole, useUsersManagement } from 'modules/user';
import { useEwaCenterManagement } from 'modules/ewa-center';
import {
  useSharedDictionaries,
  useSafeRedirect,
  isOtherSelected,
  isCaseHostingType,
  isCaseFinancialClaimsAndDeportationType,
  toLTR,
  getValue,
  getCaseClassificationLabel,
  getCaseClassificationEnglishLabel,
  getReferredByByLabel,
  getReferredByByEnglishLabel,
  getHostingTypeLabel,
  getHostingTypeEnglishLabel,
  getNationalityLabelByName,
  getNationalityEnglishLabelByName,
  getOccupationLabelByName,
  HostingTypeDictionaryUnion,
  getOccupationEnglishLabelByName,
} from 'modules/shared';
import { useDialogManagement } from 'hooks';
import { routes, TestId } from 'const';
import { CaseIcon } from 'assets/images';
import {
  Badge,
  Button,
  Card,
  ConfirmationDialog,
  FormSection,
  Page,
  PageHeader,
  Loader,
  CaseActionButton,
  ReservationOverlay,
  FormControl,
} from 'components/common';
import { FormSelect, FormTextField } from 'components/form-components';
import { CaseClassificationForm, CaseClassificationFormData } from 'components/form-containers';
import { Level } from 'components/common/Badge';
import { LaborerCaseDomainRouteWithIdProps } from '../types';
import { DetailElement, Editable } from './components';
import { useStyles } from './LaborerCaseDetails.styles';

const hostingTypeEditableSchema = yup.object().shape({
  hostingType: yup.string().required(i18nClient.t('Validation.Required')),
  hostingTypeOther: yup
    .string()
    .nullable()
    .when('hostingType', {
      is: hostingType => isOtherSelected(hostingType),
      then: yup
        .string()
        .required(i18nClient.t('Validation.Required'))
        .max(100, i18nClient.t('Validation.Max100CharsLength')),
    }),
});

export type HostingTypeEditFormData = {
  hostingType: HostingTypeDictionaryUnion;
  hostingTypeOther?: string | null;
};

export const isHostingTypePayload = (
  payload: CaseClassificationFormData | HostingTypeEditFormData
): payload is HostingTypeEditFormData => {
  return 'hostingType' in payload;
};

export const LaborerCaseDetails: React.FC<LaborerCaseDomainRouteWithIdProps> = ({ match }) => {
  const styles = useStyles();
  const { t, tBasic, i18n } = useTranslation();
  const { redirect, label } = useSafeRedirect(routes.laborerCases.main.path);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const {
    isLoading: isLaborerLoading,
    laborerCase,
    fetchLaborerCase,
    updateLaborerCase,
    resetLaborerCaseDetails,
  } = useLaborerCaseManagement();
  const {
    isLoading: isReservationLoading,
    attachIncomingMessageHandler,
    isReservationLock,
  } = useLaborerCaseReservation();
  const {
    isLoading: isSharedDictionariesLoading,
    registrarCaseClassifications,
    committeeCaseClassifications,
    referralTypeDictionary,
    hostingTypeDictionary,
    hostingTypeOptions,
    nationalities,
    laborerOccupations,
  } = useSharedDictionaries();
  // prettier-ignore
  const {
    isDialogOpen,
    openDialog,
    closeDialog,
    confirmAction,
    confirmationMessage
  } = useDialogManagement<CaseClassificationFormData | HostingTypeEditFormData>();
  const { downloadFile } = useFilesManagement();

  const hasRoles = useSelector(hasUserRoles);
  const userId = useSelector(selectUserId);

  const { caseId } = match.params;
  const isLoading = isLaborerLoading || isSharedDictionariesLoading;
  const { fetchEwaCenter } = useEwaCenterManagement();
  const { loggedUser } = useUsersManagement();
  const { ewaCenter } = useEwaCenterManagement();
  const ewaCenterType = ewaCenter?.ewaCenterType || '';
  const ewaCenterId = loggedUser?.ewaCenterId || '';
  const isArabic = i18n.language === 'ar';

  const hostingTypeEditableDefaultValues = useMemo(
    () => ({
      hostingType: laborerCase ? laborerCase.hostingType || '' : '',
      hostingTypeOther: laborerCase ? laborerCase.hostingTypeOther || '' : '',
    }),
    [laborerCase]
  );
  const canRegistrarManage = () => {
    if (hasRoles([UserRole.Registrar])) {
      return loggedUser?.privileges?.includes('REGISTRAR_VIEW_AND_MANAGE') || false;
    }
    return false;
  };

  // temporary solution
  useEffect(() => {
    if (isReservationLock) {
      closeDialog();
    }
  }, [isReservationLock]);

  useEffect(() => {
    fetchLaborerCase(caseId);
    if (hasRoles([UserRole.Registrar, UserRole.Admin])) {
      fetchEwaCenter(ewaCenterId);
    }

    attachIncomingMessageHandler(event => {
      const { data } = event;

      if (isReservationEvent(data) && data.laborerCaseId === caseId) {
        fetchLaborerCase(caseId);
      }
    });

    return () => {
      resetLaborerCaseDetails();
    };
  }, []);

  if (!laborerCase || laborerCase.id !== caseId) {
    return null;
  }

  const isEditableByLaborCommittee =
    hasRoles([UserRole.LaborCommittee]) &&
    laborerCase.status === 'PENDING_LC_REVIEW' &&
    laborerCase.reservedByUserId === userId;

  const isEditableByRegistrar = laborerCase.status === 'PENDING_CLEARANCE' && !isLaborerLoading && canRegistrarManage();

  const handleSubmitClassification = (formData: CaseClassificationFormData) => {
    if (isEditableByLaborCommittee) {
      openDialog(formData, t(['Common', 'GeneralSendConfirmationQuestion']));
      setShouldRefetch(false);
    }
  };

  const handleSubmitHostingType = (formData: HostingTypeEditFormData) => {
    const data = {
      hostingType: formData.hostingType,
      hostingTypeOther: isOtherSelected(formData.hostingType) ? formData.hostingTypeOther : null,
    };

    openDialog(data, t(['Case', 'ChangeHostingTypeConfirmationMessage']));
    setShouldRefetch(true);
  };

  const handleConfirm = () => {
    confirmAction(formData => {
      updateLaborerCase({
        data: buildUpdateLaborerCaseDto(formData, laborerCase),
        blockRedirect: isHostingTypePayload(formData),
        shouldRefetch,
      });
    });
  };
  const daysBetween = moment(laborerCase.joiningDate).diff(laborerCase.arrivedDate, 'days');

  return (
    <Page
      dataTestId={TestId.LaborerCaseDetailsContainer}
      header={
        <PageHeader
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h4">{`${t(['Laborer', 'CaseNo'])} #${laborerCase.caseNumber}`}</Typography>

              <Badge className={styles.spacer} level={getBadgeStatusLevel(laborerCase.status)}>
                {tBasic(getCaseStatusTranslationKey(laborerCase.status))}
              </Badge>
            </Box>
          }
          redirectLabel={label}
          onRedirectClick={redirect}
        />
      }
    >
      <Loader loading={isLoading} withOverflow>
        {laborerCase.laborerHostingTypeVersionDtos && (
          <Box>
            <Box className={styles.hostingLabel}>
              <Typography variant="h5"> {t(['Laborer', 'HostingType'])}</Typography>
            </Box>
            <Box display="flex" justifyContent="start" marginBottom={3}>
              {laborerCase.laborerHostingTypeVersionDtos.map((hostingType, i) => (
                <Box key={i} display="grid" textAlign="center" alignItems="start" marginLeft={4}>
                  <Box>
                    <Box className={styles.hostingHistoryLable}>
                      {isArabic ? getValue(hostingType, 'hostingType') : getValue(hostingType, 'englishName')}
                    </Box>
                  </Box>
                  <Box>
                    <Box>{toLTR(fullFormat(getValue(hostingType, 'dateTime')))}</Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          <Box>
            <Typography variant="h4">{t(['Case', 'DetailsHeader'])}</Typography>
          </Box>

          <Box>
            {(hasRoles([UserRole.Registrar]) ? canRegistrarManage() : true) && (
              <CaseActionButton type="button" laborerCase={laborerCase} />
            )}
          </Box>
        </Box>

        <Box display="table" marginBottom={4}>
          <Box display="table-row">
            <Box display="table-cell" className={styles.detailElementContainer}>
              <DetailElement title={t(['Laborer', 'JoiningToCenterDate'])}>
                {toLTR(fullFormat(laborerCase.joiningDate))}
              </DetailElement>
            </Box>
            <Box display="table-cell" className={styles.detailElementContainer}>
              <DetailElement title={t(['Laborer', 'DateOfExitField'])}>
                {toLTR(fullFormat(getValue(laborerCase, 'laborerCaseClosingDetails.exitDateTime')))}
              </DetailElement>
            </Box>
          </Box>
          <Box display="table-row">
            <Box display="table-cell" className={styles.detailElementContainer}>
              <DetailElement title={t(['Common', 'ReferredBy'])}>
                {isArabic
                  ? getReferredByByLabel(referralTypeDictionary, [laborerCase.referredBy, laborerCase.referredByOther])
                  : getReferredByByEnglishLabel(referralTypeDictionary, [
                      laborerCase.referredBy,
                      laborerCase.referredByOther,
                    ])}
              </DetailElement>
            </Box>
            <Box display="table-cell" className={styles.detailElementContainer}>
              <DetailElement title={t(['Laborer', 'RegistrarClassification'])}>
                {isArabic
                  ? getCaseClassificationLabel(registrarCaseClassifications, laborerCase.registrarClassification)
                  : getCaseClassificationEnglishLabel(
                      registrarCaseClassifications,
                      laborerCase.registrarClassification
                    )}
              </DetailElement>
            </Box>
            <Box display="table-cell">
              {isCaseHostingType(laborerCase.registrarClassification) && (
                <DetailElement title={t(['Laborer', 'HostingType'])}>
                  <Editable
                    dataEditTestId={TestId.LaborerCaseDetailsEditHostingTypeButton}
                    dataUpdateTestId={TestId.LaborerCaseDetailsUpdateHostingTypeButton}
                    dataCancelTestId={TestId.LaborerCaseDetailsCancelHostingTypeButton}
                    isEditable={isEditableByRegistrar}
                    onSubmit={handleSubmitHostingType}
                    validationSchema={hostingTypeEditableSchema}
                    defaultValues={hostingTypeEditableDefaultValues}
                    resetEditing={!isReservationLock}
                  >
                    {({ editing, formMethods }) =>
                      editing ? (
                        <div className={styles.hostingTypeContainer}>
                          <FormSelect
                            name="hostingType"
                            disabled={!hostingTypeOptions.length}
                            options={hostingTypeOptions}
                            labelKey={isArabic ? 'arabicName' : 'englishName'}
                            valueKey="name"
                            InputProps={{ classes: { root: styles.wideInput } }}
                          />
                          {isOtherSelected(formMethods.watch('hostingType')) && (
                            <div className={styles.hostingTypeOtherContainer}>
                              <FormTextField
                                name="hostingTypeOther"
                                InputProps={{ classes: { root: styles.wideInput } }}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <Typography variant="inherit">
                          {isArabic
                            ? getHostingTypeLabel(hostingTypeDictionary, [
                                laborerCase.hostingType,
                                laborerCase.hostingTypeOther,
                              ])
                            : getHostingTypeEnglishLabel(hostingTypeDictionary, [
                                laborerCase.hostingType,
                                laborerCase.hostingTypeOther,
                              ])}
                        </Typography>
                      )
                    }
                  </Editable>
                </DetailElement>
              )}
            </Box>
          </Box>
          {isCaseFinancialClaimsAndDeportationType(laborerCase.registrarClassification) && (
            <Box display="table-row">
              <Box display="table-cell" className={styles.detailElementContainer}>
                <DetailElement title={t(['Laborer', 'CommitteeClassification'])}>
                  {isArabic
                    ? getCaseClassificationLabel(committeeCaseClassifications, laborerCase.laborCommitteeClassification)
                    : getCaseClassificationEnglishLabel(
                        committeeCaseClassifications,
                        laborerCase.laborCommitteeClassification
                      )}
                </DetailElement>
              </Box>
              {laborerCase.claimAmount && (
                <Box display="table-cell" className={styles.detailElementContainer}>
                  <DetailElement title={t(['Case', 'ClaimAmountLabel'])}>{laborerCase.claimAmount}</DetailElement>
                </Box>
              )}
            </Box>
          )}
        </Box>
        {hasRoles([UserRole.MLSD, UserRole.Admin]) && (
          <Box>
            <Typography style={{ backgroundColor: '#e1e8ed', display: 'inline-block' }}>
              {laborerCase.proNotificationStatus === 'SEND'
                ? t(['Laborer', 'proNotificationSuccessStatus'])
                : t(['Laborer', 'proNotificationFailedStatus'])}
            </Typography>
          </Box>
        )}
        <Box marginBottom={4}>
          <Box className={styles.detailsContainer}>
            <Card
              elevation={3}
              badge={<Badge level={Level.INFO}>{t(['Common', 'LaborerInfo'])}</Badge>}
              className={styles.detailsCard}
            >
              <Box marginRight={2}>
                <DetailElement title={t(['Common', 'LaborerName'])}>{laborerCase.laborerName}</DetailElement>
                <DetailElement title={t(['Common', 'Nationality'])}>
                  {isArabic
                    ? getNationalityLabelByName(nationalities, laborerCase.laborerNationality)
                    : getNationalityEnglishLabelByName(nationalities, laborerCase.laborerNationality)}
                </DetailElement>
                <DetailElement title={t(['Common', 'Occupation'])}>
                  {isArabic
                    ? getOccupationLabelByName(laborerOccupations, laborerCase.laborerOccupation)
                    : getOccupationEnglishLabelByName(laborerOccupations, laborerCase.laborerOccupation)}
                </DetailElement>
                {laborerCase.arrivedDate && (
                  <DetailElement title={t(['Common', 'ArrivedDate'])}>
                    {toLTR(fullFormat(getValue(laborerCase, 'arrivedDate')))}
                  </DetailElement>
                )}
              </Box>
              <Box>
                <DetailElement title={t(['Laborer', 'IqamaBorderNo'])}>{laborerCase.laborerNumber}</DetailElement>
                <DetailElement title={t(['Common', 'PassportNo'])}>{laborerCase.laborerPassportNumber}</DetailElement>

                <DetailElement title={t(['Common', 'LaborerStatus'])}>
                  {getValue(laborerCase, 'laborerStatus')}
                </DetailElement>
                {laborerCase.arrivedDate && (
                  <DetailElement title={t(['Common', 'DaysSinceEntry'])}>{daysBetween}</DetailElement>
                )}
              </Box>
            </Card>
            {hasRoles([UserRole.MLSD]) && (
              <Card
                elevation={3}
                badge={<Badge level={Level.INFO}>{t(['Laborer', 'CaseDetailsEmployerInformation'])}</Badge>}
                className={styles.detailsCard}
              >
                <Box marginRight={2}>
                  <DetailElement title={t(['Common', 'EmployerName'])}>{laborerCase.employerName}</DetailElement>
                  <DetailElement title={t(['Laborer', 'EmployerIdNo'])}>{laborerCase.employerId}</DetailElement>
                </Box>
                <Box>
                  <DetailElement title={t(['Common', 'MobileNo'])}>
                    {getValue(laborerCase, 'employerMobileNumber')}
                  </DetailElement>
                </Box>
              </Card>
            )}
            {ewaCenterType.toString() !== '2' && (
              <Card
                elevation={3}
                badge={<Badge level={Level.INFO}>{t(['Laborer', 'CaseDetailsProInformation'])}</Badge>}
                className={styles.detailsCard}
              >
                <Box marginRight={2}>
                  <DetailElement title={t(['Laborer', 'ProName'])}>
                    {getValue(laborerCase, 'proInformation.name')}
                  </DetailElement>
                  <DetailElement title={t(['Common', 'Email'])}>
                    {getValue(laborerCase, 'proInformation.email')}
                  </DetailElement>
                </Box>
                <Box>
                  <DetailElement title={t(['Common', 'MobileNo'])}>
                    {getValue(laborerCase, 'proInformation.mobileNumber')}
                  </DetailElement>
                </Box>
              </Card>
            )}
          </Box>
        </Box>
        {laborerCase.laborTraffickingStudied && (
          <FormControl>
            <FormControlLabel
              className={laborerCase.laborTraffickingStudied ? '' : styles.hidden}
              name="laborTraffickingStudied"
              control={<Checkbox checked={laborerCase.laborTraffickingStudied} color={'primary'} />}
              label={t(['Common', 'LaborTraffickingStudied'])}
            />
          </FormControl>
        )}
        {laborerCase.workerSupported && (
          <FormControl>
            <FormControlLabel
              className={laborerCase.workerSupported ? '' : styles.hidden}
              name="workerSupported"
              control={<Checkbox checked={laborerCase.workerSupported} color={'primary'} />}
              label={t(['Common', 'WorkerSupported'])}
            />
          </FormControl>
        )}
        {laborerCase.uploadedFileNames.length > 0 && (
          <FormSection title={t(['Laborer', 'FilesSection'])}>
            {laborerCase.uploadedFileNames.map((fileName, i) => (
              <Box key={fileName} display="flex" alignItems="center" marginTop={3}>
                <Box marginRight={4}>{tBasic(`Files:Label${i + 1}`)}:</Box>
                <Button variant="text" size="large" startIcon={<GetAppIcon />} onClick={() => downloadFile(fileName)}>
                  {stripTimestampFromFileName(fileName)}
                </Button>
              </Box>
            ))}
          </FormSection>
        )}

        <Box marginTop={4} display={isEditableByLaborCommittee ? 'block' : 'none'}>
          <CaseClassificationForm
            committeeClassifications={committeeCaseClassifications}
            onSubmit={handleSubmitClassification}
          />
        </Box>

        <ConfirmationDialog
          text={confirmationMessage}
          open={isDialogOpen}
          onConfirm={handleConfirm}
          onCancel={closeDialog}
        />
      </Loader>
    </Page>
  );
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 300,
    maxWidth: theme.spacing(32),
    margin: '0 auto',
  },
  icon: {
    color: theme.palette.grey[400],
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginBottom: theme.spacing() / 2,
  },
}));

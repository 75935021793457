import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SharedAction } from '../actions';
import { NotificationVariant } from '../state';
import { selectLocationList, selectNotificationQueue } from '../selectors';

export const useSharedManagement = () => {
  const dispatch = useDispatch();

  const locationList = useSelector(selectLocationList);
  const notificationQueue = useSelector(selectNotificationQueue);

  const startLocationObserver = useCallback(
    history => {
      dispatch(SharedAction.startLocationObserver(history));
    },
    [dispatch]
  );

  /**
   * DEPRECATED: logic handled by startLocationObserver, leaving for now as it might come handy
   */
  const saveCurrentPathname = useCallback(
    (data: string) => {
      dispatch(SharedAction.saveCurrentPathname(data));
    },
    [dispatch]
  );

  /**
   * DEPRECATED: logic handled by startLocationObserver, leaving for now as it might come handy
   */
  const removeCurrentPathname = useCallback(() => {
    dispatch(SharedAction.removeCurrentPathname());
  }, [dispatch]);

  const notifyUser = useCallback(
    (message: string, variant?: NotificationVariant, keepOpen?: boolean) => {
      dispatch(SharedAction.notifyUser(message, variant, keepOpen));
    },
    [dispatch]
  );

  const notifyUserSuccess = useCallback(() => {
    dispatch(SharedAction.notifyUserSuccess());
  }, [dispatch]);

  return {
    locationList,
    notificationQueue,

    startLocationObserver,
    saveCurrentPathname,
    removeCurrentPathname,
    notifyUser,
    notifyUserSuccess,
  };
};

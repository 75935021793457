import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { useTranslation } from 'services';
import cities from 'assets/other/cities.json';
import { InputLabel, FormRow } from 'components/common';
import { FormRadioGroup, FormAutocomplete, FormTextField, FormSelect } from 'components/form-components';
import {
  maritalStatusOptions,
  workedForOptions,
  officialDocumentsOptions,
  documentTypeOptions,
  personalBelongingsOptions,
} from '../form-options';
import { FormSection } from './FormSection';

const WIDTH = 230;

type SectionLaborerProps = PropsWithDataTestId<{
  laborerName: string;
  readOnly?: boolean;
}>;

export const SectionLaborer: React.FC<SectionLaborerProps> = ({ dataTestId, laborerName, readOnly }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const officialDocumentValue = watch('officialDocument');

  return (
    <FormSection title={t(['Assessment', 'LaborerSectionName'])} dataTestId={dataTestId}>
      <FormRow>
        <Box width={WIDTH} alignSelf="flex-start">
          <InputLabel>{t(['Common', 'LaborerName'])}</InputLabel>
          <Box>{laborerName}</Box>
        </Box>

        <FormRadioGroup
          name="married"
          label={t(['Assessment', 'MaritalStatus'])}
          disabled={readOnly}
          options={maritalStatusOptions}
        />
      </FormRow>

      <FormRow>
        <FormAutocomplete
          name="cityName"
          label={t(['Common', 'LaborerCity'])}
          disabled={readOnly}
          options={cities}
          labelKey="label"
          valueKey="label"
        />

        <FormTextField name="workDurationInMonths" label={t(['Assessment', 'WorkDurationLabel'])} disabled={readOnly} />
      </FormRow>

      <FormRow>
        <FormTextField
          name="workDescription"
          label={t(['Assessment', 'WorkDescription'])}
          disabled={readOnly}
          multiline
          rows={5}
        />
      </FormRow>

      <FormRow>
        <FormRadioGroup
          name="workedFor"
          label={t(['Assessment', 'LaborerWorkedFor'])}
          disabled={readOnly}
          options={workedForOptions}
        />
      </FormRow>

      <FormRow>
        <FormRadioGroup
          name="officialDocument"
          label={t(['Assessment', 'LaborerOfficialDocuments'])}
          disabled={readOnly}
          options={officialDocumentsOptions}
        />
      </FormRow>

      {officialDocumentValue === 'YES' && (
        <FormRow>
          <FormSelect
            name="documentType"
            label={t(['Assessment', 'DocumentType'])}
            disabled={readOnly}
            options={documentTypeOptions}
            labelKey="label"
            valueKey="value"
          />
        </FormRow>
      )}

      <FormRow>
        <FormRadioGroup
          name="personalBelongings"
          label={t(['Assessment', 'LaborerPersonalBelongings'])}
          disabled={readOnly}
          options={personalBelongingsOptions}
        />
      </FormRow>
    </FormSection>
  );
};

import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import zIndex from '@material-ui/core/styles/zIndex';

const FONT_FACES = [
  'Frutiger',
  '-apple-system',
  'system-ui',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
].join(',');

const primaryMainColor = '#58abc8';
const primaryGrayColor = '#b3b2b3';
const secondaryMainColor = '#2d6287';
const secondaryGrayColor = '#515751';
const secondaryTiberColor = '#042b2f';
const secondaryGreenColor = '#5d9f58';
const selectedColor = '#265373';
const hoveredColor = '#e1e8ed';

const theme = {
  palette: {
    primary: {
      main: primaryMainColor,
      main80: lighten(primaryMainColor, 0.2),
      main60: lighten(primaryMainColor, 0.4),
      main40: lighten(primaryMainColor, 0.6),
      grey: primaryGrayColor,
      grey80: lighten(primaryGrayColor, 0.2),
      grey60: lighten(primaryGrayColor, 0.4),
      grey40: lighten(primaryGrayColor, 0.6),
      contrastText: '#fff',
      light: hoveredColor,
    },
    secondary: {
      main: secondaryMainColor,
      main80: lighten(secondaryMainColor, 0.2),
      main60: lighten(secondaryMainColor, 0.4),
      main40: lighten(secondaryMainColor, 0.6),
      grey: secondaryGrayColor,
      grey80: lighten(secondaryGrayColor, 0.2),
      grey60: lighten(secondaryGrayColor, 0.4),
      grey40: lighten(secondaryGrayColor, 0.6),
      tiber: secondaryTiberColor,
      tiber80: lighten(secondaryTiberColor, 0.2),
      tiber60: lighten(secondaryTiberColor, 0.4),
      tiber40: lighten(secondaryTiberColor, 0.6),
      green: secondaryGreenColor,
      green80: lighten(secondaryGreenColor, 0.2),
      green60: lighten(secondaryGreenColor, 0.4),
      green40: lighten(secondaryGreenColor, 0.6),
      contrastText: '#b3b2b3',
    },
    action: {
      selected: selectedColor,
      hover: hoveredColor,
    },
  },
  typography: {
    fontFamily: FONT_FACES,
  },
  zIndex: {
    ...zIndex,
    overlap: 2000,
  },
  overrides: {
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: hoveredColor,
        },
      },
    },
  },
};

export const rtlTheme = createMuiTheme({
  ...theme,
  direction: 'rtl',
});

export const ltrTheme = createMuiTheme({
  ...theme,
  direction: 'ltr',
});

export const classNameGenerator = createGenerateClassName({
  productionPrefix: 'jss-tamkeen',
});

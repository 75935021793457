import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusPending } from 'services/store';
import { Query } from 'modules/http';
import { EwaCenterAction } from './actions';
import {
  selectEwaCentersList,
  selectEwaCenterDetails,
  selectPostEwaCenterStatus,
  selectUpdateEwaCenterStatus,
  selectEwaList,
} from './selectors';
import { EditEwaCenterRequest, CreateEwaCenterRequest } from './types';

export const useEwaCenterManagement = () => {
  const dispatch = useDispatch();

  const ewaCentersList = useSelector(selectEwaCentersList);
  const ewaCenter = useSelector(selectEwaCenterDetails);
  const postEwaCenterStatus = useSelector(selectPostEwaCenterStatus);
  const updateEwaCenterStatus = useSelector(selectUpdateEwaCenterStatus);
  const ewaList = useSelector(selectEwaList);

  const isLoading =
    isStatusPending(ewaCentersList.status) ||
    isStatusPending(ewaCenter.status) ||
    isStatusPending(postEwaCenterStatus) ||
    isStatusPending(updateEwaCenterStatus) ||
    isStatusPending(ewaList.status);

  const fetchEwaCenters = useCallback(
    (query?: Query) => {
      dispatch(EwaCenterAction.fetchEwaCenters(query));
    },
    [dispatch]
  );

  const fetchEwaCenter = useCallback(
    (ewaCenterId: string) => {
      dispatch(EwaCenterAction.fetchEwaCenter(ewaCenterId));
    },
    [dispatch]
  );

  const createEwaCenter = useCallback(
    (data: CreateEwaCenterRequest) => {
      dispatch(EwaCenterAction.postEwaCenter(data));
    },
    [dispatch]
  );

  const editEwaCenter = useCallback(
    (data: EditEwaCenterRequest) => {
      dispatch(EwaCenterAction.updateEwaCenter(data));
    },
    [dispatch]
  );

  const resetEwaCenter = useCallback(() => {
    dispatch(EwaCenterAction.resetEwaCenterDetails());
  }, [dispatch]);

  const selectEwaCenter = useCallback(
    (ewaCenterId: string) => {
      dispatch(EwaCenterAction.selectEwaCenter(ewaCenterId));
    },
    [dispatch]
  );

  const fetchEwaList = useCallback(
    (query?: Query) => {
      dispatch(EwaCenterAction.fetchEwaList(query));
    },
    [dispatch]
  );

  return {
    isLoading,

    ewaCenters: ewaCentersList.data,
    ewaCentersCount: ewaCentersList.count,
    ewaCenter: ewaCenter.data,
    ewaList: ewaList.data,
    ewaListCount: ewaList.count,

    fetchEwaCenters,
    fetchEwaCenter,
    createEwaCenter,
    editEwaCenter,
    resetEwaCenter,
    selectEwaCenter,
    fetchEwaList,
  };
};

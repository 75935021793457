import { Action } from 'services/store';
import { UserActionType } from './actions';
import { UserState } from './state';
import { UserStatus, ExtendedUser } from './types';

export const userState: UserState = {
  usersList: {
    data: [],
    count: 0,
    status: 'default',
  },
  userDetails: {
    data: null,
    status: 'default',
    isNetworkConnectionProblem: false,
  },
  postUserStatus: 'default',
  inviteUserStatus: 'default',
  updateUserStatus: 'default',
  userPrivilegeStatus: 'default',
  userPointsStatus: 'default',
  roles: {
    data: [],
    status: 'default',
  },
  privileges: {
    data: [],
    status: 'default',
  },
  userIdDetails: {
    data: null,
    status: 'default',
  },
  userPoints: {
    data: null,
    status: 'default',
  },
};

export function userReducer(state = userState, action: Action): UserState {
  switch (action.type) {
    /**
     * FetchUsers
     */
    case UserActionType.FetchUsers:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          status: 'pending',
        },
      };

    case UserActionType.FetchUsersSuccess:
      return {
        ...state,
        usersList: {
          data: action.payload.users.results,
          count: action.payload.users.count,
          status: 'success',
        },
      };

    case UserActionType.FetchUsersFailure:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          status: 'failure',
        },
      };

    /**
     * FetchLoggedUserDetails
     */
    case UserActionType.FetchLoggedUserDetails:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          status: 'pending',
        },
      };

    case UserActionType.FetchLoggedUserDetailsSuccess:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: action.payload.userDetails,
          isNetworkConnectionProblem: false,
          status: 'success',
        },
      };

    case UserActionType.FetchLoggedUserDetailsFailure:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          status: 'failure',
        },
      };

    case UserActionType.FetchLoggedUserDetailsNotFound:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            status: UserStatus.Inactive,
          } as ExtendedUser,
          status: 'failure',
        },
      };

    case UserActionType.FetchLoggedUserDetailsNetworkConnection:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isNetworkConnectionProblem: true,
        },
      };

    /**
     * PostUser
     */
    case UserActionType.PostUser:
      return {
        ...state,
        postUserStatus: 'pending',
      };

    case UserActionType.PostUserSuccess:
      return {
        ...state,
        postUserStatus: 'success',
      };

    case UserActionType.PostUserFailure:
      return {
        ...state,
        postUserStatus: 'failure',
      };

    /**
     * UpdateUser
     */
    case UserActionType.UpdateUser:
      return {
        ...state,
        updateUserStatus: 'pending',
      };

    case UserActionType.UpdateUserSuccess:
      return {
        ...state,
        updateUserStatus: 'success',
      };

    case UserActionType.UpdateUserFailure:
      return {
        ...state,
        updateUserStatus: 'failure',
      };

    /**
     * InviteUser
     */
    case UserActionType.InviteUser:
      return {
        ...state,
        inviteUserStatus: 'pending',
      };

    case UserActionType.InviteUserSuccess:
      return {
        ...state,
        inviteUserStatus: 'success',
      };

    case UserActionType.InviteUserFailure:
      return {
        ...state,
        inviteUserStatus: 'failure',
      };

    /**
     * FetchRoles
     */
    case UserActionType.FetchRoles:
      return {
        ...state,
        roles: {
          ...state.roles,
          status: 'pending',
        },
      };

    case UserActionType.FetchRolesSuccess:
      return {
        ...state,
        roles: {
          data: action.payload.roles,
          status: 'success',
        },
      };

    case UserActionType.FetchRolesFailure:
      return {
        ...state,
        roles: {
          ...state.roles,
          status: 'failure',
        },
      };

    default:
      return state;
    /**
     * FetchPrivileges
     */
    case UserActionType.FetchPrivileges:
      return {
        ...state,
        privileges: {
          ...state.privileges,
          status: 'pending',
        },
      };
    case UserActionType.FetchPrivilegesSuccess:
      return {
        ...state,
        privileges: {
          data: action.payload.privileges,
          status: 'success',
        },
      };
    case UserActionType.FetchPrivilegesFailure:
      return {
        ...state,
        privileges: {
          ...state.privileges,
          status: 'failure',
        },
      };

    /**
     * FetchUserDetails
     */
    case UserActionType.FetchUser:
      return {
        ...state,
        userIdDetails: {
          ...state.userIdDetails,
          status: 'pending',
        },
      };

    case UserActionType.FetchUserSuccess:
      return {
        ...state,
        userIdDetails: {
          ...state.userIdDetails,
          data: action.payload.data,
          status: 'success',
        },
      };

    case UserActionType.FetchUserFailure:
      return {
        ...state,
        userIdDetails: {
          ...state.userIdDetails,
          status: 'failure',
        },
      };

    /**
     * UpdateUserPrivilege
     */
    case UserActionType.UpdateUserPrivilege:
      return {
        ...state,
        userPrivilegeStatus: 'pending',
      };

    case UserActionType.UpdateUserPrivilegeSuccess:
      return {
        ...state,
        userPrivilegeStatus: 'success',
      };

    case UserActionType.UpdateUserPrivilegeFailure:
      return {
        ...state,
        userPrivilegeStatus: 'failure',
      };

    /**
     * ResetUserDetails
     */
    case UserActionType.ResetUserDetails:
      return {
        ...state,
        userIdDetails: {
          ...userState.userIdDetails,
        },
      };

    /**
     * FetchUserPoints
     */
    case UserActionType.FetchUserPoints:
      return {
        ...state,
        userPoints: {
          ...state.userPoints,
          status: 'success',
        },
      };
    case UserActionType.FetchUserPointsSuccess:
      return {
        ...state,
        userPoints: {
          data: action.payload.userPoints,
          status: 'success',
        },
      };
    case UserActionType.FetchUserPointsFailure:
      return {
        ...state,
        userPoints: {
          ...state.userPoints,
          status: 'failure',
        },
      };
  }
}

import { useState } from 'react';

type ConfirmationCallback<T> = (data: T) => void | Promise<void>;

export const useDialogManagement = <T>() => {
  const [isDialogOpen, setDialogVisibility] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [dialogData, setDialogData] = useState<T | null>(null);

  const openDialog = (data?: T, message?: string): void => {
    setDialogVisibility(true);

    if (data) {
      setDialogData(data);
    }

    if (message) {
      setConfirmationMessage(message);
    }
  };

  const closeDialog = (): void => {
    setDialogVisibility(false);
    setDialogData(null);
  };

  const confirmAction = async (callback: ConfirmationCallback<T>): Promise<void> => {
    closeDialog();

    if (dialogData) {
      await callback(dialogData);
    }
  };

  return {
    isDialogOpen,
    confirmationMessage,

    openDialog,
    closeDialog,
    confirmAction,
  };
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
  toolbar: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  menuDropdownList: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  userIconBg: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
  },
  ewaCenterName: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontSize: '18px',
    fontWeight: 600,
  },
}));

import React from 'react';
import { Box } from '@material-ui/core';
import { Autocomplete, AutocompleteOption, AutocompleteProps } from 'components/common';
import { Filter } from '../types';
import { useStyles } from './FilterAutocomplete.styles';

type FilterAutocompleteProps<T extends AutocompleteOption> = Omit<AutocompleteProps<T>, 'onChange'> & {
  valueKey: keyof T;
  name: string;
  onChange: (data: Filter) => void;
};

export const FilterAutocomplete = <T extends AutocompleteOption>({
  name,
  valueKey,
  labelKey,
  options,
  onChange,
}: FilterAutocompleteProps<T>) => {
  const styles = useStyles();

  const handleOnChange: AutocompleteProps<T>['onChange'] = (_, option): void => {
    if (option) {
      onChange({ name, data: { id: option[valueKey], name: String(option[labelKey]) } });
    }
  };

  return (
    <Box className={styles.root}>
      <div className={styles.wrapper}>
        <Autocomplete
          name="laborerNationality"
          onChange={handleOnChange}
          options={options}
          labelKey={labelKey}
          disablePortal
          autoHighlight
          TextFieldProps={{
            InputProps: {
              classes: {
                notchedOutline: styles.outline,
              },
            },
            FormHelperTextProps: {
              noHint: true,
            },
          }}
        />
      </div>
    </Box>
  );
};

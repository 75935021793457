import { last } from 'lodash';
import { Action } from 'services/store';
import { SharedActionType } from './actions';
import { SharedState, ReferralTypeOptions, HostingTypeOptions, AuthorityTypeOptions } from './state';
import { ReferralType, HostingType, AuthorityType } from './types';

export const sharedState: SharedState = {
  locationList: [],
  notificationQueue: [],

  caseClassifications: {
    registrar: {
      data: [],
      status: 'default',
    },
    laborCommitte: {
      data: [],
      status: 'default',
    },
  },
  referralTypes: {
    dictionary: [],
    options: [],
    status: 'default',
  },
  hostingTypes: {
    dictionary: [],
    options: [],
    status: 'default',
  },
  nationalities: {
    data: [],
    status: 'default',
  },
  authorityTypes: {
    dictionary: [],
    options: [],
    status: 'default',
  },
  occupations: {
    data: [],
    status: 'default',
  },
};

export function sharedReducer(state = sharedState, action: Action): SharedState {
  switch (action.type) {
    /**
     * SaveCurrentPathname
     */
    case SharedActionType.SaveCurrentPathname:
      return {
        ...state,
        locationList:
          action.payload === last(state.locationList) ? state.locationList : [...state.locationList, action.payload],
      };

    /**
     * RemoveCurrentPathname
     */
    case SharedActionType.RemoveCurrentPathname:
      return {
        ...state,
        locationList: state.locationList.slice(0, state.locationList.length - 1),
      };

    /**
     * NotifyUser
     */
    case SharedActionType.NotifyUser:
      return {
        ...state,
        notificationQueue: [...state.notificationQueue, action.payload],
      };

    /**
     * NotifyUserSuccess
     */
    case SharedActionType.NotifyUserSuccess:
      return {
        ...state,
        notificationQueue: state.notificationQueue.slice(1),
      };

    /**
     * FetchRegistrarCaseClassifications
     */
    case SharedActionType.FetchRegistrarCaseClassifications:
      return {
        ...state,
        caseClassifications: {
          ...state.caseClassifications,
          registrar: {
            ...state.caseClassifications.registrar,
            status: 'pending',
          },
        },
      };

    case SharedActionType.FetchRegistrarCaseClassificationsSuccess:
      return {
        ...state,
        caseClassifications: {
          ...state.caseClassifications,
          registrar: {
            data: action.payload,
            status: 'success',
          },
        },
      };

    case SharedActionType.FetchRegistrarCaseClassificationsFailure:
      return {
        ...state,
        caseClassifications: {
          ...state.caseClassifications,
          registrar: {
            ...state.caseClassifications.registrar,
            status: 'failure',
          },
        },
      };

    /**
     * FetchCommitteeCaseClassifications
     */
    case SharedActionType.FetchCommitteeCaseClassifications:
      return {
        ...state,
        caseClassifications: {
          ...state.caseClassifications,
          laborCommitte: {
            ...state.caseClassifications.laborCommitte,
            status: 'pending',
          },
        },
      };

    case SharedActionType.FetchCommitteeCaseClassificationsSuccess:
      return {
        ...state,
        caseClassifications: {
          ...state.caseClassifications,
          laborCommitte: {
            data: action.payload,
            status: 'success',
          },
        },
      };

    case SharedActionType.FetchCommitteeCaseClassificationsFailure:
      return {
        ...state,
        caseClassifications: {
          ...state.caseClassifications,
          laborCommitte: {
            ...state.caseClassifications.laborCommitte,
            status: 'failure',
          },
        },
      };

    /**
     * FetchReferralTypes
     */
    case SharedActionType.FetchReferralTypes:
      return {
        ...state,
        referralTypes: {
          ...state.referralTypes,
          status: 'pending',
        },
      };

    case SharedActionType.FetchReferralTypesSuccess:
      return {
        ...state,
        referralTypes: {
          dictionary: action.payload,
          options: action.payload.filter((type): type is ReferralTypeOptions => type.name !== ReferralType.UNSPECIFIED),
          status: 'success',
        },
      };

    case SharedActionType.FetchReferralTypesFailure:
      return {
        ...state,
        referralTypes: {
          ...state.referralTypes,
          status: 'failure',
        },
      };

    /**
     * FetchHostingTypes
     */
    case SharedActionType.FetchHostingTypes:
      return {
        ...state,
        hostingTypes: {
          ...state.hostingTypes,
          status: 'pending',
        },
      };

    case SharedActionType.FetchHostingTypesSuccess:
      return {
        ...state,
        hostingTypes: {
          dictionary: action.payload,
          options: action.payload.filter((type): type is HostingTypeOptions => type.name !== HostingType.UNSPECIFIED),
          status: 'success',
        },
      };

    case SharedActionType.FetchHostingTypesFailure:
      return {
        ...state,
        hostingTypes: {
          ...state.hostingTypes,
          status: 'failure',
        },
      };

    /**
     * FetchNationalities
     */
    case SharedActionType.FetchNationalities:
      return {
        ...state,
        nationalities: {
          ...state.nationalities,
          status: 'pending',
        },
      };

    case SharedActionType.FetchNationalitiesSuccess:
      return {
        ...state,
        nationalities: {
          data: action.payload,
          status: 'success',
        },
      };

    case SharedActionType.FetchNationalitiesFailure:
      return {
        ...state,
        nationalities: {
          ...state.nationalities,
          status: 'failure',
        },
      };

    /**
     * FetchAuthorityTypes
     */
    case SharedActionType.FetchAuthorityTypes:
      return {
        ...state,
        authorityTypes: {
          ...state.authorityTypes,
          status: 'pending',
        },
      };

    case SharedActionType.FetchAuthorityTypesSuccess:
      return {
        ...state,
        authorityTypes: {
          dictionary: action.payload,
          options: action.payload.filter(
            (type): type is AuthorityTypeOptions => type.name !== AuthorityType.UNSPECIFIED
          ),
          status: 'success',
        },
      };

    case SharedActionType.FetchAuthorityTypesFailure:
      return {
        ...state,
        authorityTypes: {
          ...state.authorityTypes,
          status: 'failure',
        },
      };

    /**
     * FetchLaborerOccupations
     */
    case SharedActionType.FetchLaborerOccupations:
      return {
        ...state,
        occupations: {
          ...state.occupations,
          status: 'pending',
        },
      };

    case SharedActionType.FetchLaborerOccupationsSuccess:
      return {
        ...state,
        occupations: {
          data: action.payload,
          status: 'success',
        },
      };

    case SharedActionType.FetchLaborerOccupationsFailure:
      return {
        ...state,
        occupations: {
          ...state.occupations,
          status: 'failure',
        },
      };

    default:
      return state;
  }
}

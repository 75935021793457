import * as yup from 'yup';
import { i18nClient } from 'services';

export const assessmentFormSchema = () => {
  return yup.object().shape({
    married: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    cityName: yup
      .string()
      .nullable()
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(LaborerCity)' })),
    workDurationInMonths: yup
      .number()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .typeError(i18nClient.t('Validation.Pattern', { field: '$t(Assessment:WorkDuration)' }))
      .integer(i18nClient.t('Assessment:WorkDurationValueError'))
      .positive(i18nClient.t('Assessment:WorkDurationValueError'))
      .required(i18nClient.t('Validation.Required', { field: '$t(Assessment:WorkDuration)' })),
    workDescription: yup
      .string()
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Assessment:WorkDescription)' })),
    workedFor: yup.string().required(i18nClient.t('Validation.Required')),
    officialDocument: yup.string().required(i18nClient.t('Validation.Required')),
    documentType: yup.mixed().when('officialDocument', {
      is: 'YES',
      then: yup.string().required(i18nClient.t('Validation.RequiredField', { field: '$t(Assessment:DocumentType)' })),
    }),
    personalBelongings: yup.string().required(i18nClient.t('Validation.Required')),
    question1: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question2: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question3: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question4: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question5: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question6: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question7: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question8: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question9: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question10: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question11: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    question12: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    entranceReason: yup.string().required(i18nClient.t('Validation.Required')),
    disputeDescription: yup.string().required(i18nClient.t('Validation.Required')),
    laborerTemporalResidence: yup.string().required(i18nClient.t('Validation.Required')),
    socialWorkerRecommendation: yup.string().required(i18nClient.t('Validation.Required')),
  });
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 128,
    position: 'relative',
    width: '100%',
  },
  rootWithOverflow: {
    flexShrink: 1,
  },
  spinnerWrapper: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shortest,
      easing: 'ease-out',
    }),
  },
  contentLoading: {
    opacity: 0.5,
  },
}));

import { has } from 'lodash';
import { Query, HttpAPIError, GeneralErrorStatus, InternalErrorStatus } from './types';
import { DEFAULT_PAGE_SIZE } from './constants';

// NOTE: backend uses 0 indexed pagination, but on frontend side we need to starting counting from 1
export const normalizeQueryParams = (query?: Query): Query | null => {
  if (query) {
    return {
      ...query,
      pageNo: query.pageNo ? query.pageNo - 1 : 0,
      pageSize: query.pageSize ? query.pageSize : DEFAULT_PAGE_SIZE,
    };
  }

  return null;
};

export const isAPIError = (error: unknown): error is HttpAPIError => {
  return has(error, 'data.apierror');
};

export const isErrorEqualTo = (
  error: HttpAPIError,
  status: GeneralErrorStatus,
  internalStatus: InternalErrorStatus
): boolean => {
  const { apierror } = error.data;

  if (apierror) {
    return (
      apierror.httpStatus === status &&
      (apierror.internalStatus === internalStatus || apierror.internalStatus.endsWith(internalStatus))
    );
  }

  return false;
};

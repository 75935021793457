import { from, of } from 'rxjs';
import { switchMap, mergeMap, map, catchError, filter } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import { apiClient } from 'root';
import { routes } from 'const';
import { ofType, Action } from 'services/store';
import { i18nClient } from 'services/translation-client';
import { normalizeQueryParams, Paginated } from 'modules/http';
import { SharedAction } from 'modules/shared';
import { EwaCenterAction, EwaCenterActionType } from './actions';
import { EwaCenterShortResponse, EwaCenterDetailsResponse } from './types';
import { prepareEwaCenterErrorMessage } from './helpers';

export const fetchEwaCentersEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.FetchEwaCenters),
    switchMap(({ payload }) =>
      from(
        apiClient.get<Paginated<EwaCenterShortResponse>>('/ewacenters/search', {
          params: normalizeQueryParams(payload.query),
        })
      ).pipe(
        map(({ data }) => EwaCenterAction.fetchEwaCentersSuccess(data)),
        catchError(() =>
          of(
            EwaCenterAction.fetchEwaCentersFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const fetchEwaListEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.FetchEwaList),
    switchMap(({ payload }) =>
      from(
        apiClient.get<Paginated<EwaCenterShortResponse>>('/ewacenters/search', {
          params: normalizeQueryParams(payload.query),
        })
      ).pipe(
        map(({ data }) => EwaCenterAction.fetchEwaListSuccess(data)),
        catchError(() =>
          of(
            EwaCenterAction.fetchEwaListFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const fetchEwaCenterEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.FetchEwaCenter),
    switchMap(({ payload }) =>
      from(apiClient.get<EwaCenterDetailsResponse>(`/ewacenters/${payload.ewaCenterId}`)).pipe(
        map(({ data }) => EwaCenterAction.fetchEwaCenterSuccess(data)),
        catchError(() =>
          of(
            EwaCenterAction.fetchEwaCenterFailure()
            // SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const postEwaCenterEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.PostEwaCenter),
    switchMap(({ payload }) =>
      from(apiClient.post('/ewacenters', { data: payload })).pipe(
        map(() => EwaCenterAction.postEwaCenterSuccess()),
        catchError(error =>
          of(
            EwaCenterAction.postEwaCenterFailure(),
            SharedAction.notifyUser(i18nClient.t(prepareEwaCenterErrorMessage(error)), 'error')
          )
        )
      )
    )
  );

export const postEwaCenterSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.PostEwaCenterSuccess),
    mergeMap(() =>
      of(SharedAction.notifyUser(i18nClient.t('EwaCenter:AddSubmitSuccess')), push(routes.ewaCenter.main.path))
    )
  );

export const updateEwaCenterEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.UpdateEwaCenter),
    switchMap(({ payload }) =>
      from(apiClient.put('/ewacenters', { data: payload })).pipe(
        mergeMap(() => of(EwaCenterAction.updateEwaCenterSuccess(), push(routes.ewaCenter.main.path))),
        catchError(error =>
          of(
            EwaCenterAction.updateEwaCenterFailure(),
            SharedAction.notifyUser(i18nClient.t(prepareEwaCenterErrorMessage(error)), 'error')
          )
        )
      )
    )
  );

export const selectEwaCenterEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(EwaCenterActionType.SelectEwaCenter),
    filter(({ payload }) => !payload.skipRedirect),
    map(({ payload }) =>
      push({ pathname: routes.laborerCases.main.path, search: stringify({ ewaCenterId: payload.ewaCenterId }) })
    )
  );

export const ewaCenterEpics = combineEpics(
  fetchEwaCentersEpic,
  fetchEwaCenterEpic,
  postEwaCenterEpic,
  postEwaCenterSuccessEpic,
  updateEwaCenterEpic,
  selectEwaCenterEpic,
  fetchEwaListEpic
);

import React from 'react';
import { Box, Typography, Chip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'services';
import { useStyles } from './FilterList.styles';

type FilterListProps = {
  entries: string[][];
  ignores: string[];
  entriesTranslationMap?: Record<string, string>;
  onDelete: (name: string) => void;
};

export const FilterList: React.FC<FilterListProps> = ({ entries, ignores, entriesTranslationMap, onDelete }) => {
  const styles = useStyles();
  const { tBasic, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  if (entries.length < 1) {
    return null;
  }

  const pickFilterName = (name: string) => (entriesTranslationMap ? entriesTranslationMap[name] : name);

  return (
    <Box display="flex" marginBottom={2}>
      {entries
        .filter(([name]) => ignores.indexOf(name) === -1)
        .map(([name, label, labelEn]) => (
          <Box key={name} display="flex" alignItems="center" justifyContent="flexStart" marginRight="15px">
            <Typography style={{ marginRight: '10px' }} color="textSecondary">
              {tBasic(pickFilterName(name))}:
            </Typography>
            <Chip
              classes={styles}
              color="primary"
              label={isArabic || !labelEn ? label : labelEn}
              onDelete={() => onDelete(name)}
              deleteIcon={<HighlightOffIcon />}
            />
          </Box>
        ))}
    </Box>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { LaborerCaseResponse, isReopenWindowAvailable } from 'modules/laborer-case';
import { hasUserRoles, UserRole, selectUserId } from 'modules/user';
import { useTranslation } from 'services';
import { useStyles } from './CaseActionButton.styles';
import {
  PrintActionButton,
  ReopenActionButton,
  CloseActionButton,
  StartAssessmentActionButton,
  ReleaseCaseActionButton,
} from './components';

type CaseActionButtonProps = {
  laborerCase: LaborerCaseResponse;
  type: 'button' | 'link';
};

export const CaseActionButton: React.FC<CaseActionButtonProps> = ({ laborerCase, type }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const hasRoles = useSelector(hasUserRoles);
  const userId = useSelector(selectUserId);

  const isCaseAlreadyReserved = isNil(laborerCase.reservedByUserId) ? false : laborerCase.reservedByUserId !== userId;

  const isExportVisible =
    (laborerCase.oldCaseFlow &&
      hasRoles([UserRole.SocialWorker]) &&
      ['PENDING_CLEARANCE', 'CLOSED', 'REOPENED'].includes(laborerCase.status)) ||
    (!laborerCase.oldCaseFlow &&
      hasRoles([UserRole.SocialWorker, UserRole.LaborCommittee]) &&
      ['PENDING_LC_REVIEW', 'PENDING_CLEARANCE', 'CLOSED', 'REOPENED'].includes(laborerCase.status));

  const isExitFormVisible = hasRoles([UserRole.Registrar]) && laborerCase.status === 'CLOSED';

  const isCloseVisible = hasRoles([UserRole.Registrar]) && laborerCase.status === 'PENDING_CLEARANCE';

  const isStartAssessmentVisible = hasRoles([UserRole.SocialWorker]) && laborerCase.status === 'PENDING_SW_REVIEW';

  const isReleaseCaseVisible = hasRoles([UserRole.EwaCenterManager]) && !!laborerCase.reservedByUserId;

  if (isExportVisible) {
    return (
      <PrintActionButton
        laborerCaseId={laborerCase.id}
        type={type}
        className={styles.actionButton}
        printType="CASE_SUMMARY"
        label={t(['Assessment', 'Result'])}
      />
    );
  }

  if (isExitFormVisible && laborerCase.laborerCaseClosingDetails) {
    const { exitDateTime } = laborerCase.laborerCaseClosingDetails;
    const isReopenVisible = !laborerCase.successorExists && isReopenWindowAvailable(exitDateTime);

    return (
      <>
        {isReopenVisible && (
          <ReopenActionButton
            laborerCaseId={laborerCase.id}
            type={type}
            className={styles.actionButton}
            disabled={isCaseAlreadyReserved}
            exitDateTime={exitDateTime}
          />
        )}
        <PrintActionButton
          laborerCaseId={laborerCase.id}
          type={type}
          className={styles.actionButton}
          printType="EXIT_FORM"
          label={t(['Common', 'Print'])}
        />
      </>
    );
  }

  if (isCloseVisible) {
    return (
      <CloseActionButton
        laborerCaseId={laborerCase.id}
        type={type}
        className={styles.actionButton}
        disabled={isCaseAlreadyReserved}
      />
    );
  }

  if (isStartAssessmentVisible) {
    return (
      <StartAssessmentActionButton
        laborerCaseId={laborerCase.id}
        type={type}
        className={styles.actionButton}
        disabled={isCaseAlreadyReserved}
      />
    );
  }

  if (isReleaseCaseVisible) {
    return (
      <ReleaseCaseActionButton
        laborerCaseId={laborerCase.id}
        type={type}
        className={styles.actionButton}
        disabled={false}
      />
    );
  }

  return null;
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>(theme => ({
  root: {
    fontSize: theme.spacing(2) + 2,
    position: 'static',
  },
  shrink: {
    transform: 'none',
  },
  formControl: {
    marginBottom: '6px',
  },
}));

import React from 'react';
import MuiFormHelperText, { FormHelperTextProps as MuiFormHelperTextProps } from '@material-ui/core/FormHelperText';
import { useStyles } from './FormHelperText.styles';

export type FormHelperTextProps = PropsWithDataTestId<MuiFormHelperTextProps> & {
  noHint?: boolean;
};

export const FormHelperText: React.FC<FormHelperTextProps> = ({ classes, dataTestId, ...props }) => {
  const styles = useStyles();

  return (
    <MuiFormHelperText classes={{ ...styles, ...classes }} data-test-id={dataTestId} variant="standard" {...props} />
  );
};

import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Paper, { PaperProps } from '@material-ui/core/Paper';

type CardProps = PaperProps & {
  badge?: React.ReactNode;
  className?: string;
};

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    padding: theme.spacing(3),
  },
  badge: {
    position: 'absolute',
    left: '60px',
    top: 0,
    transform: 'translateY(-50%)',
  },
}));

export const Card: React.FC<CardProps> = ({ children, badge, className, ...paperProps }) => {
  const styles = useStyles();

  return (
    <Paper className={cn(className, styles.card)} {...paperProps}>
      {badge && <div className={styles.badge}>{badge}</div>}
      {children}
    </Paper>
  );
};

import { Action } from 'services/store';
import { LaborerActionType } from './actions';
import { LaborerState } from './state';

export const laborerState: LaborerState = {
  laborerCase: {
    data: null,
    status: 'default',
  },
  laborerCasesList: {
    data: [],
    count: 0,
    status: 'default',
  },
  postCaseStatus: 'default',
  updateCaseStatus: 'default',
  reservationStatus: 'default',
  reservedCaseId: null,
};

export function laborerReducer(state = laborerState, action: Action): LaborerState {
  switch (action.type) {
    /**
     * FetchLaborerCase
     */
    case LaborerActionType.FetchLaborerCase:
      return {
        ...state,
        laborerCase: {
          ...state.laborerCase,
          status: 'pending',
        },
      };

    case LaborerActionType.FetchLaborerCaseSuccess:
      return {
        ...state,
        laborerCase: {
          data: action.payload,
          status: 'success',
        },
      };

    case LaborerActionType.FetchLaborerCaseFailure:
      return {
        ...state,
        laborerCase: {
          ...state.laborerCase,
          status: 'failure',
        },
      };

    /**
     * FetchLaborerCases
     */
    case LaborerActionType.FetchLaborerCases:
      return {
        ...state,
        laborerCasesList: {
          ...state.laborerCasesList,
          status: 'pending',
        },
      };

    case LaborerActionType.FetchLaborerFilteredCases:
      return {
        ...state,
        laborerCasesList: {
          ...state.laborerCasesList,
          status: 'pending',
        },
      };

    case LaborerActionType.FetchLaborerCasesSuccess:
      return {
        ...state,
        laborerCasesList: {
          data: action.payload.cases.results,
          count: action.payload.cases.count,
          status: 'success',
        },
      };

    case LaborerActionType.FetchLaborerCasesFailure:
      return {
        ...state,
        laborerCasesList: {
          ...state.laborerCasesList,
          status: 'failure',
        },
      };

    /**
     * PostLaborerCase
     */
    case LaborerActionType.PostLaborerCase:
      return {
        ...state,
        postCaseStatus: 'pending',
      };

    case LaborerActionType.PostLaborerCaseSuccess:
      return {
        ...state,
        postCaseStatus: 'success',
      };

    case LaborerActionType.PostLaborerCaseFailure:
      return {
        ...state,
        postCaseStatus: 'failure',
      };

    /**
     * UpdateLaborerCase
     */
    case LaborerActionType.UpdateLaborerCase:
      return {
        ...state,
        updateCaseStatus: 'pending',
      };

    case LaborerActionType.UpdateLaborerCaseSuccess:
      return {
        ...state,
        updateCaseStatus: 'success',
      };

    case LaborerActionType.UpdateLaborerCaseFailure:
      return {
        ...state,
        updateCaseStatus: 'failure',
      };

    /**
     * ReserveLaborerCase
     */
    case LaborerActionType.ReserveLaborerCase:
      return {
        ...state,
        reservationStatus: 'pending',
      };

    case LaborerActionType.ReserveLaborerCaseSuccess:
      return {
        ...state,
        reservationStatus: 'success',
      };

    case LaborerActionType.ReserveLaborerCaseFailure:
      return {
        ...state,
        reservationStatus: 'failure',
      };

    case LaborerActionType.SaveReservedCaseId:
      return {
        ...state,
        reservedCaseId: action.payload.laborerCaseId,
      };

    case LaborerActionType.ClearReservedCaseId:
      return {
        ...state,
        reservedCaseId: null,
      };

    /**
     * ResetLaborerCaseDetails
     */
    case LaborerActionType.ResetLaborerCaseDetails:
      return {
        ...state,
        laborerCase: {
          ...laborerState.laborerCase,
        },
      };

    default:
      return state;
  }
}

import * as yup from 'yup';
import { addLaborerSchema, AddLaborerFormData } from 'components';
import { toUTCISOString } from 'services';
import moment from 'moment';
import {
  getOccupationNameByCode,
  prepareFormPayload,
  RegistrarClassificationType,
  ReferralTypeDictionaryUnion,
  HostingTypeDictionaryUnion,
  NationalityType,
  OccupationResponse,
} from 'modules/shared';
import { CreateLaborerCaseRequest } from '../types';

const createLaborerCaseSchema = () => {
  return yup
    .object<AddLaborerFormData>()
    .concat(addLaborerSchema())
    .shape({
      laborerNumber: yup.number(),
      laborerNationality: yup.mixed<NationalityType>(),
      employerId: yup.number(),
      registrarClassification: yup.mixed<RegistrarClassificationType>(),
      referredBy: yup.mixed<ReferralTypeDictionaryUnion>(),
      hostingType: yup.mixed<HostingTypeDictionaryUnion | null>(),
      laborTraffickingStudied: yup.boolean(),
      workerSupported: yup.boolean(),
      lastEntryDate: yup.string().defined().nullable(),
    })
    .defined();
};

export const buildCreateLaborerCaseDto = (
  formData: AddLaborerFormData,
  uploadedFileNames: string[],
  occupations: OccupationResponse[]
): CreateLaborerCaseRequest => {
  const { laborerOccupationCode, ...data } = createLaborerCaseSchema().cast(prepareFormPayload(formData));
  const laborerOccupation = getOccupationNameByCode(occupations, laborerOccupationCode);
  const DaysBetweenValue = moment(new Date()).diff(formData.arrivedDate, 'days');
  return {
    ...data,
    laborerOccupation,
    uploadedFileNames,
    joiningDate: toUTCISOString(new Date()),
    DaysBetween: DaysBetweenValue.toString(),
  };
};

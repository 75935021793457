export const formatFormData = (data: any): any =>
  Object.keys(data).reduce((accu, fieldName: string) => {
    let value = data[fieldName];
    if (value === 'true') {
      value = true;
    } else if (value === 'false') {
      value = false;
    }
    if (fieldName === 'workDurationInMonths') {
      value = parseInt(value, 10);
    }

    return {
      ...accu,
      [fieldName]: value,
    };
  }, {});

import { makeStyles, Theme } from '@material-ui/core/styles';

// TODO: remove the important rule as soon as we solve the global specificity issue

export const useStyles = makeStyles((theme: Theme) => ({
  hiddenField: {
    display: 'none !important',
  },
  quotaCard: {
    padding: `2px 10px`,
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'center',
    marginBlockStart: '20px',
    fontSize: '1rem !important',
    alignSelf: 'flex-start',
    margin: '0px !important',
    marginTop: '2px',
  },
  quotaNumber: {
    fontSize: '1rem !important',
  },
  title: {
    color: 'white',
    fontSize: '1rem !important',
  },
  row: {
    justifyContent: 'space-between',
  },
  box: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    width: '330px',
  },
}));

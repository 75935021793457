import { captureException } from '@sentry/react';
import { wait } from 'services/http-client';

enum WebSocketConnectionStatus {
  Connecting,
  Open,
  Closing,
  Closed,
  Uninitialized,
}

export const ABNORMAL_CLOSURE = 1006;

type WebSocketClientOptions = {
  healthCheck?: boolean;
  autoReconnect?: boolean;
  packMessage?: (data: any) => string;
  unpackMessage?: (data: any) => any;
};

type SendMessage<T> = {
  data: T | ArrayBufferLike | Blob | ArrayBufferView;
  options?: {
    retryLimit?: number;
    retryDelay?: number;
  };
};

export class WebSocketClient {
  private readonly baseUrl: string;
  private readonly options: WebSocketClientOptions;
  private intervalId: NodeJS.Timeout | null = null;
  private path: string | null = null;
  private socket: WebSocket | null = null;
  private reconnectRetries = 5;

  constructor(baseUrl: string, options: WebSocketClientOptions) {
    this.baseUrl = baseUrl;
    this.options = options;
  }

  private async reconnect(): Promise<void> {
    this.reconnectRetries -= 1;

    await wait(10000);

    if (this.path && this.reconnectRetries !== 0) {
      this.connect(this.path);
    }
  }

  private clearHeartbeat(): void {
    if (this.options.healthCheck && this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public connect(path: string): void {
    this.path = path;

    if (!this.socket) {
      this.socket = new WebSocket(this.baseUrl + path);

      if (this.options.autoReconnect) {
        this.socket.onclose = event => {
          if (event.code === ABNORMAL_CLOSURE) {
            this.reconnect();
          }
        };
      }

      if (this.options.healthCheck) {
        this.socket.onopen = () => {
          this.intervalId = setInterval(() => {
            this.send({ data: { eventType: 'HEARTBEAT' } });
          }, 30000);
        };
      }
    }

    if (this.socket && this.socket.readyState === WebSocketConnectionStatus.Closed) {
      const newSocket = new WebSocket(this.baseUrl + path);

      newSocket.onopen = this.socket.onopen;
      newSocket.onclose = this.socket.onclose;
      newSocket.onmessage = this.socket.onmessage;
      newSocket.onerror = this.socket.onerror;

      this.socket = newSocket;
    }
  }

  public getStatus(): WebSocketConnectionStatus {
    if (this.socket) {
      return this.socket.readyState;
    }

    return WebSocketConnectionStatus.Uninitialized;
  }

  public send<T extends Record<string, any>>({ data, options }: SendMessage<T>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.socket) {
        if (this.socket.readyState === WebSocketConnectionStatus.Open) {
          const payload = this.options.packMessage
            ? this.options.packMessage(data)
            : (data as ArrayBufferLike | Blob | ArrayBufferView);

          resolve(this.socket.send(payload));
        } else if (options && options.retryLimit && this.socket.readyState === WebSocketConnectionStatus.Connecting) {
          setTimeout(() => {
            resolve(
              this.send({
                data,
                options: {
                  retryLimit: options.retryLimit! - 1,
                  retryDelay: options.retryDelay,
                },
              })
            );
          }, options.retryDelay);
        }
      } else {
        reject(new Error('WebSocket connection is not open'));
      }
    });
  }

  public close(code?: number, reason?: string): void {
    if (this.socket && this.socket.readyState === WebSocketConnectionStatus.Open) {
      this.socket.close(code, reason);
      this.clearHeartbeat();
    } else {
      throw new Error('WebSocket connection is not open');
    }
  }

  public onMessage(eventHandler: (event: MessageEvent) => void): void {
    if (this.socket) {
      this.socket.onmessage = event => {
        eventHandler({
          ...event,
          data: this.options.unpackMessage ? this.options.unpackMessage(event.data) : event.data,
        });
      };
    } else {
      throw new Error('WebSocket instance is not present');
    }
  }

  public onOpen(eventHandler: (event: Event) => void): void {
    if (this.socket) {
      this.socket.onopen = event => {
        eventHandler(event);
        this.reconnectRetries = 5;
      };
    } else {
      throw new Error('WebSocket instance is not present');
    }
  }

  public onClose(eventHandler: (event: CloseEvent) => void): void {
    if (this.socket) {
      this.socket.onclose = event => {
        eventHandler(event);
        this.clearHeartbeat();

        if (this.options.autoReconnect && event.code === ABNORMAL_CLOSURE) {
          this.reconnect();
        }
      };
    } else {
      throw new Error('WebSocket instance is not present');
    }
  }

  public onError(eventHandler: (event: Event) => void): void {
    if (this.socket) {
      this.socket.onerror = event => {
        captureException(event);
        eventHandler(event);
      };
    } else {
      throw new Error('WebSocket instance is not present');
    }
  }
}

import { createAction } from 'services/store/create-action';
import { RequestConfig } from 'services/api-client';
import { Paginated } from 'modules/http';
import { UsersQuery } from './query';
import {
  CreateBasicUserRequest,
  ExtendedUser,
  Role,
  Privilege,
  UserDetailsResponse,
  EditUserPrivilege,
  UserPoints,
} from './types';

export enum UserActionType {
  FetchUsers = '[User]: Fetch users',
  FetchUsersSuccess = '[User]: Fetch users - success',
  FetchUsersFailure = '[User]: Fetch users - failure',

  FetchLoggedUserDetails = '[User]: Fetch logged user details',
  FetchLoggedUserDetailsSuccess = '[User]: Fetch logged user details - success',
  FetchLoggedUserDetailsFailure = '[User]: Fetch logged user details - failure',
  FetchLoggedUserDetailsNotFound = '[User]: Fetch logged user details - not found',
  FetchLoggedUserDetailsNetworkConnection = '[User]: Fetch logged user details - network connection',
  FetchLoggedUserDetailsStartPooling = '[User]: Fetch logged user details - start pooling',

  PostUser = '[User]: Post user',
  PostUserSuccess = '[User]: Post user - success',
  PostUserFailure = '[User]: Post user - failure',

  UpdateUser = '[User] Update user',
  UpdateUserSuccess = '[User] Update user - success',
  UpdateUserFailure = '[User] Update user - failure',

  InviteUser = '[User] Invite user',
  InviteUserSuccess = '[User] Invite user - success',
  InviteUserFailure = '[User] Invite user - failure',

  FetchRoles = '[User]: Fetch roles',
  FetchRolesSuccess = '[User]: Fetch roles - success',
  FetchRolesFailure = '[User]: Fetch roles - failure',

  FetchPrivileges = '[User]: Fetch privileges',
  FetchPrivilegesSuccess = '[User]: Fetch privileges - success',
  FetchPrivilegesFailure = '[User]: Fetch privileges - failure',

  FetchUser = '[User]: Fetch user',
  FetchUserSuccess = '[User]: Fetch user - success',
  FetchUserFailure = '[User]: Fetch user - failure',

  UpdateUserPrivilege = '[User]: Update user privilege',
  UpdateUserPrivilegeSuccess = '[User]: Update user privilege - success',
  UpdateUserPrivilegeFailure = '[User]: Update user privilege - failure',

  ResetUserDetails = '[User]: Reset user details',

  DeleteUser = '[User]: Delete user',
  DeleteUserSuccess = '[User]: Delete user - success',
  DeleteUserFailure = '[User]: Delete user - failure',

  FetchUserPoints = '[User]: Fetch user points',
  FetchUserPointsSuccess = '[User]: Fetch user points - success',
  FetchUserPointsFailure = '[User]: Fetch user points - failure',
}

export const UserAction = {
  fetchUsers: (query?: UsersQuery) => createAction(UserActionType.FetchUsers, { query }),
  fetchUsersSuccess: (users: Paginated<ExtendedUser>) => createAction(UserActionType.FetchUsersSuccess, { users }),
  fetchUsersFailure: () => createAction(UserActionType.FetchUsersFailure),

  fetchLoggedUserDetails: (requestOptions?: RequestConfig) =>
    createAction(UserActionType.FetchLoggedUserDetails, { requestOptions }),
  fetchLoggedUserDetailsSuccess: (userDetails: ExtendedUser) =>
    createAction(UserActionType.FetchLoggedUserDetailsSuccess, { userDetails }),
  fetchLoggedUserDetailsFailure: () => createAction(UserActionType.FetchLoggedUserDetailsFailure),
  fetchLoggedUserDetailsNotFound: () => createAction(UserActionType.FetchLoggedUserDetailsNotFound),
  fetchLoggedUserDetailsNetworkConnection: () => createAction(UserActionType.FetchLoggedUserDetailsNetworkConnection),
  fetchLoggedUserDetailsStartPooling: () => createAction(UserActionType.FetchLoggedUserDetailsStartPooling),

  postUser: (data: CreateBasicUserRequest) => createAction(UserActionType.PostUser, data),
  postUserSuccess: () => createAction(UserActionType.PostUserSuccess),
  postUserFailure: () => createAction(UserActionType.PostUserFailure),

  updateUser: (data: ExtendedUser) => createAction(UserActionType.UpdateUser, data),
  updateUserSuccess: () => createAction(UserActionType.UpdateUserSuccess),
  updateUserFailure: () => createAction(UserActionType.UpdateUserFailure),

  inviteUser: (userId: ExtendedUser['id']) => createAction(UserActionType.InviteUser, { userId }),
  inviteUserSuccess: () => createAction(UserActionType.InviteUserSuccess),
  inviteUserFailure: () => createAction(UserActionType.InviteUserFailure),

  fetchRoles: () => createAction(UserActionType.FetchRoles),
  fetchRolesSuccess: (roles: Role[]) => createAction(UserActionType.FetchRolesSuccess, { roles }),
  fetchRolesFailure: () => createAction(UserActionType.FetchRolesFailure),

  fetchPrivileges: () => createAction(UserActionType.FetchPrivileges),
  fetchPrivilegesSuccess: (privileges: Privilege[]) =>
    createAction(UserActionType.FetchPrivilegesSuccess, { privileges }),
  fetchPrivilegesFailure: () => createAction(UserActionType.FetchPrivilegesFailure),

  fetchUser: (userId: string) => createAction(UserActionType.FetchUser, { userId }),
  fetchUserSuccess: (data: UserDetailsResponse) => createAction(UserActionType.FetchUserSuccess, { data }),
  fetchUserFailure: () => createAction(UserActionType.FetchUserFailure),

  updateUserPrivilege: (id: string, privileges: EditUserPrivilege) =>
    createAction(UserActionType.UpdateUserPrivilege, { id, privileges }),
  updateUserPrivilegeSuccess: (id: string, privileges: EditUserPrivilege) =>
    createAction(UserActionType.UpdateUserPrivilegeSuccess, { id, privileges }),
  updateUserPrivilegeFailure: () => createAction(UserActionType.UpdateUserPrivilegeFailure),

  resetUserDetails: () => createAction(UserActionType.ResetUserDetails),

  deleteUser: (userId: string) => createAction(UserActionType.DeleteUser, { userId }),
  deleteUserSuccess: () => createAction(UserActionType.DeleteUserSuccess),
  deleteUserFailure: () => createAction(UserActionType.DeleteUserFailure),

  fetchUserPoints: () => createAction(UserActionType.FetchUserPoints),
  fetchUserPointsSuccess: (userPoints: UserPoints) =>
    createAction(UserActionType.FetchUserPointsSuccess, { userPoints }),
  fetchUserPointsFailure: () => createAction(UserActionType.FetchUserPointsFailure),
};

export type UserActionCreator = typeof UserAction;

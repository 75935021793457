import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isString } from 'lodash';
import { AppBar, Box, Toolbar, IconButton, MenuItem, MenuList, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  useUsersManagement,
  isUserInactive,
  hasUserRoles,
  selectUserRoles,
  getArabicUserRoleName,
  getEnglishUserRoleName,
  UserRole,
  UserStatus,
  selectUserPoints,
} from 'modules/user';
import { useEwaCenterManagement, selectSelectedEwaCenterId, getEwaCenterNameFromId } from 'modules/ewa-center';
import { useTranslation, ISO6391Code } from 'services';
import { routes, REPLACE_SIGN } from 'const';
import { KEYCLOAK_SSO_ACCOUNT_SETTINGS_URL, isStaging } from 'config';
import MusanedLogo from 'assets/images/musaned-logo.svg';
import TranslationLogo from 'assets/images/translation-logo.svg';
import { UserIcon, BundlesIcon } from 'assets/images';
import { DropdownPopper } from 'components';
import { useStyles } from './AppPanel.styles';

type AppPanelProps = {
  onLogout: () => void;
};

export const AppPanel: React.FC<AppPanelProps> = ({ onLogout }) => {
  const history = useHistory();
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(ISO6391Code.arabic);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { ewaCenters, fetchEwaCenters, ewaCenter } = useEwaCenterManagement();
  const { loggedUser, fetchUserPoints } = useUsersManagement();
  const userPoints = useSelector(selectUserPoints);
  const hasRoles = useSelector(hasUserRoles);
  const userRoles = useSelector(selectUserRoles);
  const selectedEwaCenterIdByMLSD = useSelector(selectSelectedEwaCenterId);
  const ewaCenterType = ewaCenter?.ewaCenterType;

  const fullName = loggedUser ? `${loggedUser.firstName} ${loggedUser.lastName}` : '';
  const userRoleName = loggedUser ? getArabicUserRoleName(userRoles, loggedUser.userRole) : REPLACE_SIGN;
  const userRoleEnglishName = loggedUser ? getEnglishUserRoleName(userRoles, loggedUser.userRole) : REPLACE_SIGN;
  const isArabic = i18n.language === 'ar';
  const loggedUserEwaCenterName = loggedUser && loggedUser.ewaCenterName;
  const ewaCenterName = loggedUserEwaCenterName || getEwaCenterNameFromId(ewaCenters, selectedEwaCenterIdByMLSD);
  const isUserLoggedAndActive = loggedUser && loggedUser.status === UserStatus.Active;

  const toggleLanguage = (): string => {
    return language === ISO6391Code.arabic ? ISO6391Code.english : ISO6391Code.arabic;
  };

  const handleLanguageToggle = (): void => {
    setLanguage(toggleLanguage);
  };

  const handleRedirectToAccountManagment = (): void => {
    window.open(KEYCLOAK_SSO_ACCOUNT_SETTINGS_URL);
  };

  const handleRedirectToSwitchCenter = (): void => {
    history.push(routes.ewaCenter.selection.path);
  };

  const handleRedirectToBundles = (): void => {
    history.push(routes.bundles.main.path);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (hasRoles([UserRole.MLSD])) {
      fetchEwaCenters({ pageSize: 999 });
    }

    if (isStaging() && hasRoles([UserRole.EwaCenterManager]) && ewaCenterType?.toString() === '4') {
      fetchUserPoints();
    }
  }, [ewaCenterType]);

  return (
    <AppBar className={styles.appBar} position="relative" color="inherit">
      <Toolbar className={styles.toolbar} disableGutters>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center" marginLeft={5} marginRight={5}>
            <img width="146" height="48" src={MusanedLogo} />
            {isUserLoggedAndActive && !hasRoles([UserRole.Admin]) && (
              <Typography className={styles.ewaCenterName} color="primary">
                {ewaCenterName}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="row">
            <Box style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: '1rem' }}>
              <IconButton color="primary" size="small" onClick={handleLanguageToggle}>
                <img width="35" height="20" src={TranslationLogo} />
                {toggleLanguage() === 'ar' ? 'العربية' : 'English'}
              </IconButton>
              {isStaging() && hasRoles([UserRole.EwaCenterManager]) && ewaCenterType?.toString() === '4' && (
                <IconButton color="primary" size="small" onClick={handleRedirectToBundles}>
                  <BundlesIcon color="disabled" style={{ marginTop: '5px' }} />
                  <div style={{ marginLeft: '3px', marginRight: '5px' }}>
                    <Typography color="primary"> {userPoints}</Typography>
                  </div>
                </IconButton>
              )}
            </Box>
            {isUserLoggedAndActive && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginRight={2}
                  marginLeft={2}
                  className={styles.userIconBg}
                >
                  <UserIcon color="primary" />
                </Box>
                <Box display="flex" flexDirection="column" marginRight={1}>
                  <Typography color="primary">{fullName}</Typography>
                  <Typography color="primary">{isArabic ? userRoleName : userRoleEnglishName}</Typography>
                </Box>
              </Box>
            )}
            <Box>
              <IconButton color="primary" onClick={handleOpen}>
                <MoreVertIcon />
              </IconButton>
              <DropdownPopper anchorEl={anchorEl} handleClose={handleClose}>
                <MenuList className={styles.menuDropdownList} onClick={handleClose}>
                  {hasRoles([UserRole.MLSD]) && (
                    <MenuItem onClick={handleRedirectToSwitchCenter}>{t(['Common', 'SwitchEWACenter'])}</MenuItem>
                  )}

                  {!(loggedUser && isUserInactive(loggedUser.status)) && (
                    <MenuItem onClick={handleRedirectToAccountManagment}>{t(['Common', 'AccountSettings'])}</MenuItem>
                  )}

                  <MenuItem onClick={onLogout}>{t(['Common', 'Logout'])}</MenuItem>
                </MenuList>
              </DropdownPopper>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

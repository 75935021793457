import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusPending } from 'services/store';
import { SharedAction } from '../actions';
import {
  selectRegistrarCaseClassifications,
  selectCommitteCaseClassifications,
  selectReferralTypes,
  selectHostingTypes,
  selectNationalities,
  selectAuthorityTypes,
  selectLaborerOccupations,
} from '../selectors';

// TODO: check useCallback dependency list correctness

export const useSharedDictionaries = () => {
  const dispatch = useDispatch();

  const registrarCaseClassifications = useSelector(selectRegistrarCaseClassifications);
  const committeeCaseClassifications = useSelector(selectCommitteCaseClassifications);
  const referralTypes = useSelector(selectReferralTypes);
  const hostingTypes = useSelector(selectHostingTypes);
  const nationalities = useSelector(selectNationalities);
  const authorityTypes = useSelector(selectAuthorityTypes);
  const laborerOccupations = useSelector(selectLaborerOccupations);

  const isLoading =
    isStatusPending(registrarCaseClassifications.status) ||
    isStatusPending(committeeCaseClassifications.status) ||
    isStatusPending(referralTypes.status) ||
    isStatusPending(hostingTypes.status) ||
    isStatusPending(nationalities.status) ||
    isStatusPending(authorityTypes.status) ||
    isStatusPending(laborerOccupations.status);

  const fetchRegistrarCaseClassifications = useCallback(() => {
    dispatch(SharedAction.fetchRegistrarCaseClassifications());
  }, [dispatch]);

  const fetchLaborCommitteCaseClassifications = useCallback(() => {
    dispatch(SharedAction.fetchLaborCommitteCaseClassifications());
  }, [dispatch]);

  const fetchReferralTypes = useCallback(() => {
    dispatch(SharedAction.fetchReferralTypes());
  }, [dispatch]);

  const fetchHostingTypes = useCallback(() => {
    dispatch(SharedAction.fetchHostingTypes());
  }, [dispatch]);

  const fetchNationalities = useCallback(() => {
    dispatch(SharedAction.fetchNationalities());
  }, [dispatch]);

  const fetchAuthorityTypes = useCallback(() => {
    dispatch(SharedAction.fetchAuthorityTypes());
  }, [dispatch]);

  const fetchLaborerOccupations = useCallback(() => {
    dispatch(SharedAction.fetchLaborerOccupations());
  }, [dispatch]);

  return {
    isLoading,

    registrarCaseClassifications: registrarCaseClassifications.data,
    committeeCaseClassifications: committeeCaseClassifications.data,
    referralTypeDictionary: referralTypes.dictionary,
    referralTypeOptions: referralTypes.options,
    hostingTypeDictionary: hostingTypes.dictionary,
    hostingTypeOptions: hostingTypes.options,
    nationalities: nationalities.data,
    authorityTypeDictionary: authorityTypes.dictionary,
    authorityTypeOptions: authorityTypes.options,
    laborerOccupations: laborerOccupations.data,

    fetchRegistrarCaseClassifications,
    fetchLaborCommitteCaseClassifications,
    fetchReferralTypes,
    fetchHostingTypes,
    fetchNationalities,
    fetchAuthorityTypes,
    fetchLaborerOccupations,
  };
};

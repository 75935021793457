import * as yup from 'yup';
import { i18nClient } from 'services';
import moment from 'moment';
import { isOtherSelected } from 'modules/shared';
import { ARABIC_PHONE_PATTERN, EMPLOYER_ID_PATTERN } from 'const';

export type CloseCaseFormData = {
  authority: string;
  authorityOther?: string | null;
  authorizedPersonName: string | null;
  authorizationNumber: string | null;
  endDate: Date | null;
  phoneNumber: string;
  employerId: string;

  financialClaimsHasBeenReceived: string;
  personalItemsHasBeenReceived: string;
  laborerSatisfaction: string;
};

export const closeCaseFormSchema = () => {
  return yup.object().shape<CloseCaseFormData>({
    authority: yup.string().required(i18nClient.t('Validation.Required')),
    authorityOther: yup
      .string()
      .nullable()
      .when('authority', {
        is: authority => isOtherSelected(authority),
        then: yup.string().required(i18nClient.t('Validation.Required')),
      }),
    authorizedPersonName: yup.string().defined().required(i18nClient.t('Validation.Required')),
    authorizationNumber: yup
      .string()
      .defined()
      .matches(/^[0-9]*$/, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(Case:AuthorizationNo)' }),
        excludeEmptyString: true,
      }),
    endDate: yup
      .date()
      .defined()
      .transform((date, momentDate) => {
        if (momentDate?.toDate) {
          return momentDate.toDate();
        }
        if (momentDate) {
          return momentDate;
        }
        return null;
      }),
    phoneNumber: yup
      .string()
      .matches(ARABIC_PHONE_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(MobileNo)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(MobileNo)' })),
    employerId: yup
      .string()
      .matches(EMPLOYER_ID_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:IdNoLocation)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Laborer:IdNoLocation)' })),
    financialClaimsHasBeenReceived: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    personalItemsHasBeenReceived: yup
      .mixed()
      .oneOf(['true', 'false'], i18nClient.t('Validation.Required'))
      .required(i18nClient.t('Validation.Required')),
    laborerSatisfaction: yup.string().required(i18nClient.t('Validation.Required')),
  });
};

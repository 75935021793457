import { i18nClient } from 'services';

type ReopenReasonOption = {
  value: string;
  label: string;
};

export const reopenReasonOptions: ReopenReasonOption[] = [
  {
    value: i18nClient.t('Case:ReopenReason.Option1'),
    label: i18nClient.t('Case:ReopenReason.Option1'),
  },
  {
    value: i18nClient.t('Case:ReopenReason.Option2'),
    label: i18nClient.t('Case:ReopenReason.Option2'),
  },
  {
    value: i18nClient.t('Case:ReopenReason.Option3'),
    label: i18nClient.t('Case:ReopenReason.Option3'),
  },
  {
    value: i18nClient.t('Case:ReopenReason.Option4'),
    label: i18nClient.t('Case:ReopenReason.Option4'),
  },
  {
    value: i18nClient.t('Case:ReopenReason.Option5'),
    label: i18nClient.t('Case:ReopenReason.Option5'),
  },
  {
    value: i18nClient.t('Case:ReopenReason.Option6'),
    label: i18nClient.t('Case:ReopenReason.Option6'),
  },
  {
    value: i18nClient.t('Case:ReopenReason.Option7'),
    label: i18nClient.t('Case:ReopenReason.Option7'),
  },
  {
    value: i18nClient.t('Other'),
    label: i18nClient.t('Other'),
  },
];

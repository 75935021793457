import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  deletableColorPrimary: {
    backgroundColor: theme.palette.primary.main40,
    color: theme.palette.text.primary,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
    margin: '0 6px 0 5px !important',
  },
}));

import React from 'react';
import { Link, Typography, CircularProgress } from '@material-ui/core';
import { usePrintView, PrintType } from 'modules/laborer-case';
import { Button } from 'components';

type PrintActionButtonProps = {
  type: 'button' | 'link';
  laborerCaseId: string;
  className?: string;
  label: string;
  printType: PrintType;
};

export const PrintActionButton: React.FC<PrintActionButtonProps> = ({
  laborerCaseId,
  type,
  className,
  printType,
  label,
}) => {
  const { handleOpenPrintView, pdfDocument } = usePrintView();
  const isLoading = pdfDocument.caseId === laborerCaseId && pdfDocument.generating;

  if (type === 'button') {
    return (
      <Button className={className} disabled={isLoading} onClick={() => handleOpenPrintView(laborerCaseId, printType)}>
        {isLoading ? <CircularProgress color="primary" size={20} /> : <Typography>{label}</Typography>}
      </Button>
    );
  }

  return isLoading ? (
    <CircularProgress className={className} color="primary" size={20} />
  ) : (
    <Link
      className={className}
      component="button"
      underline="always"
      onClick={() => handleOpenPrintView(laborerCaseId, printType)}
    >
      <Typography>{label}</Typography>
    </Link>
  );
};

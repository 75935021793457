export enum RegistrarClassification {
  HOSTING = 'HOSTING',
  FINANCIAL_CLAIM_AND_DEPORTATION = 'FINANCIAL_CLAIM_AND_DEPORTATION',
}

export type RegistrarClassificationType = keyof typeof RegistrarClassification;

export enum LaborCommitteeClassification {
  FINANCIAL_CLAIM = 'FINANCIAL_CLAIM',
  DEPORTATION = 'DEPORTATION',
}

export type LaborCommitteeClassificationType = keyof typeof LaborCommitteeClassification;

export enum ReferralType {
  POLICE_STATION = 'POLICE_STATION',
  EMBASSY = 'EMBASSY',
  PRO = 'PRO',
  EMPLOYER = 'EMPLOYER',
  INTERNAL_REFERRAL = 'INTERNAL_REFERRAL',
  AIRPORT = 'AIRPORT',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type ReferralTypeDictionaryUnion = keyof typeof ReferralType;
export type ReferralTypeOptionsUnion = Exclude<ReferralTypeDictionaryUnion, 'UNSPECIFIED'>;

export enum HostingType {
  NO_CASE = 'NO_CASE',
  FINANCIAL_CLAIMS_COURT = 'FINANCIAL_CLAIMS_COURT',
  FINANCIAL_CLAIMS_POLICE = 'FINANCIAL_CLAIMS_POLICE',
  FINANCIAL_CLAIMS_PUBLIC_PROSECUTION = 'FINANCIAL_CLAIMS_PUBLIC_PROSECUTION',
  DID_NOT_RECEIVE_HER_RIGHTS = 'DID_NOT_RECEIVE_HER_RIGHTS',
  LABORER_MUST_PAY_HER_EMPLOYER = 'LABORER_MUST_PAY_HER_EMPLOYER',
  DEPORTATION_PENDING_VISA_ISSUANCE = 'DEPORTATION_PENDING_VISA_ISSUANCE',
  DEPORTATION_PENDING_FLIGHT_BOOKING_PROCEDURES = 'DEPORTATION_PENDING_FLIGHT_BOOKING_PROCEDURES',
  FINANCIAL_CLAIMS_PENDING_VISA_ISSUANCE = 'FINANCIAL_CLAIMS_PENDING_VISA_ISSUANCE',
  PENDING_TRAVEL_DOCUMENTS = 'PENDING_TRAVEL_DOCUMENTS',
  PENDING_LC_CLASSIFICATION_DEPORTATION = 'PENDING_LC_CLASSIFICATION_DEPORTATION',
  PENDING_LC_CLASSIFICATION_FINANCIAL_CLAIMS = 'PENDING_LC_CLASSIFICATION_FINANCIAL_CLAIMS',
  PENDING_PROCESS_FINALIZATION = 'PENDING_PROCESS_FINALIZATION',
  LABORER_HAS_AN_OBJECTION_TO_LC_CLASSIFICATION = 'LABORER_HAS_AN_OBJECTION_TO_LC_CLASSIFICATION',
  ILLEGAL_IMMIGRATION_BEGGING = 'ILLEGAL_IMMIGRATION_BEGGING',
  WAITING_FOR_PICKUP_FROM_PRO = 'WAITING_FOR_PICKUP_FROM_PRO',
  WAITING_FOR_TRAVEL_DOCUMENT = 'WAITING_FOR_TRAVEL_DOCUMENT',
  WAITING_FOR_COMMITTEE_DECISION = 'WAITING_FOR_COMMITTEE_DECISION',
  WAITING_FOR_FINAL_EXIT_VISA_GRANTED = 'WAITING_FOR_FINAL_EXIT_VISA_GRANTED',
  WAITING_FOR_RESERVATION = 'WAITING_FOR_RESERVATION',
  WAITING_FOR_LEAVING = 'WAITING_FOR_LEAVING',
  WAITING_FOR_PAYMENT_DUES = 'WAITING_FOR_PAYMENT_DUES',
  WAITING_FOR_REFERRED_REQUESTING_PARTY = 'WAITING_FOR_REFERRED_REQUESTING_PARTY',
  FINANCIAL_CLAIMS_ON_WORKER = 'FINANCIAL_CLAIMS_ON_WORKER',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type HostingTypeDictionaryUnion = keyof typeof HostingType;
export type HostingTypeOptionsUnion = Exclude<HostingTypeDictionaryUnion, 'UNSPECIFIED'>;

export enum ActiveHostingType {
  WAITING_FOR_TRAVEL_DOCUMENT = 'WAITING_FOR_TRAVEL_DOCUMENT',
  WAITING_FOR_COMMITTEE_DECISION = 'WAITING_FOR_COMMITTEE_DECISION',
  WAITING_FOR_FINAL_EXIT_VISA_GRANTED = 'WAITING_FOR_FINAL_EXIT_VISA_GRANTED',
  WAITING_FOR_RESERVATION = 'WAITING_FOR_RESERVATION',
  WAITING_FOR_LEAVING = 'WAITING_FOR_LEAVING',
  WAITING_FOR_PAYMENT_DUES = 'WAITING_FOR_PAYMENT_DUES',
  WAITING_FOR_REFERRED_REQUESTING_PARTY = 'WAITING_FOR_REFERRED_REQUESTING_PARTY',
  FINANCIAL_CLAIMS_ON_WORKER = 'FINANCIAL_CLAIMS_ON_WORKER',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type ActiveHostingTypeDictionaryUnion = keyof typeof ActiveHostingType;
export type ActiveHostingTypeOptionsUnion = Exclude<ActiveHostingTypeDictionaryUnion, 'UNSPECIFIED'>;

export enum AuthorityType {
  EMPLOYER = 'EMPLOYER',
  COMMISSION = 'COMMISSION',
  LINES_REP = 'LINES_REP',
  EMBASSY_REPRESENTATIVE = 'EMBASSY_REPRESENTATIVE',
  REPRESENTATIVE_OF_THE_SECURITY_CENTER = 'REPRESENTATIVE_OF_THE_SECURITY_CENTER',
  RECRUITMENT_OFFICE_REPRESENTATIVE = 'RECRUITMENT_OFFICE_REPRESENTATIVE',
  DEATH = 'DEATH',
  HOSPITAL = 'HOSPITAL',
  PSYCHOLOGICAL_HEALTH = 'PSYCHOLOGICAL_HEALTH',
  EXPATRIATE_MANAGEMENT_REPRESENTATIVE = 'EXPATRIATE_MANAGEMENT_REPRESENTATIVE',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type AuthorityTypeDictionaryUnion = keyof typeof AuthorityType;
export type AuthorityTypeOptionsUnion = Exclude<AuthorityTypeDictionaryUnion, 'UNSPECIFIED'>;

export type NationalityType =
  | 'ALBANIAN'
  | 'ALGERIAN'
  | 'AMERICAN'
  | 'ANDORRAN'
  | 'ANGUILLAN'
  | 'ARGENTINE'
  | 'ARMENIAN'
  | 'AUSTRALIAN'
  | 'AUSTRIAN'
  | 'AZERBAIJANI'
  | 'BAHRAINI'
  | 'BARBADIAN'
  | 'BELARUSIAN'
  | 'BELGIAN'
  | 'BERMUDIAN'
  | 'BHUTANESE'
  | 'BOLIVIAN'
  | 'BOSNIAN'
  | 'BOTSWANA'
  | 'BRAZILIAN'
  | 'BRITISH'
  | 'BRITISH_VIRGIN_ISLANDER'
  | 'BRUNEIAN'
  | 'BULGARIAN'
  | 'BURKINABE'
  | 'BURMESE'
  | 'CAMBODIAN'
  | 'CAMEROONIAN'
  | 'CAPE_VERDIAN'
  | 'CAYMANIAN'
  | 'CENTRAL_AFRICAN'
  | 'CHADIAN'
  | 'CHILEAN'
  | 'CHINESE'
  | 'CHRISTMAS_ISLANDER'
  | 'CITIZEN_OF_THE_HOLY_SEE'
  | 'COLOMBIAN'
  | 'COSTA_RICAN'
  | 'CROAT'
  | 'CYPRIOT'
  | 'CZECH'
  | 'DANE'
  | 'DJIBOUTI'
  | 'DOMINICAN'
  | 'PERUVIAN'
  | 'QATARI'
  | 'SAINT_HELENIAN'
  | 'TOGOLESE'
  | 'NI_VANUATU'
  | 'SAMOAN'
  | 'MAHORAN'
  | 'ECUADOREAN'
  | 'EGYPTIAN'
  | 'EMIRATI'
  | 'ESTONIAN'
  | 'FIJIAN'
  | 'FINN'
  | 'FRENCH'
  | 'FRENCH_GUIANESE'
  | 'GABONESE'
  | 'GEORGIAN'
  | 'GERMAN'
  | 'GREEK'
  | 'GREENLANDIC'
  | 'GRENADIAN'
  | 'GUATEMALAN'
  | 'GUINEAN'
  | 'HAITIAN'
  | 'HONDURAN'
  | 'HUNGARIAN'
  | 'ICELANDER'
  | 'IRAQI'
  | 'IRISHMAN'
  | 'ITALIAN'
  | 'JAMAICAN'
  | 'JAPANESE'
  | 'JORDANIAN'
  | 'KAZAKHSTANI'
  | 'KUWAITI'
  | 'LEBANESE'
  | 'LIBERIAN'
  | 'LIECHTENSTEINER'
  | 'LITHUANIAN'
  | 'LUXEMBOURGER'
  | 'MALAWIAN'
  | 'MALAYSIAN'
  | 'MALDIVIAN'
  | 'MALTESE'
  | 'MARSHALLESE'
  | 'MARTINIQUE'
  | 'MAURITIAN'
  | 'MEXICAN'
  | 'MOLDOVAN'
  | 'MONEGASQUE'
  | 'MONTSERRATIAN'
  | 'MOZAMBICAN'
  | 'NAMIBIAN'
  | 'NAURUAN'
  | 'NEPALESE'
  | 'NETHERLANDER'
  | 'NEW_ZEALANDER'
  | 'NICARAGUAN'
  | 'NORTH_KOREAN'
  | 'NORWEGIAN'
  | 'OMANI'
  | 'PALESTINIAN'
  | 'PANAMANIAN'
  | 'PARAGUAYAN'
  | 'POLE'
  | 'PORTUGUESE'
  | 'PUERTO_RICAN'
  | 'RUSSIAN'
  | 'SAINT_LUCIAN'
  | 'SAINT_VINCENTIAN'
  | 'SALVADORAN'
  | 'SAMMARINESE'
  | 'SAO_TOMEAN'
  | 'SAUDI'
  | 'SENEGALESE'
  | 'SERBIAN'
  | 'SEYCHELLOIS'
  | 'SIERRA_LEONEAN'
  | 'SINGAPOREAN'
  | 'SLOVAK'
  | 'SLOVENE'
  | 'SOUTH_AFRICAN'
  | 'SPANIARD'
  | 'SUDANESE'
  | 'SURINAMESE'
  | 'SWEDE'
  | 'SWISS'
  | 'SYRIAN'
  | 'TAIWANESE'
  | 'TAJIK'
  | 'TANZANIA'
  | 'THAI'
  | 'TURK'
  | 'TURKMEN'
  | 'TUVALUAN'
  | 'UKRAINIAN'
  | 'URUGUAYAN'
  | 'UZBEK'
  | 'VENEZUELAN'
  | 'YEMENI'
  | 'ZAMBIAN'
  | 'ZIMBABWEAN'
  | 'ANGOLAN'
  | 'BAHAMIAN'
  | 'AFGHAN'
  | 'GIBRALTARIAN'
  | 'GUAMANIAN'
  | 'GUYANESE'
  | 'HEARD_AND_MCDONALD_ISLANDER'
  | 'KYRGYZ'
  | 'I_KIRIBATI'
  | 'COMORAN'
  | 'LAOTIAN'
  | 'LIBYAN'
  | 'MONTENEGRIN'
  | 'MONGOLIAN'
  | 'MALAGASY'
  | 'ERITREAN'
  | 'NIGERIEN'
  | 'NIGERIAN'
  | 'KENYAN'
  | 'GHANAIAN'
  | 'RWANDAN'
  | 'BENINESE'
  | 'BURUNDIAN'
  | 'UGANDAN'
  | 'ETHIOPIAN'
  | 'INDIAN'
  | 'VIETNAMESE'
  | 'FILIPINO'
  | 'SRI_LANKAN'
  | 'BANGLADESHI'
  | 'PAKISTANI'
  | 'INDONESIAN'
  | 'MAURITANIAN'
  | 'MOROCCAN'
  | 'SOMALI'
  | 'TUNISIAN'
  | 'PALESTINIAN_WITH_EGYPTIAN_DOCUMENT'
  | 'PALESTINIAN_WITH_LEBANESE_DOCUMENT'
  | 'PALESTINIAN_WITH_JORDANIAN_DOCUMENT'
  | 'PALESTINIAN_WITH_IRAQI_DOCUMENT'
  | 'PALESTINIAN_WITH_SYRIAN_DOCUMENT';

export type OccupationType =
  | 'DOMESTIC_LABORER'
  | 'NANNY'
  | 'DOMESTIC_NURSE'
  | 'DOMESTIC_COOK'
  | 'CHIROPRACTOR'
  | 'PRIVATE_TEACHER'
  | 'SPEECH_AND_HEARING_SPECIALIST'
  | 'CLEANER';

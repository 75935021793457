import { isNil, isPlainObject } from 'lodash';
import { IQAMA_NUMBER_PATTERN } from 'const';
import { isErrorEqualTo, isAPIError } from 'modules/http';

export const isIqamaNumber = (search: string) => {
  return IQAMA_NUMBER_PATTERN.test(search);
};

export const normalizeFormInput = (data: Record<string, any>, substitute = ''): any => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      isNil(value) ? substitute : isPlainObject(value) ? normalizeFormInput(value, substitute) : value,
    ])
  );
};

export const prepareFetchLaborerInfoErrorMessage = (error: unknown): string => {
  if (isAPIError(error)) {
    if (isErrorEqualTo(error, 'NOT_FOUND', 'LABORER_BY_ID')) {
      return 'Laborer:NotFound';
    }
    if (isErrorEqualTo(error, 'NOT_FOUND', 'NIC_ERROR')) {
      return 'Laborer:NicError';
    }
    if (isErrorEqualTo(error, 'NOT_FOUND', 'DWH_ERROR')) {
      return 'Laborer:DwhError';
    }
    if (isErrorEqualTo(error, 'CONFLICT', 'OUTSIDE_BORDER')) {
      return 'Laborer:OutsideBorder';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'OUTSIDE_BORDER')) {
      return 'Laborer:OutsideBorder';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'TRAVELSTATUS_NOT_ELIGIBLE')) {
      return 'Laborer:OutsideBorder';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'OCCUPATION_NOT_ELIGIBLE')) {
      return 'Laborer:OccupationNotValid';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'GENDER_NOT_ELIGIBLE')) {
      return 'Laborer:GenderNotValid';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'COUNTRY_NOT_MATCHED')) {
      return 'Laborer:CountryNotMatched';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'COUNTRY_WRONG_SELECTED')) {
      return 'Laborer:CountryWrongSelected';
    }
    if (isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'RECRUITING_OFFICE_NOT_MATCHED')) {
      return 'Laborer:RecruitingOfficeNotMatched';
    }

    if (isErrorEqualTo(error, 'BAD_REQUEST', 'NOT_ELIGIBLE')) {
      return 'Laborer:OccupationNotValid';
    }
    if (isErrorEqualTo(error, 'CONFLICT', 'LABORER_BY_ID_EXISTS')) {
      return 'Laborer:AlreadyExistsError';
    }
    if (isErrorEqualTo(error, 'CONFLICT', 'EXCEEDED_QUOTA_ERROR')) {
      return 'Laborer:QuotaExceeded';
    }
  }

  return 'Common:GeneralErrorMessage';
};

export const prepareFetchCitizenOrMuqeemLaborerInfoErrorMessage = (error: unknown): string =>
  isAPIError(error) && isErrorEqualTo(error, 'NOT_FOUND', 'LABORER_BY_ID')
    ? 'Common:GeneralNotFoundMessage'
    : 'Common:GeneralErrorMessage';

export const prepareFetchEmployerLaborersListErrorMessage = (error: unknown): string =>
  isAPIError(error) && isErrorEqualTo(error, 'NOT_FOUND', 'EMPLOYER_BY_ID')
    ? 'Common:GeneralNotFoundMessage'
    : 'Common:GeneralErrorMessage';

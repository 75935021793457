import { makeStyles, Theme } from '@material-ui/core/styles';
import { SECTION_MARGIN_VERTICAL } from 'const';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 0,
    padding: 0,
    border: 0,
    '& + &': {
      marginTop: theme.spacing(SECTION_MARGIN_VERTICAL),
    },
  },
  title: {
    marginBottom: 24,
  },
}));

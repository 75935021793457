import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { useTranslation } from 'services';
import { Button } from 'components';

type ConfirmationDialogProps = DialogProps & {
  open: boolean;
  text: string;
  onClose?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, text, onCancel, onConfirm }) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <Dialog open={open} fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText style={{ textAlign: isArabic ? 'right' : 'left' }} id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" autoFocus onClick={onCancel}>
          {t(['Common', 'Cancel'])}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t(['Common', 'Confirm'])}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import cn from 'classnames';
import { Box } from '@material-ui/core';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';
import { useStyles } from './FormSection.styles';

type FormSectionProps = {
  title?: string;
  className?: string;
  TypographyProps?: MuiTypographyProps;
};

export const FormSection: React.FC<FormSectionProps> = ({ children, title, className, TypographyProps }) => {
  const styles = useStyles();

  return (
    <Box className={cn(styles.container, className)}>
      {title && (
        <MuiTypography className={styles.title} variant="h5" {...TypographyProps}>
          {title}
        </MuiTypography>
      )}
      {children}
    </Box>
  );
};

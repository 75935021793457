import { makeStyles, Theme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

type PageHeaderStylesProps = {
  timeLeft?: number;
};

export const useStyles = makeStyles<Theme, PageHeaderStylesProps>((theme: Theme) => ({
  wrapper: {
    height: 155,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(3),
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  timer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: props => (props.timeLeft && props.timeLeft > 5000 ? theme.palette.secondary.grey : '#ef545d'),
  },
  timerLabel: {
    fontSize: '18px',
  },
  time: {
    fontSize: '18px',
    fontWeight: 600,
  },
}));

import { UUID_V4_BASE } from 'const';

type StaticRoute = {
  path: string;
};

type DynamicRoute = StaticRoute & {
  link: (param: string) => string;
  isValid: (pathname: string) => boolean;
};

const ewaCentersPathValidator = (path: keyof RoutesConfig['ewaCenter'], pathname: string): boolean => {
  const basePathRegexp = `(/ewa-centers/)${UUID_V4_BASE}`;

  /* eslint-disable prefer-template */
  const detailsPathRegexp = new RegExp('^' + basePathRegexp + '$');
  /* eslint-enable prefer-template */

  switch (path) {
    case 'details':
      return detailsPathRegexp.test(pathname);

    default:
      return false;
  }
};

const userPathValidator = (path: keyof RoutesConfig['users'], pathname: string): boolean => {
  const basePathRegexp = `(/users/)${UUID_V4_BASE}`;

  /* eslint-disable prefer-template */
  const detailsPathRegexp = new RegExp('^' + basePathRegexp + '$');
  /* eslint-enable prefer-template */

  switch (path) {
    case 'details':
      return detailsPathRegexp.test(pathname);

    default:
      return false;
  }
};

const laborerCasesPathValidator = (path: keyof RoutesConfig['laborerCases'], pathname: string): boolean => {
  const basePathRegexp = `(/laborer-cases/)${UUID_V4_BASE}`;

  /* eslint-disable prefer-template */
  const detailsPathRegexp = new RegExp('^' + basePathRegexp + '$');
  const assessmentPathRegexp = new RegExp('^' + basePathRegexp + '(/create-assessment)$');
  const closePathRegexp = new RegExp('^' + basePathRegexp + '(/close)$');
  const reopenPathRegexp = new RegExp('^' + basePathRegexp + '(/reopen)$');
  /* eslint-enable prefer-template */

  switch (path) {
    case 'details':
      return detailsPathRegexp.test(pathname);

    case 'assessment':
      return assessmentPathRegexp.test(pathname);

    case 'close':
      return closePathRegexp.test(pathname);

    case 'reopen':
      return reopenPathRegexp.test(pathname);

    default:
      return false;
  }
};

type RoutesConfig = {
  root: string;
  inactive: string;
  ewaCenter: {
    main: StaticRoute;
    add: StaticRoute;
    selection: StaticRoute;
    details: DynamicRoute;
  };
  users: {
    main: StaticRoute;
    add: StaticRoute;
    details: DynamicRoute;
  };
  laborerCases: {
    main: StaticRoute;
    add: StaticRoute;
    lookup: StaticRoute;
    details: DynamicRoute;
    assessment: DynamicRoute;
    close: DynamicRoute;
    reopen: DynamicRoute;
  };
  bundles: {
    main: StaticRoute;
    invoices: StaticRoute;
  };
};

export const routes: RoutesConfig = {
  root: '/',

  inactive: '/inactive',

  ewaCenter: {
    main: {
      path: '/ewa-centers',
    },
    add: {
      path: '/ewa-centers/add',
    },
    selection: {
      path: '/ewa-centers/selection',
    },
    details: {
      path: '/ewa-centers/:ewaCenterId',
      link: (ewaCenterId: string) => `/ewa-centers/${ewaCenterId}`,
      isValid: (pathname: string) => ewaCentersPathValidator('details', pathname),
    },
  },

  users: {
    main: {
      path: '/users',
    },
    add: {
      path: '/users/add',
    },
    details: {
      path: '/users/:userId',
      link: (userId: string) => `/users/${userId}`,
      isValid: (pathname: string) => userPathValidator('details', pathname),
    },
  },

  laborerCases: {
    main: {
      path: '/laborer-cases',
    },
    add: {
      path: '/laborer-cases/add',
    },
    lookup: {
      path: '/laborer-cases/search',
    },
    details: {
      path: '/laborer-cases/:caseId',
      link: (caseId: string) => `/laborer-cases/${caseId}`,
      isValid: (pathname: string) => laborerCasesPathValidator('details', pathname),
    },
    assessment: {
      path: '/laborer-cases/:caseId/create-assessment',
      link: (caseId: string) => `/laborer-cases/${caseId}/create-assessment`,
      isValid: (pathname: string) => laborerCasesPathValidator('assessment', pathname),
    },
    close: {
      path: '/laborer-cases/:caseId/close',
      link: (caseId: string) => `/laborer-cases/${caseId}/close`,
      isValid: (pathname: string) => laborerCasesPathValidator('close', pathname),
    },
    reopen: {
      path: '/laborer-cases/:caseId/reopen',
      link: (caseId: string) => `/laborer-cases/${caseId}/reopen`,
      isValid: (pathname: string) => laborerCasesPathValidator('reopen', pathname),
    },
  },

  bundles: {
    main: {
      path: '/bundles',
    },
    invoices: {
      path: '/bundles/invoices',
    },
  },
};

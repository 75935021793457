import { RootState } from 'services/store';
import { ExtendedUser } from './types';

export const selectUsersList = (store: RootState): RootState['user']['usersList'] => {
  return store.user.usersList;
};

export const selectUserDetails = (store: RootState): RootState['user']['userDetails'] => {
  return store.user.userDetails;
};

export const selectIsNetworkConnectionProblem = (
  store: RootState
): RootState['user']['userDetails']['isNetworkConnectionProblem'] => {
  return store.user.userDetails.isNetworkConnectionProblem;
};

export const selectUserPrivileges = (store: RootState): RootState['user']['privileges']['data'] => {
  return store.user.privileges.data;
};

export const selectUserRoles = (store: RootState): RootState['user']['roles']['data'] => {
  return store.user.roles.data;
};

export const selectUserId = (store: RootState): ExtendedUser['id'] | null => {
  const userDetails = store.user.userDetails.data;

  return userDetails ? userDetails.id : null;
};

export const selectUserIdDetails = (store: RootState): RootState['user']['userIdDetails'] => {
  return store.user.userIdDetails;
};

export const selectUdateUserPrivilege = (store: RootState): RootState['user']['userPrivilegeStatus'] => {
  return store.user.userPrivilegeStatus;
};

export const selectCenterId = (store: RootState): ExtendedUser['ewaCenterId'] | null => {
  const userDetails = store.user.userDetails.data;

  return userDetails ? userDetails.ewaCenterId : null;
};

export const selectPostUserStatus = (store: RootState): RootState['user']['postUserStatus'] => {
  return store.user.postUserStatus;
};

export const selectUpdateUserStatus = (store: RootState): RootState['user']['updateUserStatus'] => {
  return store.user.updateUserStatus;
};

export const selectInviteUserStatus = (store: RootState): RootState['user']['inviteUserStatus'] => {
  return store.user.inviteUserStatus;
};

export const selectUserPoints = (store: RootState): RootState['user']['userPoints']['data'] => {
  return store.user.userPoints.data;
};

export const selectUserPointsStatus = (store: RootState): RootState['user']['userPointsStatus'] => {
  return store.user.userPointsStatus;
};

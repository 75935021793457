import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useTranslation } from 'services';
import { TestId } from 'const';
import { Button, Card, ConfirmationDialog } from 'components';
import { SectionLaborer } from './sections/SectionLaborer';
import { SectionWork } from './sections/SectionWork';
import { SectionDispute } from './sections/SectionDispute';
import { computeViolations } from './violation';
import { assessmentFormSchema } from './validation';
import { LaborerAssessmentData, Violations } from './types';
import { formatFormData } from './helpers';
import { useStyles } from './AssessmentForm.styles';

const SPACE = 6;

type AssessmentFormProps = {
  laborerName: string;
  onSubmit: (formData: LaborerAssessmentData, violations: Violations) => Promise<boolean>;
  isReservationLock: boolean;
};

export const AssessmentForm: React.FC<AssessmentFormProps> = ({ laborerName, onSubmit, isReservationLock }) => {
  const { t, tBasic } = useTranslation();
  const styles = useStyles();
  const formMethods = useForm<LaborerAssessmentData>({
    mode: 'onChange',
    resolver: yupResolver(assessmentFormSchema()),
  });
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [violations, setViolations] = useState<string[][]>([]);

  const handleSubmit = async (data: LaborerAssessmentData): Promise<void> => {
    const violationsData = computeViolations(data);
    setViolations(violationsData.pairs);
    const wasSubmitted = await onSubmit(formatFormData(data), violationsData.request);
    setSubmitted(wasSubmitted);
  };
  const { formState, trigger } = formMethods;
  const isSubmitDisabled = !formState.isDirty || !formState.isValid || formState.isSubmitting;

  const handleSubmitButtonClick = async () => {
    await trigger();

    if (!isSubmitDisabled) {
      setAlertOpen(true);
    }
  };

  const handleAlertConfirm = () => {
    const values = formMethods.getValues();
    handleSubmit(values);
    setAlertOpen(false);
  };
  const handleAlertCancel = () => {
    setAlertOpen(false);
  };

  // temporary solution
  useEffect(() => {
    if (isReservationLock) {
      handleAlertCancel();
    }
  }, [isReservationLock]);

  return (
    <Box marginTop={SPACE} marginBottom={SPACE}>
      <FormProvider {...formMethods}>
        <form autoComplete="off">
          <Box>
            <SectionLaborer
              dataTestId={TestId.AssessmentFormSectionLaborer}
              laborerName={laborerName}
              readOnly={submitted}
            />
          </Box>

          <Box marginTop={SPACE}>
            <SectionWork dataTestId={TestId.AssessmentFormSectionWork} readOnly={submitted} />
          </Box>

          <Box marginTop={SPACE}>
            <SectionDispute dataTestId={TestId.AssessmentFormSectionDispute} readOnly={submitted} />
          </Box>

          {!submitted && (
            <Box marginTop={SPACE}>
              <Button dataTestId={TestId.LaborerCaseAssessmentSubmitButton} onClick={handleSubmitButtonClick}>
                {t(['Common', 'Submit'])}
              </Button>
            </Box>
          )}
        </form>
      </FormProvider>

      {submitted && (
        <Box marginTop={SPACE}>
          <Card className={styles.assessmentResults}>
            <Box>{t(['Assessment', 'Result'])}</Box>

            <Box marginTop={2}>
              {violations.length > 0 ? (
                violations.map(v => (
                  <Box marginTop={1} key={`${v[0]}${v[1]}}`}>
                    {`[${tBasic(`Assessment:${v[0]}`)}]: ${tBasic(`Assessment:${v[1]}`)}`}
                  </Box>
                ))
              ) : (
                <Box marginTop={1}>{t(['Assessment', 'NoResults'])}</Box>
              )}
            </Box>
          </Card>
        </Box>
      )}

      <ConfirmationDialog
        text={t(['Common', 'GeneralSendConfirmationQuestion'])}
        open={alertOpen}
        onConfirm={handleAlertConfirm}
        onCancel={handleAlertCancel}
      />
    </Box>
  );
};

import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useUsersManagement, isUserInactive, UserRole } from 'modules/user';
import { ApplicationLocationState } from 'modules/shared';
import { routes } from 'const';

const defaultUserPath: Record<UserRole, string> = {
  [UserRole.Admin]: routes.ewaCenter.main.path,
  [UserRole.Registrar]: routes.laborerCases.main.path,
  [UserRole.LaborCommittee]: routes.laborerCases.main.path,
  [UserRole.SocialWorker]: routes.laborerCases.main.path,
  [UserRole.MLSD]: routes.ewaCenter.selection.path,
  [UserRole.EwaCenterManager]: routes.laborerCases.main.path,
  [UserRole.none]: routes.inactive,
};

/**
 * NOTE: the root route "/" does not provide any view to consume, it is used to mount below component,
 * which in turn is responsible for user redirection to his/her default path. It's also responsible for
 * inactive user redirection to /inactive route.
 *
 * Default path is an application starting point specific to user role.
 */

export const RedirectInterceptor: React.FC = () => {
  const { state } = useLocation<ApplicationLocationState>();
  const { loggedUser } = useUsersManagement();

  if (loggedUser) {
    const redirectPath = defaultUserPath[loggedUser.userRole];
    const shouldRedirect = !!redirectPath && !(state && state.status === 404);

    if (isUserInactive(loggedUser.status)) {
      return <Redirect to={routes.inactive} />;
    }

    if (shouldRedirect) {
      return <Redirect to={redirectPath} />;
    }

    return null;
  }

  return null;
};

import { Action } from 'services/store';
import { BundlesActionType } from './actions';
import { BundleState } from './state';

export const invoiceState: BundleState = {
  invoiceList: {
    data: [],
    count: 0,
    status: 'default',
  },
};

export function bundleReducer(state = invoiceState, action: Action): BundleState {
  switch (action.type) {
    /**
     * FetchInvoices
     */
    case BundlesActionType.FetchInvoices:
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          status: 'pending',
        },
      };

    case BundlesActionType.FetchInvoicesSuccess:
      return {
        ...state,
        invoiceList: {
          data: action.payload.invoices.results,
          count: action.payload.invoices.count,
          status: 'success',
        },
      };

    case BundlesActionType.FetchInvoicesFailure:
      return {
        ...state,
        invoiceList: {
          ...state.invoiceList,
          status: 'failure',
        },
      };

    default:
      return state;
  }
}

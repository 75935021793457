import * as yup from 'yup';
import { i18nClient } from 'services';
import {
  ADDRESS_PATTERN,
  ARABIC_LATIN_ALPHANUMERIC_PATTERN,
  ARABIC_LATIN_CHARACTER_PATTERN,
  ARABIC_PHONE_PATTERN,
  RETRIEVED_EMPLOYER_ID_PATTERN,
  IQAMA_BORDER_NUMBER_PATTERN,
  LABORER_PRO_ID_PATTERN,
  DISALLOWED_ARABIC_CHARACTER_PATTERN,
} from 'const';
import { isOtherSelected, isCaseHostingType } from 'modules/shared';

export type AddLaborerFormData = {
  laborerName: string;
  laborerNumber: string;
  laborerNationality: string;
  laborerPassportNumber: string;
  laborerOccupationCode: string;
  laborerStatus: string | null;
  arrivedDate: string | null;
  laborTraffickingStudied: boolean;
  workerSupported: boolean;
  DaysBetween: string | null;
  lastEntryDate: string | null;
  isPRORequired: boolean;

  employerName?: string | null;
  employerId?: string | null;
  employerMobileNumber?: string | null;

  proInformation: {
    name?: string | null;
    email?: string | null;
    mobileNumber?: string | null;
  };

  registrarClassification: string;

  referredBy: string;
  referredByOther?: string | null;

  hostingType?: string | null;
  hostingTypeOther?: string | null;
};

export const addLaborerSchema = () => {
  return yup.object().shape<AddLaborerFormData>({
    laborerName: yup
      .string()
      .trim()
      .matches(ARABIC_LATIN_CHARACTER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(LaborerName)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(LaborerName)' })),
    laborerNumber: yup.string<AddLaborerFormData['laborerNumber']>().matches(IQAMA_BORDER_NUMBER_PATTERN, {
      message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:BorderIqamaNo)' }),
      excludeEmptyString: true,
    }),
    laborerNationality: yup
      .string()
      .nullable() // autocomplete default value
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Nationality)' })),
    laborerPassportNumber: yup
      .string()
      .trim()
      .matches(ARABIC_LATIN_ALPHANUMERIC_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(PassportNo)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(PassportNo)' })),
    laborerOccupationCode: yup.string().required(i18nClient.t('Validation.RequiredField', { field: '$t(Occupation)' })),
    laborerStatus: yup.string().defined().nullable(),
    arrivedDate: yup.string().defined().nullable(),
    DaysBetween: yup.string().defined().nullable(),
    lastEntryDate: yup.string().defined().nullable(),
    laborTraffickingStudied: yup.boolean(),
    workerSupported: yup.boolean(),
    employerName: yup
      .string()
      .trim()
      .nullable()
      .matches(ARABIC_LATIN_CHARACTER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(EmployerName)' }),
        excludeEmptyString: true,
      }),
    employerId: yup
      .string()
      .nullable()
      .matches(RETRIEVED_EMPLOYER_ID_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:CaseDetailsEmployerId)' }),
        excludeEmptyString: true,
      }),
    employerMobileNumber: yup
      .string()
      .nullable()
      .matches(ARABIC_PHONE_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(MobileNo)' }),
        excludeEmptyString: true,
      }),

    proInformation: yup
      .object({
        name: yup
          .string()
          .nullable()
          .matches(ARABIC_LATIN_CHARACTER_PATTERN, {
            message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:ProName)' }),
            excludeEmptyString: true,
          }),
        email: yup
          .string()
          .nullable()
          .matches(DISALLOWED_ARABIC_CHARACTER_PATTERN, {
            message: i18nClient.t('Validation.Pattern', { field: '$t(Email)' }),
            excludeEmptyString: true,
          })
          .email(i18nClient.t('Validation.Pattern', { field: '$t(Email)' })),
        mobileNumber: yup
          .string()
          .nullable()
          .matches(ARABIC_PHONE_PATTERN, {
            message: i18nClient.t('Validation.Pattern', { field: '$t(MobileNo)' }),
            excludeEmptyString: true,
          }),
      })
      .defined(),

    registrarClassification: yup
      .string()
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Laborer:RegistrarClassification)' })),

    referredBy: yup.string().required(i18nClient.t('Validation.Required')),
    referredByOther: yup
      .string()
      .nullable()
      .trim()
      .when('referredBy', {
        is: referredBy => isOtherSelected(referredBy),
        then: yup
          .string()
          .required(i18nClient.t('Validation.Required'))
          .max(100, i18nClient.t('Validation.Max100CharsLength')),
      }),

    hostingType: yup
      .string()
      .nullable()
      .when('registrarClassification', {
        is: registrarClassification => isCaseHostingType(registrarClassification),
        then: yup.string().required(i18nClient.t('Validation.Required')),
      }),
    hostingTypeOther: yup
      .string()
      .nullable()
      .trim()
      .when('hostingType', {
        is: hostingType => isOtherSelected(hostingType),
        then: yup
          .string()
          .required(i18nClient.t('Validation.Required'))
          .max(100, i18nClient.t('Validation.Max100CharsLength')),
      }),
    isPRORequired: yup.boolean(),
  });
};

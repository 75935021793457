import React from 'react';
import { useStyles } from './DetailElement.styles';

type DetailElementProps = {
  title: string;
};

export const DetailElement: React.FC<DetailElementProps> = ({ title, children }) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
    </div>
  );
};

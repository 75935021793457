import React, { VFC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { isNotProduction } from 'config';
import { TextField, TextFieldProps } from 'components/common';
import { format } from 'services/date';

type ForbiddenProps = 'onChange' | 'onBlur' | 'defaultValue' | 'size' | 'children';
type FormTextFieldProps = Omit<TextFieldProps, ForbiddenProps> & {
  name: string;
  isNeedFormatDate?: boolean;
};

export const FormTextField: VFC<FormTextFieldProps> = ({ name, isNeedFormatDate, ...props }) => {
  const { errors, control } = useFormContext();
  const error = get(errors, name, null);
  const errorMessage = get(error, 'message', '');

  if (isNotProduction() && !control) {
    console.error('FormTextField: this component must be descendant of `FormProvider` in order to use form context.');
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ name: fieldName, value, onChange, onBlur }) => (
        <TextField
          name={fieldName}
          value={isNeedFormatDate ? format(value) : value}
          onChange={onChange}
          onBlur={onBlur}
          error={Boolean(error)}
          helperText={errorMessage}
          {...props}
        />
      )}
    />
  );
};

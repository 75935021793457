import React from 'react';
import { LaborerCaseResponse, LaborerSatisfactionStatus } from 'modules/laborer-case';
import {
  getValue,
  getReferredByByLabel,
  getNationalityLabelByName,
  getAuthorityTypeLabel,
  getOccupationLabelByName,
  ReferralTypeResponse,
  NationalityResponseDto,
  AuthorityTypeResponse,
  OccupationResponse,
} from 'modules/shared';
import { useTranslation } from 'services/translation-client';
import { fullFormat } from 'services/date';
import MusanedLogo from 'assets/images/musaned-logo.png';
import FrutigerFont from 'assets/fonts/FrutigerLTArabic-55Roman.ttf';
import { createSequence } from '../utils';
import { printStyles } from '../Print.styles';

type PrintExitFormProps = {
  caseDetails: LaborerCaseResponse;
  laborerOccupations: OccupationResponse[];
  referralTypes: ReferralTypeResponse[];
  nationalities: NationalityResponseDto[];
  authorityTypes: AuthorityTypeResponse[];
  ewaCenterName: string;
  reactPdf: typeof import('@react-pdf/renderer');
};

export const PrintExitForm: React.FC<PrintExitFormProps> = ({
  caseDetails,
  ewaCenterName,
  laborerOccupations,
  referralTypes,
  nationalities,
  authorityTypes,
  reactPdf,
}) => {
  const { t } = useTranslation();

  const { Document, Page, View, Text, Image, Font, StyleSheet } = reactPdf;
  const styles = StyleSheet.create(printStyles);
  Font.register({ family: 'Frutiger', src: FrutigerFont });
  Font.registerHyphenationCallback(word => [word]);

  const getYesNoAnswer = (answer: boolean) => {
    return t(['Common', answer ? 'Yes' : 'No']);
  };

  const getLaborerSatisfaction = (option: LaborerSatisfactionStatus) => {
    if (option === 'EXCELLENT') {
      return t(['Common', 'Excellent']);
    }

    if (option === 'GOOD') {
      return t(['Common', 'Good']);
    }

    return t(['Common', 'Poor']);
  };

  const caseClosingExitDateTime = getValue(caseDetails, 'laborerCaseClosingDetails.exitDateTime');
  const caseClosingEmployerId = getValue(caseDetails, 'laborerCaseClosingDetails.employerId');
  const caseClosingPhoneNumber = getValue(caseDetails, 'laborerCaseClosingDetails.phoneNumber');
  const caseClosingAuthorizationNumber = getValue(caseDetails, 'laborerCaseClosingDetails.authorizationNumber');
  const caseClosingAuthorizedPersonName = getValue(caseDetails, 'laborerCaseClosingDetails.authorizedPersonName');
  const caseClosingAuthority = getValue(caseDetails, 'laborerCaseClosingDetails.authority');
  const caseClosingAuthorityOther = getValue(caseDetails, 'laborerCaseClosingDetails.authorityOther');
  const caseClosingEndDate = getValue(caseDetails, 'laborerCaseClosingDetails.endDate');
  const caseClosingFinancialClaims = getValue(caseDetails, 'laborerCaseClosingDetails.financialClaimsHasBeenReceived');
  const caseClosingPersonalItems = getValue(caseDetails, 'laborerCaseClosingDetails.personalItemsHasBeenReceived');
  const caseClosingLaborerSatisfaction = getValue(caseDetails, 'laborerCaseClosingDetails.laborerSatisfaction');

  const caseClosingAuthorityLabel = getAuthorityTypeLabel(authorityTypes, [
    caseClosingAuthority,
    caseClosingAuthorityOther,
  ]);
  const authoritySequence = createSequence(caseClosingAuthorityLabel, '20/30') || [];

  return (
    <Document title={`Exit Form - Case No.${caseDetails.caseNumber}`}>
      <Page style={styles.body}>
        <View style={styles.pageHeader}>
          <Text style={styles.pageTitle}>{t(['ExitFormPrint', 'MainHeader'])}</Text>
          <Image source={MusanedLogo} style={styles.pageImage} />
        </View>

        <Text style={styles.sectionHeader}>{t(['ExitFormPrint', 'ExitTimeSection'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValueSpacious]}>
              <Text style={styles.tableCell}>{ewaCenterName}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'EwaCenterName'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValueSpacious]}>
              <Text style={styles.tableCell}>{fullFormat(caseDetails.joiningDate)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'JoiningToCenterDate'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{fullFormat(caseClosingExitDateTime, 'hh:mm A')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['ExitFormPrint', 'TimeOfExitField'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{fullFormat(caseClosingExitDateTime)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'DateOfExitField'])}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionHeader}>{t(['Common', 'LaborerInfo'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerName}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'LaborerName'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerNumber}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'BorderIqamaNo'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerPassportNumber}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'PassportNo'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>
                {getNationalityLabelByName(nationalities, caseDetails.laborerNationality)}
              </Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Nationality'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>
                {getReferredByByLabel(referralTypes, [caseDetails.referredBy, caseDetails.referredByOther])}
              </Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'ReferredBy'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>
                {getOccupationLabelByName(laborerOccupations, caseDetails.laborerOccupation)}
              </Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Occupation'])}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionHeader}>{t(['Case', 'AuthPersonInfo'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseClosingAuthorizedPersonName}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['ExitFormPrint', 'AuthPersonInfoNameField'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              {authoritySequence.map((sequence, index) => (
                <Text key={index} style={styles.tableCell}>
                  {sequence}
                </Text>
              ))}
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Case', 'AuthPersonInfoAuthority'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseClosingPhoneNumber}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'MobileNo'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseClosingEmployerId}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'IdNoLocation'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{fullFormat(caseClosingEndDate)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['ExitFormPrint', 'AuthPersonInfoAuthEndDateField'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseClosingAuthorizationNumber}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Case', 'AuthorizationNo'])}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionHeader}>{t(['Case', 'ExitQuestions'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue, { width: '50%' }]}>
              <Text style={styles.tableCell}>{getYesNoAnswer(caseClosingFinancialClaims)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader, { width: '50%' }]}>
              <Text style={styles.tableCell}>{t(['Case', 'FinancialClaimsFieldLabel'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue, { width: '50%' }]}>
              <Text style={styles.tableCell}>{getYesNoAnswer(caseClosingPersonalItems)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader, { width: '50%' }]}>
              <Text style={styles.tableCell}>{t(['Case', 'PersonalItemsFieldLabel'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue, { width: '50%' }]}>
              <Text style={styles.tableCell}>{getLaborerSatisfaction(caseClosingLaborerSatisfaction)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader, { width: '50%' }]}>
              <Text style={styles.tableCell}>{t(['Case', 'LaborerSatisfactionFieldLabel'])}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

import { RootState } from 'services/store';

export const selectLaborerInfo = (store: RootState): RootState['laborerInfo']['laborerInfoDetails'] => {
  return store.laborerInfo.laborerInfoDetails;
};

export const selectLaborerInfoCaseClosing = (store: RootState): RootState['laborerInfo']['laborerInfoCaseClosing'] => {
  return store.laborerInfo.laborerInfoCaseClosing;
};

export const selectLaborerInfoEmployerList = (store: RootState): RootState['laborerInfo']['employerList'] => {
  return store.laborerInfo.employerList;
};

export const selectLaborerInfoNotEligible = (store: RootState): RootState['laborerInfo']['notEligible'] => {
  return store.laborerInfo.notEligible;
};

export const selectQuota = (store: RootState): RootState['laborerInfo']['quota'] => {
  return store.laborerInfo.quota;
};

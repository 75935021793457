import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useForm, useWatch, FormProvider, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import { useTranslation } from 'services';
import { SECTION_MARGIN_HORIZONTAL, TestId } from 'const';
import cities from 'assets/other/cities.json';
import nationalities from 'assets/other/nationalities.json';
import types from 'assets/other/ewa-center-type.json';
import { Button, FormSection, FormRow, GoogleMap, GoogleMapLocation } from 'components/common';
import { FormTextField, FormAutocomplete } from 'components/form-components';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import { ewaCenterFormSchema, EwaCenterFormData } from './validation';

const initialMapDataValues: GoogleMapLocation = {
  buildingNumber: '',
  streetName: '',
  district: '',
  postalCode: '',
  locationAdditionalNumber: '',
};

const formInitialValues: EwaCenterFormData = {
  name: '',
  email: '',
  capacity: '',
  ewaCenterType: '',
  embassyNicCode: '',
  proNumber: '',
  city: '',
  mobileNumber: '',
  postalBox: null,
  ...initialMapDataValues,
};

type EwaCenterFormProps = {
  ewaCenter?: EwaCenterFormData;
  onSubmit: SubmitHandler<EwaCenterFormData>;
};

const prepareInitialLocationValues = (ewaCenter: EwaCenterFormProps['ewaCenter']): GoogleMapLocation => {
  return ewaCenter
    ? {
        buildingNumber: String(ewaCenter.buildingNumber),
        streetName: ewaCenter.streetName,
        district: ewaCenter.district,
        postalCode: String(ewaCenter.postalCode),
        locationAdditionalNumber: String(ewaCenter.locationAdditionalNumber),
      }
    : initialMapDataValues;
};

const stringifyObjectValues = (object: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(object).map(([key, value]) => [key, String(value)]));

export const EwaCenterForm: React.FC<EwaCenterFormProps> = ({ ewaCenter, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const [location, setLocation] = useState<GoogleMapLocation>(prepareInitialLocationValues(ewaCenter));
  const formMethods = useForm<EwaCenterFormData>({
    mode: 'onChange',
    defaultValues: ewaCenter || formInitialValues,
    resolver: yupResolver(ewaCenterFormSchema()),
  });
  const watchLocationValues = useWatch<GoogleMapLocation>({
    name: ['buildingNumber', 'streetName', 'district', 'postalCode', 'locationAdditionalNumber'],
    control: formMethods.control,
    defaultValue: location,
  });

  const { setValue, handleSubmit, watch } = formMethods;

  const handleChangeLocationFromMapEvent = (mapLocation: GoogleMapLocation) => {
    Object.entries<string>(mapLocation).forEach(([fieldName, fieldValue]) => {
      setValue(fieldName, fieldValue, { shouldValidate: true, shouldDirty: true });
    });
    setValue('postalBox', null, { shouldValidate: true, shouldDirty: true });
  };

  const debouncedSetLocation = useCallback(
    debounce(
      (locationValues: Partial<GoogleMapLocation>) =>
        setLocation(prevLocation => ({ ...prevLocation, ...stringifyObjectValues(locationValues) })),
      400
    ),
    []
  );

  useEffect(() => {
    debouncedSetLocation(watchLocationValues);
  }, [watchLocationValues, debouncedSetLocation]);

  const ewaCenterTypeValue = watch('ewaCenterType');
  const isArabic = i18n.language === 'ar';

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormSection title={t(['EwaCenter', 'AddFormSection'])}>
          <FormRow>
            <FormTextField
              dataTestId={TestId.EwaCenterCenterNameInput}
              name="name"
              label={t(['Common', 'EwaCenterName'])}
            />
            <FormAutocomplete
              dataTestId={TestId.EwaCenterCenterCityInput}
              name="city"
              label={t(['Common', 'City'])}
              options={cities}
              labelKey="label"
              valueKey="label"
            />
          </FormRow>

          <FormRow>
            <FormTextField name="email" label={t(['Common', 'Email'])} placeholder="x@x.x" />
            <FormTextField name="mobileNumber" label={t(['Common', 'MobileNo'])} placeholder="9665xxxxxxxx+" />
          </FormRow>

          <FormRow>
            <FormTextField name="capacity" label={t(['Common', 'Capacity'])} />
            <FormAutocomplete
              name="ewaCenterType"
              label={t(['Common', 'ewaCenterType'])}
              options={types}
              labelKey={isArabic ? 'arabicName' : 'name'}
              valueKey="code"
            />
          </FormRow>
          {ewaCenterTypeValue?.toString() === '2' ? (
            <FormRow>
              <FormTextField name="proNumber" label={t(['Common', 'ProNumber'])} />
            </FormRow>
          ) : null}
          {ewaCenterTypeValue?.toString() === '3' ? (
            <FormRow>
              <FormAutocomplete
                name="embassyNicCode"
                label={t(['Common', 'embassyNicCode'])}
                options={nationalities}
                labelKey={isArabic ? 'arabicName' : 'englishName'}
                valueKey="nicCode"
              />
            </FormRow>
          ) : null}
        </FormSection>

        <FormSection title={t(['EwaCenter', 'AddFormMapSection'])}>
          <Box display="flex" alignItems="flex-start">
            <Box marginRight={SECTION_MARGIN_HORIZONTAL}>
              <GoogleMap location={location} onLocationChange={handleChangeLocationFromMapEvent} />
            </Box>

            <Box marginRight={2}>
              <FormRow>
                <FormTextField name="district" label={t(['Common', 'District'])} />
                <FormTextField name="buildingNumber" label={t(['Common', 'BuildingNo'])} />
              </FormRow>

              <FormRow>
                <FormTextField name="streetName" label={t(['Common', 'StreetName'])} />
                <FormTextField name="postalBox" label={t(['Common', 'PostalBox'])} />
              </FormRow>

              <FormRow>
                <FormTextField name="postalCode" label={t(['Common', 'PostalCode'])} />
                <FormTextField name="locationAdditionalNumber" label={t(['Common', 'AdditionalNo'])} />
              </FormRow>
            </Box>
          </Box>
        </FormSection>

        <FormSection>
          <Button dataTestId={TestId.EwaCenterSubmitButton} type="submit">
            {t(['Common', ewaCenter ? 'Update' : 'Add'])}
          </Button>
        </FormSection>
      </form>
    </FormProvider>
  );
};

import { createAction } from 'services/store/create-action';
import { LaborerInfoResponseDto, LaborerInfoResponse, LaborerByIqama, LaborerByVisa, Quota } from './types';

export enum LaborerInfoActionType {
  FetchLaborerInfo = '[LaborerInfo]: Fetch laborer info',
  FetchLaborerInfoWithNationality = '[LaborerInfo]: Fetch laborer info with nationality',
  FetchLaborerInfoSuccess = '[LaborerInfo]: Fetch laborer info - success',
  FetchLaborerInfoFailure = '[LaborerInfo]: Fetch laborer info - failure',

  FetchCitizenLaborerInfo = '[LaborerInfo]: Fetch citizen laborer info',
  FetchCitizenLaborerInfoSuccess = '[LaborerInfo]: Fetch citizen laborer info - success',
  FetchCitizenLaborerInfoFailure = '[LaborerInfo]: Fetch citizen laborer info - failure',

  FetchMuqeemLaborerInfo = '[LaborerInfo]: Fetch muqeem laborer info',
  FetchMuqeemLaborerInfoSuccess = '[LaborerInfo]: Fetch muqeem laborer info - success',
  FetchMuqeemLaborerInfoFailure = '[LaborerInfo]: Fetch muqeem laborer info - failure',

  FetchEmployerLaborersList = '[LaborerInfo]: Fetch employer laborers list',
  FetchEmployerLaborersListSuccess = '[LaborerInfo]: Fetch employer laborers list - success',
  FetchEmployerLaborersListFailure = '[LaborerInfo]: Fetch employer laborers list - failure',

  FetchQuota = '[LaborerInfo]: Fetch quota',
  FetchQuotaSuccess = '[LaborerInfo]: Fetch quota - success',
  FetchQuotaFailure = '[LaborerInfo]: Fetch quota - failure',

  SetNotEligible = '[LaborerInfo]: Set not eligible',

  ClearLaborerRelatedData = '[LaborerInfo]: Clear laborer related data',
}

export const LaborerInfoAction = {
  fetchLaborerInfo: (borderIqama: string) => createAction(LaborerInfoActionType.FetchLaborerInfo, { borderIqama }),
  fetchLaborerInfoWithNationality: (borderIqama: string, nationality: string, registrarOption: string) =>
    createAction(LaborerInfoActionType.FetchLaborerInfoWithNationality, {
      borderIqama,
      nationality,
      registrarOption,
    }),
  fetchLaborerInfoSuccess: (data: LaborerInfoResponseDto) =>
    createAction(LaborerInfoActionType.FetchLaborerInfoSuccess, data),
  fetchLaborerInfoFailure: () => createAction(LaborerInfoActionType.FetchLaborerInfoFailure),

  fetchCitizenLaborerInfo: (employerId: string, checkDate: string) =>
    createAction(LaborerInfoActionType.FetchCitizenLaborerInfo, { employerId, checkDate }),
  fetchCitizenLaborerInfoSuccess: (data: LaborerInfoResponse) =>
    createAction(LaborerInfoActionType.FetchCitizenLaborerInfoSuccess, data),
  fetchCitizenLaborerInfoFailure: () => createAction(LaborerInfoActionType.FetchCitizenLaborerInfoFailure),

  fetchMuqeemLaborerInfo: (employerId: string, checkDate: string) =>
    createAction(LaborerInfoActionType.FetchMuqeemLaborerInfo, { employerId, checkDate }),
  fetchMuqeemLaborerInfoSuccess: (data: LaborerInfoResponse) =>
    createAction(LaborerInfoActionType.FetchMuqeemLaborerInfoSuccess, data),
  fetchMuqeemLaborerInfoFailure: () => createAction(LaborerInfoActionType.FetchMuqeemLaborerInfoFailure),

  fetchEmployerLaborersList: (employerId: string, checkDate: string) =>
    createAction(LaborerInfoActionType.FetchEmployerLaborersList, { employerId, checkDate }),
  fetchEmployerLaborersListSuccess: (laborersByIqama: LaborerByIqama[], laborersByVisa: LaborerByVisa[]) =>
    createAction(LaborerInfoActionType.FetchEmployerLaborersListSuccess, { laborersByIqama, laborersByVisa }),
  fetchEmployerLaborersListFailure: () => createAction(LaborerInfoActionType.FetchEmployerLaborersListFailure),

  setNotEligible: (notEligible: boolean) => createAction(LaborerInfoActionType.SetNotEligible, { notEligible }),

  clearLaborerRelatedData: () => createAction(LaborerInfoActionType.ClearLaborerRelatedData),

  fetchQuota: () => createAction(LaborerInfoActionType.FetchQuota),
  fetchQuotaSuccess: (data: Quota) => createAction(LaborerInfoActionType.FetchQuotaSuccess, data),
  fetchQuotaFailure: () => createAction(LaborerInfoActionType.FetchQuotaFailure),
};

export type LaborerInfoActionCreator = typeof LaborerInfoAction;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import { routes } from 'const';
import { ActionButton } from './ActionButton';

type CloseActionButtonProps = {
  type: 'button' | 'link';
  laborerCaseId: string;
  className?: string;
  disabled: boolean;
};

export const CloseActionButton: React.FC<CloseActionButtonProps> = ({ type, laborerCaseId, className, disabled }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRedirectToCloseForm = (): void => {
    history.push(routes.laborerCases.close.link(laborerCaseId));
  };

  return (
    <ActionButton
      disabled={disabled}
      className={className}
      type={type}
      label={t(['Case', 'Close'])}
      onClick={handleRedirectToCloseForm}
    />
  );
};

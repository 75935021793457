import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import get from 'lodash/get';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'services';
import moment from 'moment';
import { normalizeFormInput, LaborerInfoResponseDto } from 'modules/laborer-info';
import {
  isOtherSelected,
  isCaseHostingType,
  RegistrarClassificationResponse,
  ReferralTypeOptions,
  HostingTypeOptions,
  NationalityResponseDto,
  OccupationResponse,
} from 'modules/shared';
import { hasUserRoles, UserRole, useUsersManagement } from 'modules/user';
import { useEwaCenterManagement } from 'modules/ewa-center';
import { Badge, Button, Card, FilesUpload, FormRow, FormSection, FormHelperText, FormControl } from 'components/common';
import { NON_PHYSICAL_EMPLOYER_ID_PATTERN, ZERO_WIDTH_SPACE_UNICODE, TestId } from 'const';
import { FormTextField, FormSelect, FormAutocomplete } from 'components/form-components';
import { Level } from 'components/common/Badge';
import { isNil } from 'lodash';
import { addLaborerSchema, AddLaborerFormData } from './validation';
import { useStyles } from './AddLaborerForm.styles';

const formInitialValues: AddLaborerFormData = {
  laborerName: '',
  laborerNumber: '',
  laborerNationality: '',
  laborerPassportNumber: '',
  laborerOccupationCode: '',
  laborerStatus: '',
  arrivedDate: '',
  laborTraffickingStudied: false,
  workerSupported: false,
  DaysBetween: '',
  lastEntryDate: '',
  isPRORequired: false,
  registrarClassification: '',
  referredBy: '',
  referredByOther: '',
  hostingType: '',
  hostingTypeOther: '',
  employerName: null,
  employerId: null,
  employerMobileNumber: null,
  proInformation: {
    name: null,
    email: null,
    mobileNumber: null,
  },
};

const isFieldFilled = (laborer: LaborerInfoResponseDto | null) => (path: string): boolean => {
  return Boolean(get(laborer, path));
};

type AddLaborerFormProps = {
  laborerInfo: LaborerInfoResponseDto | null;
  laborerOccupations: OccupationResponse[];
  hostingTypeOptions: HostingTypeOptions[];
  referralTypeOptions: ReferralTypeOptions[];
  registrarCaseClassifications: RegistrarClassificationResponse[];
  nationalities: NationalityResponseDto[];
  notEligible: boolean;
  onFileListChange: (files: File[]) => void;
  onSubmit: SubmitHandler<AddLaborerFormData>;
};

export const AddLaborerForm: React.FC<AddLaborerFormProps> = ({
  laborerInfo,
  laborerOccupations,
  hostingTypeOptions,
  referralTypeOptions,
  registrarCaseClassifications,
  nationalities,
  notEligible,
  onSubmit,
  onFileListChange,
}) => {
  const [disclaimed, setChecked] = React.useState(false);
  const [LaborTraffickingStudied, setLaborTraffickingStudied] = useState<boolean>(false);
  const [WorkerSupported, setWorkerSupported] = useState<boolean>(false);
  const [IsPRORequired, setIsPRORequired] = useState<boolean>(false);
  const [LastEntryDate, setLastEntryDate] = useState<string | null>('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleWorkerSupported = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkerSupported(event.target.checked);
  };

  const handleIsPRORequired = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPRORequired(event.target.checked);
  };
  const handleLaborTrafficking = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaborTraffickingStudied(event.target.checked);
  };
  const interceptOnSubmit = (formData: AddLaborerFormData) => {
    if (!disclaimed) {
      return;
    }
    onSubmit({
      ...formData,
      workerSupported: WorkerSupported,
      isPRORequired: IsPRORequired,
      laborTraffickingStudied: LaborTraffickingStudied,
      DaysBetween: daysBetween.toString(),
      lastEntryDate: LastEntryDate,
    });
  };
  const { t } = useTranslation();
  const styles = useStyles();
  const formMethods = useForm<AddLaborerFormData>({
    mode: 'onChange',
    defaultValues: formInitialValues,
    resolver: yupResolver(addLaborerSchema()),
  });
  const disclaimerLabel =
    'أتعهد بالمحافظة على سرية جميع البيانات المذكورة أعلاه وبصحة البيانات المدخلة وأتحمل كافة المسؤولية ‎ في حال ثبت غير ذلك';
  const { watch, setValue, reset, trigger, handleSubmit, formState } = formMethods;
  const hostingTypeValue = watch('hostingType');
  const referredByValue = watch('referredBy');
  const registrarClassificationValue = watch('registrarClassification');
  const isFilled = isFieldFilled(laborerInfo);
  const isNonPhysicalEmployer = !!laborerInfo && NON_PHYSICAL_EMPLOYER_ID_PATTERN.test(String(laborerInfo.employerId));
  const isProFieldDisabled = isNonPhysicalEmployer || notEligible;
  const proNull = !!laborerInfo && isNil(laborerInfo.proInformation.name);
  const isMobileNumberNull = !!laborerInfo && isNil(laborerInfo.employerMobileNumber);
  const isEmployerNameNull = !!laborerInfo && isNil(laborerInfo.employerName);
  const isEmployerIDNull = !!laborerInfo && isNil(laborerInfo.employerId);
  const { fetchEwaCenter } = useEwaCenterManagement();
  const { loggedUser } = useUsersManagement();
  const { ewaCenter } = useEwaCenterManagement();
  const ewaCenterType = ewaCenter?.ewaCenterType || '';
  const ewaCenterId = loggedUser?.ewaCenterId || '';

  useEffect(() => {
    fetchEwaCenter(ewaCenterId);
  }, []);

  const isFieldDisabled = (fieldName: string) => {
    try {
      yup.reach(addLaborerSchema(), fieldName).validateSync(get(laborerInfo, fieldName));
      return isFilled(fieldName);
    } catch {
      return false;
    }
  };
  const filterFunc = () => hostingTypeOptions.slice(17);
  useEffect(() => {
    if (laborerInfo) {
      const normalizedFormData = {
        ...formInitialValues,
        ...normalizeFormInput(laborerInfo),
      };
      const formValues = Object.entries<string>(normalizedFormData).map<string[]>(([fieldName, fieldValue]) => [
        fieldName,
        fieldValue,
      ]);

      formValues.forEach(([fieldName, fieldValue]) => {
        setValue(fieldName, fieldValue, { shouldValidate: true, shouldDirty: true });
      });

      setLastEntryDate(laborerInfo.lastEntryDate);
    } else {
      // INFO: this visually clears values from form inputs
      reset(formInitialValues);
    }
  }, [laborerInfo]);

  useEffect(() => {
    const triggerHostingTypeValidation = async () => {
      await trigger('hostingType');
    };

    if (isCaseHostingType(registrarClassificationValue)) {
      triggerHostingTypeValidation();
    }
  }, [registrarClassificationValue]);

  useEffect(() => {
    const triggerHostingTypeOtherValidation = async () => {
      await trigger('hostingTypeOther');
    };
    const triggerReferredByOtherValidation = async () => {
      await trigger('referredByOther');
    };

    if (hostingTypeValue && isOtherSelected(hostingTypeValue)) {
      triggerHostingTypeOtherValidation();
    }

    if (isOtherSelected(referredByValue)) {
      triggerReferredByOtherValidation();
    }
  }, [hostingTypeValue, referredByValue]);

  const daysBetween = moment(new Date()).diff(formMethods.getValues().arrivedDate, 'days');
  const hasRoles = useSelector(hasUserRoles);
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(interceptOnSubmit)} autoComplete="off">
        {ewaCenterType && ['1', '4'].includes(ewaCenterType.toString()) && (
          <FormControlLabel
            className={styles.checkBox}
            name="isPRORequired"
            control={<Checkbox checked={IsPRORequired} onChange={handleIsPRORequired} color={'primary'} />}
            label={t(['Common', 'IsPRORequired'])}
          />
        )}
        <Box className={styles.detailsContainer}>
          <Card
            className={styles.detailsCard}
            elevation={3}
            badge={<Badge level={Level.INFO}>{t(['Common', 'LaborerInfo'])}</Badge>}
          >
            <Box marginRight={2}>
              <FormTextField
                className={styles.formField}
                name="laborerName"
                label={t(['Common', 'LaborerName'])}
                disabled={isFieldDisabled('laborerName') || notEligible}
                InputProps={{ classes: { root: styles.unsetInputWidth } }}
              />

              <FormAutocomplete
                dataTestId={TestId.AddLaborerCaseNationalityInput}
                className={styles.formField}
                name="laborerNationality"
                label={t(['Common', 'Nationality'])}
                disabled={isFieldDisabled('laborerNationality') || notEligible}
                options={nationalities}
                labelKey="arabicNationalityName"
                valueKey="name"
                TextFieldProps={{ InputProps: { classes: { root: styles.unsetInputWidth } } }}
              />

              <FormSelect
                className={styles.formField}
                name="laborerOccupationCode"
                label={t(['Common', 'Occupation'])}
                disabled={isFieldDisabled('laborerOccupationCode') || notEligible}
                options={laborerOccupations}
                labelKey="arabicName"
                valueKey="code"
                InputProps={{ classes: { root: styles.unsetInputWidth } }}
              />
              {isFieldDisabled('arrivedDate') && (
                <React.Fragment>
                  <FormTextField
                    className={styles.formField}
                    name="arrivedDate"
                    isNeedFormatDate
                    label={t(['Common', 'ArrivedDate'])}
                    disabled
                    InputProps={{ classes: { root: styles.wideInput } }}
                  />
                  <FormTextField
                    className={styles.formFieldWide}
                    name="DaysBetween"
                    value={daysBetween}
                    label={t(['Common', 'DaysSinceEntry'])}
                    disabled
                    InputProps={{ classes: { root: styles.wideInput } }}
                  />
                  <FormTextField
                    className={styles.hidden}
                    name="lastEntryDate"
                    isNeedFormatDate
                    label={t(['Common', 'ArrivedDate'])}
                    disabled
                    InputProps={{ classes: { root: styles.wideInput } }}
                  />
                </React.Fragment>
              )}
            </Box>

            <Box>
              <FormTextField
                className={styles.formField}
                name="laborerNumber"
                label={t(['Laborer', 'BorderIqamaNo'])}
                disabled
                InputProps={{ classes: { root: styles.unsetInputWidth } }}
              />
              <FormTextField
                className={styles.formField}
                name="laborerPassportNumber"
                label={t(['Common', 'PassportNo'])}
                disabled={isFieldDisabled('laborerPassportNumber') || notEligible}
                InputProps={{ classes: { root: styles.unsetInputWidth } }}
              />
              <FormTextField
                className={styles.formField}
                name="laborerStatus"
                label={t(['Common', 'LaborerStatus'])}
                disabled
                InputProps={{ classes: { root: styles.unsetInputWidth } }}
              />
            </Box>
          </Card>
          {hasRoles([UserRole.MLSD]) && (
            <Card
              className={styles.detailsCard}
              elevation={3}
              badge={<Badge level={Level.INFO}>{t(['Laborer', 'CaseDetailsEmployerInformation'])}</Badge>}
            >
              <Box marginRight={2}>
                <FormTextField
                  className={styles.formField}
                  name="employerName"
                  label={t(['Common', 'EmployerName'])}
                  placeholder={isEmployerNameNull ? '-' : ''}
                  disabled
                  InputProps={{ classes: { root: styles.unsetInputWidth } }}
                />
                <FormTextField
                  className={styles.formField}
                  name="employerMobileNumber"
                  label={t(['Common', 'MobileNo'])}
                  placeholder={isMobileNumberNull ? '-' : '9665xxxxxxxx+'}
                  disabled
                  InputProps={{ classes: { root: styles.unsetInputWidth } }}
                />
              </Box>
              <Box>
                <FormTextField
                  className={styles.formField}
                  name="employerId"
                  label={t(['Laborer', 'EmployerIdNo'])}
                  placeholder={isEmployerIDNull ? '-' : ''}
                  disabled
                  InputProps={{ classes: { root: styles.unsetInputWidth } }}
                />
              </Box>
            </Card>
          )}
          {/* {ewaCenterType && ewaCenterType.toString() !== '2' && (
            <Card
              className={styles.detailsCard}
              elevation={3}
              badge={
                <Badge level={isNonPhysicalEmployer ? Level.DEFAULT : Level.INFO}>
                  {t(['Laborer', 'CaseDetailsProInformation'])}
                </Badge>
              }
            >
              <Box marginRight={2}>
                <FormTextField
                  className={styles.formField}
                  name="proInformation.name"
                  label={t(['Laborer', 'ProName'])}
                  placeholder={proNull ? '-' : ''}
                  disabled
                  InputProps={{ grayedOut: isNonPhysicalEmployer, classes: { root: styles.unsetInputWidth } }}
                />

                <FormTextField
                  className={styles.formField}
                  name="proInformation.email"
                  label={t(['Common', 'Email'])}
                  placeholder={proNull ? '-' : isNonPhysicalEmployer ? '' : 'x@x.x'}
                  disabled
                  InputProps={{ grayedOut: isNonPhysicalEmployer, classes: { root: styles.unsetInputWidth } }}
                />
              </Box>

              <Box>
                <FormTextField
                  className={styles.formField}
                  name="proInformation.mobileNumber"
                  label={t(['Common', 'MobileNo'])}
                  placeholder={proNull ? '-' : isNonPhysicalEmployer ? '' : '9665xxxxxxxx+'}
                  disabled
                  InputProps={{ grayedOut: isNonPhysicalEmployer, classes: { root: styles.unsetInputWidth } }}
                />
              </Box>
            </Card>
          )} */}
        </Box>
        <FormSection>
          <FormControl>
            <FormControlLabel
              className={styles.checkBox}
              name="laborTraffickingStudied"
              control={
                <Checkbox checked={LaborTraffickingStudied} onChange={handleLaborTrafficking} color={'primary'} />
              }
              label={t(['Common', 'LaborTraffickingStudied'])}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              className={styles.checkBox}
              name="workerSupported"
              control={<Checkbox checked={WorkerSupported} onChange={handleWorkerSupported} color={'primary'} />}
              label={t(['Common', 'WorkerSupported'])}
            />
          </FormControl>
        </FormSection>

        <div className={styles.formField}>
          <FormRow>
            <FormSelect
              name="referredBy"
              label={t(['Common', 'ReferredBy'])}
              disabled={!referralTypeOptions.length}
              options={referralTypeOptions}
              labelKey="arabicName"
              valueKey="name"
            />
            {isOtherSelected(referredByValue) && (
              <FormTextField
                name="referredByOther"
                label={ZERO_WIDTH_SPACE_UNICODE}
                InputProps={{ classes: { root: styles.wideInput } }}
              />
            )}
          </FormRow>
        </div>

        <div className={styles.formField}>
          <FormRow>
            <FormSelect
              className={styles.formField}
              name="registrarClassification"
              label={t(['Laborer', 'RegistrarClassification'])}
              disabled={!registrarCaseClassifications.length || notEligible}
              options={registrarCaseClassifications}
              labelKey="arabicName"
              valueKey="name"
            />
            {isCaseHostingType(registrarClassificationValue) && (
              <div className={styles.formField}>
                <FormRow>
                  <FormSelect
                    name="hostingType"
                    label={t(['Laborer', 'HostingType'])}
                    disabled={!hostingTypeOptions.length}
                    options={filterFunc()}
                    labelKey="arabicName"
                    valueKey="name"
                    InputProps={{ classes: { root: styles.wideInput } }}
                  />

                  {hostingTypeValue && isOtherSelected(hostingTypeValue) && (
                    <FormTextField
                      name="hostingTypeOther"
                      label={ZERO_WIDTH_SPACE_UNICODE}
                      InputProps={{ classes: { root: styles.wideInput } }}
                    />
                  )}
                </FormRow>
              </div>
            )}
          </FormRow>
        </div>

        <FormSection title={t(['Laborer', 'FilesSection'])}>
          <FilesUpload dataTestId={TestId.AddLaborerCaseUploadFileButton} onChange={onFileListChange} />
        </FormSection>
        <FormControl required error={!disclaimed}>
          <FormControlLabel
            className={styles.disclaimer}
            name="disclaimed"
            control={<Checkbox checked={disclaimed} onChange={handleChange} color={'primary'} />}
            label={disclaimerLabel}
          />
          {formState.isSubmitted && !disclaimed && (
            <FormHelperText error>{t(['Common', 'Validation', 'Required'])}</FormHelperText>
          )}
        </FormControl>
        <FormSection>
          <Button type="submit">{t(['Common', 'Submit'])}</Button>
        </FormSection>
      </form>
    </FormProvider>
  );
};

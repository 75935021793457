/**
 * Layout
 */
export const SECTION_MARGIN_HORIZONTAL = 4;
export const SECTION_MARGIN_VERTICAL = 8;
export const HELPER_TEXT_MIN_HEIGHT = 25;

/**
 * Miscellaneous
 */
export const REPLACE_SIGN = '-';
export const OTHER_OPTION = 'OTHER';
export const LTR_UNICODE = '\u200E';
export const ZERO_WIDTH_SPACE_UNICODE = '\u200B';

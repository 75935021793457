import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Snackbar, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { NotificationVariant, useSharedManagement } from 'modules/shared';
import { useTranslation } from 'services';
import { useStyles } from './NotificationOutlet.styles';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

type NotificationOutletState = {
  message: string;
  variant: NotificationVariant;
  keepOpen: boolean;
  open: boolean;
  closing: boolean;
};

const initialState: NotificationOutletState = {
  message: '',
  variant: 'success',
  open: false,
  keepOpen: false,
  closing: false,
};

// TODO: do not show duplicates if same notification are next to each other in the queue

export const NotificationOutlet: React.FC = () => {
  const [state, setState] = useState<NotificationOutletState>(initialState);
  const styles = useStyles();
  const { notifyUserSuccess, notificationQueue } = useSharedManagement();
  const Icon = variantIcon[state.variant];
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const handleClose = (_: React.SyntheticEvent | MouseEvent, reason?: string): void => {
    if (reason === 'timeout' && !state.open) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      open: false,
      closing: true,
    }));
  };

  const handleExited = (): void => {
    notifyUserSuccess();
    setState({ ...initialState });
  };

  useEffect(() => {
    if (!state.open && !state.closing && notificationQueue.length > 0) {
      const [notification] = notificationQueue;

      setState({
        message: notification.message,
        variant: notification.variant || 'success',
        open: true,
        keepOpen: notification.keepOpen || false,
        closing: false,
      });
    }
  }, [state, notificationQueue]);

  return (
    <div dir="ltr">
      <Snackbar
        className={styles.root}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        autoHideDuration={state.keepOpen ? null : 5000}
        message={
          <span className={styles.message} style={{ textAlign: isArabic ? 'right' : 'left' }}>
            <Icon className={cn(styles.icon, styles.iconVariant)} />
            {state.message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={e => handleClose(e, 'user-action')}>
            <CloseIcon className={styles.icon} />
          </IconButton>,
        ]}
        onClose={handleClose}
        onExited={handleExited}
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: false,
        }}
        ContentProps={{ className: styles[state.variant] }}
        disableWindowBlurListener
      />
    </div>
  );
};

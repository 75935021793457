import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Input } from 'components/common';
import { Filter } from '../types';
import { useStyles } from './FilterInput.styles';

type FilterInputProps = {
  name: string;
  onSubmit: (data: Filter) => void;
  number?: boolean;
  submitEmptyValue?: boolean;
};

export const FilterInput: React.FC<FilterInputProps> = ({ name, onSubmit, number, submitEmptyValue = false }) => {
  const styles = useStyles();
  const [value, setValue] = useState<string>('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (value !== '' || submitEmptyValue) {
      onSubmit({ name, data: value });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value;
    const numRegex = /^[0-9]*$/;

    if (!number || numRegex.test(val)) {
      setValue(val);
    }
  };

  return (
    <Box className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit} autoComplete="off">
        <Input
          classes={{ notchedOutline: styles.outline }}
          name="search"
          value={value}
          onChange={handleChange}
          autoFocus
        />

        <IconButton type="submit" color="primary" disableFocusRipple disableRipple size="small">
          <SearchIcon />
        </IconButton>
      </form>
    </Box>
  );
};

/* eslint no-console: 0 */

export class LocalStorage {
  key: string;
  localStorage: Storage;

  constructor(key: string, localStorage: Storage) {
    this.key = key;
    this.localStorage = localStorage;
  }

  load<T>(): T | null {
    const defaultValue = null;

    try {
      const value = this.localStorage.getItem(this.key);

      if (value) {
        try {
          return JSON.parse(value);
        } catch (e) {
          console.warn('This value is not JSON.parse-able', e);
          return defaultValue;
        }
      }

      return defaultValue;
    } catch (e) {
      console.warn('Error getting access to storage. Are cookies blocked?', e);
      return defaultValue;
    }
  }

  save(value: unknown): void | null {
    try {
      return this.localStorage.setItem(this.key, JSON.stringify(value));
    } catch (e) {
      console.warn('Error getting access to storage. Are cookies blocked?', e);
      return null;
    }
  }
}

import moment from 'moment';
import momentHijri from 'moment-hijri';

export const DEFAULT_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_HIJRI_FORMAT = 'iDD/iMM/iYYYY';
export const DEFAULT_HIJRI_FULL_TIME_FORMAT = 'iDD/iMM/iYYYY, hh:mm A';
export const DEFAULT_FULL_FORMAT = 'DD/MM/YYYY, hh:mm A';

export const DEFAULT_DATE_RANGE = {
  MIN_DATE: '1900-01-01',
  MAX_DATE: '2100-01-01',
  HIJRI_MIN_DATE: '1921-09-02',
  HIJRI_MAX_DATE: '2076-11-26',
};

export const format = (date: string, dateFormat = DEFAULT_FORMAT): string => {
  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.locale('en-GB').format(dateFormat);
  }

  return date;
};

export const fullFormat = (date: string, dateFormat = DEFAULT_FULL_FORMAT): string => {
  const momentDate = moment(date);

  if (momentDate.isValid()) {
    return momentDate.locale('en-GB').format(dateFormat);
  }

  return date;
};

export const toUTCISOString = (date: Date | number, keepOffset?: boolean): string =>
  moment(date).locale('en-GB').toISOString(keepOffset);

export const toHijriDateFormat = (date: string, dateFormat = DEFAULT_HIJRI_FORMAT): string => {
  const momentDate = momentHijri(date);

  if (momentDate.isValid()) {
    return momentDate.locale('en-GB').format(dateFormat);
  }

  return date;
};

export const toHijriDateTimeFormat = (date: string): string => toHijriDateFormat(date, DEFAULT_HIJRI_FULL_TIME_FORMAT);

export const isDateValid = (date: any): boolean => moment(date).isValid();

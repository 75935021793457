import { makeStyles, Theme } from '@material-ui/core/styles';

type EditableStyleProps = {
  editing: boolean;
};

export const useStyles = makeStyles<Theme, EditableStyleProps>(theme => ({
  container: {
    display: 'flex',
    alignItems: props => (props.editing ? 'start' : 'center'),
  },
  icon: {
    marginLeft: theme.spacing(1),
    height: 30,
  },
}));

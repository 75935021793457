import React, { useState } from 'react';
import cn from 'classnames';
import { Box, Collapse } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Card } from 'components';
import { useStyles } from './FormSection.styles';

type FormSectionPropTypes = PropsWithDataTestId<{
  title: string;
}>;

export const FormSection: React.FC<FormSectionPropTypes> = ({ children, title, dataTestId }) => {
  const styles = useStyles();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleToggle = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Card className={styles.container}>
      <Box className={styles.header}>
        <Box className={styles.title}>{title}</Box>
        <button data-test-id={dataTestId} className={styles.toggler} onClick={handleToggle} type="button">
          <KeyboardArrowDownIcon className={cn(styles.arrow, { [styles.rotated]: isOpened })} />
        </button>
      </Box>
      <Collapse in={isOpened}>
        <Box className={styles.content}>{children}</Box>
      </Collapse>
    </Card>
  );
};

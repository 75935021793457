import React from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'services/translation-client';
import { useSharedManagement } from 'modules/shared';
import { useEwaCenterManagement, getEwaCenterNameFromId } from 'modules/ewa-center';
import { getReportFile } from '../api';

export const useReportFile = () => {
  const { t } = useTranslation();

  const { notifyUser } = useSharedManagement();
  const { ewaCenters } = useEwaCenterManagement();

  const downloadReport = async (startDate: string, endDate: string, ewaCenter: string | null): Promise<void> => {
    const ewaCenterName = getEwaCenterNameFromId(ewaCenters, ewaCenter);

    try {
      const formattedStartDate = moment(startDate).hours(3).minutes(0).seconds(0).milliseconds(0).toISOString();
      const formattedEndDate = moment(endDate).hours(26).minutes(59).seconds(59).milliseconds(0).toISOString();
      const file = await getReportFile(formattedStartDate, formattedEndDate, ewaCenter);
      saveAs(
        file,
        `قائمة العاملات المتواجدات في مركز إيواء  ${ewaCenterName} من تاريخ ${moment(startDate).format(
          'YYYY-MM-DD'
        )} حتى تاريخ ${moment(endDate).format('YYYY-MM-DD')}.xlsx`
      );
    } catch (e) {
      if (e.status === 404) {
        notifyUser(t(['Laborer', 'NoLaborerRecordError']), 'error');
      } else {
        notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
      }
    }
  };

  return {
    downloadReport,
  };
};

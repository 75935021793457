/**
 * This regex matches strings that start with '+9665' followed by any 8 digits
 */
export const ARABIC_PHONE_PATTERN = /^(\+?9665)\d{8}$/;

/**
 * This regex matches \u0621-\u064A range ( arabic alphabet ), any whitespace
 * any lower and any uppercase Latin character and also period and hyphen signs
 */
export const ARABIC_LATIN_CHARACTER_PATTERN = /^[\s\u0621-\u064Aa-zA-Z.-]+$/;

/**
 * This regex matches \u0621-\u064A range ( arabic alphabet ) and \u0660-\u0669 range ( arabic-indic numbers),
 * any whitespace, any lower, any uppercase Latin character and numbers, also period and hyphen signs
 */
export const ARABIC_LATIN_ALPHANUMERIC_PATTERN = /^[\s\u0621-\u064A\u0660-\u0669a-zA-Z0-9.-]+$/;

/**
 * This regex matches strings that contain only 10 digits and the starting character is 2
 */
export const IQAMA_NUMBER_PATTERN = /^(?=2)\d{10}$/;

/**
 * This regex matches strings that contain only 10 digits and the starting character is 2 or 3 or 4
 */
export const IQAMA_BORDER_NUMBER_PATTERN = /^(?=2|3|4)\d{10}$/;

/**
 * This regex matches strings that contain only 10 digits and the starting character is either 1 or 2
 */
export const EMPLOYER_ID_PATTERN = /^(?=1|2)\d{10}$/;

/**
 * This regex matches strings that contain only 10 digits and the starting character is either 1, 2 or 7
 */
export const RETRIEVED_EMPLOYER_ID_PATTERN = /^(?=1|2|7)\d{10}$/;

/**
 * This regex matches strings that contain only 10 digits and the starting character is 7
 */
export const NON_PHYSICAL_EMPLOYER_ID_PATTERN = /^(?=7)\d{10}$/;

/**
 * This regex matches number with hyphens
 */
export const LABORER_PRO_ID_PATTERN = /^\d+(?:-\d+)*$/;

/**
 * This is a placeholder for address regex pattern, for now it accepts any char
 */
export const ADDRESS_PATTERN = /^.*$/;

/**
 * This regex matches strings that are either empty or any character is in range of \u0621-\u064A ( arabic alphabet )
 * or \u0660-\u0669 ( arabic-indic numbers )
 */
export const DISALLOWED_ARABIC_CHARACTER_PATTERN = /^[^\u0621-\u064A\u0660-\u0669]+$|^$/;

/**
 * This is UUID v4 base string
 */
export const UUID_V4_BASE = '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

/**
 * This regex matches strings that are UUID version 4 compliant
 */
export const UUID_V4_PATTERN = new RegExp(`^${UUID_V4_BASE}$`, 'i');

/**
 * This regex matches strings that contain only 10 digits
 */
export const PRO_NUMBER_PATTERN = /^\d{10}$/;

/**
 * This regex matches strings that contain  8-10 digits
 */
export const PASSPORT_NUMBER_PATTERN = /^.{5,10}$/;

/**
 * This regex matches strings that contain only 10 digits and the starting character is 3 or 4
 */
export const BORDER_NUMBER_PATTERN = /^(?=3|4)\d{10}$/;

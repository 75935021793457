import { Observable, OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FluxAction, ActionsOfType } from './create-action';

/**
 * Helper function for reducing boilerplate in epics.
 *
 *  Original implementation: {@link https://github.com/Hotell/rex-tils/blob/master/src/redux/rx-operators.ts}.
 *
 * @param {T1 extends string} t1
 */
export function ofType<V, T1 extends string>(t1: T1): OperatorFunction<V, ActionsOfType<V, T1>>;

/**
 * Helper function for reducing boilerplate in epics.
 *
 *  Original implementation: {@link https://github.com/Hotell/rex-tils/blob/master/src/redux/rx-operators.ts}.
 *
 * @param {T1 extends string} t1
 * @param {T1 extends string} t2
 */
export function ofType<V, T1 extends string, T2 extends string>(
  t1: T1,
  t2: T2
): OperatorFunction<V, ActionsOfType<V, T1 | T2>>;
export function ofType<V, T1 extends string, T2 extends string, T3 extends string>(
  t1: T1,
  t2: T2,
  t3: T3
): OperatorFunction<V, ActionsOfType<V, T1 | T2 | T3>>;
export function ofType<V, T1 extends string, T2 extends string, T3 extends string, T4 extends string>(
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4
): OperatorFunction<V, ActionsOfType<V, T1 | T2 | T3 | T4>>;
export function ofType<
  V,
  T1 extends string,
  T2 extends string,
  T3 extends string,
  T4 extends string,
  T5 extends string
>(t1: T1, t2: T2, t3: T3, t4: T4, t5: T5): OperatorFunction<V, ActionsOfType<V, T1 | T2 | T3 | T4 | T5>>;

export function ofType(...keys: string[]) {
  return (source: Observable<FluxAction<string>>) => source.pipe(filter(action => keys.indexOf(action.type) !== -1));
}

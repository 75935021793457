import moment from 'moment';
import { Level } from 'components/common/Badge';
import { Query, isErrorEqualTo, isAPIError } from 'modules/http';
import { isObject } from 'modules/shared';
import { LaborerCaseStatus, LaborerCasesQuery, ReservationEvent } from './types';

export const CASE_REOPEN_TIME_WINDOW = 7;

const statusTranslationMap: Record<LaborerCaseStatus, string> = {
  PENDING_LC_REVIEW: 'Case:Status.PendingLCReview',
  UNDER_LC_REVIEW: '',
  PENDING_SW_REVIEW: 'Case:Status.PendingSWReview',
  UNDER_SW_REVIEW: '',
  PENDING_CLEARANCE: 'Case:Status.PendingClearance',
  CLOSED: 'Case:ClosedStatus',
  REOPENED: 'Case:ReopenedStatus',
};

const statusLevelMap: Record<LaborerCaseStatus, Level> = {
  PENDING_LC_REVIEW: Level.DEFAULT,
  UNDER_LC_REVIEW: Level.DEFAULT,
  PENDING_SW_REVIEW: Level.DEFAULT,
  UNDER_SW_REVIEW: Level.DEFAULT,
  PENDING_CLEARANCE: Level.DEFAULT,
  CLOSED: Level.SUCCESS,
  REOPENED: Level.DANGER,
};

export const filterEntryTranslationMap: Record<keyof Omit<LaborerCasesQuery, keyof Query>, string> = {
  laborerNumber: 'Laborer:CaseIqamaNo',
  registrarClassificationId: 'Laborer:RegistrarClassification',
  laborCommitteeClassificationId: 'Laborer:CommitteeClassification',
  status: 'Laborer:CaseStatus',
  laborerNationality: 'Nationality',
  hostingType: 'Laborer:HostingType',
  hostingTypeOther: 'Laborer:HostingType',
  ewaCenterId: 'Users:EwaCenter',
  laborerPassportNumber: 'Common:PassportNo',
  stayDurationFrom: 'Common:From',
  stayDurationTo: 'Common:To',
};

// TODO: create Dto builder
const laborerDtoMap: { [key: string]: string } = {
  borderNumber: 'laborerNumber',
  iqamaNumber: 'laborerNumber',
  name: 'laborerName',
  nationality: 'laborerNationality',
  occupation: 'laborerOccupationCode',
};

export const getBadgeStatusLevel = (currentStatus: LaborerCaseStatus) => statusLevelMap[currentStatus];

export const getCaseStatusTranslationKey = (currentStatus: LaborerCaseStatus) => statusTranslationMap[currentStatus];

export const remainingDaysFromReopenTimeWindow = (exitDateTime: string): number => {
  const today = moment().startOf('day');
  const dayOfSevenDaysFromExitTime = moment(exitDateTime).add(CASE_REOPEN_TIME_WINDOW, 'days').startOf('day');

  if (dayOfSevenDaysFromExitTime.diff(today, 'days') === 0) {
    return 1;
  }

  return dayOfSevenDaysFromExitTime.diff(today, 'days');
};

export const isReopenWindowAvailable = (exitDateTime: string): boolean => {
  const today = moment().startOf('day');
  const dayOfExitDateTime = moment(exitDateTime).startOf('day');

  return today.diff(dayOfExitDateTime, 'days') <= CASE_REOPEN_TIME_WINDOW;
};

export const mapLaborerInfoByEmployerIdToLaborerCase = <T>(data: T) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [laborerDtoMap[key] ? laborerDtoMap[key] : key, value])
  );

export const isReservationEvent = (eventData: any): eventData is ReservationEvent => {
  if (isObject(eventData)) {
    return (
      'laborerCaseId' in eventData &&
      'eventType' in eventData &&
      (eventData.eventType === 'RESERVE' || eventData.eventType === 'RELEASE')
    );
  }

  return false;
};

export const isReleaseEvent = (eventData: any) => isReservationEvent(eventData) && eventData.eventType === 'RELEASE';

export const isReservedByUserEvent = (eventData: any, userId: string | null) =>
  isReservationEvent(eventData) && userId && eventData.eventType === 'RESERVE' && eventData.userId === userId;

export const isCaseAlredyReservedEvent = (eventData: any) =>
  isObject(eventData) && eventData.eventType === 'ERROR' && eventData.message === 'Case is already reserved.';

export const preparePostLaborerCaseErrorMessage = (error: unknown): string =>
  isAPIError(error) && isErrorEqualTo(error, 'CONFLICT', 'LABORER_BY_ID_EXISTS')
    ? 'Laborer:AlreadyExistsError'
    : isAPIError(error) && isErrorEqualTo(error, 'LOCKED', 'EWA_CENTER_INSUFFICIENT_POINT')
    ? 'Common:InsufficientErrorMessage'
    : 'Common:GeneralErrorMessage';

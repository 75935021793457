import { makeStyles, Theme } from '@material-ui/core/styles';
import { SECTION_MARGIN_HORIZONTAL } from 'const';

export const useStyles = makeStyles((theme: Theme) => ({
  formRow: {
    display: 'flex',
    '& + &': {
      marginTop: 6,
    },
  },
  child: {
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      marginLeft: theme.spacing(SECTION_MARGIN_HORIZONTAL),
      marginRight: theme.spacing(SECTION_MARGIN_HORIZONTAL),
    },
  },
}));

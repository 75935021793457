import { isBoolean } from 'lodash';
import { LaborerAssessmentResponse } from 'modules/laborer-case';
import { isArabic } from 'modules/shared';

export const normalizeCaseAssessmentDetails = (caseAssessment: LaborerAssessmentResponse) => {
  return Object.fromEntries(
    Object.entries(caseAssessment).map(([key, value]) => {
      return [key, isBoolean(value) ? (value === true ? 'true' : 'false') : value];
    })
  );
};

/**
 * Currently we have 4 different kinds of row propotion in our pdf layouts, these are:
 * 20%/30% - table column that act as a header has 20% width, table column that holds value has 30% width
 * 20%/80% - table column that act as a header has 20% width, table column that holds value has 80% width
 * 40%/60% - table column that act as a header has 40% width, table column that holds value has 60% width
 * 50%/50% - table column that act as a header has 50% width, table column that holds value has 50% width
 */
type RowProportions = '20/30' | '20/80' | '40/60' | '50/50';

export const flipParentheses = (sentence: string): string => {
  return sentence.replace(/\((.*?)\)/g, ')$1(');
};

/**
 * Creates a regular expression that will split long sentences into smaller chunks by passed characterLimit param.
 * Chunks are created in a manner that words are staying untouched and are not cut in half.
 */
const createSplittingRegExp = (characterLimit: number): RegExp => {
  return new RegExp(`\\S[\\s\\S]{0,${characterLimit}}\\S(?=\\s|$)`, 'g');
};

/**
 * Split a given sentence by a new line character and flat map all the chunks against the passed regexp
 * so we end up with array of strings
 */
export const splitAndMap = (sentence: string, regexp: RegExp): (string | null)[] => {
  return sentence.split('\n').flatMap(chunk => chunk.match(regexp));
};

/**
 * NOTE: below function is created to mitigate the react-pdf not supporting RTL languages.
 *
 * Issue: strings are being processed as LTR and the non-extendable, built-in algorithm
 * would create a multilined sequences of flipped arabic sentences. Also parentheses signs "(" or ")"
 * are being flipped as well.
 *
 * Solution: As our print layout has a static dimensions we can create an array of strings
 * divided by constant number of characters.
 *
 * With current table cell font size of 10pt, these are the characters limits that we should be splitting by:
 *
 * 25 - if the sentence is in English and we've got 30% of table row width
 * 30 - if the sentence is in Arabic and we've got 30% of table row width
 *
 * 40 - if the sentence is in English and we've got 50% of table row width
 * 50 - if the sentence is in Arabic and we've got 50% of table row width
 *
 * 55 - if the sentence is in English and we've got 60% of table row width
 * 65 - if the sentence is in Arabic and we've got 60% of table row width
 *
 * 75 - if the sentence is in English and we've got 80% of table row width
 * 85 - if the sentence is in Arabic and we've got 80% of table row width
 */
export const createSequence = (sentence: string, type: RowProportions): (string | null)[] | null => {
  switch (type) {
    case '20/30':
      return isArabic(sentence)
        ? splitAndMap(flipParentheses(sentence), createSplittingRegExp(30))
        : splitAndMap(sentence, createSplittingRegExp(25));

    case '20/80':
      return isArabic(sentence)
        ? splitAndMap(flipParentheses(sentence), createSplittingRegExp(85))
        : splitAndMap(sentence, createSplittingRegExp(75));

    case '40/60':
      return isArabic(sentence)
        ? splitAndMap(flipParentheses(sentence), createSplittingRegExp(65))
        : splitAndMap(sentence, createSplittingRegExp(55));

    case '50/50':
      return isArabic(sentence)
        ? splitAndMap(flipParentheses(sentence), createSplittingRegExp(50))
        : splitAndMap(sentence, createSplittingRegExp(40));

    default:
      return null;
  }
};

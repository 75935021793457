import * as yup from 'yup';
import { i18nClient } from 'services/translation-client';
import { IQAMA_BORDER_NUMBER_PATTERN } from 'const';

export enum LookupSearchByMode {
  BorderOrIqamaNumber = 'borderIqama',
  EmployerId = 'employerId',
}

export type LookupSearchLaborerFormData = {
  searchByMode: LookupSearchByMode;
  searchByValue: string;
  checkDate?: Date | null;
};

export const lookupSearchLaborerSchema = () => {
  return yup.object().shape<LookupSearchLaborerFormData>({
    searchByMode: yup.string<LookupSearchByMode>().defined().oneOf([LookupSearchByMode.BorderOrIqamaNumber]),
    searchByValue: yup
      .string()
      .defined()
      .matches(IQAMA_BORDER_NUMBER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(Laborer:BorderIqamaNo)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Laborer:BorderIqamaNo)' })),
  });
};

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { captureException } from '@sentry/react';
import { createCustomResponse, wait, CustomHttpResponse } from './helpers';

export type Credentials = {
  token?: string;
  idToken?: string;
};

export type BasicRequestConfig = AxiosRequestConfig & {
  repeatNumber?: number;
  delay?: number;
};

interface BasicHttpClientConstructor {
  axiosInstance: AxiosInstance;
}

export class BasicHttpClient {
  axiosInstance: AxiosInstance;

  constructor({ axiosInstance }: BasicHttpClientConstructor) {
    this.axiosInstance = axiosInstance;
  }

  configRepeatInterceptor(): void {
    this.axiosInstance.interceptors.response.use(undefined, async error => {
      const repeatNumber = error.config.repeatNumber || 1;
      const delay = error.config.delay || 2000;

      if (repeatNumber === 1) {
        captureException(error);
        return Promise.reject(error);
      }

      await wait(delay);

      return this.axiosInstance.request({
        ...error.config,
        repeatNumber: repeatNumber - 1,
      });
    });
  }

  async request<T>(path: string, options: BasicRequestConfig): Promise<CustomHttpResponse<T>> {
    const axiosOptions: AxiosRequestConfig = {
      url: path,
      ...options,
    };

    try {
      const response = await this.axiosInstance.request<T>(axiosOptions);

      return Promise.resolve(createCustomResponse<T>(response));
    } catch (error) {
      captureException(error);
      const responseObject = error.response;

      if (!responseObject) {
        const method = axiosOptions.method ? axiosOptions.method.toUpperCase() : 'GET';
        throw new Error(`Could not make ${method} request to URL ${axiosOptions.url} - Received error: ${error}`);
      }

      return Promise.reject(createCustomResponse<T>(responseObject));
    }
  }
}

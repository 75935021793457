import { History } from 'history';
import { createAction } from 'services/store/create-action';
import { ApplicationLocationState } from 'modules/shared';
import { NotificationVariant } from './state';
import {
  RegistrarClassificationResponse,
  LaborCommitteeClassificationResponse,
  ReferralTypeResponse,
  HostingTypeResponse,
  NationalityResponseDto,
  AuthorityTypeResponse,
  OccupationResponse,
} from './types';

export enum SharedActionType {
  SaveCurrentPathname = '[Shared]: Save current location pathname',
  RemoveCurrentPathname = '[Shared] Remove current location pathname',

  NotifyUser = '[Shared]: Notify user',
  NotifyUserSuccess = '[Shared]: Notify user - success',

  InspectLocationQueryParams = '[Shared]: Inspect location query params',

  StartLocationObserver = '[Shared] Start location observer',

  FetchRegistrarCaseClassifications = '[Shared]: Fetch dictionary registrar case classifications',
  FetchRegistrarCaseClassificationsSuccess = '[Shared]: Fetch dictionary registrar case classifications - success',
  FetchRegistrarCaseClassificationsFailure = '[Shared]: Fetch dictionary registrar case classifications - failure',

  FetchCommitteeCaseClassifications = '[Shared]: Fetch dictionary laborcommittee case classifications',
  FetchCommitteeCaseClassificationsSuccess = '[Shared]: Fetch dictionary laborcommittee case classifications - success',
  FetchCommitteeCaseClassificationsFailure = '[Shared]: Fetch dictionary laborcommittee case classifications - failure',

  FetchReferralTypes = '[Shared]: Fetch dictionary referral types',
  FetchReferralTypesSuccess = '[Shared]: Fetch dictionary referral types - success',
  FetchReferralTypesFailure = '[Shared]: Fetch dictionary referral types - failure',

  FetchHostingTypes = '[Shared]: Fetch dictionary hosting types',
  FetchHostingTypesSuccess = '[Shared]: Fetch dictionary hosting types - success',
  FetchHostingTypesFailure = '[Shared]: Fetch dictionary hosting types - failure',

  FetchNationalities = '[Shared]: Fetch dictionary nationalities',
  FetchNationalitiesSuccess = '[Shared]: Fetch dictionary nationalities - success',
  FetchNationalitiesFailure = '[Shared]: Fetch dictionary nationalities - failure',

  FetchAuthorityTypes = '[Shared]: Fetch dictionary authority types',
  FetchAuthorityTypesSuccess = '[Shared]: Fetch dictionary authority types - success',
  FetchAuthorityTypesFailure = '[Shared]: Fetch dictionary authority types - failure',

  FetchLaborerOccupations = '[Shared]: Fetch Laborer occupations',
  FetchLaborerOccupationsSuccess = '[Shared]: Fetch Laborer occupations - success',
  FetchLaborerOccupationsFailure = '[Shared]: Fetch Laborer occupations - failure',
}

export const SharedAction = {
  saveCurrentPathname: (data: string) => createAction(SharedActionType.SaveCurrentPathname, data),
  removeCurrentPathname: () => createAction(SharedActionType.RemoveCurrentPathname),

  notifyUser: (message: string, variant?: NotificationVariant, keepOpen?: boolean) =>
    createAction(SharedActionType.NotifyUser, { message, variant, keepOpen }),
  notifyUserSuccess: () => createAction(SharedActionType.NotifyUserSuccess),

  inspectLocationQueryParams: () => createAction(SharedActionType.InspectLocationQueryParams),

  startLocationObserver: (history: History<ApplicationLocationState>) =>
    createAction(SharedActionType.StartLocationObserver, { history }),

  fetchRegistrarCaseClassifications: () => createAction(SharedActionType.FetchRegistrarCaseClassifications),
  fetchRegistrarCaseClassificationsSuccess: (data: RegistrarClassificationResponse[]) =>
    createAction(SharedActionType.FetchRegistrarCaseClassificationsSuccess, data),
  fetchRegistrarCaseClassificationsFailure: () =>
    createAction(SharedActionType.FetchRegistrarCaseClassificationsFailure),

  fetchLaborCommitteCaseClassifications: () => createAction(SharedActionType.FetchCommitteeCaseClassifications),
  fetchLaborCommitteCaseClassificationsSuccess: (data: LaborCommitteeClassificationResponse[]) =>
    createAction(SharedActionType.FetchCommitteeCaseClassificationsSuccess, data),
  fetchLaborCommitteCaseClassificationsFailure: () =>
    createAction(SharedActionType.FetchCommitteeCaseClassificationsFailure),

  fetchReferralTypes: () => createAction(SharedActionType.FetchReferralTypes),
  fetchReferralTypesSuccess: (data: ReferralTypeResponse[]) =>
    createAction(SharedActionType.FetchReferralTypesSuccess, data),
  fetchReferralTypesFailure: () => createAction(SharedActionType.FetchReferralTypesFailure),

  fetchHostingTypes: () => createAction(SharedActionType.FetchHostingTypes),
  fetchHostingTypesSuccess: (data: HostingTypeResponse[]) =>
    createAction(SharedActionType.FetchHostingTypesSuccess, data),
  fetchHostingTypesFailure: () => createAction(SharedActionType.FetchHostingTypesFailure),

  fetchNationalities: () => createAction(SharedActionType.FetchNationalities),
  fetchNationalitiesSuccess: (data: NationalityResponseDto[]) =>
    createAction(SharedActionType.FetchNationalitiesSuccess, data),
  fetchNationalitiesFailure: () => createAction(SharedActionType.FetchNationalitiesFailure),

  fetchAuthorityTypes: () => createAction(SharedActionType.FetchAuthorityTypes),
  fetchAuthorityTypesSuccess: (data: AuthorityTypeResponse[]) =>
    createAction(SharedActionType.FetchAuthorityTypesSuccess, data),
  fetchAuthorityTypesFailure: () => createAction(SharedActionType.FetchAuthorityTypesFailure),

  fetchLaborerOccupations: () => createAction(SharedActionType.FetchLaborerOccupations),
  fetchLaborerOccupationsSuccess: (data: OccupationResponse[]) =>
    createAction(SharedActionType.FetchLaborerOccupationsSuccess, data),
  fetchLaborerOccupationsFailure: () => createAction(SharedActionType.FetchLaborerOccupationsFailure),
};

export type SharedActionCreator = typeof SharedAction;

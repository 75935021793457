import { useState, useEffect } from 'react';

export const useCountdownTimer = (time: number) => {
  const [isActive, setIsActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isActive || !timeLeft) {
        clearTimeout(timeoutId);
        return;
      }

      setTimeLeft(timeLeft - 1000);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeLeft, isActive]);

  const startCountdown = () => {
    setTimeLeft(time);
    setIsActive(true);
  };

  const stopCountdown = () => {
    setTimeLeft(0);
    setIsActive(false);
  };

  const reset = () => {
    setTimeLeft(time);
    setIsActive(false);
  };

  return {
    isActive,
    timeLeft,
    startCountdown,
    stopCountdown,
    reset,
  };
};

import { combineEpics } from 'redux-observable';
import { userEpics } from 'modules/user/epics';
import { laborerCaseEpics } from 'modules/laborer-case/epics';
import { laborerInfoEpics } from 'modules/laborer-info/epics';
import { ewaCenterEpics } from 'modules/ewa-center/epics';
import { bundlesEpics } from 'modules/bundles/epics';
import { sharedEpics } from 'modules/shared/epics';

export const rootEpic = combineEpics(
  userEpics,
  laborerCaseEpics,
  laborerInfoEpics,
  ewaCenterEpics,
  bundlesEpics,
  sharedEpics
);

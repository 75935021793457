import React from 'react';
import { useTranslation } from 'services';
import { useLaborerCaseReservation } from 'modules/laborer-case';
import { ActionButton } from './ActionButton';

type ReleaseCaseActionButtonProps = {
  type: 'button' | 'link';
  laborerCaseId: string;
  className?: string;
  disabled: boolean;
};

export const ReleaseCaseActionButton: React.FC<ReleaseCaseActionButtonProps> = ({
  type,
  laborerCaseId,
  className,
  disabled,
}) => {
  const { t } = useTranslation();
  const { releaseLaborerCase } = useLaborerCaseReservation();

  const handleReleaseLaborerCase = (): void => {
    releaseLaborerCase(laborerCaseId);
  };

  return (
    <ActionButton
      disabled={disabled}
      className={className}
      type={type}
      label={t(['Case', 'ReleaseCaseAction'])}
      onClick={handleReleaseLaborerCase}
    />
  );
};

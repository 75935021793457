import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
    height: '91px',
    padding: '15px',
    marginTop: '30px',
    '&:first-of-type': {
      marginTop: '20px',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    height: '57px',
    marginBottom: '35px',
  },
  badge: {
    position: 'absolute',
    top: 0,
    transform: 'translateY(-50%)',
  },
}));

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '200px',
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main60,
    borderRadius: '4px',
  },
}));

import * as yup from 'yup';
import { i18nClient } from 'services';
import {
  ARABIC_PHONE_PATTERN,
  ARABIC_LATIN_ALPHANUMERIC_PATTERN,
  ADDRESS_PATTERN,
  DISALLOWED_ARABIC_CHARACTER_PATTERN,
  PRO_NUMBER_PATTERN,
} from 'const';

export type EwaCenterFormData = {
  name: string;
  city: string;
  email: string;
  mobileNumber: string;
  capacity: number | string;
  ewaCenterType: string;
  embassyNicCode?: string | null;
  proNumber?: string | null;

  district: string;
  buildingNumber: number | string;
  streetName: string;
  postalBox: number | null;
  postalCode: number | string;
  locationAdditionalNumber: number | string;
};

export const ewaCenterFormSchema = () => {
  return yup.object().shape<EwaCenterFormData>({
    name: yup
      .string()
      .matches(ARABIC_LATIN_ALPHANUMERIC_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(EwaCenterName)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(EwaCenterName)' })),
    city: yup
      .string()
      .nullable() // autocomplete default value
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(City)' })),
    email: yup
      .string()
      .matches(DISALLOWED_ARABIC_CHARACTER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(Email)' }),
      })
      .email(i18nClient.t('Validation.Pattern', { field: '$t(Email)' }))
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Email)' })),
    mobileNumber: yup
      .string()
      .matches(ARABIC_PHONE_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(MobileNo)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(MobileNo)' })),
    capacity: yup
      .number()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .typeError(i18nClient.t('Validation.Pattern', { field: '$t(Capacity)' }))
      .integer(i18nClient.t('Validation.Pattern', { field: '$t(Capacity)' }))
      .positive(i18nClient.t('Validation.Pattern', { field: '$t(Capacity)' }))
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Capacity)' })),
    ewaCenterType: yup
      .string()
      .nullable()
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(ewaCenterType)' })),
    embassyNicCode: yup
      .string()
      .nullable()
      .when('ewaCenterType', {
        is: ewaCenterType => ewaCenterType === '3',
        then: yup
          .string()
          .typeError(i18nClient.t('Validation.Pattern', { field: '$t(embassyNicCode)' }))
          .required(i18nClient.t('Validation.RequiredField', { field: '$t(embassyNicCode)' })),
        otherwise: yup.string().typeError(i18nClient.t('Validation.Pattern', { field: '$t(embassyNicCode)' })),
      }),
    proNumber: yup
      .string<EwaCenterFormData['proNumber']>()
      .nullable()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .matches(PRO_NUMBER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(ProNumber)' }),
        excludeEmptyString: true,
      })
      .when('ewaCenterType', {
        is: ewaCenterType => ewaCenterType === '2',
        then: yup
          .string()
          .typeError(i18nClient.t('Validation.Pattern', { field: '$t(ProNumber)' }))
          .required(i18nClient.t('Validation.RequiredField', { field: '$t(ProNumber)' })),
        otherwise: yup.string().typeError(i18nClient.t('Validation.Pattern', { field: '$t(ProNumber)' })),
      }),

    district: yup
      .string()
      .matches(ADDRESS_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(District)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(District)' })),
    buildingNumber: yup
      .number()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .typeError(i18nClient.t('Validation.Pattern', { field: '$t(BuildingNo)' }))
      .integer(i18nClient.t('Validation.Pattern', { field: '$t(BuildingNo)' }))
      .positive(i18nClient.t('Validation.Pattern', { field: '$t(BuildingNo)' }))
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(BuildingNo)' })),
    streetName: yup
      .string()
      .matches(ADDRESS_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(StreetName)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(StreetName)' })),
    postalBox: yup
      .number<EwaCenterFormData['postalBox']>()
      .nullable()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .typeError(i18nClient.t('Validation.Pattern', { field: '$t(PostalBox)' }))
      .integer(i18nClient.t('Validation.Pattern', { field: '$t(PostalBox)' }))
      .positive(i18nClient.t('Validation.Pattern', { field: '$t(PostalBox)' })),
    postalCode: yup
      .number()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .typeError(i18nClient.t('Validation.Pattern', { field: '$t(PostalCode)' }))
      .integer(i18nClient.t('Validation.Pattern', { field: '$t(PostalCode)' }))
      .positive(i18nClient.t('Validation.Pattern', { field: '$t(PostalCode)' }))
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(PostalCode)' })),
    locationAdditionalNumber: yup
      .number()
      .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
      .typeError(i18nClient.t('Validation.Pattern', { field: '$t(AdditionalNo)' }))
      .integer(i18nClient.t('Validation.Pattern', { field: '$t(AdditionalNo)' }))
      .positive(i18nClient.t('Validation.Pattern', { field: '$t(AdditionalNo)' }))
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(AdditionalNo)' })),
  });
};

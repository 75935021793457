import { RootState } from 'services/store';

export const selectLaborerCase = (store: RootState): RootState['laborer']['laborerCase'] => {
  return store.laborer.laborerCase;
};

export const selectLaborerCasesList = (store: RootState): RootState['laborer']['laborerCasesList'] => {
  return store.laborer.laborerCasesList;
};

export const selectUpdateCaseStatus = (store: RootState): RootState['laborer']['updateCaseStatus'] => {
  return store.laborer.updateCaseStatus;
};

export const selectPostCaseStatus = (store: RootState): RootState['laborer']['postCaseStatus'] => {
  return store.laborer.postCaseStatus;
};

export const selectReservationStatus = (store: RootState): RootState['laborer']['reservationStatus'] => {
  return store.laborer.reservationStatus;
};

export const selectReservedCaseId = (store: RootState): RootState['laborer']['reservedCaseId'] => {
  return store.laborer.reservedCaseId;
};

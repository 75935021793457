import { makeStyles, Theme } from '@material-ui/core/styles';
import { toPx } from 'modules/shared';

export const useStyles = makeStyles<Theme>(theme => ({
  root: {
    width: 230,

    '&$error $notchedOutline': {
      borderColor: 'unset',
    },
  },
  inputMultiline: {
    lineHeight: toPx(32),
  },
  // custom classes
  rootMultiline: {
    width: 490,
  },
  inputGrayedOut: {
    backgroundColor: theme.palette.grey[200],
  },
  // overrides
  // if input is both disabled and errored, make the latter one take precedence
  error: {
    '& fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  notchedOutline: {},
}));

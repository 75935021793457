import React, { useEffect } from 'react';
import { useTranslation } from 'services';
import { useSharedManagement } from 'modules/shared';
import { useFilesManagement, ACCEPTED_FILE_FORMATS } from 'modules/files';
import { UploadButton } from './UploadButton';
import { useStyles } from './FilesUpload.styles';

const DEFAULT_SIZE = 5;
const MAX_FILE_SIZE = 5 /* Mb */ * 1024 * 1024; // in bytes

type FilesUploadPropTypes = {
  dataTestId?: string;
  label?: string;
  onChange: (files: File[]) => void;
  size?: 1 | 5;
};

export const FilesUpload: React.FC<FilesUploadPropTypes> = ({ dataTestId, label, onChange, size = DEFAULT_SIZE }) => {
  const { t, tBasic } = useTranslation();
  const styles = useStyles();
  const { notifyUser } = useSharedManagement();
  const { files, setFiles } = useFilesManagement<File | undefined>(Array.from({ length: size }));

  const isDisabled = (i: number): boolean => {
    if (i === 0) {
      return false;
    }

    return !files[i - 1];
  };

  const getFileName = (i: number): string => {
    const file = files[i];
    return file ? file.name : '';
  };

  const handleChange = (i: number) => (event: React.ChangeEvent<HTMLInputElement>): void => {
    const elem = event.target.files;
    const file = elem && elem[0];
    if (file) {
      if (!ACCEPTED_FILE_FORMATS.includes(file.type)) {
        notifyUser(t(['Files', 'InvalidFormatMessage']), 'error');
      } else if (file.size > MAX_FILE_SIZE) {
        notifyUser(t(['Files', 'MaxSizeExceededMessage']), 'error');
      } else {
        setFiles(files.map((f, j) => (i === j ? file : f)));
      }
    }
  };

  const handleRemove = (i: number) => (): void => {
    const updatedList = files.filter((f, j) => i !== j);
    setFiles([...updatedList, undefined]);
  };

  useEffect(() => {
    onChange(files.filter(f => f) as File[]);
  }, [files]);

  return (
    <>
      {files.map((file, i) => (
        <UploadButton
          dataTestId={`${dataTestId}-${i + 1}`}
          key={(file && file.name) || i}
          className={styles.uploader}
          disabled={isDisabled(i)}
          fileName={getFileName(i)}
          label={label || `${tBasic(`Files:Label${i + 1}`)}:`}
          onChange={handleChange(i)}
          onRemove={handleRemove(i)}
        />
      ))}
    </>
  );
};

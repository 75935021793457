const DEFAULT = 'default';
const PENDING = 'pending';
const SUCCESS = 'success';
const FAILURE = 'failure';

export type Status = typeof DEFAULT | typeof PENDING | typeof SUCCESS | typeof FAILURE;

export const isStatusDefault = (status: Status | undefined): status is typeof DEFAULT => status === DEFAULT;
export const isStatusPending = (status: Status | undefined): status is typeof PENDING => status === PENDING;
export const isStatusSuccess = (status: Status | undefined): status is typeof SUCCESS => status === SUCCESS;
export const isStatusFailure = (status: Status | undefined): status is typeof FAILURE => status === FAILURE;

import { apiClient } from 'root';
import { LaborerCaseResponse, LaborerAssessmentResponse } from './types';

export const getLaborerCase = async (laborerCaseId: string) => {
  const { data } = await apiClient.get<LaborerCaseResponse>(`/laborercases/${laborerCaseId}`);
  return data;
};

export const postLaborerAssessment = async (data: any) => {
  await apiClient.post('/laborerassessments', { data });
};

export const getLaborerAssessment = async (caseId: string) => {
  const { data } = await apiClient.get<LaborerAssessmentResponse>(`/laborerassessments/${caseId}`);
  return data;
};

export const getReportFile = async (startDate: string, endDate: string, ewaCenter: string | null | undefined) => {
  const { data } = await apiClient.post<Blob>(`/statistics/ondemand`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    data: {
      ewaCenter,
      startDate,
      endDate,
    },
  });

  return data;
};

export const getReportFileManager = async (startDate: string, endDate: string) => {
  const { data } = await apiClient.post<Blob>(`/statistics/ondemand/ecm`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    data: {
      startDate,
      endDate,
    },
  });

  return data;
};

export const getReportFileAdmin = async (startDate: string, endDate: string) => {
  const { data } = await apiClient.post<Blob>(`/statistics/ondemand/v2`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    data: {
      startDate,
      endDate,
    },
  });

  return data;
};

import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EwaCenterIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      version="1.1"
      id="ewa-center-icon"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g>
        <g>
          <path
            d="M154.518,145.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C164.518,150.21,160.041,145.733,154.518,145.733z M144.518,201.733h-28v-36h28V201.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M154.518,257.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C164.518,262.21,160.041,257.733,154.518,257.733z M144.518,313.733h-28v-36h28V313.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M154.518,369.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C164.518,374.21,160.041,369.733,154.518,369.733z M144.518,425.733h-28v-36h28V425.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M415.497,369.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C425.497,374.21,421.02,369.733,415.497,369.733z M405.497,425.733h-28v-36h28V425.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M241.511,145.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C251.511,150.21,247.034,145.733,241.511,145.733z M231.511,201.733h-28v-36h28V201.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M328.503,145.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C338.503,150.21,334.026,145.733,328.503,145.733z M318.503,201.733h-28v-36h28V201.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M241.511,257.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C251.511,262.21,247.034,257.733,241.511,257.733z M231.511,313.733h-28v-36h28V313.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M328.503,257.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C338.503,262.21,334.026,257.733,328.503,257.733z M318.503,313.733h-28v-36h28V313.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M415.497,145.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C425.497,150.21,421.02,145.733,415.497,145.733z M405.497,201.733h-28v-36h28V201.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M415.497,257.733h-48c-5.523,0-10,4.477-10,10v56c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-56
			C425.497,262.21,421.02,257.733,415.497,257.733z M405.497,313.733h-28v-36h28V313.733z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M502,492h-37.511V104.063c0-5.523-4.477-10-10-10h-55.502V58c0-5.523-4.477-10-10-10H334V10c0-5.523-4.477-10-10-10H188
			c-5.523,0-10,4.477-10,10v38h-54.986c-5.523,0-10,4.477-10,10v36.063H57.511c-5.523,0-10,4.477-10,10V492H10
			c-5.523,0-10,4.477-10,10s4.477,10,10,10h492c5.523,0,10-4.477,10-10S507.523,492,502,492z M198,20h116v28H198V20z M298,492
			h-69.191L298,422.809V492z M298,394.587c-0.223,0.187-0.443,0.38-0.653,0.59L214,478.524v-42.382L278.143,372H298V394.587z
			 M214,407.857V372h35.857L214,407.857z M444.489,492H377.51H318V372h3.5c5.523,0,10-4.477,10-10s-4.477-10-10-10h-131
			c-5.523,0-10,4.477-10,10s4.477,10,10,10h3.5v120H67.511V114.063h111.007c5.523,0,10-4.477,10-10s-4.477-10-10-10h-45.504V68
			h245.973v26.063H257.524c-5.523,0-10,4.477-10,10s4.477,10,10,10h186.965V492z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M224.58,96.99c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
			c1.86,1.87,4.44,2.93,7.07,2.93s5.21-1.06,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S226.44,98.85,224.58,96.99z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

import { makeStyles, Theme, fade } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles((theme: Theme) => ({
  footerBar: {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    height: 50,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  mailIcon: { height: 18, width: 18, marginLeft: 5, marginRight: 3, color: theme.palette.primary.main },
  mailLink: { color: theme.palette.text.primary, marginLeft: 23, marginRight: 23 },
  versionText: { color: theme.palette.text.hint },
  infoBox: {
    height: '100%',
    width: '50%',
    justifyContent: 'start',
    alignItems: 'center',
    display: 'flex',
  },
  mailBox: {
    height: '100%',
    marginTop: 5,
  },
}));

import React from 'react';
import cn from 'classnames';
import ReactPaginating from 'react-paginating';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'services';
import { DEFAULT_PAGE_SIZE } from 'modules/http';
import { useStyles } from './Pagination.styles';

type PaginationProps = {
  pageSize?: number;
  currentPage?: number;
  total: number;
  onPageChange: (page: number) => void;
};

const PAGE_COUNT = 5;

export const Pagination: React.FC<PaginationProps> = ({
  currentPage = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  total,
  onPageChange,
}) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const handlePageChange = (pageNumber: number) => (): void => {
    onPageChange(pageNumber);
  };

  if (total <= pageSize) {
    return null;
  }

  return (
    <ReactPaginating currentPage={currentPage} total={total} limit={pageSize} pageCount={PAGE_COUNT}>
      {({ pages, hasNextPage, hasPreviousPage, previousPage, nextPage, totalPages }): React.ReactNode => (
        <nav className={styles.pagination}>
          <Paper>
            <button
              onClick={handlePageChange(1)}
              className={cn(styles.button, styles.edgeButton)}
              disabled={currentPage === 1}
            >
              {t(['Common', 'First'])}
            </button>
          </Paper>

          <Paper className={styles.listWrapper}>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <button
                  onClick={handlePageChange(previousPage)}
                  className={cn(styles.button, styles.arrowButton, styles.prev)}
                  disabled={!hasPreviousPage}
                >
                  <span className={cn(styles.arrow, isArabic ? styles.prevArrow : styles.nextArrow)}>previous</span>
                </button>
              </li>

              {pages.map(p => (
                <li key={p} className={styles.listItem}>
                  <button
                    onClick={handlePageChange(p)}
                    className={cn(styles.button, styles.page, { [styles.acttivePage]: p === currentPage })}
                  >
                    {p}
                  </button>
                </li>
              ))}

              <li className={styles.listItem}>
                <button
                  onClick={handlePageChange(nextPage)}
                  className={cn(styles.button, styles.arrowButton, styles.next)}
                  disabled={!hasNextPage}
                >
                  <span className={cn(styles.arrow, isArabic ? styles.nextArrow : styles.prevArrow)}>next</span>
                </button>
              </li>
            </ul>
          </Paper>

          <Paper>
            <button
              onClick={handlePageChange(totalPages)}
              className={cn(styles.button, styles.edgeButton)}
              disabled={currentPage === totalPages}
            >
              {t(['Common', 'Last'])}
            </button>
          </Paper>
        </nav>
      )}
    </ReactPaginating>
  );
};

import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { Button } from 'components';

type ActionButtonProps = {
  type: 'button' | 'link';
  className?: string;
  disabled: boolean;
  label: string;
  onClick: () => void;
};

export const ActionButton: React.FC<ActionButtonProps> = ({ className, disabled, type, onClick, label }) =>
  type === 'button' ? (
    <Button className={className} onClick={onClick} disabled={disabled}>
      <Typography>{label}</Typography>
    </Button>
  ) : (
    <Link className={className} component="button" underline="always" onClick={onClick} disabled={disabled}>
      <Typography>{label}</Typography>
    </Link>
  );

import { isEqual, pick } from 'lodash';
import { LaborerAssessmentData, Violations } from './types';

/**
 * NOTE: boolean values are stringified because
 * react-hook-form does so with boolean values controlled by radio buttons
 */

const subViolations: { [key: string]: any[] } = {
  subViolation1: [
    {
      entranceReason: 'EMPLOYER_RETURN',
      question6: 'true',
    },
  ],
  subViolation2: [
    {
      entranceReason: 'WORKER_RETURN',
      question11: 'true',
    },
  ],
  subViolation3: [
    {
      entranceReason: 'EMPLOYER_RETURN',
      question6: 'true',
    },
  ],
  subViolation4: [
    {
      entranceReason: 'WORKER_RETURN',
      question2: 'false',
      question3: 'false',
    },
  ],
  subViolation5: [
    {
      entranceReason: 'WORKER_RETURN',
      question7: 'true',
    },
  ],
  subViolation6: [
    {
      entranceReason: 'WORKER_RETURN',
      question8: 'true',
    },
    {
      entranceReason: 'WORKER_RETURN',
      question10: 'true',
    },
  ],
  subViolation7: [
    {
      entranceReason: 'WORKER_RETURN',
      question9: 'true',
    },
  ],
  subViolation8: [
    {
      entranceReason: 'WORKER_RETURN',
      question12: 'true',
    },
  ],
  subViolation9: [
    {
      entranceReason: 'WORKER_RETURN',
      question11: 'true',
    },
  ],
  subViolation10: [
    {
      entranceReason: 'WORKER_RETURN',
      question2: 'false',
      question4: 'false',
      question6: 'true',
      question8: 'true',
      question10: 'true',
      question11: 'true',
    },
  ],
};
const mainViolations: { [key: string]: string } = {
  subViolation1: 'mainViolation1',
  subViolation2: 'mainViolation1',
  subViolation3: 'mainViolation2',
  subViolation4: 'mainViolation2',
  subViolation5: 'mainViolation2',
  subViolation6: 'mainViolation2',
  subViolation7: 'mainViolation2',
  subViolation8: 'mainViolation2',
  subViolation9: 'mainViolation2',
  subViolation10: 'mainViolation3',
};

const computeSubViolation = (questions: any): string[] => {
  let violations: string[] = [];
  Object.keys(subViolations).forEach(violation => {
    const possibilities = subViolations[violation];
    possibilities.forEach(group => {
      const picked = pick(questions, Object.keys(group));
      const isSubViolation = isEqual(picked, group);
      if (isSubViolation && !violations.includes(violation)) {
        violations = [...violations, violation];
      }
    });
  });

  return violations;
};

const computeMainViolation = (subV: string[]): { [key: string]: string } => {
  const violations = subV.reduce(
    (accu, v: string) => ({
      ...accu,
      [v]: mainViolations[v],
    }),
    {}
  );

  return violations;
};

type ViolationsData = {
  pairs: string[][];
  request: Violations;
};

export const computeViolations = (data: LaborerAssessmentData): ViolationsData => {
  const subViolation = computeSubViolation(data);
  const mainViolation = computeMainViolation(subViolation);
  const pairs = subViolation.map((s: string) => [mainViolation[s], s]);
  const mainKeys = Object.values(mainViolation);

  const request = {
    mainViolation1: mainKeys.includes('mainViolation1'),
    mainViolation2: mainKeys.includes('mainViolation2'),
    mainViolation3: mainKeys.includes('mainViolation3'),
    subViolation1: subViolation.includes('subViolation1'),
    subViolation2: subViolation.includes('subViolation2'),
    subViolation3: subViolation.includes('subViolation3'),
    subViolation4: subViolation.includes('subViolation4'),
    subViolation5: subViolation.includes('subViolation5'),
    subViolation6: subViolation.includes('subViolation6'),
    subViolation7: subViolation.includes('subViolation7'),
    subViolation8: subViolation.includes('subViolation8'),
    subViolation9: subViolation.includes('subViolation9'),
    subViolation10: subViolation.includes('subViolation10'),
  };

  return { pairs, request };
};

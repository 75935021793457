import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isDevelopment } from 'config';
import { Assessment } from './translations/ar/assessment.json';
import { Case } from './translations/ar/case.json';
import { Common } from './translations/ar/common.json';
import { EwaCenter } from './translations/ar/ewa-center.json';
import { ExitFormPrint } from './translations/ar/exit-form-print.json';
import { Files } from './translations/ar/files.json';
import { Laborer } from './translations/ar/laborer.json';
import { Users } from './translations/ar/users.json';
import { Bundles } from './translations/ar/bundles.json';
import { Invoices } from './translations/ar/invoices.json';
import { Assessment as AssessmentEn } from './translations/en/assessment-en.json';
import { Case as CaseEn } from './translations/en/case-en.json';
import { Common as CommonEn } from './translations/en/common-en.json';
import { EwaCenter as EwaCenterEn } from './translations/en/ewa-center-en.json';
import { ExitFormPrint as ExitFormPrintEn } from './translations/en/exit-form-print-en.json';
import { Files as FilesEn } from './translations/en/files-en.json';
import { Laborer as LaborerEn } from './translations/en/laborer-en.json';
import { Users as UsersEn } from './translations/en/users-en.json';
import { Bundles as BundlesEn } from './translations/en/bundles-en.json';
import { Invoices as InvoicesEn } from './translations/en/invoices-en.json';
// import { TypedTFunction } from './use-translation';

// type i18nClient = Omit<typeof i18n, 't'> & {
//   t: TypedTFunction;
// };

export const ISO6391Code = {
  english: 'en',
  arabic: 'ar',
};

const resources = {
  en: {
    Assessment: AssessmentEn,
    Case: CaseEn,
    Common: CommonEn,
    EwaCenter: EwaCenterEn,
    ExitFormPrint: ExitFormPrintEn,
    Files: FilesEn,
    Laborer: LaborerEn,
    Users: UsersEn,
    Bundles: BundlesEn,
    Invoices: InvoicesEn,
  },

  ar: {
    Assessment,
    Case,
    Common,
    EwaCenter,
    ExitFormPrint,
    Files,
    Laborer,
    Users,
    Bundles,
    Invoices,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: ISO6391Code.arabic,
  defaultNS: 'Common',
  interpolation: {
    escapeValue: false,
  },
  debug: isDevelopment(),
});

// i18n.t = ([namespace, ...keys]: [string, ...string[]], params?: TOptions | string) =>
//   i18n.t(`${namespace}:${keys.join('.')}`, params);

export const i18nClient = i18n;

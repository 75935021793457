import React from 'react';
import { Box, Container } from '@material-ui/core';
import { ContainerProps } from '@material-ui/core/Container';

type PageProps = ContainerProps & {
  dataTestId?: string;
  header?: React.ReactNode;
};

export const Page: React.FC<PageProps> = ({ children, maxWidth = false as const, dataTestId, header }) => (
  <Box data-test-id={dataTestId}>
    {header}
    <Container maxWidth={maxWidth}>
      <Box paddingTop={4} paddingBottom={4}>
        {children}
      </Box>
    </Container>
  </Box>
);

import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const WarningIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      version="1.1"
      id="case-icon"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M155 456 c-60 -28 -87 -56 -114 -116 -36 -79 -19 -183 42 -249 33
-36 115 -71 167 -71 52 0 134 35 167 71 34 37 63 110 63 159 0 52 -35 134 -71
167 -37 34 -110 63 -159 63 -27 0 -65 -10 -95 -24z m180 -15 c128 -58 164
-223 72 -328 -101 -115 -283 -88 -348 52 -79 171 104 354 276 276z"
        />
        <path
          d="M234 347 c-3 -8 -4 -41 -2 -73 2 -44 7 -59 18 -59 12 0 15 14 15 69
0 47 -4 71 -12 73 -7 3 -15 -2 -19 -10z"
        />
        <path
          d="M234 169 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"
        />
      </g>
    </SvgIcon>
  );
};

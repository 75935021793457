import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useTranslation } from 'services';
import { isOtherSelected, AuthorityTypeOptions } from 'modules/shared';
import { LaborerInfoResponse } from 'modules/laborer-info';
import { TestId } from 'const';
import { Button, FormSection, FormRow, FormHelperText, FilesUpload } from 'components/common';
import { FormTextField, FormSelect, FormDatePicker, FormRadioGroup } from 'components/form-components';
import { yesOrNoOptions, laborerSatisfactionOptions } from './form-options';
import { closeCaseFormSchema, CloseCaseFormData } from './validation';
import { useStyles } from './CloseCaseForm.styles';

const initialCloseCaseFormValues: CloseCaseFormData = {
  authority: '',
  authorityOther: null,
  phoneNumber: '',
  authorizationNumber: '',
  authorizedPersonName: '',
  endDate: null,
  financialClaimsHasBeenReceived: '',
  personalItemsHasBeenReceived: '',
  laborerSatisfaction: '',
  employerId: '',
};

type CloseCaseFormProps = {
  files: File[];
  authorityTypeOptions: AuthorityTypeOptions[];
  laborerInfoCaseClosing: LaborerInfoResponse | null;
  onSubmit: SubmitHandler<CloseCaseFormData>;
  onFileListChange: (files: File[]) => void;
  onAuthorizedPersonFetch: (employerId: string, checkDate: Date) => void;
};

// TODO: remove file from upload list when form is resetting

export const CloseCaseForm: React.FC<CloseCaseFormProps> = ({
  files,
  authorityTypeOptions,
  laborerInfoCaseClosing,
  onSubmit,
  onFileListChange,
  onAuthorizedPersonFetch,
}) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const [employerId, setEmployerId] = useState('');
  const [checkDate, setCheckDate] = useState<Date | undefined>();
  const formMethods = useForm<CloseCaseFormData>({
    mode: 'onTouched',
    defaultValues: initialCloseCaseFormValues,
    resolver: yupResolver(closeCaseFormSchema()),
  });

  const { formState, watch, trigger, reset, setValue, handleSubmit } = formMethods;
  const employerIdValue = watch('employerId');
  const checkDateValue = watch('checkDate');
  const authorityValue = watch('authority');

  const interceptOnSubmit = (formData: CloseCaseFormData) => {
    if (!files.length) {
      return;
    }

    onSubmit(formData);
  };

  useEffect(() => {
    if (laborerInfoCaseClosing?.laborerName) {
      setValue('authorizedPersonName', laborerInfoCaseClosing.laborerName);
    }
  }, [laborerInfoCaseClosing]);
  const isArabic = i18n.language === 'ar';

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(interceptOnSubmit)} autoComplete="off">
        <FormSection title={t(['Case', 'AuthPersonInfo'])}>
          <FormRow>
            <FormSelect
              name="authority"
              label={t(['Case', 'AuthPersonInfoAuthority'])}
              options={authorityTypeOptions}
              labelKey={isArabic ? 'arabicName' : 'englishName'}
              valueKey="name"
            />
            <FormRow>
              <FormTextField name="employerId" label={t(['Laborer', 'IdNoLocation'])} placeholder="1234567890" />
            </FormRow>
            <FormTextField name="authorizedPersonName" label={t(['Common', 'Name'])} />
          </FormRow>
          {isOtherSelected(authorityValue) && (
            <FormRow>
              <FormTextField name="authorityOther" multiline rows={5} />
            </FormRow>
          )}

          <FormRow>
            <FormTextField name="authorizationNumber" label={t(['Case', 'AuthorizationNo'])} />
            <FormDatePicker name="endDate" label={t(['Case', 'ExpiryDate'])} variant="inline" disablePast autoOk />
          </FormRow>

          <FormRow className={styles.extraBottomSpace}>
            <FormTextField name="phoneNumber" label={t(['Common', 'MobileNo'])} placeholder="9665xxxxxxxx+" />
          </FormRow>

          <FormRow>
            <FilesUpload
              dataTestId={TestId.CloseLaborerCaseUploadFileButton}
              size={1}
              label={t(['Files', 'Attachment'])}
              onChange={onFileListChange}
            />
            {formState.isSubmitted && !files.length && (
              <FormHelperText error>{t(['Common', 'Validation', 'Required'])}</FormHelperText>
            )}
          </FormRow>
        </FormSection>

        <FormSection title={t(['Case', 'ExitQuestions'])}>
          <FormRow className={styles.extraBottomSpace}>
            <FormRadioGroup
              name="financialClaimsHasBeenReceived"
              label={t(['Case', 'FinancialClaimsFieldLabel'])}
              options={yesOrNoOptions()}
            />
          </FormRow>

          <FormRow className={styles.extraBottomSpace}>
            <FormRadioGroup
              name="personalItemsHasBeenReceived"
              label={t(['Case', 'PersonalItemsFieldLabel'])}
              options={yesOrNoOptions()}
            />
          </FormRow>

          <FormRow className={styles.extraBottomSpace}>
            <FormRadioGroup
              name="laborerSatisfaction"
              label={t(['Case', 'LaborerSatisfactionFieldLabel'])}
              options={laborerSatisfactionOptions()}
            />
          </FormRow>
        </FormSection>

        <FormSection>
          <Button dataTestId={TestId.CloseLaborerCaseSubmitButton} type="submit">
            {t(['Common', 'Save'])}
          </Button>
        </FormSection>
      </form>
    </FormProvider>
  );
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    marginTop: '35px',
    display: 'flex',
    justifyContent: 'center',
  },
  listWrapper: {
    margin: '0 8px',
  },
  list: {
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    '& + &': {
      borderLeft: `1px solid ${theme.palette.secondary.contrastText}`,
    },
  },
  button: {
    height: '36px',
    border: 'none',
    padding: '0',
    borderRadius: 0,
    background: 'transparent',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&[disabled]': {
      cursor: 'default',
    },
  },
  page: {
    width: '30px',
  },
  acttivePage: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
  },
  arrowButton: {
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  edgeButton: {
    borderRadius: '4px',
    width: '60px',
  },
  prev: {
    borderRadius: '4px 0 0 4px',
  },
  next: {
    borderRadius: '0 4px 4px 0',
  },
  arrow: {
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  prevArrow: {
    borderWidth: '6px 6px 6px 0',
    borderColor: `transparent ${theme.palette.secondary.main} transparent transparent`,
  },
  nextArrow: {
    borderWidth: '6px 0 6px 6px',
    borderColor: `transparent transparent transparent ${theme.palette.secondary.main}`,
  },
}));

import React from 'react';
import { MenuList, MenuItem } from '@material-ui/core';
import { useTranslation } from 'services';
import { Filter } from '../types';
import { useStyles } from './FilterOptions.styles';

export type FilterOption = {
  id: number | string;
  name: string;
  nameEn?: string;
};

type FilterOptionsProps = {
  name: string;
  options: FilterOption[];
  onSelect: (data: Filter) => void;
};

export const FilterOptions: React.FC<FilterOptionsProps> = ({ name, options, onSelect }) => {
  const styles = useStyles();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const handleSelect = (option: FilterOption) => () => {
    onSelect({ name, data: option });
  };

  return (
    <MenuList className={styles.list}>
      {options.map(option => (
        <MenuItem key={option.id} onClick={handleSelect(option)} classes={{ root: styles.menuItem }}>
          {isArabic || !option.nameEn ? option.name : option.nameEn}
        </MenuItem>
      ))}
    </MenuList>
  );
};

import { omit } from 'lodash';
import { NationalityResponseDto } from 'modules/shared';
import { LaborerInfoResponse, LaborerInfoResponseDto } from '../types';

export const toLaborerInfoResponseDto = (
  laborerInfo: LaborerInfoResponse,
  nationalities: NationalityResponseDto[]
): LaborerInfoResponseDto => {
  const nationality = nationalities.find(nat => nat.nicCode === Number(laborerInfo.laborerNationalityCode));

  return {
    ...omit(laborerInfo, ['laborerNationality', 'laborerNationalityCode']),
    laborerNationality: nationality ? nationality.name : null,
  };
};

import React, { useState } from 'react';
import { isFunction } from 'lodash';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Button } from 'components';
import { DropdownPopper } from '.';
import { useStyles } from './Dropdown.styles';

type DropdownProps = {
  label: string;
  children: React.ReactNode | ((props: { handleClose: () => void }) => React.ReactNode);
};

export const Dropdown: React.FC<DropdownProps> = ({ children, label }) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpen = Boolean(anchorEl);
  const variant = isOpen ? 'contained' : 'text';

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={variant}
        endIcon={<ArrowDropDown />}
        size="small"
        classes={{
          root: styles.button,
          containedSizeSmall: styles.buttonActive,
          containedPrimary: styles.buttonActive,
        }}
      >
        {label}
      </Button>
      <DropdownPopper anchorEl={anchorEl} handleClose={handleClose}>
        {isFunction(children) ? children({ handleClose }) : children}
      </DropdownPopper>
    </>
  );
};

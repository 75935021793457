import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { parseUrl } from 'query-string';
import { nth } from 'lodash';
import { useTranslation } from 'services/translation-client';
import { routes } from 'const';
import { useSharedManagement, ApplicationLocationState } from 'modules/shared';

export const useSafeRedirect = (fallbackPath: string) => {
  const history = useHistory<ApplicationLocationState>();
  const { t } = useTranslation();
  const { locationList } = useSharedManagement();

  const previousPath = nth(locationList, -2) || fallbackPath;

  const label = useMemo(() => {
    const { url: prevUrl } = parseUrl(previousPath);

    /**
     * Ewa centers
     */
    if (prevUrl === routes.ewaCenter.main.path) {
      return t(['EwaCenter', 'ListPageTitle']);
    }
    if (prevUrl === routes.ewaCenter.add.path) {
      return t(['Common', 'AddNew']);
    }
    if (routes.ewaCenter.details.isValid(prevUrl)) {
      return t(['Common', 'Edit']);
    }

    /**
     * Users
     */
    if (prevUrl === routes.users.main.path) {
      return t(['Users', 'ListPageTitle']);
    }
    if (prevUrl === routes.users.add.path) {
      return t(['Users', 'AddUser']);
    }

    /**
     * Laborer cases
     */
    if (prevUrl === routes.laborerCases.main.path) {
      return t(['Common', 'LaborersList']);
    }
    if (prevUrl === routes.laborerCases.add.path) {
      return t(['Common', 'AddNew']);
    }
    if (routes.laborerCases.details.isValid(prevUrl)) {
      return t(['Assessment', 'GoBack']);
    }
    if (routes.laborerCases.assessment.isValid(prevUrl)) {
      return t(['Assessment', 'StartAssesment']);
    }
    if (routes.laborerCases.close.isValid(prevUrl)) {
      return t(['Case', 'Close']);
    }
    if (routes.laborerCases.reopen.isValid(prevUrl)) {
      return t(['Case', 'Reopen']);
    }

    /**
     * Bundles
     */
    if (routes.bundles.invoices.path) {
      return t(['Common', 'Invoices']);
    }

    /**
     * Fallback
     */
    return t(['Common', 'GoBack']);
  }, [previousPath, t]);

  const redirect = () => {
    history.push(previousPath, {
      isSafe: true,
    });
  };

  return {
    label,
    redirect,
  };
};

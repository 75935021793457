import { makeStyles, Theme } from '@material-ui/core/styles';
import { SECTION_MARGIN_VERTICAL } from 'const';

// TODO: remove the important rule as soon as we solve the global specificity issue

export const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    display: 'flex',
    alignItems: 'stretch',
    margin: theme.spacing(SECTION_MARGIN_VERTICAL, 0),
  },
  detailsCard: {
    width: 500,
    display: 'flex',
    justifyContent: 'space-between',
    '& + &': {
      marginLeft: theme.spacing(3),
    },
    '& > *': {
      flex: '0 1 50%',
      maxWidth: `calc(50% - ${theme.spacing(1)}px)`,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  disclaimer: {
    display: 'flex',
  },
  formField: {
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  unsetInputWidth: {
    width: 'unset',
  },
  wideInput: {
    width: 350,
  },
  formFieldWide: {
    marginBottom: `${theme.spacing(1)}px !important`,
    width: 350,
  },
  checkBox: {
    marginBottom: `${theme.spacing(1)}px !important`,
    fontSize: '18px',
    color: '#515751',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

import React from 'react';
import { KeycloakProvider, KeycloakTokens, KeycloakEvent } from '@react-keycloak/web';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { CssBaseline, StylesProvider } from '@material-ui/core';
import { ThemeProvider, jssPreset } from '@material-ui/core/styles';
import { AuthenticatedHttpClient, ApiClient, LocalStorage, WebSocketClient } from 'services';
import { useUsersManagement } from 'modules/user';
import {
  isDevelopment,
  APP_ROOT_URL,
  API_BASE_URL,
  WS_BASE_URL,
  KEYCLOAK_SSO_AUTH_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
} from 'config';
import { NotificationOutlet } from 'components';
import { App } from 'views/App';
import { rtlTheme, ltrTheme, classNameGenerator } from './theme';

const keycloakStore = new LocalStorage('KeycloakStorage', localStorage);

const keycloakClient = Keycloak({
  url: KEYCLOAK_SSO_AUTH_URL,
  clientId: KEYCLOAK_CLIENT_ID,
  realm: KEYCLOAK_REALM,
});

const authenticatedHttpClient = new AuthenticatedHttpClient({
  axiosInstance: axios.create({
    baseURL: API_BASE_URL,
  }),
  fetchCredentials: keycloakStore.load.bind(keycloakStore),
});

export const apiClient = new ApiClient({ httpClient: authenticatedHttpClient });

export const webSocketClient = new WebSocketClient(WS_BASE_URL, {
  healthCheck: true,
  autoReconnect: true,
  packMessage: JSON.stringify,
  unpackMessage: JSON.parse,
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const Root: React.FC = () => {
  const { fetchLoggedUserDetails, fetchRoles, fetchPrivileges } = useUsersManagement();

  const handleTokensChange = (tokens: KeycloakTokens): void => {
    keycloakStore.save(tokens);
  };

  const handleOnReadyEvent = (event: KeycloakEvent) => {
    if (event === 'onReady') {
      fetchLoggedUserDetails({ repeatNumber: 3 });
      fetchRoles();
      fetchPrivileges();
    }

    if (event === 'onAuthRefreshError') {
      keycloakClient.logout({ redirectUri: APP_ROOT_URL });
    }
  };

  return (
    <StylesProvider jss={jss} generateClassName={classNameGenerator}>
      <ThemeProvider theme={ltrTheme}>
        <NotificationOutlet />
      </ThemeProvider>

      <KeycloakProvider
        keycloak={keycloakClient}
        initConfig={{
          onLoad: 'login-required',
          checkLoginIframe: false,
          enableLogging: isDevelopment(),
        }}
        onEvent={handleOnReadyEvent}
        onTokens={handleTokensChange}
      >
        <ThemeProvider theme={rtlTheme}>
          <CssBaseline />
          <App />
          <ToastContainer position="top-right" pauseOnHover closeOnClick autoClose={false} hideProgressBar />
        </ThemeProvider>
      </KeycloakProvider>
    </StylesProvider>
  );
};

import * as yup from 'yup';
import { i18nClient } from 'services/translation-client';
import { isCaseFinancialClaimsType } from 'modules/shared';

export type CaseClassificationFormData = {
  laborCommitteeClassification: string;
  claimAmount?: number | null;
};

export const caseClassificationFormSchema = () => {
  return yup.object().shape<CaseClassificationFormData>({
    laborCommitteeClassification: yup
      .string()
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Laborer:CommitteeClassification)' })),
    claimAmount: yup
      .number()
      .nullable()
      .when('laborCommitteeClassification', {
        is: isCaseFinancialClaimsType,
        then: yup
          .number()
          .transform((value, originalvalue) => (originalvalue === '' ? undefined : value))
          .typeError(i18nClient.t('Validation.Pattern', { field: '$t(Case:ClaimAmount)' }))
          .integer(i18nClient.t('Validation.Pattern', { field: '$t(Case:ClaimAmount)' }))
          .positive(i18nClient.t('Case:ClaimAmountValueError'))
          .required(i18nClient.t('Validation.RequiredField', { field: '$t(Case:ClaimAmount)' })),
      }),
  });
};

import { RootState } from 'services/store';
import { isAPIError, isErrorEqualTo, Query } from 'modules/http';
import { REPLACE_SIGN } from 'const';
import { UserRole, UserStatus, Role } from './types';
import { selectUserDetails } from './selectors';
import { UsersQuery } from './query';

export const hasUserRoles = (store: RootState) => (roles: UserRole[]): boolean => {
  const { data: user } = selectUserDetails(store);
  const userRole = user ? user.userRole : null;

  return userRole ? roles.includes(userRole) : false;
};

export const getArabicUserRoleName = (roles: Role[], userRole: UserRole): string => {
  const userRoleItem = roles.find(role => role.name === userRole);

  return userRoleItem ? userRoleItem.arabicName : REPLACE_SIGN;
};

export const getEnglishUserRoleName = (roles: Role[], userRole: UserRole): string => {
  const userRoleItem = roles.find(role => role.name === userRole);

  return userRoleItem ? userRoleItem.engilshName : REPLACE_SIGN;
};

export const isUserInactive = (userStatus: UserStatus): boolean => {
  return userStatus === UserStatus.Inactive;
};

export const preparePostUserErrorMessage = (error: unknown): string => {
  if (isAPIError(error) && isErrorEqualTo(error, 'CONFLICT', 'USER_BY_ID_ALREADY_EXISTS')) {
    return 'Users:AlreadyExistsError';
  }

  if (isAPIError(error) && isErrorEqualTo(error, 'CONFLICT', 'MANAGER_IN_THIS_EWA_CENTER_ALREADY_REGISTERED')) {
    return 'Users:EwaCenterManagerAlreadyAssignedError';
  }

  return 'Common:GeneralErrorMessage';
};

export const prepareInviteUserErrorMessage = (error: unknown): string =>
  isAPIError(error) && isErrorEqualTo(error, 'CONFLICT', 'USER_ALREADY_REGISTERED')
    ? 'Users:AlreadyRegisteredError'
    : 'Common:GeneralErrorMessage';

export const filterEntryTranslationMap: Record<keyof Omit<UsersQuery, keyof Query>, string> = {
  email: 'Common:Email',
  ewaCenterId: 'Users:EwaCenter',
  firstName: 'Common:FirstName',
  lastName: 'Common:LastName',
  role: 'Common:Role',
};

import { Action } from 'services/store';
import { LaborerInfoActionType } from './actions';
import { LaborerInfoState } from './state';

export const laborerInfoState: LaborerInfoState = {
  laborerInfoDetails: {
    data: null,
    status: 'default',
  },
  laborerInfoCaseClosing: {
    data: null,
    status: 'default',
  },
  employerList: {
    laborersByIqama: [],
    laborersByVisa: [],
    status: 'default',
  },
  notEligible: false,
  quota: 0,
};

export function laborerInfoReducer(state = laborerInfoState, action: Action): LaborerInfoState {
  switch (action.type) {
    /**
     * FetchLaborerInfo
     */
    case LaborerInfoActionType.FetchLaborerInfo:
      return {
        ...state,
        laborerInfoDetails: {
          ...state.laborerInfoDetails,
          status: 'pending',
        },
      };

    case LaborerInfoActionType.FetchLaborerInfoSuccess:
      return {
        ...state,
        laborerInfoDetails: {
          data: action.payload,
          status: 'success',
        },
      };

    case LaborerInfoActionType.FetchLaborerInfoFailure:
      return {
        ...state,
        laborerInfoDetails: {
          ...state.laborerInfoDetails,
          status: 'failure',
        },
      };

    /**
     * FetchCitizenLaborerInfo / FetchMuqeemLaborerInfo
     */
    case LaborerInfoActionType.FetchCitizenLaborerInfo:
    case LaborerInfoActionType.FetchMuqeemLaborerInfo:
      return {
        ...state,
        laborerInfoCaseClosing: {
          ...state.laborerInfoCaseClosing,
          status: 'pending',
        },
      };

    case LaborerInfoActionType.FetchCitizenLaborerInfoSuccess:
    case LaborerInfoActionType.FetchMuqeemLaborerInfoSuccess:
      return {
        ...state,
        laborerInfoCaseClosing: {
          data: action.payload,
          status: 'success',
        },
      };

    case LaborerInfoActionType.FetchCitizenLaborerInfoFailure:
    case LaborerInfoActionType.FetchMuqeemLaborerInfoFailure:
      return {
        ...state,
        laborerInfoCaseClosing: {
          ...state.laborerInfoCaseClosing,
          status: 'failure',
        },
      };

    /**
     * FetchEmployerList
     */
    case LaborerInfoActionType.FetchEmployerLaborersList:
      return {
        ...state,
        employerList: {
          ...state.employerList,
          status: 'pending',
        },
      };

    case LaborerInfoActionType.FetchEmployerLaborersListSuccess:
      return {
        ...state,
        employerList: {
          laborersByIqama: action.payload.laborersByIqama,
          laborersByVisa: action.payload.laborersByVisa,
          status: 'success',
        },
      };

    case LaborerInfoActionType.FetchEmployerLaborersListFailure:
      return {
        ...state,
        employerList: {
          ...state.employerList,
          status: 'failure',
        },
      };

    /**
     * SetQuota
     */
    case LaborerInfoActionType.FetchQuotaSuccess:
      return {
        ...state,
        quota: action.payload,
      };

    /**
     * SetNotEligible
     */
    case LaborerInfoActionType.SetNotEligible:
      return {
        ...state,
        notEligible: action.payload.notEligible,
      };

    /**
     * ClearLaborerRelatedData
     */
    case LaborerInfoActionType.ClearLaborerRelatedData:
      return {
        ...state,
        laborerInfoDetails: {
          ...laborerInfoState.laborerInfoDetails,
        },
        laborerInfoCaseClosing: {
          ...laborerInfoState.laborerInfoCaseClosing,
        },
        employerList: {
          ...laborerInfoState.employerList,
        },
        notEligible: false,
      };

    default:
      return state;
  }
}

import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { version } from 'const';
import { useStyles } from './FooterPanel.styles';
import { useTranslation } from '../../../services';

export const FooterPanel: any = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={styles.footerBar} justifyContent="space-between" width="100%">
      <Box className={styles.mailBox}>
        <Typography variant="caption">&nbsp;{t(['Common', 'ContactInfo'])}</Typography>
        <Box>
          <Link href="mailto:support.ewa@musaned.com.sa" variant="subtitle2" className={styles.mailLink}>
            {'support.ewa@musaned.com.sa'}
          </Link>
        </Box>
      </Box>
      <Box className={styles.infoBox}>
        <Typography variant="caption" className={styles.versionText}>
          {t(['Common', 'Copyright'])}
        </Typography>
      </Box>
    </Box>
  );
};

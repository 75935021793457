import React from 'react';
import MuiRadio, { RadioProps as MuiRadioProps } from '@material-ui/core/Radio';
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@material-ui/core/RadioGroup';
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core/FormControlLabel';

type RadioOption = Record<string, string | number>;
export type RadioGroupProps = MuiRadioGroupProps & {
  options: RadioOption[];
  disabled?: boolean;
  FormControlLabelProps?: Omit<MuiFormControlLabelProps, 'control' | 'disabled'>;
  RadioProps?: MuiRadioProps;
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  row = true,
  disabled,
  options,
  FormControlLabelProps,
  RadioProps,
  ...props
}) => (
  <MuiRadioGroup row={row} {...props}>
    {options.map(option => (
      <MuiFormControlLabel
        key={option.label}
        label={option.label}
        value={option.value}
        disabled={disabled}
        control={<MuiRadio {...RadioProps} />}
        {...FormControlLabelProps}
      />
    ))}
  </MuiRadioGroup>
);

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  column: {
    padding: '0 5px',
    flexGrow: 0,
    flexShrink: 0,
  },
  columnHeader: {
    fontSize: '14px',
    color: theme.palette.secondary.contrastText,
    marginBottom: '10px',
  },
  columnValue: {
    fontSize: '15px',
    textAlign: 'left',
  },
  columnLeftValue: {
    fontSize: '15px',
    textAlign: 'right',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main80,
    fontSize: theme.typography.fontSize,
    boxShadow: theme.shadows[3],
  },
  arrow: {
    color: theme.palette.primary.main80,
  },
}));

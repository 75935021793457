import React from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Box, IconButton } from '@material-ui/core';
import { useTranslation } from 'services';
import { ACCEPTED_FILE_FORMATS } from 'modules/files';
import { Button } from 'components';

type UploadButtonPropTypes = {
  className?: string;
  disabled?: boolean;
  fileName?: string;
  dataTestId?: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemove: () => void;
};

export const UploadButton: React.FC<UploadButtonPropTypes> = ({
  className,
  disabled,
  fileName = '',
  dataTestId,
  label,
  onChange,
  onRemove,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" className={className}>
      {label ? <Box marginRight={4}>{label}</Box> : null}
      <Button
        dataTestId={dataTestId}
        component="label"
        disabled={disabled}
        variant="outlined"
        startIcon={<DescriptionOutlinedIcon />}
      >
        <input accept={ACCEPTED_FILE_FORMATS.join(',')} hidden onChange={onChange} type="file" />
        {t(['Files', 'UploadAttachment'])}
      </Button>
      {fileName ? (
        <Box marginLeft={2} color="error.main">
          <IconButton onClick={onRemove} size="small" color="inherit">
            <HighlightOffIcon />
          </IconButton>
        </Box>
      ) : null}
      <Box marginLeft={2}>{fileName}</Box>
    </Box>
  );
};

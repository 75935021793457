import { i18n, TFunction, TOptions } from 'i18next';
import { useTranslation as usei18nTranslation, Namespace, UseTranslationOptions } from 'react-i18next';
import assessmentTranslations from './translations/ar/assessment.json';
import caseTranslations from './translations/ar/case.json';
import commonTranslations from './translations/ar/common.json';
import ewaCenterTranslations from './translations/ar/ewa-center.json';
import exitFormPrintTranslations from './translations/ar/exit-form-print.json';
import filesTranslations from './translations/ar/files.json';
import laborerTranslations from './translations/ar/laborer.json';
import usersTranslations from './translations/ar/users.json';
import bundlesTranslations from './translations/ar/bundles.json';
import invoicesTranslations from './translations/ar/invoices.json';
import assessmentEnTranslations from './translations/en/assessment-en.json';
import caseEnTranslations from './translations/en/case-en.json';
import commonEnTranslations from './translations/en/common-en.json';
import ewaCenterEnTranslations from './translations/en/ewa-center-en.json';
import exitFormPrintEnTranslations from './translations/en/exit-form-print-en.json';
import filesEnTranslations from './translations/en/files-en.json';
import laborerEnTranslations from './translations/en/laborer-en.json';
import bundlesEnTranslations from './translations/en/bundles-en.json';
import invoicesEnTranslations from './translations/en/invoices-en.json';
import usersEnTranslations from './translations/en/users-en.json';

type TranslationsUnion = typeof assessmentTranslations &
  typeof caseTranslations &
  typeof commonTranslations &
  typeof ewaCenterTranslations &
  typeof exitFormPrintTranslations &
  typeof filesTranslations &
  typeof laborerTranslations &
  typeof usersTranslations &
  typeof bundlesTranslations &
  typeof invoicesTranslations &
  typeof assessmentEnTranslations &
  typeof caseEnTranslations &
  typeof commonEnTranslations &
  typeof ewaCenterEnTranslations &
  typeof exitFormPrintEnTranslations &
  typeof filesEnTranslations &
  typeof laborerEnTranslations &
  typeof bundlesEnTranslations &
  typeof invoicesEnTranslations &
  typeof usersEnTranslations;

type Dictionary = string | DictionaryObject;
type DictionaryObject = { [K: string]: Dictionary };

interface TypedTFunctionCreator<D extends Dictionary> {
  <K extends keyof D, K1 extends keyof D[K]>(keys: [K, K1], options?: TOptions | string): D[K][K1];
  <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1]>(
    keys: [K, K1, K2],
    options?: TOptions | string
  ): D[K][K1][K2];
}

export type TypedTFunction = TypedTFunctionCreator<TranslationsUnion>;

type UseTranslationResponse = {
  t: TypedTFunction;
  tBasic: TFunction;
  i18n: i18n;
  ready: boolean;
};

export const useTranslation = (ns?: Namespace, options?: UseTranslationOptions): UseTranslationResponse => {
  const { t: tFunction, i18n: i18nInstance, ready } = usei18nTranslation(ns, options);

  return {
    t: ([namespace, ...keys]: [string, ...string[]], params?: TOptions | string) =>
      tFunction(`${namespace}:${keys.join('.')}`, params),
    tBasic: tFunction,
    i18n: i18nInstance,
    ready,
  };
};

import { Moment } from 'moment-hijri';
import DateIoHijriUtils from '@date-io/hijri';
import 'moment/locale/ar-sa';

/* eslint-disable @typescript-eslint/ban-ts-comment */

export class HijriUtils extends DateIoHijriUtils {
  date(value: null): null;
  date(value: Date): Moment;
  date(value?: any): Moment | null {
    if (value === null) {
      return null;
    }

    return this.moment(value).locale('en-GB');
  }

  // @ts-ignore
  format(date: Moment, format: string): string {
    return this.moment(date).locale('en-GB').format(format);
  }

  getCalendarHeaderText(date: Moment): string {
    const month = this.moment(date).locale('ar-SA').format('iMMMM');
    const year = this.moment(date).locale('en-GB').format('iYYYY');

    return `${month} ${year}`;
  }

  getDatePickerHeaderText(date: Moment): string {
    const dayName = this.moment(date).locale('ar-SA').format('dddd');
    const dayOfMonth = this.moment(date).locale('en-GB').format('iD');
    const month = this.moment(date).locale('ar-SA').format('iMMM');

    return `${dayName}, ${dayOfMonth} ${month}`;
  }

  // @ts-ignore
  mergeDateAndTime(date: Moment): Moment {
    const merged = this.moment(date);
    const now = this.date(new Date());

    merged.hours(now.hours());
    merged.minutes(now.minutes());
    merged.seconds(now.seconds());
    merged.milliseconds(now.milliseconds());

    return merged;
  }

  getYearRange(start: Moment, end: Moment) {
    const startDate = this.moment(start).startOf('iYear');
    const endDate = this.moment(end).endOf('iYear');
    const years: Moment[] = [];

    let current = startDate;
    while (current.isBefore(endDate)) {
      years.push(current);
      current = current.clone().add(1, 'iYear');
    }

    return years;
  }
}

import { createStore as createReduxStore, compose, applyMiddleware, PreloadedState } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { rootReducer } from './root-reducer';
import { rootEpic } from './root-epic';

/* eslint-disable no-underscore-dangle */

export const createStore = <T>(history: History, preloadedState?: PreloadedState<T>) => {
  const epicMiddleware = createEpicMiddleware();
  const middlewares = [epicMiddleware, routerMiddleware(history)];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createReduxStore(
    rootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  epicMiddleware.run(rootEpic);

  return store;
};

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusPending } from 'services/store';
import { useFilesManagement } from 'modules/files';
import { OccupationResponse } from 'modules/shared/types';
import { AddLaborerFormData } from 'components';
import { LaborerAction, UpdateLaborerCaseData } from '../actions';
import { selectLaborerCase, selectLaborerCasesList, selectUpdateCaseStatus, selectPostCaseStatus } from '../selectors';
import { LaborerCasesQuery } from '../types';
import { buildCreateLaborerCaseDto } from '../dto';

// TODO: use reselect lib to combine and memoize selectors

export const useLaborerCaseManagement = () => {
  const dispatch = useDispatch();
  const { postFiles, isLoading: isUploading } = useFilesManagement();

  const laborerCase = useSelector(selectLaborerCase);
  const laborerCasesList = useSelector(selectLaborerCasesList);
  const postCaseStatus = useSelector(selectPostCaseStatus);
  const updateCaseStatus = useSelector(selectUpdateCaseStatus);

  const isLoading =
    isUploading ||
    isStatusPending(laborerCase.status) ||
    isStatusPending(laborerCasesList.status) ||
    isStatusPending(postCaseStatus) ||
    isStatusPending(updateCaseStatus);

  const fetchLaborerCase = useCallback(
    (laborerCaseId: string) => {
      dispatch(LaborerAction.fetchLaborerCase(laborerCaseId));
    },
    [dispatch]
  );

  const fetchLaborerCases = useCallback(
    (query?: LaborerCasesQuery) => {
      dispatch(LaborerAction.fetchLaborerCases(query));
    },
    [dispatch]
  );

  const fetchLaborerFilteredCases = useCallback(
    (query?: LaborerCasesQuery) => {
      dispatch(LaborerAction.fetchLaborerFilteredCases(query));
    },
    [dispatch]
  );

  const createLaborerCase = useCallback(
    async (laborerCaseFormData: AddLaborerFormData, files: File[], occupations: OccupationResponse[]) => {
      const uploadedFileNames = files.length ? await postFiles(files) : [];
      const payload = buildCreateLaborerCaseDto(laborerCaseFormData, uploadedFileNames, occupations);

      dispatch(LaborerAction.postLaborerCase(payload));
    },
    [dispatch]
  );

  const updateLaborerCase = useCallback(
    (payload: UpdateLaborerCaseData) => {
      dispatch(LaborerAction.updateLaborerCase(payload));
    },
    [dispatch]
  );

  const resetLaborerCaseDetails = useCallback(() => {
    dispatch(LaborerAction.resetLaborerCaseDetails());
  }, [dispatch]);

  return {
    isLoading,
    laborerCases: laborerCasesList.data,
    laborerCasesCount: laborerCasesList.count,
    laborerCase: laborerCase.data,

    fetchLaborerCases,
    fetchLaborerCase,
    fetchLaborerFilteredCases,
    createLaborerCase,
    updateLaborerCase,
    resetLaborerCaseDetails,
  };
};

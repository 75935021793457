import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import { routes } from 'const';
import { ActionButton } from './ActionButton';

type StartAssessmentActionButtonProps = {
  type: 'button' | 'link';
  laborerCaseId: string;
  className?: string;
  disabled: boolean;
};

export const StartAssessmentActionButton: React.FC<StartAssessmentActionButtonProps> = ({
  type,
  laborerCaseId,
  className,
  disabled,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRedirectToAssesmentForm = (): void => {
    history.push(routes.laborerCases.assessment.link(laborerCaseId));
  };

  return (
    <ActionButton
      disabled={disabled}
      className={className}
      type={type}
      label={t(['Assessment', 'StartAssesment'])}
      onClick={handleRedirectToAssesmentForm}
    />
  );
};

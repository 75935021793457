import React from 'react';
import MuiInputLabel, { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import { useStyles } from './InputLabel.styles';

export type InputLabelProps = PropsWithDataTestId<MuiInputLabelProps> & {
  htmlFor?: string;
};

export const InputLabel: React.FC<InputLabelProps> = ({ classes, dataTestId, ...props }) => {
  const styles = useStyles(props);

  return (
    <MuiInputLabel
      classes={{ ...styles, ...classes }}
      data-test-id={dataTestId}
      shrink
      disableAnimation
      variant="standard"
      {...props}
    />
  );
};

import { from, of, interval } from 'rxjs';
import { switchMap, mergeMap, map, mapTo, catchError, filter, withLatestFrom } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { push } from 'connected-react-router';
import { parse } from 'query-string';
import { apiClient } from 'root';
import { routes } from 'const';
import { ofType, Action, RootState } from 'services/store';
import { i18nClient } from 'services/translation-client';
import { isAPIError, isErrorEqualTo, normalizeQueryParams, Paginated } from 'modules/http';
import { EwaCenterAction } from 'modules/ewa-center';
import { UserRole } from 'modules/user';
import { SharedAction } from 'modules/shared';
import { isUserInactive, preparePostUserErrorMessage, prepareInviteUserErrorMessage } from './helpers';
import { UserAction, UserActionType } from './actions';
import { ExtendedUser, Role, UserStatus, Privilege, UserDetailsResponse, EditUserPrivilege, UserPoints } from './types';

export const fetchUsersEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchUsers),
    switchMap(({ payload }) =>
      from(
        apiClient.get<Paginated<ExtendedUser>>('/users/search', {
          params: normalizeQueryParams(payload.query),
        })
      ).pipe(
        map(({ data }) => UserAction.fetchUsersSuccess(data)),
        catchError(() =>
          of(
            UserAction.fetchUsersFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const refetchUsersEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(UserActionType.UpdateUserSuccess, UserActionType.InviteUserSuccess),
    withLatestFrom(state$),
    map(([_, { router }]) => {
      const queryParams = parse(router.location.search, { parseNumbers: true });

      return UserAction.fetchUsers(queryParams);
    })
  );

export const fetchLoggedUserDetailsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchLoggedUserDetails),
    switchMap(({ payload }) => {
      const { requestOptions } = payload;

      return from(apiClient.get<ExtendedUser>('/users/me', requestOptions)).pipe(
        mergeMap(({ data }) => {
          if (data?.userRole === UserRole.EwaCenterManager && data?.ewaCenterId) {
            return of(
              EwaCenterAction.fetchEwaCenter(data.ewaCenterId),
              UserAction.fetchLoggedUserDetailsSuccess(data),
              SharedAction.inspectLocationQueryParams()
            );
          }
          return of(UserAction.fetchLoggedUserDetailsSuccess(data), SharedAction.inspectLocationQueryParams());
        }),
        catchError(error => {
          if (error.message && error.message.indexOf('Network Error') !== -1) {
            return of(UserAction.fetchLoggedUserDetailsFailure(), UserAction.fetchLoggedUserDetailsNetworkConnection());
          }

          if (isAPIError(error) && isErrorEqualTo(error, 'NOT_FOUND', 'USER_BY_ID')) {
            return of(UserAction.fetchLoggedUserDetailsNotFound(), push(routes.inactive));
          }

          if (isAPIError(error) && isErrorEqualTo(error, 'FORBIDDEN', 'USER_EWA_CENTER_INACTIVE')) {
            return of(UserAction.fetchLoggedUserDetailsNotFound(), push(routes.inactive));
          }

          if (isAPIError(error) && isErrorEqualTo(error, 'FORBIDDEN', 'USER_INACTIVE')) {
            return of(UserAction.fetchLoggedUserDetailsNotFound(), push(routes.inactive));
          }

          if (requestOptions && !requestOptions.failSilently) {
            return of(
              UserAction.fetchLoggedUserDetailsFailure(),
              SharedAction.notifyUser(i18nClient.t('Common:GeneralRefreshPageErrorMessage'), 'error')
            );
          }

          return of(UserAction.fetchLoggedUserDetailsFailure());
        })
      );
    })
  );

export const fetchLoggedUserDetailsSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchLoggedUserDetailsSuccess),
    filter(({ payload }) => isUserInactive(payload.userDetails.status)),
    mapTo(push(routes.inactive))
  );

/**
 * Fetch user details each 6 minutes 30 seconds, so that it won't overlap 5 minute token refresh interval,
 * check for inactivity status change
 */
const fetchLoggedUserDetailsStartPoolingEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchLoggedUserDetailsStartPooling),
    switchMap(() =>
      interval(6.3 * 60 * 1000).pipe(map(() => UserAction.fetchLoggedUserDetails({ failSilently: true })))
    )
  );

export const postUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.PostUser),
    switchMap(({ payload }) =>
      from(apiClient.post('/users', { data: payload })).pipe(
        map(() => UserAction.postUserSuccess()),
        catchError(error =>
          of(
            UserAction.postUserFailure(),
            SharedAction.notifyUser(i18nClient.t(preparePostUserErrorMessage(error)), 'error')
          )
        )
      )
    )
  );

export const postUserSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.PostUserSuccess),
    mergeMap(() => of(SharedAction.notifyUser(i18nClient.t('Users:AddSubmitSuccess')), push(routes.users.main.path)))
  );

export const updateUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.UpdateUser),
    switchMap(({ payload }) =>
      from(apiClient.put('/users', { data: payload })).pipe(
        mergeMap(() =>
          of(
            UserAction.updateUserSuccess(),
            SharedAction.notifyUser(i18nClient.t('Users:UpdateUserSuccessMessage')),
            push(routes.users.main.path)
          )
        ),
        catchError(() =>
          of(
            UserAction.updateUserFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const inviteUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.InviteUser),
    switchMap(({ payload }) =>
      from(apiClient.post(`/users/${payload.userId}/invitations`)).pipe(
        mergeMap(() =>
          of(
            UserAction.inviteUserSuccess(),
            SharedAction.notifyUser(i18nClient.t('Users:ResendInvitationSuccessMessage'))
          )
        ),
        catchError(error =>
          of(
            UserAction.inviteUserFailure(),
            SharedAction.notifyUser(i18nClient.t(prepareInviteUserErrorMessage(error)), 'error')
          )
        )
      )
    )
  );

export const fetchRolesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchRoles),
    switchMap(() =>
      from(apiClient.get<Role[]>('/roles')).pipe(
        map(({ data }) => UserAction.fetchRolesSuccess(data)),
        catchError(() => of(UserAction.fetchRolesFailure()))
      )
    )
  );

export const fetchPrivilegesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchPrivileges),
    switchMap(() =>
      from(apiClient.get<Privilege[]>('/privileges/REGISTRAR')).pipe(
        map(({ data }) => UserAction.fetchPrivilegesSuccess(data)),
        catchError(() => of(UserAction.fetchPrivilegesFailure()))
      )
    )
  );

export const fetchUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchUser),
    switchMap(({ payload }) =>
      from(apiClient.get<UserDetailsResponse>(`/users/${payload.userId}`)).pipe(
        map(({ data }) => UserAction.fetchUserSuccess(data)),
        catchError(() =>
          of(
            UserAction.fetchUserFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const updateUserPrivilegeEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.UpdateUserPrivilege),
    switchMap(({ payload }) => {
      const { privileges, id } = payload;
      return from(
        apiClient.put<EditUserPrivilege>(`/users/${id}/privileges`, { data: payload.privileges })
      ).pipe(
        mergeMap(() =>
          of(
            UserAction.updateUserPrivilegeSuccess(id, privileges),
            SharedAction.notifyUser(i18nClient.t('Users:UpdateUserPrivilegeSuccessMessage')),
            push(routes.users.main.path)
          )
        ),
        catchError(() =>
          of(
            UserAction.updateUserPrivilegeFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      );
    })
  );

export const deleteUserEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.DeleteUser),
    switchMap(({ payload }) =>
      from(apiClient.delete(`/users/${payload.userId}`)).pipe(
        mergeMap(() =>
          of(
            UserAction.deleteUserSuccess(),
            SharedAction.notifyUser(i18nClient.t('Users:DeleteUserSuccessMessage')),
            push(routes.users.main.path)
          )
        ),
        catchError(() =>
          of(
            UserAction.deleteUserFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );
export const fetchUserPointsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(UserActionType.FetchUserPoints),
    switchMap(() =>
      from(apiClient.get<UserPoints>('/point/balance')).pipe(
        map(({ data }) => UserAction.fetchUserPointsSuccess(data)),
        catchError(() => of(UserAction.fetchUserPointsFailure()))
      )
    )
  );

export const userEpics = combineEpics(
  fetchUsersEpic,
  refetchUsersEpic,
  fetchLoggedUserDetailsEpic,
  fetchLoggedUserDetailsSuccessEpic,
  fetchLoggedUserDetailsStartPoolingEpic,
  postUserEpic,
  postUserSuccessEpic,
  updateUserEpic,
  inviteUserEpic,
  fetchRolesEpic,
  fetchPrivilegesEpic,
  fetchUserEpic,
  updateUserPrivilegeEpic,
  deleteUserEpic,
  fetchUserPointsEpic
);

import React from 'react';
import cn from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './Message.styles';

type Message = {
  message: string;
  variant?: TypographyProps['variant'];
};

type MessageProps = {
  className?: string;
  primary?: Message;
  secondary?: Message;
  Icon?: React.ComponentType<SvgIconProps>;
};

export const Message: React.SFC<MessageProps> = ({ className, children, primary, secondary, Icon }) => {
  const styles = useStyles();

  return (
    <Grid className={cn(styles.root, className)} container direction="column" alignItems="center" justify="center">
      {Icon && <Icon className={styles.icon} />}
      {primary && (
        <Typography variant={primary.variant || 'subtitle2'} align="center">
          {primary.message}
        </Typography>
      )}
      {secondary && (
        <Typography variant={secondary.variant || 'caption'} align="center">
          {secondary.message}
        </Typography>
      )}
      {children}
    </Grid>
  );
};

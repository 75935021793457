export interface FluxAction<T extends string> {
  type: T;
}

export interface FluxActionWithPayload<T extends string, P> extends FluxAction<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): FluxAction<T>;
export function createAction<T extends string, P>(type: T, payload: P): FluxActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P): FluxAction<T> | FluxActionWithPayload<T, P> {
  return payload === undefined ? { type } : { type, payload };
}

type ActionCreatorsMapObject = { [actionCreator: string]: (...args: any[]) => any };

export type ActionUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export type ActionsOfType<ActionsUnion, ActionType extends string> = ActionsUnion extends FluxAction<ActionType>
  ? ActionsUnion
  : never;

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selected: {
    color: `${theme.palette.primary.contrastText} !important`,
  },
}));

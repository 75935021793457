import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'const';
import { useCountdownTimer } from 'hooks';
import { webSocketClient } from 'root';
import { selectUserId, hasUserRoles, UserRole } from 'modules/user';
import { isStatusPending } from 'services/store';
import { isReservedByUserEvent, isCaseAlredyReservedEvent, isReleaseEvent, isReopenWindowAvailable } from '../helpers';
import { useLaborerCaseManagement, useRestoreReservation } from '.';
import { selectReservationStatus, selectReservedCaseId } from '../selectors';
import { LaborerAction } from '../actions';
import { ReservationEvent } from '../types';

const RESERVATION_TIME = 1000 * 60 * 5;

export const useLaborerCaseReservation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { laborerCase } = useLaborerCaseManagement();
  const reservedCaseId = useSelector(selectReservedCaseId);
  const { isActive, startCountdown, stopCountdown, timeLeft, reset } = useCountdownTimer(RESERVATION_TIME);
  const [reservationEventData, setReservationEventData] = useState<ReservationEvent | null>(null);

  const connectToReservationSocket = useCallback(() => {
    dispatch(LaborerAction.connectToReservationSocket());
  }, [dispatch]);

  const clearReservedCaseId = useCallback(() => {
    dispatch(LaborerAction.clearReservedCaseId());
  }, [dispatch]);

  const saveReservedCaseId = useCallback(
    (laborerCaseId: string) => {
      dispatch(LaborerAction.saveReservedCaseId(laborerCaseId));
    },
    [dispatch]
  );

  const reserveLaborerCase = useCallback(
    (laborerCaseId: string) => {
      dispatch(LaborerAction.reserveLaborerCase(laborerCaseId));
    },
    [dispatch]
  );

  const reserveLaborerCaseSuccess = useCallback(() => {
    dispatch(LaborerAction.reserveLaborerCaseSuccess());
  }, [dispatch]);

  const reserveLaborerCaseFailure = useCallback(() => {
    dispatch(LaborerAction.reserveLaborerCaseFailure());
  }, [dispatch]);

  const releaseLaborerCase = useCallback(
    (laborerCaseId: string) => {
      dispatch(LaborerAction.releaseLaborerCase(laborerCaseId));
    },
    [dispatch]
  );

  const reserveLaborerCaseAgain = useCallback(
    (laborerCaseId: string) => {
      dispatch(LaborerAction.reserveLaborerCaseAgain(laborerCaseId));
    },
    [dispatch]
  );

  const { updateReservedCaseStorege } = useRestoreReservation({
    connect: connectToReservationSocket,
    reserve: reserveLaborerCase,
  });

  const hasRoles = useSelector(hasUserRoles);
  const currentUserId = useSelector(selectUserId);
  const reservationStatus = useSelector(selectReservationStatus);

  const isLaborerCaseDetailsView = routes.laborerCases.details.isValid(location.pathname);
  const isLaborerCasesListView = routes.laborerCases.main.path === location.pathname;
  const isReservedByCurrentUser = currentUserId === (laborerCase && laborerCase.reservedByUserId);

  const shouldReleaseLaborerCase = isLaborerCasesListView && isReservedByCurrentUser;
  const isReservationLock = !isActive && timeLeft === 0;

  useEffect(() => {
    if (isActive && isReservedByCurrentUser && laborerCase && timeLeft === 0 && reservedCaseId === laborerCase.id) {
      releaseLaborerCase(laborerCase.id);
      stopCountdown();
    }
  }, [isActive, isReservedByCurrentUser, laborerCase, timeLeft]);

  useEffect(() => {
    if (!isLaborerCasesListView && isReservedByCurrentUser && laborerCase && reservedCaseId === laborerCase.id) {
      startCountdown();
    }
  }, [isLaborerCasesListView, isReservedByCurrentUser, laborerCase]);

  useEffect(() => {
    if (isActive && !isReservedByCurrentUser && laborerCase) {
      reset();
    }
  }, [isActive, isReservedByCurrentUser, laborerCase]);

  useEffect(() => {
    connectToReservationSocket();
  }, []);

  useEffect(() => {
    if (
      isReservedByUserEvent(reservationEventData, currentUserId) &&
      reservationEventData &&
      laborerCase &&
      reservationEventData.laborerCaseId === laborerCase.id &&
      isStatusPending(reservationStatus)
    ) {
      saveReservedCaseId(reservationEventData.laborerCaseId);
      reserveLaborerCaseSuccess();
    }

    if (isCaseAlredyReservedEvent(reservationEventData) && !reservedCaseId && isStatusPending(reservationStatus)) {
      reserveLaborerCaseFailure();
    }

    if (
      isReleaseEvent(reservationEventData) &&
      reservationEventData &&
      reservationEventData.releasedByUserId !== currentUserId &&
      reservationEventData.laborerCaseId === reservedCaseId
    ) {
      stopCountdown();
    }

    if (
      isReleaseEvent(reservationEventData) &&
      reservationEventData &&
      reservationEventData.laborerCaseId === reservedCaseId
    ) {
      clearReservedCaseId();
    }

    return () => {
      setReservationEventData(null);
    };
  }, [reservationEventData, currentUserId, reservedCaseId]);

  /**
   * Laborer case reservation logic
   */
  useEffect(() => {
    if (
      laborerCase &&
      laborerCase.reservedByUserId &&
      laborerCase.reservedByUserId !== currentUserId &&
      !isLaborerCaseDetailsView &&
      !isReservationLock
    ) {
      history.push(routes.laborerCases.main.path);
      return;
    }

    if (isLaborerCasesListView || isReservationLock) {
      return;
    }

    if (currentUserId && laborerCase && laborerCase.reservedByUserId === null) {
      if (hasRoles([UserRole.Registrar]) && laborerCase.status === 'PENDING_CLEARANCE') {
        reserveLaborerCase(laborerCase.id);
      }

      if (
        hasRoles([UserRole.Registrar]) &&
        laborerCase.status === 'CLOSED' &&
        !laborerCase.successorExists &&
        laborerCase.laborerCaseClosingDetails &&
        isReopenWindowAvailable(laborerCase.laborerCaseClosingDetails.exitDateTime)
      ) {
        reserveLaborerCase(laborerCase.id);
      }

      if (hasRoles([UserRole.SocialWorker]) && laborerCase.status === 'PENDING_SW_REVIEW') {
        reserveLaborerCase(laborerCase.id);
      }

      if (hasRoles([UserRole.LaborCommittee]) && laborerCase.status === 'PENDING_LC_REVIEW') {
        reserveLaborerCase(laborerCase.id);
      }
    }
  }, [laborerCase, currentUserId, isReservationLock, isLaborerCasesListView, isLaborerCaseDetailsView]);

  /**
   * Laborer case releasing logic
   */
  useEffect(() => {
    if (
      laborerCase &&
      shouldReleaseLaborerCase &&
      hasRoles([UserRole.Registrar, UserRole.SocialWorker, UserRole.LaborCommittee])
    ) {
      releaseLaborerCase(laborerCase.id);
      stopCountdown();
    }
  }, [laborerCase, shouldReleaseLaborerCase]);

  function attachIncomingMessageHandler(eventHandler: (event: MessageEvent) => void) {
    webSocketClient.onMessage(event => {
      eventHandler(event);
      updateReservedCaseStorege(event.data);
      setReservationEventData(event.data);
    });
  }

  return {
    isLoading: isStatusPending(reservationStatus),
    reserveLaborerCase,
    releaseLaborerCase,
    reserveLaborerCaseAgain,
    isReservationLock,
    reservationTimeLeft: timeLeft,
    isReservationTimerActive: isActive,

    attachIncomingMessageHandler,
  };
};

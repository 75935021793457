import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'services';
import { useLaborerCaseManagement } from 'modules/laborer-case';
import { selectUserId } from 'modules/user';
import { useStyles } from './ReservationOverlay.styles';

type ReservationOverlayProps = {
  reserve: (caseId: string, userId: string) => void;
};

export const ReservationOverlay: React.FC<ReservationOverlayProps> = ({ reserve }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { laborerCase } = useLaborerCaseManagement();

  const userId = useSelector(selectUserId);

  const handleReserveLaborerCase = () => {
    if (laborerCase && laborerCase.id && userId) {
      reserve(laborerCase.id, userId);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className={styles.container}>
      <Container>
        <Box display="flex" justifyContent="center" marginTop={15}>
          <Typography variant="h5">
            {t(['Case', 'ReservationTimeUpText1'])}
            <span className={styles.reserveLink} onClick={handleReserveLaborerCase}>
              {t(['Case', 'ReservationTimeUpButton'])}
            </span>
            {t(['Case', 'ReservationTimeUpText2'])}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

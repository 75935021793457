import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'services';
import { Button, FormSection, FormRow } from 'components/common';
import { FormTextField, FormSelect } from 'components/form-components';
import { reopenReasonOptions } from './form-options';
import { reopenCaseFormSchema, ReopenCaseFormData } from './validation';

type ReopenCaseFormProps = {
  onSubmit: SubmitHandler<ReopenCaseFormData>;
};

export const ReopenCaseForm: React.FC<ReopenCaseFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const formMethods = useForm<ReopenCaseFormData>({
    mode: 'onTouched',
    resolver: yupResolver(reopenCaseFormSchema()),
  });

  const { watch, handleSubmit } = formMethods;

  const reopenReasonValue = watch('reopenReason');

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormSection title={t(['Case', 'ReopenReasonSection'])}>
          <FormRow>
            <FormSelect name="reopenReason" options={reopenReasonOptions} labelKey="label" valueKey="value" />
          </FormRow>

          {reopenReasonValue === t(['Common', 'Other']) && (
            <FormRow>
              <FormTextField name="reopenReasonOther" multiline rows={5} />
            </FormRow>
          )}
        </FormSection>

        <FormSection>
          <Button type="submit">{t(['Common', 'Save'])}</Button>
        </FormSection>
      </form>
    </FormProvider>
  );
};

import React, { useState } from 'react';
import { Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'services';
import { useStyles } from './Dropdown.styles';

type DropdownPopperProps = {
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
  children: React.ReactNode;
};

export const DropdownPopper: React.FC<DropdownPopperProps> = ({ children, handleClose, anchorEl }) => {
  const styles = useStyles();
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const isOpen = Boolean(anchorEl);

  return (
    <Popper
      className={styles.popper}
      open={isOpen}
      anchorEl={anchorEl}
      modifiers={{ arrow: { enabled: true, element: arrowRef } }}
      placement="bottom"
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <div className={styles.content}>
            <span className={styles.arrow} ref={setArrowRef} />
            <ClickAwayListener onClickAway={handleClose}>
              <Paper style={{ direction: isArabic ? 'rtl' : 'ltr' }} elevation={4}>
                {children}
              </Paper>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
};

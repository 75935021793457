import { RootState } from 'services/store';

export const selectEwaCentersList = (store: RootState): RootState['ewaCenter']['ewaCentersList'] => {
  return store.ewaCenter.ewaCentersList;
};

export const selectEwaList = (store: RootState): RootState['ewaCenter']['ewaList'] => {
  return store.ewaCenter.ewaList;
};

export const selectEwaCenterDetails = (store: RootState): RootState['ewaCenter']['ewaCenterDetails'] => {
  return store.ewaCenter.ewaCenterDetails;
};

export const selectSelectedEwaCenterId = (store: RootState): RootState['ewaCenter']['selectedEwaCenterId'] => {
  return store.ewaCenter.selectedEwaCenterId;
};

export const selectPostEwaCenterStatus = (store: RootState): RootState['ewaCenter']['postEwaCenterStatus'] => {
  return store.ewaCenter.postEwaCenterStatus;
};

export const selectUpdateEwaCenterStatus = (store: RootState): RootState['ewaCenter']['updateEwaCenterStatus'] => {
  return store.ewaCenter.updateEwaCenterStatus;
};

import React from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useTranslation } from 'services/translation-client';
import { useSharedManagement } from 'modules/shared';
import { getReportFile } from '../api';

export const useReportFile = () => {
  const { t } = useTranslation();

  const { notifyUser } = useSharedManagement();

  const downloadReport = async (): Promise<void> => {
    try {
      const file = await getReportFile();
      saveAs(file, `users.xlsx`);
    } catch {
      notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
    }
  };

  return {
    downloadReport,
  };
};

import * as yup from 'yup';
import { omit } from 'lodash';
import { closeCaseFormSchema, CloseCaseFormData } from 'components';
import { toUTCISOString } from 'services';
import { LaborerSatisfactionStatus } from 'modules/laborer-case';
import { prepareFormPayload, AuthorityTypeDictionaryUnion } from 'modules/shared';
import { EditLaborerCaseRequest, LaborerCaseResponse } from '../types';

const closeLaborerCaseSchema = () => {
  return yup
    .object<CloseCaseFormData>()
    .concat(closeCaseFormSchema())
    .shape({
      authority: yup.mixed<AuthorityTypeDictionaryUnion>(),
      financialClaimsHasBeenReceived: yup.boolean(),
      personalItemsHasBeenReceived: yup.boolean(),
      laborerSatisfaction: yup.mixed<LaborerSatisfactionStatus>(),
    })
    .defined();
};

export const buildCloseLaborerCaseDto = (
  formData: CloseCaseFormData,
  laborerCase: LaborerCaseResponse,
  uploadedFileNames: string[]
): EditLaborerCaseRequest => ({
  ...omit(laborerCase, ['oldCaseFlow']),
  status: 'CLOSED',
  laborerCaseClosingDetails: {
    ...closeLaborerCaseSchema().cast(prepareFormPayload(formData)),
    endDate: formData.endDate ? toUTCISOString(formData.endDate) : null,
    exitDateTime: toUTCISOString(new Date()),
    uploadedFileName: uploadedFileNames[0],
  },
});

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isStatusPending } from 'services/store';
import {
  selectLaborerInfo,
  selectLaborerInfoCaseClosing,
  selectLaborerInfoEmployerList,
  selectLaborerInfoNotEligible,
  selectQuota,
} from './selectors';
import { LaborerInfoAction } from './actions';

export const useLaborerInfoManagement = () => {
  const dispatch = useDispatch();

  const quota = useSelector(selectQuota);
  const laborerInfo = useSelector(selectLaborerInfo);
  const laborerInfoCaseClosing = useSelector(selectLaborerInfoCaseClosing);
  const employerList = useSelector(selectLaborerInfoEmployerList);
  const notEligible = useSelector(selectLaborerInfoNotEligible);

  const isLoading =
    isStatusPending(laborerInfo.status) ||
    isStatusPending(laborerInfoCaseClosing.status) ||
    isStatusPending(employerList.status);

  const fetchQuota = useCallback(() => {
    dispatch(LaborerInfoAction.fetchQuota());
  }, [dispatch]);

  const fetchLaborerInfo = useCallback(
    (borderIqama: string) => {
      dispatch(LaborerInfoAction.fetchLaborerInfo(borderIqama));
    },
    [dispatch]
  );

  const fetchLaborerInfoWithNationality = useCallback(
    (borderIqama: string, nationality: string, registrarOption: string) => {
      dispatch(LaborerInfoAction.fetchLaborerInfoWithNationality(borderIqama, nationality, registrarOption));
    },
    [dispatch]
  );

  const fetchCitizenLaborerInfo = useCallback(
    (employerId: string, checkDate: string) => {
      dispatch(LaborerInfoAction.fetchCitizenLaborerInfo(employerId, checkDate));
    },
    [dispatch]
  );

  const fetchMuqeemLaborerInfo = useCallback(
    (employerId: string, checkDate: string) => {
      dispatch(LaborerInfoAction.fetchMuqeemLaborerInfo(employerId, checkDate));
    },
    [dispatch]
  );

  const fetchEmployerLaborersList = useCallback(
    (employerId: string, checkDate: string) => {
      dispatch(LaborerInfoAction.fetchEmployerLaborersList(employerId, checkDate));
    },
    [dispatch]
  );

  const clearLaborerRelatedData = useCallback(() => {
    dispatch(LaborerInfoAction.clearLaborerRelatedData());
  }, [dispatch]);

  return {
    isLoading,

    laborerInfo: laborerInfo.data,
    laborerInfoCaseClosing: laborerInfoCaseClosing.data,
    laborersByIqama: employerList.laborersByIqama,
    laborersByVisa: employerList.laborersByVisa,
    notEligible,
    quota,

    fetchQuota,
    fetchLaborerInfo,
    fetchCitizenLaborerInfo,
    fetchMuqeemLaborerInfo,
    fetchEmployerLaborersList,
    clearLaborerRelatedData,
    fetchLaborerInfoWithNationality,
  };
};

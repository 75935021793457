import { REPLACE_SIGN } from 'const';
import { isAPIError, isErrorEqualTo, Query } from 'modules/http';
import { EwaCenterShortResponse } from './types';
import { EwaCentersQuery } from './query';

export const getEwaCenterNameFromId = (
  ewaCenters: EwaCenterShortResponse[],
  userEwaCenterId: string | null
): string => {
  const ewaCenterItem = ewaCenters.find(ewaCenter => ewaCenter.id === userEwaCenterId);

  return ewaCenterItem ? ewaCenterItem.name : REPLACE_SIGN;
};

export const prepareEwaCenterErrorMessage = (error: unknown): string =>
  isAPIError(error) && isErrorEqualTo(error, 'CONFLICT', 'EWA_CENTER_BY_ID_ALREADY_EXISTS')
    ? 'EwaCenter:AlreadyExistsError'
    : 'Common:GeneralErrorMessage';

export const filterEntryTranslationMap: Record<keyof Omit<EwaCentersQuery, keyof Query>, string> = {
  ewaCenterId: 'Users:EwaCenter',
  cityName: 'Common:City',
  capacityFrom: 'Common:From',
  capacityTo: 'Common:To',
};

import { AxiosResponse } from 'axios';

export type CustomHttpResponse<T> = AxiosResponse<T> & {
  url?: string | null;
  ok?: boolean;
};

export const createCustomResponse = <T>(responseValues: AxiosResponse<T>): CustomHttpResponse<T> => {
  const defaultValues = {
    data: undefined,
    status: 200,
    statusText: 'OK',
    headers: {},
    config: {},
    url: undefined,
    request: {},
  };

  return {
    ...defaultValues,
    ...responseValues,
    ok: responseValues.statusText === 'OK',
    url: responseValues.config ? responseValues.config.url : null,
  };
};

export const wait = (ms = 0) => new Promise(r => setTimeout(r, ms));

import * as yup from 'yup';
import { i18nClient } from 'services';
import { UserRole } from 'modules/user';
import { ARABIC_LATIN_CHARACTER_PATTERN, DISALLOWED_ARABIC_CHARACTER_PATTERN } from 'const';

export type AddUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  ewaCenterId?: string | null;
  userRole: UserRole;
  privileges?: string[] | null | undefined;
};

export const addUserFormSchema = () => {
  return yup.object().shape<AddUserFormData>({
    firstName: yup
      .string()
      .matches(ARABIC_LATIN_CHARACTER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(FirstName)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(FirstName)' }))
      .min(2, i18nClient.t('Validation.MinLength', { field: '$t(FirstName)' })),
    lastName: yup
      .string()
      .matches(ARABIC_LATIN_CHARACTER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(LastName)' }),
        excludeEmptyString: true,
      })
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(LastName)' }))
      .min(2, i18nClient.t('Validation.MinLength', { field: '$t(LastName)' })),
    email: yup
      .string()
      .matches(DISALLOWED_ARABIC_CHARACTER_PATTERN, {
        message: i18nClient.t('Validation.Pattern', { field: '$t(Email)' }),
      })
      .email(i18nClient.t('Validation.Pattern', { field: '$t(Email)' }))
      .required(i18nClient.t('Validation.RequiredField', { field: '$t(Email)' })),
    ewaCenterId: yup
      .string()
      .nullable()
      .when('userRole', {
        is: role => role !== UserRole.MLSD,
        then: yup.string().required(i18nClient.t('Validation.RequiredField', { field: '$t(Users:EwaCenter)' })),
      }),
    userRole: yup.string<UserRole>().required(i18nClient.t('Validation.RequiredField', { field: '$t(Role)' })),
    privileges: yup.array<string>().nullable(),
  });
};

import React, { FC, forwardRef } from 'react';
import cn from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { InputLabel, InputLabelProps as InternalInputLabelProps } from '../InputLabel';
import { Input, InputProps as InternalInputProps } from '../Input';
import { FormHelperText, FormHelperTextProps as InternalFormHelperTextProps } from '../FormHelperText';
import { useStyles } from './TextField.styles';

type ForbiddenProps = 'select' | 'SelectProps';
export type TextFieldProps = PropsWithDataTestId<
  Omit<MuiTextFieldProps, ForbiddenProps | 'InputLabelProps' | 'InputProps' | 'FormHelperTextProps'>
> & {
  InputLabelProps?: InternalInputLabelProps;
  InputProps?: InternalInputProps;
  FormHelperTextProps?: InternalFormHelperTextProps;
};

export const TextField: FC<TextFieldProps> = forwardRef<HTMLDivElement, TextFieldProps>(function TextField(props, ref) {
  const styles = useStyles();
  const {
    autoComplete,
    autoFocus = false,
    classes,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    rows,
    rowsMax,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    type,
    value,
    variant,
    dataTestId,
    ...other
  } = props;

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  return (
    <FormControl
      className={cn(classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      ref={ref}
      required={required}
      color={color}
      variant={variant}
      {...other}
    >
      {label && (
        <InputLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}

      <Input
        aria-describedby={helperTextId}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        multiline={multiline}
        name={name}
        rows={rows}
        rowsMax={rowsMax}
        type={type}
        value={value}
        id={id}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        inputProps={inputProps}
        dataTestId={dataTestId}
        {...InputProps}
      />

      <div className={cn({ [styles.helperTextWrapper]: !FormHelperTextProps?.noHint })}>
        {helperText && (
          <FormHelperText id={helperTextId} {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </div>
    </FormControl>
  );
});

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'services';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NationalityResponseDto } from 'modules/shared';
import { TestId } from 'const';
import { Button, FormRow } from 'components/common';
import registrarOptions from 'assets/other/registrar-search-options.json';
import { FormSelect, FormTextField, FormDatePicker, FormAutocomplete } from 'components/form-components';
import { searchByOptions } from './form-options';
import { searchLaborerSchema, SearchByMode, SearchLaborerFormData } from './validation';

type SearchLaborerFormProps = {
  onChangeSearchByMode: (searchByMode: SearchByMode) => void;
  onSubmit: SubmitHandler<SearchLaborerFormData>;
  nationalities: NationalityResponseDto[];
};

export const SearchLaborerForm: React.FC<SearchLaborerFormProps> = ({
  onChangeSearchByMode,
  onSubmit,
  nationalities,
}) => {
  const { t, i18n } = useTranslation();
  const [nationality, setNationality] = useState<string>('');
  const formMethods = useForm<SearchLaborerFormData>({
    mode: 'onTouched',
    defaultValues: {
      searchByMode: searchByOptions[0].value,
      laborerSearchNationality: '',
    },
    resolver: yupResolver(searchLaborerSchema()),
  });

  const { watch, clearErrors, setValue, handleSubmit } = formMethods;

  const searchByModeValue = watch('searchByMode');
  const searchByValueValue = watch('searchByValue');
  const isDateOfBirth = /^1/.test(searchByValueValue);

  useEffect(() => {
    clearErrors('searchByValue');
    setValue('searchByValue', '');
    onChangeSearchByMode(searchByModeValue);
  }, [searchByModeValue]);

  useEffect(() => {
    if (nationalities.length === 1) {
      setNationality(nationalities[0].name);
      setValue('laborerSearchNationality', nationalities[0].arabicNationalityName);
    }
  }, [nationalities]);

  const interceptOnSubmit = (formData: SearchLaborerFormData) => {
    if (nationalities.length === 1) {
      onSubmit({
        ...formData,
        laborerSearchNationality: nationality,
      });
    } else {
      onSubmit({
        ...formData,
      });
    }
  };

  const isArabic = i18n.language === 'ar';
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(interceptOnSubmit)} autoComplete="off">
        <FormRow>
          <FormSelect
            style={{ display: 'none' }}
            name="searchByMode"
            options={searchByOptions}
            labelKey="label"
            valueKey="value"
            disabled={true}
          />
        </FormRow>

        <FormRow>
          <FormAutocomplete
            name="registrarOption"
            label={t(['Laborer', 'registrarOption'])}
            options={registrarOptions}
            labelKey={isArabic ? 'arabicName' : 'englishName'}
            valueKey="name"
          />
          <FormTextField
            dataTestId={TestId.AddLaborerCaseSearchByIqamaInput}
            name="searchByValue"
            label={t(['Laborer', 'BorderIqamaPassportNo'])}
          />
          {nationalities.length === 1 ? (
            <FormTextField name="laborerSearchNationality" label={t(['Common', 'Nationality'])} disabled />
          ) : (
            <FormAutocomplete
              dataTestId={TestId.AddLaborerCaseNationalitySearchInput}
              name="laborerSearchNationality"
              label={t(['Common', 'Nationality'])}
              options={nationalities}
              labelKey={isArabic ? 'arabicNationalityName' : 'englishNationalityName'}
              valueKey="name"
            />
          )}

          <Button dataTestId={TestId.AddLaborerCaseSearchButton} type="submit">
            {t(['Common', 'Search'])}
          </Button>
        </FormRow>
      </form>
    </FormProvider>
  );
};

import { makeStyles, Theme } from '@material-ui/core/styles';
import { SECTION_MARGIN_VERTICAL } from 'const';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  detailsContainer: {
    display: 'flex',
    alignItems: 'stretch',
    margin: theme.spacing(SECTION_MARGIN_VERTICAL, 0),
  },
  detailsCard: {
    width: 500,
    display: 'flex',
    justifyContent: 'space-between',
    '& + &': {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    '& > *': {
      flex: '0 1 50%',
      maxWidth: `calc(50% - ${theme.spacing(1)}px)`,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  detailElementContainer: {
    maxWidth: theme.spacing(40),
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(5),
  },
  hostingTypeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 1528px)': {
      display: 'block',
    },
  },
  hostingTypeOtherContainer: {
    marginLeft: theme.spacing(2),
    '@media (max-width: 1528px)': {
      marginLeft: 0,
    },
  },
  wideInput: {
    width: 390,
  },
  spacer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  hidden: {
    visibility: 'hidden',
  },
  hostingHistoryLable: {
    backgroundColor: '#e1e8ed',
    borderRadius: '4px',
    padding: theme.spacing(0.8),
    marginBottom: theme.spacing(0.7),
    marginTop: theme.spacing(2),
  },
  hostingLabel: {
    color: '#454545',
  },
}));

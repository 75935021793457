import React, { forwardRef } from 'react';
import cn from 'classnames';
import MuiOutlinedInput, { OutlinedInputProps as MuiOutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { useStyles } from './Input.styles';

export type InputProps = PropsWithDataTestId<MuiOutlinedInputProps> & {
  grayedOut?: boolean;
};

export const Input = forwardRef<unknown, InputProps>(function Input(
  { dataTestId, classes, grayedOut, multiline, inputProps, ...props },
  ref
) {
  const { root, rootMultiline, inputGrayedOut, ...styles } = useStyles();

  return (
    <MuiOutlinedInput
      ref={ref}
      classes={{
        ...styles,
        ...classes,
        root: cn(root, classes?.root, { [rootMultiline]: multiline }),
        input: cn({ [inputGrayedOut]: grayedOut }),
      }}
      inputProps={{ 'data-test-id': dataTestId, ...inputProps }}
      color="primary"
      margin="dense"
      multiline={multiline}
      {...props}
    />
  );
});

import React, { useMemo } from 'react';
import cn from 'classnames';
import { Tooltip, Typography } from '@material-ui/core';
import { isArabic } from 'modules/shared';
import { useStyles } from './ListItemColumn.styles';
import { useTranslation } from '../../../services';

type ListItemColumnProps = {
  className?: string;
  header?: string;
  value?: string | number | React.ReactNode;
  truncate?: boolean;
};

export const ListItemColumn: React.FC<ListItemColumnProps> = ({ children, className, header, value, truncate }) => {
  const styles = useStyles();
  const { i18n } = useTranslation();
  const isAr = i18n.language === 'ar';
  const dir = useMemo(() => (value && isArabic(value.toString()) ? 'rtl' : 'ltr'), [value]);

  return (
    <div className={cn(styles.column, className)}>
      {children || (
        <>
          <Typography className={styles.columnHeader}>{header}</Typography>
          <Tooltip
            arrow
            interactive
            classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
            placement="top-start"
            disableHoverListener={!truncate}
            title={value || ''}
          >
            <Typography noWrap dir={dir} className={isAr ? styles.columnValue : styles.columnLeftValue}>
              {value}
            </Typography>
          </Tooltip>
        </>
      )}
    </div>
  );
};

import { createAction } from 'services/store/create-action';
import { Paginated } from 'modules/http';
import { InvoiceListQuery } from './query';
import { InvoicesResponse, BundlePurchase, PurchaseBundleResponse } from './types';

export enum BundlesActionType {
  FetchInvoices = '[Bundles]: Fetch invoices',
  FetchInvoicesSuccess = '[Bundles]: Fetch invoices - success',
  FetchInvoicesFailure = '[Bundles]: Fetch invoices - failure',

  PostPurchaseBundle = '[Bundles]: Post purchase bundle',
  PostPurchaseBundleSuccess = '[Bundles]: Post purchase bundle - success',
  PostPurchaseBundleFailure = '[Bundles]: Post purchase bundle - failure',
}

export const BundlesAction = {
  fetchInvoices: (query?: InvoiceListQuery) => createAction(BundlesActionType.FetchInvoices, { query }),
  fetchInvoicesSuccess: (invoices: Paginated<InvoicesResponse>) =>
    createAction(BundlesActionType.FetchInvoicesSuccess, { invoices }),
  fetchInvoicesFailure: () => createAction(BundlesActionType.FetchInvoicesFailure),

  postPurchaseBundle: (data: BundlePurchase) => createAction(BundlesActionType.PostPurchaseBundle, data),
  postPurchaseBundleSuccess: (data: PurchaseBundleResponse) =>
    createAction(BundlesActionType.PostPurchaseBundleSuccess, data),
  postPurchaseBundleFailure: () => createAction(BundlesActionType.PostPurchaseBundleFailure),
};

export type BundlesActionCreator = typeof BundlesAction;

/**
 * @enum Contains component IDs
 * Used to simplify querying html elements when writing E2E tests by providing
 * unique identifiers
 */
export enum TestId {
  EwaCentersListContainer = 'ewa-centers-list-container',
  EwaCenterDetailsContainer = 'ewa-center-details-container',
  EwaCenterGoogleMap = 'ewa-center-google-map',
  EwaCenterCenterNameInput = 'ewa-center-center-name-input',
  EwaCenterCenterCityInput = 'ewa-center-center-city-input',
  EwaCenterSubmitButton = 'ewa-center-submit-button',

  NavigationPanelCentersIcon = 'navigation-panel-centers-icon',
  NavigationPanelUsersIcon = 'navigation-panel-users-icon',
  NavigationPanelLaborerCasesIcon = 'navigation-panel-laborer-cases-icon',

  EwaCentersListEditButton = 'ewa-centers-list-edit-center-button',
  EwaCentersListAddButton = 'ewa-centers-list-add-center-button',

  UsersListAddButton = 'users-list-add-user-button',

  UserCenterInput = 'user-center-input',
  UserCenterDropdown = 'user-center-dropdown',
  UserRoleInput = 'user-role-input',
  UserRoleDropdown = 'user-role-dropdown',
  UserSubmitButton = 'user-submit-button',

  AddLaborerCaseSearchLaborerInput = 'add-laborer-case-search-laborer-input',
  AddLaborerCaseSearchByIqamaInput = 'add-laborer-case-search-by-iqama-input',
  AddLaborerCaseNationalitySearchInput = 'add-laborer-case-nationality-search-input',
  AddLaborerCaseNationalityInput = 'add-laborer-case-nationality-input',
  lookupLaborerCaseNationalityInput = 'lookup-laborer-case-nationality-input',
  LookupLaborerCaseUploadFileButton = 'lookup-laborer-case-upload-file',
  AddLaborerCaseUploadFileButton = 'add-laborer-case-upload-file',
  AddLaborerCaseSearchButton = 'add-laborer-case-search-button',
  LookupLaborerCaseSearchButton = 'lookup-laborer-case-search-button',
  LookupLaborerCaseSearchByIqamaInput = 'lookup-laborer-case-search-by-iqama-input',

  CloseLaborerCaseSearchButton = 'close-laborer-case-search-button',
  CloseLaborerCaseUploadFileButton = 'close-laborer-case-upload-file',
  CloseLaborerCaseSubmitButton = 'close-laborer-case-submit-button',

  LaborerCaseDetailsEditHostingTypeButton = 'laborer-case-details-edit-hosting-type-button',
  LaborerCaseDetailsUpdateHostingTypeButton = 'laborer-case-details-update-hosting-type-button',
  LaborerCaseDetailsCancelHostingTypeButton = 'laborer-case-details-cancel-hosting-type-button',

  LaborerCaseAssessmentSubmitButton = 'laborer-case-assessment-submit-button',

  AddEwaCenterContainer = 'add-ewa-center-container',

  UsersListContainer = 'users-list-container',
  AddUserContainer = 'add-user-container',

  LaborerCasesContainer = 'laborer-cases-container',
  LaborerCaseDetailsContainer = 'laborer-case-details-container',
  LaborerCaseAssessmentContainer = 'laborer-case-assessment-container',
  AddLaborerCaseContainer = 'add-laborer-case-container',
  LookupLaborerCaseContainer = 'lookup-laborer-case-container',
  CloseLaborerCaseContainer = 'close-laborer-case-container',
  ReopenLaborerCaseContainer = 'reopen-laborer-case-container',

  AssessmentFormSectionLaborer = 'assessment-form-section-laborer',
  AssessmentFormSectionWork = 'assessment-form-section-work',
  AssessmentFormSectionDispute = 'assessment-form-section-dispute',

  BundlesContainer = 'bundles-container',
}

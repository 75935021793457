import React from 'react';
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';
import { InputAdornment, IconButton } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { DEFAULT_FORMAT, DEFAULT_HIJRI_FORMAT } from 'services/date';
import { HijriUtils } from './hijri-utils';
import { CustomUtils } from './custom-utils';
import { TextField } from '../TextField';
import 'moment/locale/ar-sa';
import 'moment/locale/en-gb';

type MuiPickersProviderProps = {
  hijri: DatePickerProps['hijri'];
};

const MuiPickersProvider: React.FC<MuiPickersProviderProps> = ({ children, hijri }) => {
  return hijri ? (
    <MuiPickersUtilsProvider key="ar-SA" utils={HijriUtils} locale="ar-SA">
      {children}
    </MuiPickersUtilsProvider>
  ) : (
    <MuiPickersUtilsProvider key="en-GB" utils={CustomUtils} locale="en-GB">
      {children}
    </MuiPickersUtilsProvider>
  );
};

export type DatePickerProps = PropsWithDataTestId<MuiDatePickerProps> & {
  hijri?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = ({ dataTestId, format, disabled, hijri = true, ...props }) => {
  const minDate = hijri ? '1921-09-02' : '1900-01-01';
  const maxDate = hijri ? '2076-11-26' : '2100-01-01';
  const dateFormat = format ?? hijri ? DEFAULT_HIJRI_FORMAT : DEFAULT_FORMAT;

  const CalendarIcon = (
    <InputAdornment position="end">
      <IconButton disabled={disabled} size="small">
        <EventIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <MuiPickersProvider hijri={hijri}>
      <MuiDatePicker
        data-test-id={dataTestId}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        format={dateFormat}
        PopoverProps={{
          disableScrollLock: true,
          disableAutoFocus: true,
          disableEnforceFocus: true,
          disableRestoreFocus: true,
        }}
        TextFieldComponent={TextField}
        InputProps={{ endAdornment: CalendarIcon }}
        {...props}
      />
    </MuiPickersProvider>
  );
};

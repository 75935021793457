import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '4px 5px',
    width: 'auto',
    color: theme.palette.text.primary,
  },
  buttonActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  content: {
    padding: theme.spacing(3, 0, 0),
  },
  arrow: {
    position: 'absolute',
    fontSize: 10,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

type Environment = 'e2e' | 'development' | 'staging' | 'uat' | 'production' | 'develop';

export type ConfigObject = {
  APP_ENVIRONMENT: Environment;
  APP_ROOT_URL: string;
  API_BASE_URL: string;
  WS_BASE_URL: string;

  KEYCLOAK_SSO_AUTH_URL: string;
  KEYCLOAK_SSO_ACCOUNT_SETTINGS_URL: string;
  KEYCLOAK_CLIENT_ID: string;
  KEYCLOAK_REALM: string;

  GOOGLE_MAPS_API_KEY: string;
  SENTRY_DSN: string;
};

export const {
  APP_ENVIRONMENT,
  APP_ROOT_URL,
  API_BASE_URL,
  WS_BASE_URL,

  KEYCLOAK_SSO_AUTH_URL,
  KEYCLOAK_SSO_ACCOUNT_SETTINGS_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,

  GOOGLE_MAPS_API_KEY,
  SENTRY_DSN,
} = window.appConfig;

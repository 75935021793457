import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { init } from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createStore } from './services/store';
import { isDevelopment, SENTRY_DSN, APP_ENVIRONMENT } from './config';
import { version } from './const';
import { Root } from './root';
import './index.css';

init({
  dsn: SENTRY_DSN,
  debug: isDevelopment(),
  enabled: !isDevelopment(),
  environment: APP_ENVIRONMENT,
  release: `musaned-ewa-frontend@${version}`,
});

const history = createBrowserHistory();
const store = createStore(history);

ReactDOM.render(
  <ReduxStoreProvider store={store}>
    <ConnectedRouter history={history}>
      <Root />
    </ConnectedRouter>
  </ReduxStoreProvider>,
  document.getElementById('root')
);

import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export const BundlesIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      version="1.0"
      id="case-icon"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      width="18.034"
      height="16.1"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M269 491 c-64 -21 -69 -27 -69 -95 l0 -63 -68 -5 c-37 -3 -82 -13
-100 -22 l-32 -17 0 -124 0 -124 32 -18 c24 -13 56 -18 118 -18 105 0 150 19
150 64 0 28 1 28 68 33 37 3 82 13 100 22 l32 17 0 159 0 159 -32 17 c-37 19
-158 28 -199 15z m171 -24 c74 -25 18 -66 -90 -66 -66 0 -125 18 -125 39 0 21
59 39 125 39 30 0 71 -5 90 -12z m-90 -86 c30 0 72 6 93 13 l38 14 -3 -26 c-4
-33 -48 -51 -128 -51 -80 0 -124 18 -128 51 l-3 26 38 -14 c21 -7 63 -13 93
-13z m0 -70 c30 0 72 6 93 13 l38 14 -3 -26 c-4 -33 -47 -51 -125 -51 -42 -1
-53 2 -53 15 0 9 -17 23 -40 32 -50 21 -52 34 -2 16 20 -7 62 -13 92 -13z
m-110 -14 c74 -25 18 -66 -90 -66 -66 0 -125 18 -125 39 0 21 59 39 125 39 30
0 71 -5 90 -12z m236 -72 c-7 -18 -69 -35 -128 -35 -45 0 -48 2 -48 25 0 23 3
25 53 26 28 0 68 6 87 12 37 13 49 4 36 -28z m-326 -14 c30 0 72 6 93 13 l38
14 -3 -26 c-4 -33 -48 -51 -128 -51 -80 0 -124 18 -128 51 l-3 26 38 -14 c21
-7 63 -13 93 -13z m326 -56 c-7 -18 -69 -35 -128 -35 -45 0 -48 2 -48 25 0 23
3 25 53 26 28 0 68 6 87 12 37 13 49 4 36 -28z m-326 -14 c30 0 72 6 93 13
l38 14 -3 -26 c-4 -33 -48 -51 -128 -51 -80 0 -124 18 -128 51 l-3 26 38 -14
c21 -7 63 -13 93 -13z m0 -70 c30 0 72 6 93 13 l38 14 -3 -26 c-4 -33 -48 -51
-128 -51 -80 0 -124 18 -128 51 l-3 26 38 -14 c21 -7 63 -13 93 -13z"
        />
      </g>
    </SvgIcon>
  );
};

import React from 'react';
import cn from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './Loader.styles';

type LoaderProps = {
  className?: string;
  loading: boolean;
  withOverflow?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ children, className, loading, withOverflow }) => {
  const styles = useStyles();
  const rootClassName = cn(className, styles.root, { [styles.rootWithOverflow]: withOverflow });

  return (
    <div className={rootClassName}>
      {loading && (
        <div className={styles.spinnerWrapper}>
          <CircularProgress />
        </div>
      )}
      <div className={cn(styles.content, { [styles.contentLoading]: loading })}>{children}</div>
    </div>
  );
};

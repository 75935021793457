import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import { Link, Typography, Tooltip } from '@material-ui/core';
import { remainingDaysFromReopenTimeWindow } from 'modules/laborer-case';
import { routes } from 'const';
import { Button } from 'components';
import { useStyles } from './ReopenActionButton.styles';

type ReopenActionButtonProps = {
  type: 'button' | 'link';
  laborerCaseId: string;
  className?: string;
  exitDateTime: string;
  disabled: boolean;
};

export const ReopenActionButton: React.FC<ReopenActionButtonProps> = ({
  type,
  laborerCaseId,
  className,
  exitDateTime,
  disabled,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const styles = useStyles();

  const handleRedirectToReopenForm = (): void => {
    history.push(routes.laborerCases.reopen.link(laborerCaseId));
  };

  return (
    <Tooltip
      arrow
      interactive
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      placement="top"
      disableHoverListener={disabled}
      title={t(['Case', 'ReopenRemainingDays'], {
        days: remainingDaysFromReopenTimeWindow(exitDateTime),
      })}
    >
      {type === 'button' ? (
        <Button className={className} onClick={handleRedirectToReopenForm} disabled={disabled}>
          <Typography>{t(['Case', 'Reopen'])}</Typography>
        </Button>
      ) : (
        <Link
          className={className}
          component="button"
          underline="always"
          onClick={handleRedirectToReopenForm}
          disabled={disabled}
        >
          <Typography>{t(['Case', 'Reopen'])}</Typography>
        </Link>
      )}
    </Tooltip>
  );
};

import { from, of } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { push } from 'connected-react-router';
import { apiClient } from 'root';
import { routes } from 'const';
import { ofType, Action } from 'services/store';
import { i18nClient } from 'services/translation-client';
import { SharedAction } from 'modules/shared';
import { normalizeQueryParams, Paginated } from 'modules/http';
import { BundlesAction, BundlesActionType } from './actions';
import { PurchaseBundleResponse, InvoicesResponse } from './types';

export const postPurchaseBundleEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(BundlesActionType.PostPurchaseBundle),
    switchMap(({ payload }) =>
      from(
        apiClient.post<PurchaseBundleResponse>('/invoice/purchase', { data: payload })
      ).pipe(
        map(({ data }) => BundlesAction.postPurchaseBundleSuccess(data)),
        catchError(error =>
          of(
            BundlesAction.postPurchaseBundleFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const postPurchaseBundleSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(BundlesActionType.PostPurchaseBundleSuccess),
    mergeMap(({ payload }) =>
      of(
        SharedAction.notifyUser(
          i18nClient.t('Bundles:PurchaseSuccess', { amount: payload.amount, billId: payload.billId })
        ),
        push(routes.bundles.main.path)
      )
    )
  );

export const fetchInvoicesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(BundlesActionType.FetchInvoices),
    switchMap(({ payload }) =>
      from(
        apiClient.get<Paginated<InvoicesResponse>>('/invoice/invoice-list', {
          params: normalizeQueryParams(payload.query),
        })
      ).pipe(
        map(({ data }) => BundlesAction.fetchInvoicesSuccess(data)),
        catchError(() =>
          of(
            BundlesAction.fetchInvoicesFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const bundlesEpics = combineEpics(postPurchaseBundleEpic, postPurchaseBundleSuccessEpic, fetchInvoicesEpic);

import { useState } from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'services/translation-client';
import { useSharedManagement } from 'modules/shared';
import { postFile, getFile } from './api';
import { stripTimestampFromFileName } from './helpers';

export const useFilesManagement = <T = File>(state: T[] = []) => {
  const { t } = useTranslation();
  const { notifyUser } = useSharedManagement();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<T[]>(state);

  const postFiles = async (fileList: File[]): Promise<string[]> => {
    setIsLoading(true);
    let fileNames: string[] = [];

    try {
      fileNames = await Promise.all(fileList.map(postFile));
    } catch {
      notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
    } finally {
      setIsLoading(false);
    }

    return fileNames;
  };

  const downloadFile = async (fileName: string): Promise<void> => {
    setIsLoading(true);

    try {
      const file = await getFile(fileName);
      saveAs(file, stripTimestampFromFileName(fileName));
    } catch {
      notifyUser(t(['Common', 'GeneralErrorMessage']), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,

    files,

    setFiles,
    postFiles,
    downloadFile,
  };
};

import React, { useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'services/translation-client';
import {
  selectUserRoles,
  hasUserRoles,
  UserRole,
  selectCenterId,
  selectUserPrivileges,
  useUsersManagement,
  EditUserPrivilege,
  CreateExtendedUserUserRequest,
} from 'modules/user';
import { EwaCenterShortResponse } from 'modules/ewa-center';
import { TestId } from 'const';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { SharedAction } from 'modules/shared';
import { Button, FormSection, FormRow, FormControl } from 'components/common';
import { FormTextField, FormSelect } from 'components/form-components';
import { AddUserFormData, addUserFormSchema } from './validation';
import { useStyles } from './AddUserForm.styles';

const formInitialValues: AddUserFormData = {
  firstName: '',
  lastName: '',
  email: '',
  ewaCenterId: '',
  userRole: UserRole.none,
  privileges: [],
};
type AddUserFormProps = {
  ewaCenters: EwaCenterShortResponse[];
  onSubmit: SubmitHandler<AddUserFormData>;
  userDetails?: AddUserFormData;
  userId?: string;
};

export const AddUserForm: React.FC<AddUserFormProps> = ({ ewaCenters, userDetails, onSubmit, userId }) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const userRoles = useSelector(selectUserRoles);
  const userPrivileges = useSelector(selectUserPrivileges);
  const hasRoles = useSelector(hasUserRoles);
  const dispatch = useDispatch();
  const formMethods = useForm<AddUserFormData>({
    mode: 'onTouched',
    defaultValues: userDetails || formInitialValues,
    resolver: yupResolver(addUserFormSchema()),
  });
  const { watch, handleSubmit, setValue } = formMethods;
  const userRoleValue = watch('userRole');
  const isMLSDRoleSelected = userRoleValue === UserRole.MLSD;
  const isRegistrarRoleSelected = userRoleValue === UserRole.Registrar;
  const centerId = useSelector(selectCenterId);
  const { updateUserPrivilege, editUser, isLoading } = useUsersManagement();
  const roles = userRoles
    .filter(role => role.name !== UserRole.Admin)
    .map(({ name, arabicName, engilshName }) => ({ name: arabicName, value: name, engilshName }));
  const currentUserPrivileges = userDetails?.privileges || [];
  let privilegesIDs = currentUserPrivileges;
  const handlePrivilege = (privilege: string, checked: boolean) => {
    if (!checked) {
      privilegesIDs = privilegesIDs.filter(obj => {
        return obj !== privilege;
      });
    } else {
      privilegesIDs.push(privilege);
    }
  };

  const disabled = !!userDetails;
  const interceptOnSubmit = (formData: AddUserFormData) => {
    const privilegeInitialValues: EditUserPrivilege = {
      privileges: privilegesIDs,
    };

    if (!privilegesIDs.length && isRegistrarRoleSelected) {
      dispatch(SharedAction.notifyUser(t(['Users', 'UserPrivilegeRestriction']), 'error'));
    } else if (userId && privilegeInitialValues) {
      if (hasRoles([UserRole.Admin])) {
        editUser({
          id: userId,
          ...userDetails,
          userRole: formData.userRole,
          ewaCenterId: formData.ewaCenterId,
          privileges: formData.userRole === 'REGISTRAR' ? privilegesIDs : null,
        } as CreateExtendedUserUserRequest);
      } else {
        updateUserPrivilege(userId, privilegeInitialValues);
      }
    } else {
      onSubmit({
        privileges: privilegesIDs,
        ...formData,
      });
    }
  };
  const checkCurrentUserPrivilege = (code: string) => {
    let val = false;
    currentUserPrivileges.forEach(cur => {
      if (code === cur) {
        val = true;
      }
    });
    return val;
  };
  const isArabic = i18n.language === 'ar';

  useEffect(() => {
    if (hasRoles([UserRole.EwaCenterManager])) {
      setValue('ewaCenterId', centerId);
      setValue('userRole', 'REGISTRAR');
    }
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(interceptOnSubmit)} autoComplete="off">
        <FormSection title={t(['Users', 'AddFormSection'])}>
          <FormRow>
            <FormTextField name="firstName" label={t(['Common', 'FirstName'])} disabled={disabled} />
            <FormTextField name="lastName" label={t(['Common', 'LastName'])} disabled={disabled} />
          </FormRow>

          <FormRow>
            <FormTextField name="email" label={t(['Common', 'Email'])} placeholder="x@x.x" disabled={disabled} />
            {!isMLSDRoleSelected && !hasRoles([UserRole.EwaCenterManager]) && (
              <FormSelect
                dataInputTestId={TestId.UserCenterInput}
                dataDropdownTestId={TestId.UserCenterDropdown}
                name="ewaCenterId"
                label={t(['Users', 'EwaCenter'])}
                options={ewaCenters}
                labelKey="name"
                valueKey="id"
              />
            )}
            {hasRoles([UserRole.EwaCenterManager]) && (
              <Controller
                name="ewaCenterId"
                defaultValue={centerId}
                style={{ display: 'none' }}
                render={() => <FormControl></FormControl>}
                disabled={disabled}
              />
            )}
          </FormRow>

          {!hasRoles([UserRole.EwaCenterManager]) ? (
            <FormRow>
              <FormSelect
                dataInputTestId={TestId.UserRoleInput}
                dataDropdownTestId={TestId.UserRoleDropdown}
                name="userRole"
                label={t(['Common', 'Role'])}
                options={roles}
                labelKey={isArabic ? 'name' : 'engilshName'}
                valueKey="value"
              />
            </FormRow>
          ) : (
            <Controller
              name="userRole"
              defaultValue="REGISTRAR"
              style={{ display: 'none' }}
              render={() => <FormControl></FormControl>}
            />
          )}
        </FormSection>
        {isRegistrarRoleSelected && (
          <FormSection title={t(['Users', 'UserPrivilege'])}>
            <FormControl>
              {userPrivileges.map(privilege => (
                <FormControlLabel
                  className={styles.checkBox}
                  key={privilege.id}
                  control={
                    <Checkbox
                      onChange={event => handlePrivilege(privilege.code, event.target.checked)}
                      color={'primary'}
                      defaultChecked={checkCurrentUserPrivilege(privilege.code)}
                    />
                  }
                  label={isArabic ? privilege.arabicName : privilege.name}
                />
              ))}
            </FormControl>
          </FormSection>
        )}
        <FormSection>
          {userDetails ? (
            <Button dataTestId={TestId.UserSubmitButton} type="submit">
              {t(['Common', 'Update'])}
            </Button>
          ) : (
            <Button dataTestId={TestId.UserSubmitButton} type="submit">
              {t(['Common', 'Add'])}
            </Button>
          )}
        </FormSection>
      </form>
    </FormProvider>
  );
};

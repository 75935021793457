import { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

/* eslint-disable no-dupe-class-members */

export class CustomUtils extends MomentUtils {
  date(value: null): null;
  date(value: Date): Moment;
  date(value?: any): Moment | null {
    if (value === null) {
      return null;
    }

    return this.moment(value).locale('en-GB');
  }

  mergeDateAndTime(date: Moment): Moment {
    const merged = this.moment(date);
    const now = this.date(new Date());

    merged.hours(now.hours());
    merged.minutes(now.minutes());
    merged.seconds(now.seconds());
    merged.milliseconds(now.milliseconds());

    return merged;
  }
}

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUsersManagement, isUserInactive, UserRole } from 'modules/user';
import { routes } from 'const';

type ProtectedRouteProps = RouteProps & {
  availableFor: UserRole[];
  extraCondition?: boolean;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  availableFor,
  extraCondition = true,
  ...routeProps
}) => {
  const { loggedUser } = useUsersManagement();

  if (loggedUser) {
    if (isUserInactive(loggedUser.status)) {
      return <Redirect to={routes.inactive} />;
    }

    if (availableFor.includes(loggedUser.userRole) && extraCondition) {
      return <Route {...routeProps} />;
    }
  }

  return <Redirect to={routes.root} />;
};

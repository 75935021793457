import { apiClient } from 'root';
import { FilesResponse } from './types';

export const postFile = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);
  const {
    data: { fileName },
  } = await apiClient.post<FilesResponse>('/files', {
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return fileName;
};

export const getFile = async (fileName: string) => {
  const { data } = await apiClient.get<Blob>(`/files/${fileName}`, { responseType: 'blob' });
  return data;
};

import moment from 'moment';
import React, { ReactNode, ComponentType } from 'react';
import { isString } from 'lodash';
import { Box, Link, Typography } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useStyles } from './PageHeader.styles';

type PageHeaderProps = {
  title?: ReactNode;
  redirectLabel?: string;
  timeLeft?: number;
  hasTimer?: boolean;
  onRedirectClick?: () => void;
  Icon?: ComponentType<SvgIconProps>;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  redirectLabel,
  onRedirectClick,
  Icon,
  timeLeft,
  hasTimer = false,
}) => {
  const styles = useStyles({ timeLeft });

  return (
    <Box className={styles.wrapper}>
      <Box display="flex" justifyContent="space-between" width="100%">
        {redirectLabel && (
          <Link component="button" color="secondary" underline="always" onClick={onRedirectClick}>
            <Typography>{`< ${redirectLabel}`}</Typography>
          </Link>
        )}
        {hasTimer && (
          <div className={styles.timer}>
            <Typography className={styles.timerLabel}>صلاحية الإسناد</Typography>
            <Typography className={styles.time}>{moment.utc(timeLeft).format('mm:ss')}</Typography>
          </div>
        )}
      </Box>
      <Box display="flex" alignItems="flex-end">
        {Icon && <Icon fontSize="large" />}

        {isString(title) ? (
          <Box marginLeft={2}>
            <Typography variant="h4">{title}</Typography>
          </Box>
        ) : (
          title
        )}
      </Box>
    </Box>
  );
};

import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { useStyles } from './Button.styles';

const hasWhiteSpace = (s: string): boolean => /\s/g.test(s);

type ButtonPropTypes = PropsWithDataTestId<MuiButtonProps> & {
  component?: string;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonPropTypes>(function Button(
  { children, dataTestId, ...props },
  ref
) {
  const styles = useStyles();
  const size = typeof children === 'string' && !hasWhiteSpace(children) ? 'small' : 'medium';

  return (
    <MuiButton
      data-test-id={dataTestId}
      ref={ref}
      classes={styles}
      color="primary"
      size={size}
      variant="contained"
      {...props}
    >
      {children}
    </MuiButton>
  );
});

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { webSocketClient } from 'root';
import { selectUserId } from 'modules/user';
import { LocalStorage } from 'services/local-storage';
import { isReleaseEvent, isReservedByUserEvent } from '../helpers';

const reservationStytemStore = new LocalStorage('reservedCaseList', localStorage);

const getReservedCaseList = () => reservationStytemStore.load<string[]>() || [];

const saveIdOfReservedCase = (caseId: string) => {
  const reservedCaseList = getReservedCaseList();
  const newList = [...Array.from(new Set([...reservedCaseList, caseId]))];

  reservationStytemStore.save(newList);
};

const removeIdOfReservedCase = (caseId: string) => {
  const reservedCaseList = getReservedCaseList();
  const newList = reservedCaseList.filter(id => id !== caseId);

  reservationStytemStore.save(newList);
};

type HookOptions = {
  connect: () => void;
  reserve: (caseId: string, userId: string) => void;
};

export const useRestoreReservation = ({ connect, reserve }: HookOptions) => {
  const [isWsConnectionLost, setWsConnectionStatus] = useState(false);
  const currentUserId = useSelector(selectUserId);

  useEffect(() => {
    connect();

    if (webSocketClient) {
      webSocketClient.onClose(error => {
        if (error.code === 1001) {
          setWsConnectionStatus(true);
          connect();
        }
      });
    }

    return () => {
      if (webSocketClient) {
        webSocketClient.onClose(() => {});
      }
    };
  }, []);

  useEffect(() => {
    if (isWsConnectionLost) {
      webSocketClient.onOpen(() => {
        setWsConnectionStatus(false);

        getReservedCaseList().forEach(caseId => {
          if (caseId && currentUserId) {
            reserve(caseId, currentUserId);
            removeIdOfReservedCase(caseId);
          }
        });
      });
    }
  }, [isWsConnectionLost]);

  const updateReservedCaseStorege = (data: any) => {
    if (isReleaseEvent(data)) {
      removeIdOfReservedCase(data.laborerCaseId);
    }

    if (isReservedByUserEvent(data, currentUserId)) {
      saveIdOfReservedCase(data.laborerCaseId);
    }
  };

  return {
    updateReservedCaseStorege,
  };
};

import * as yup from 'yup';
import { i18nClient } from 'services/translation-client';

export type ReopenCaseFormData = {
  reopenReason: string;
  reopenReasonOther?: string;
};

export const reopenCaseFormSchema = () => {
  return yup.object().shape<ReopenCaseFormData>({
    reopenReason: yup.string().required(i18nClient.t('Validation.Required')),
    reopenReasonOther: yup.string().when('reopenReason', {
      is: reopenReason => reopenReason === i18nClient.t('Other'),
      then: yup.string().required(i18nClient.t('Validation.Required')),
    }),
  });
};

import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { userReducer } from 'modules/user/reducer';
import { laborerReducer } from 'modules/laborer-case/reducer';
import { bundleReducer } from 'modules/bundles/reducer';
import { laborerInfoReducer } from 'modules/laborer-info/reducer';
import { ewaCenterReducer } from 'modules/ewa-center/reducer';
import { sharedReducer } from 'modules/shared/reducer';

export const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    laborer: laborerReducer,
    laborerInfo: laborerInfoReducer,
    ewaCenter: ewaCenterReducer,
    shared: sharedReducer,
    bundle: bundleReducer,
  });

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

import React, { VFC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { isNotProduction } from 'config';
import { DatePicker, DatePickerProps } from 'components/common';

type ForbiddenProps = 'value' | 'onChange' | 'onBlur' | 'defaultValue' | 'size' | 'children';
type FormDatePickerProps = Omit<DatePickerProps, ForbiddenProps> & {
  name: string;
};

export const FormDatePicker: VFC<FormDatePickerProps> = ({ name, ...props }) => {
  const { errors, control } = useFormContext();
  const error = get(errors, name, null);
  const errorMessage = get(error, 'message', '');

  if (isNotProduction() && !control) {
    console.error('FormDatePicker: this component must be descendant of `FormProvider` in order to use form context.');
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ name: fieldName, value, onChange, onBlur }) => (
        <DatePicker
          name={fieldName}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={Boolean(error)}
          helperText={errorMessage}
          {...props}
        />
      )}
    />
  );
};

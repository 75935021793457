import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'services';
import { FormRadioGroup } from 'components/form-components';
import { workQuestions, workOptions } from '../form-options';
import { FormSection } from './FormSection';

type SectionWorkProps = PropsWithDataTestId<{
  readOnly?: boolean;
}>;

export const SectionWork: React.FC<SectionWorkProps> = ({ dataTestId, readOnly }) => {
  const { t } = useTranslation();

  return (
    <FormSection title={t(['Assessment', 'WorkSectionName'])} dataTestId={dataTestId}>
      {workQuestions.map((question, index) => (
        <Box key={question.name} display="flex" alignItems="flex-start">
          <Box flex="1 1 40%" display="flex" alignItems="center" height={42}>
            <Box flex="0 0 24px">{index + 1}</Box>
            <Box flex="1 1 auto">{question.label}</Box>
          </Box>

          <Box flex="1 1 60%">
            <FormRadioGroup name={question.name} disabled={readOnly} options={workOptions} />
          </Box>
        </Box>
      ))}
    </FormSection>
  );
};

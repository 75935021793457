import React from 'react';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'services/translation-client';
import { TextField, TextFieldProps as InternalTextFieldProps } from '../TextField';
import { useStyles } from './Autocomplete.styles';

export type AutocompleteOption = Record<string, string | number>;
export type AutocompletePropsMap<T> = MuiAutocompleteProps<T, false, false, false>;

export type AutocompleteProps<T extends AutocompleteOption> = PropsWithDataTestId<
  Omit<AutocompletePropsMap<T>, 'renderInput'>
> & {
  labelKey: keyof T;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  TextFieldProps?: Omit<InternalTextFieldProps, Exclude<keyof AutocompleteRenderInputParams, 'InputProps'>>;
};

export const Autocomplete = <T extends AutocompleteOption>({
  classes,
  options,
  dataTestId,
  labelKey,
  name,
  label,
  error,
  helperText,
  TextFieldProps,
  ...props
}: AutocompleteProps<T>) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { InputProps, ...otherTextFieldProps } = TextFieldProps ?? {};

  const getLabel = (option: T): string => {
    return option[labelKey].toString() ?? '';
  };

  return (
    <MuiAutocomplete
      id={dataTestId}
      classes={{ popperDisablePortal: styles.popperDisablePortal, endAdornment: styles.endAdornment, ...classes }}
      options={options}
      getOptionLabel={getLabel}
      noOptionsText={t(['Common', 'NoResults'])}
      renderInput={(params: AutocompleteRenderInputParams) => {
        const { InputProps: ParamsInputProps, ...otherParams } = params;

        return (
          <TextField
            name={name}
            label={label}
            error={error}
            helperText={helperText}
            InputProps={{ ...InputProps, ...ParamsInputProps }}
            {...otherTextFieldProps}
            {...otherParams}
          />
        );
      }}
      {...props}
    />
  );
};

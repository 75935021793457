import { makeStyles, Theme } from '@material-ui/core/styles';

// TODO: remove the important rule as soon as we solve the global specificity issue

export const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    whiteSpace: 'nowrap',
    marginRight: `${theme.spacing(3)}px !important`,

    '&[disabled]': {
      cursor: 'not-allowed',
    },
  },
}));

import * as yup from 'yup';
import { omit } from 'lodash';
import { caseClassificationFormSchema, CaseClassificationFormData } from 'components';
import { isHostingTypePayload, HostingTypeEditFormData } from 'views/LaborerCases/LaborerCaseDetails';
import { LaborCommitteeClassificationType } from 'modules/shared';
import { EditLaborerCaseRequest, LaborerCaseResponse } from '../types';

const updateLaborerCaseSchema = () => {
  return yup
    .object<CaseClassificationFormData>()
    .concat(caseClassificationFormSchema())
    .shape({
      laborCommitteeClassification: yup.mixed<LaborCommitteeClassificationType>(),
    })
    .defined();
};

export const buildUpdateLaborerCaseDto = (
  formData: CaseClassificationFormData | HostingTypeEditFormData,
  laborerCase: LaborerCaseResponse
): EditLaborerCaseRequest => {
  const laborerCaseBase = omit(laborerCase, 'oldCaseFlow');

  if (isHostingTypePayload(formData)) {
    return {
      ...laborerCaseBase,
      ...formData,
    };
  }

  return {
    ...laborerCaseBase,
    ...updateLaborerCaseSchema().cast(formData),
    status: laborerCase.oldCaseFlow ? 'PENDING_SW_REVIEW' : 'PENDING_CLEARANCE',
  };
};

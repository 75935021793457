import React from 'react';
import { useTranslation } from 'services';
import { FormRow } from 'components/common';
import { FormRadioGroup, FormTextField } from 'components/form-components';
import { entranceReasonOptions } from '../form-options';
import { FormSection } from './FormSection';

type SectionDisputeProps = PropsWithDataTestId<{
  readOnly?: boolean;
}>;

export const SectionDispute: React.FC<SectionDisputeProps> = ({ dataTestId, readOnly }) => {
  const { t } = useTranslation();

  return (
    <FormSection title={t(['Assessment', 'DisputeSectionName'])} dataTestId={dataTestId}>
      <FormRow>
        <FormRadioGroup
          name="entranceReason"
          label={t(['Assessment', 'DisputeEntranceReason'])}
          disabled={readOnly}
          options={entranceReasonOptions}
        />
      </FormRow>

      <FormRow>
        <FormTextField
          name="disputeDescription"
          label={t(['Assessment', 'DisputeDisputesDescription'])}
          disabled={readOnly}
          multiline
          rows={5}
        />
      </FormRow>

      <FormRow>
        <FormTextField
          name="laborerTemporalResidence"
          label={t(['Assessment', 'DisputeLaborerTempResidence'])}
          disabled={readOnly}
          multiline
          rows={5}
        />
      </FormRow>

      <FormRow>
        <FormTextField
          name="socialWorkerRecommendation"
          label={t(['Assessment', 'DisputeSocialWorkerRecommendation'])}
          disabled={readOnly}
          multiline
          rows={5}
        />
      </FormRow>
    </FormSection>
  );
};

import React, { lazy, useEffect, Suspense } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Box, CircularProgress } from '@material-ui/core';
import { useUsersManagement, hasUserRoles, UserRole, selectIsNetworkConnectionProblem } from 'modules/user';
import { useSharedManagement, ApplicationLocationState } from 'modules/shared';
import { selectSelectedEwaCenterId } from 'modules/ewa-center';
import { APP_ROOT_URL } from 'config';
import { routes } from 'const';
import { Inactive } from 'views/Inactive';
import { ApplicationCoreElements, Loader, RedirectInterceptor } from 'components';

const LazyEwaCentersModule = lazy(
  () =>
    import(
      /* webpackChunkName: 'ewa-centers-module'
       */ 'views/EwaCenters'
    )
);

const LazyUsersModule = lazy(
  () =>
    import(
      /* webpackChunkName: 'users-module'
       */ 'views/Users'
    )
);

const LazyLaborerCasesModule = lazy(
  () =>
    import(
      /* webpackChunkName: 'laborer-cases-module'
       */ 'views/LaborerCases'
    )
);

const LazyBundlesModule = lazy(
  () =>
    import(
      /* webpackChunkName: 'bundles-module'
       */ 'views/Bundles'
    )
);

// TODO: Make Suspense fallback loader a fullscreen one

export const App: React.FC = () => {
  const history = useHistory<ApplicationLocationState>();
  const { keycloak } = useKeycloak();
  const { loggedUser, fetchLoggedUserDetailsStartPooling } = useUsersManagement();
  const { startLocationObserver } = useSharedManagement();

  const hasRoles = useSelector(hasUserRoles);
  const isNetworkConnectionProblem = useSelector(selectIsNetworkConnectionProblem);
  const isEwaCenterSelectedByMLSD = Boolean(useSelector(selectSelectedEwaCenterId));

  const shouldHideCoreElements = hasRoles([UserRole.MLSD]) && !isEwaCenterSelectedByMLSD;

  const handleLogout = (): void => {
    keycloak?.logout({ redirectUri: APP_ROOT_URL });
  };

  const isUserNotAuthed = (keycloak && !keycloak.authenticated) || !loggedUser;

  /**
   * Logout user after 15 seconds of timeout, arbitrary amount of time
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isUserNotAuthed && !isNetworkConnectionProblem) {
        handleLogout();
      }
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, [isUserNotAuthed, isNetworkConnectionProblem]);

  useEffect(() => {
    startLocationObserver(history);
    fetchLoggedUserDetailsStartPooling();
  }, []);

  if (isNetworkConnectionProblem) {
    return (
      <ApplicationCoreElements hide={false} onLogout={handleLogout}>
        {null}
      </ApplicationCoreElements>
    );
  }

  if (isUserNotAuthed) {
    return (
      <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={100} thickness={2.5} />
      </Box>
    );
  }

  return (
    <ApplicationCoreElements hide={shouldHideCoreElements} onLogout={handleLogout}>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route exact path={routes.root} component={RedirectInterceptor} />

          <Route exact path={routes.inactive} component={Inactive} />

          <Route path={routes.ewaCenter.main.path} component={LazyEwaCentersModule} />

          <Route path={routes.users.main.path} component={LazyUsersModule} />

          <Route path={routes.laborerCases.main.path} component={LazyLaborerCasesModule} />

          <Route path={routes.bundles.main.path} component={LazyBundlesModule} />

          <Redirect to={{ pathname: routes.root, state: { status: 404 } }} />
        </Switch>
      </Suspense>
    </ApplicationCoreElements>
  );
};

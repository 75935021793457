import { i18nClient } from 'services';
import { LaborerSatisfactionStatus } from 'modules/laborer-case';

export const yesOrNoOptions = () => {
  return [
    {
      value: 'true',
      label: i18nClient.t('Yes'),
    },
    {
      value: 'false',
      label: i18nClient.t('No'),
    },
  ];
};

type LaborerSatisfactionOption = {
  value: LaborerSatisfactionStatus;
  label: string;
};

export const laborerSatisfactionOptions = () => {
  return [
    {
      value: 'EXCELLENT',
      label: i18nClient.t('Excellent'),
    },
    {
      value: 'GOOD',
      label: i18nClient.t('Good'),
    },
    {
      value: 'POOR',
      label: i18nClient.t('Poor'),
    },
  ];
};

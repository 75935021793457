import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'services';
import { useUsersManagement, isUserInactive } from 'modules/user';
import { routes } from 'const';
import { Message } from 'components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 64px)',
  },
  message: {
    maxWidth: theme.spacing(100),
  },
}));

export const Inactive: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { loggedUser } = useUsersManagement();

  if (loggedUser) {
    if (!isUserInactive(loggedUser.status)) {
      return <Redirect to={routes.root} />;
    }

    return (
      <Box className={styles.root} display="flex" justifyContent="center" alignItems="center">
        <Message
          className={styles.message}
          primary={{ message: t(['Users', 'InactiveUserMessage']), variant: 'h4' }}
          Icon={ErrorOutlineIcon}
        />
      </Box>
    );
  }

  return null;
};

import { AxiosInstance } from 'axios';
import { BasicHttpClient, Credentials, BasicRequestConfig } from './basic-http-client';
import { CustomHttpResponse } from './helpers';

interface AuthenticatedHttpClientConstructor {
  axiosInstance: AxiosInstance;
  fetchCredentials: () => Credentials | null;
}

export class AuthenticatedHttpClient {
  axiosInstance: AuthenticatedHttpClientConstructor['axiosInstance'];
  fetchCredentials: AuthenticatedHttpClientConstructor['fetchCredentials'];

  httpClient: BasicHttpClient;

  constructor({ axiosInstance, fetchCredentials }: AuthenticatedHttpClientConstructor) {
    this.axiosInstance = axiosInstance;
    this.fetchCredentials = fetchCredentials;
    this.httpClient = new BasicHttpClient({ axiosInstance });
    this.httpClient.configRepeatInterceptor();
  }

  request<T>(path: string, options: BasicRequestConfig): Promise<CustomHttpResponse<T>> {
    const credentials = this.fetchCredentials();
    const authorizationHeaders = credentials ? { Authorization: `Bearer ${credentials.token}` } : {};
    const httpClientOptions = {
      ...options,
      headers: { ...options.headers, ...authorizationHeaders },
    };

    return this.httpClient.request<T>(path, httpClientOptions);
  }
}

import React from 'react';
import { Box } from '@material-ui/core';
import { AppPanel, NavigationPanel, FooterPanel } from '..';
import { useStyles } from './ApplicationCoreElements.styles';
import { useTranslation } from '../../../services';

type ApplicationCoreElementsProps = {
  hide: boolean;
  onLogout: () => void;
};

export const ApplicationCoreElements: React.FC<ApplicationCoreElementsProps> = ({ children, hide, onLogout }) => {
  const styles = useStyles();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  if (hide) {
    return <>{children}</>;
  }

  return (
    <Box display="flex" dir={isArabic ? 'rtl' : 'ltr'}>
      <NavigationPanel />
      <Box flexGrow={1}>
        <AppPanel onLogout={onLogout} />
        <main className={styles.content}>
          {/* <div className={styles.appPanelSpacer} /> */}
          {children}
        </main>
        <FooterPanel />
      </Box>
    </Box>
  );
};

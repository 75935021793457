import { from, of } from 'rxjs';
import { pickBy, identity } from 'lodash';
import { switchMap, map, mergeMap, catchError, filter, withLatestFrom } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { apiClient } from 'root';
import { ofType, Action, RootState } from 'services/store';
import { i18nClient } from 'services/translation-client';
import { isErrorEqualTo } from 'modules/http';
import { SharedAction } from 'modules/shared';
import { LaborerInfoAction, LaborerInfoActionType } from './actions';
import {
  isIqamaNumber,
  prepareFetchLaborerInfoErrorMessage,
  prepareFetchCitizenOrMuqeemLaborerInfoErrorMessage,
  prepareFetchEmployerLaborersListErrorMessage,
} from './helpers';
import { toLaborerInfoResponseDto } from './dto';
import { LaborerInfoResponse, LaborerInfoByEmployerIdResponse, LaborerByVisa, LaborerByIqama, Quota } from './types';

/* eslint-disable camelcase */

export const fetchLaborerInfoEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchLaborerInfo),
    switchMap(({ payload }) =>
      from(
        apiClient.get<LaborerInfoResponse>('/laborersinfo', {
          params: {
            [isIqamaNumber(payload.borderIqama) ? 'iqama_number' : 'border_number']: payload.borderIqama,
          },
        })
      ).pipe(
        withLatestFrom(state$),
        mergeMap(([{ data }, { shared }]) => {
          if (payload.borderIqama !== String(data.laborerNumber)) {
            return of(
              LaborerInfoAction.fetchLaborerInfoSuccess(toLaborerInfoResponseDto(data, shared.nationalities.data)),
              SharedAction.notifyUser(i18nClient.t('Laborer:LaborerWillBeRegisteredByIqamaNotification'))
            );
          }

          return of(
            LaborerInfoAction.fetchLaborerInfoSuccess(toLaborerInfoResponseDto(data, shared.nationalities.data))
          );
        }),
        catchError(error => {
          const errorMessage = prepareFetchLaborerInfoErrorMessage(error);
          const notEligible = isErrorEqualTo(error, 'BAD_REQUEST', 'NOT_ELIGIBLE');

          return of(
            LaborerInfoAction.fetchLaborerInfoFailure(),
            LaborerInfoAction.setNotEligible(notEligible),
            SharedAction.notifyUser(i18nClient.t(errorMessage), 'error')
          );
        })
      )
    )
  );

export const fetchLaborerInfoWithNationalityEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchLaborerInfoWithNationality),
    switchMap(({ payload }) =>
      from(
        apiClient.get<LaborerInfoResponse>('/laborersinfo', {
          params: {
            [payload.registrarOption]: payload.borderIqama,
            nationality: payload.nationality,
          },
        })
      ).pipe(
        withLatestFrom(state$),
        mergeMap(([{ data }, { shared }]) => {
          if (payload.borderIqama !== String(data.laborerNumber)) {
            return of(
              LaborerInfoAction.fetchLaborerInfoSuccess(toLaborerInfoResponseDto(data, shared.nationalities.data)),
              SharedAction.notifyUser(i18nClient.t('Laborer:LaborerWillBeRegisteredByIqamaNotification'))
            );
          }

          return of(
            LaborerInfoAction.fetchLaborerInfoSuccess(toLaborerInfoResponseDto(data, shared.nationalities.data))
          );
        }),
        catchError(error => {
          const errorMessage = prepareFetchLaborerInfoErrorMessage(error);
          const notEligible = isErrorEqualTo(error, 'NOT_ACCEPTABLE', 'NOT_ELIGIBLE');
          const laborerExistsWithDetails = isErrorEqualTo(
            error,
            'CONFLICT',
            'LABORER_BY_ID_EXISTS_WITH_EWA_CENTER_DETAILS'
          );

          let EwaCenter = '';
          let Number = '';
          let Email = '';
          if (laborerExistsWithDetails) {
            const { apierror } = error.data;

            if (apierror) {
              const msg = apierror?.message?.replace('{', '')?.replace('}', '');

              msg.split(',').forEach((element: string) => {
                if (element.includes('Email')) {
                  Email = element?.replace('Email=', '');
                } else if (element.includes('Name')) {
                  EwaCenter = element?.replace('Name=', '');
                } else if (element.includes('Mobile')) {
                  Number = element?.replace('Mobile Number=+', '');
                }
              });
            }
          }

          const err = laborerExistsWithDetails
            ? SharedAction.notifyUser(
                i18nClient.t('Laborer:AlreadyExistsErrorWithDetails', {
                  EwaCenter,
                  Number,
                  Email,
                }),
                'error',
                true
              )
            : SharedAction.notifyUser(i18nClient.t(errorMessage), 'error');

          return of(LaborerInfoAction.fetchLaborerInfoFailure(), LaborerInfoAction.setNotEligible(notEligible), err);
        })
      )
    )
  );

// TODO: add unit test
const fetchQuotaEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchQuota),
    switchMap(() =>
      from(apiClient.get<Quota>('/quota')).pipe(
        map(({ data }) => LaborerInfoAction.fetchQuotaSuccess(data)),
        catchError(() => {
          // const errorMessage = prepareFetchQuotaErrorMessage(error);

          return of(
            LaborerInfoAction.fetchQuotaFailure()
            // SharedAction.notifyUser(i18nClient.t(errorMessage), 'error')
          );
        })
      )
    )
  );

// TODO: add unit test
const fetchCitizenLaborerInfoEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchCitizenLaborerInfo),
    switchMap(({ payload }) =>
      from(
        apiClient.get<LaborerInfoResponse>('/laborersinfo/citizens', {
          params: {
            iqama_number: payload.employerId,
            checkDate: payload.checkDate,
          },
        })
      ).pipe(
        map(({ data }) => LaborerInfoAction.fetchCitizenLaborerInfoSuccess(data)),
        catchError(error => {
          const errorMessage = prepareFetchCitizenOrMuqeemLaborerInfoErrorMessage(error);

          return of(
            LaborerInfoAction.fetchCitizenLaborerInfoFailure(),
            SharedAction.notifyUser(i18nClient.t(errorMessage), 'error')
          );
        })
      )
    )
  );

// TODO: add unit test
const fetchMuqeemLaborerInfoEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchMuqeemLaborerInfo),
    switchMap(({ payload }) =>
      from(
        apiClient.get<LaborerInfoResponse>('/laborersinfo/muqeems', {
          params: {
            iqama_number: payload.employerId,
            checkDate: payload.checkDate,
          },
        })
      ).pipe(
        map(({ data }) => LaborerInfoAction.fetchMuqeemLaborerInfoSuccess(data)),
        catchError(error => {
          const errorMessage = prepareFetchCitizenOrMuqeemLaborerInfoErrorMessage(error);

          return of(
            LaborerInfoAction.fetchMuqeemLaborerInfoFailure(),
            SharedAction.notifyUser(i18nClient.t(errorMessage), 'error')
          );
        })
      )
    )
  );

export const fetchEmployerListEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchEmployerLaborersList),
    switchMap(({ payload }) =>
      from(
        apiClient.get<LaborerInfoByEmployerIdResponse>('/laborersinfo/employer', {
          params: {
            id: payload.employerId,
            checkDate: payload.checkDate,
          },
        })
      ).pipe(
        map(({ data }) => {
          const laborersByIqama = data.employerLaborerDtoList.map(item => pickBy(item, identity)) as LaborerByIqama[];
          const laborersByVisa = data.employerVisaInfoDtoList.map(item => pickBy(item, identity)) as LaborerByVisa[];

          return LaborerInfoAction.fetchEmployerLaborersListSuccess(laborersByIqama, laborersByVisa);
        }),
        catchError(error =>
          of(
            LaborerInfoAction.fetchEmployerLaborersListFailure(),
            SharedAction.notifyUser(i18nClient.t(prepareFetchEmployerLaborersListErrorMessage(error)), 'error')
          )
        )
      )
    )
  );

export const fetchEmployerListSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerInfoActionType.FetchEmployerLaborersListSuccess),
    filter(({ payload }) => !payload.laborersByIqama.length),
    map(() => SharedAction.notifyUser(i18nClient.t('Laborer:NoRegisteredWorkerError'), 'error'))
  );

export const laborerInfoEpics = combineEpics(
  fetchLaborerInfoEpic,
  fetchCitizenLaborerInfoEpic,
  fetchMuqeemLaborerInfoEpic,
  fetchEmployerListEpic,
  fetchEmployerListSuccessEpic,
  fetchQuotaEpic,
  fetchLaborerInfoWithNationalityEpic
);

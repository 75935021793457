import React, { useState, useEffect, useCallback } from 'react';
import { LoadScript, GoogleMap as GMap, Marker } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { TestId } from 'const';

/* eslint-disable camelcase */

const RIJAD_COORDINATES = {
  lat: 24.7255553,
  lng: 46.5423441,
};

const CONTAINER_STYLE = {
  width: '400px',
  height: '400px',
};

const addressPartsFactory = (addressElements: google.maps.GeocoderAddressComponent[]) => (type: string): string => {
  const element = addressElements.find(addressElement => addressElement.types.includes(type));

  return element ? element.long_name : '';
};

const createAddressFromLocation = (location: GoogleMapLocation): string => {
  return `${location.buildingNumber.trim()} ${location.streetName.trim()}, ${location.district.trim()} ${location.postalCode.trim()}`;
};

export type GoogleMapLocation = {
  buildingNumber: string;
  streetName: string;
  district: string;
  postalCode: string;
  locationAdditionalNumber: string;
};

interface MapContainerProps {
  location: GoogleMapLocation;
  onLocationChange: (data: GoogleMapLocation) => void;
}

const MapContainer: React.FC<MapContainerProps> = ({ location, onLocationChange }) => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | undefined>();
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | undefined>();
  const geocoder = new google.maps.Geocoder();
  let timeoutId: NodeJS.Timeout | null = null;

  const getCoordsFromAddress = useCallback((): void => {
    const address = createAddressFromLocation(location);

    geocoder.geocode({ address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const { geometry } = results[0];

        if (geometry) {
          const coords = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };

          if (mapInstance) {
            mapInstance.panTo(coords);
          }

          setMarkerPosition(coords);
        } else {
          setMarkerPosition(undefined);
        }
      }
    });
  }, [location, mapInstance]);

  const getLocationFromCoords = useCallback((locationCoords: google.maps.LatLngLiteral): void => {
    geocoder.geocode({ location: locationCoords }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const { address_components } = results[0];

        if (address_components.length) {
          const addressParts = addressPartsFactory(address_components);
          const mapLocation = {
            buildingNumber: addressParts('street_number'),
            streetName: addressParts('route'),
            district: addressParts('sublocality'),
            postalCode: addressParts('postal_code'),
            locationAdditionalNumber: addressParts('postal_code_suffix'),
          };

          onLocationChange(mapLocation);
        }
      }
    });
  }, []);

  const handleClearClickTimeout = (): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const handleClickOrDrag = (event: google.maps.MouseEvent | google.maps.IconMouseEvent): void => {
    const coords = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    timeoutId = setTimeout(() => {
      setMarkerPosition(coords);
      getLocationFromCoords(coords);
    }, 200);
  };

  useEffect(getCoordsFromAddress, [location]);

  return (
    <GMap
      id={TestId.EwaCenterGoogleMap}
      mapContainerStyle={CONTAINER_STYLE}
      center={RIJAD_COORDINATES}
      zoom={8}
      onClick={handleClickOrDrag}
      onDblClick={handleClearClickTimeout}
      onLoad={setMapInstance}
    >
      {markerPosition && (
        <Marker
          draggable
          animation={google.maps.Animation.BOUNCE}
          position={markerPosition}
          onDragEnd={handleClickOrDrag}
        />
      )}
    </GMap>
  );
};

export const GoogleMap: React.FC<MapContainerProps> = ({ location, onLocationChange }) => (
  <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} loadingElement={<div style={{ height: '100%' }} />}>
    <MapContainer location={location} onLocationChange={onLocationChange} />
  </LoadScript>
);

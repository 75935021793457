import React from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@material-ui/core/MenuItem';
import { Input, InputProps as InternalInputProps } from '../Input';
import { useStyles } from './Select.styles';

// TODO: use MenuItemProps

export type SelectOption = Record<string, string | number>;
export type SelectProps<T extends SelectOption> = PropsWithCustomDataTestId<
  'dataInputTestId' | 'dataDropdownTestId',
  MuiSelectProps
> & {
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  InputProps?: InternalInputProps;
  MenuItemProps?: MuiMenuItemProps;
};

export const Select = <T extends SelectOption>({
  classes,
  options,
  labelKey,
  valueKey,
  InputProps,
  dataInputTestId,
  dataDropdownTestId,
  ...props
}: SelectProps<T>) => {
  const styles = useStyles();

  return (
    <MuiSelect
      id={dataInputTestId}
      classes={{ select: styles.select, ...classes }}
      input={<Input {...InputProps} />}
      MenuProps={{ disableScrollLock: true, MenuListProps: { id: dataDropdownTestId } }}
      {...props}
    >
      {options.map(option => {
        const itemLabel = option[labelKey] ?? '';
        const itemValue = option[valueKey] ?? '';

        return (
          <MuiMenuItem key={itemValue} classes={{ selected: styles.selected }} value={itemValue}>
            {itemLabel}
          </MuiMenuItem>
        );
      })}
    </MuiSelect>
  );
};

import ReactPDF from '@react-pdf/renderer';

export const printStyles: ReactPDF.Styles = {
  body: {
    fontFamily: 'Frutiger',
    fontSize: 14,
    textAlign: 'right',
    paddingTop: 25,
    paddingBottom: 45,
    paddingHorizontal: 45,
  },
  pageHeader: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  pageImage: {
    width: '30%',
    marginLeft: 'auto',
  },
  pageTitle: {
    fontSize: 22,
    width: '65%',
    paddingTop: 10,
  },
  sectionHeader: {
    fontSize: 18,
    margin: 12,
    marginTop: 20,
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#cecfd5',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: '#cecfd5',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: '20%',
    backgroundColor: '#ecf0f1',
  },
  tableColValue: {
    width: '30%',
  },
  tableColValueSpacious: {
    width: '80%',
  },
  tableCell: {
    fontSize: 10,
    marginHorizontal: 5,
  },
};

import React from 'react';
import { get } from 'lodash';
import { fullFormat, useTranslation } from 'services';
import {
  LaborerCaseResponse,
  LaborerAssessmentResponse,
  LaborerCaseAssessmentWorkedFor,
  LaborerCaseAssessmentOfficialDocument,
  LaborerCaseAssessmentDocumentType,
  LaborerCaseAssessmentEntranceReason,
} from 'modules/laborer-case';
import {
  getValue,
  getCaseClassificationLabel,
  getNationalityLabelByName,
  getOccupationLabelByName,
  RegistrarClassificationResponse,
  LaborCommitteeClassificationResponse,
  NationalityResponseDto,
  OccupationResponse,
} from 'modules/shared';
import { computeViolations } from 'components/form-containers/AssessmentForm/violation';
import MusanedLogo from 'assets/images/musaned-logo.png';
import FrutigerFont from 'assets/fonts/FrutigerLTArabic-55Roman.ttf';
import { normalizeCaseAssessmentDetails, createSequence, flipParentheses } from '../utils';
import { printStyles } from '../Print.styles';

type PrintCaseSummaryProps = {
  caseDetails: LaborerCaseResponse;
  caseAssessment: LaborerAssessmentResponse;
  registrarCaseClassifications: RegistrarClassificationResponse[];
  committeeCaseClassifications: LaborCommitteeClassificationResponse[];
  laborerOccupations: OccupationResponse[];
  nationalities: NationalityResponseDto[];
  reactPdf: typeof import('@react-pdf/renderer');
};

export const PrintCaseSummary: React.FC<PrintCaseSummaryProps> = ({
  caseDetails,
  caseAssessment,
  registrarCaseClassifications,
  committeeCaseClassifications,
  laborerOccupations,
  nationalities,
  reactPdf,
}) => {
  const { t, tBasic } = useTranslation();

  const { Document, Page, View, Text, Image, Font, StyleSheet } = reactPdf;
  const styles = StyleSheet.create(printStyles);
  Font.register({ family: 'Frutiger', src: FrutigerFont });
  Font.registerHyphenationCallback(word => [word]);

  const violations = computeViolations(normalizeCaseAssessmentDetails(caseAssessment) as any);

  const getMartialStatus = (married: boolean) => {
    return t(['Assessment', married ? 'Married' : 'Single']);
  };

  const getWorkedFor = (option: LaborerCaseAssessmentWorkedFor) => {
    return option === 'PRIMARY_BUSINESS_OWNER'
      ? t(['Assessment', 'LaborerWorkedFor1'])
      : t(['Assessment', 'LaborerWorkedFor2']);
  };

  const getOfficialDocument = (option: LaborerCaseAssessmentOfficialDocument) => {
    if (option === 'USER_IS_CONVINCED') {
      return t(['Assessment', 'UserIsConvinced']);
    }

    if (option === 'NO') {
      return t(['Common', 'No']);
    }

    return t(['Common', 'Yes']);
  };

  const getDocumentType = (option: LaborerCaseAssessmentDocumentType) => {
    if (option === 'PASSPORT') {
      return t(['Assessment', 'Passport']);
    }

    if (option === 'RESIDENCE') {
      return t(['Assessment', 'Residence']);
    }

    if (option === 'DOCUMENT') {
      return t(['Assessment', 'Document']);
    }

    return t(['Assessment', 'ResidentPersonalityReport']);
  };

  const getPersonalBelongings = (belongings: boolean) => {
    return t(['Common', belongings ? 'Yes' : 'No']);
  };

  const getAssessmentAnswer = (answer: boolean) => {
    return t(['Common', answer ? 'Yes' : 'No']);
  };

  const getAssessmentQuestion = (index: number) => {
    return get(caseAssessment, `question${index}`);
  };

  const getEntranceReason = (option: LaborerCaseAssessmentEntranceReason) => {
    return option === 'EMPLOYER_RETURN'
      ? t(['Assessment', 'DisputeEntranceReasonR1'])
      : t(['Assessment', 'DisputeEntranceReasonR2']);
  };

  const caseSummaryEmployerName = getValue(caseDetails, 'employerName');
  const caseSummaryProInformationName = getValue(caseDetails, 'proInformation.name');

  const employerNameSequence = createSequence(caseSummaryEmployerName, '20/30') || [];
  const proInformationNameSequence = createSequence(caseSummaryProInformationName, '20/30') || [];
  const workDescriptionSequence = createSequence(caseAssessment.workDescription, '20/80') || [];
  const disputeDescriptionSequence = createSequence(caseAssessment.disputeDescription, '40/60') || [];
  const laborerTemporalResidenceSequence = createSequence(caseAssessment.laborerTemporalResidence, '40/60') || [];
  const socialWorkerRecommendationSequence = createSequence(caseAssessment.socialWorkerRecommendation, '40/60') || [];

  const renderCaseNumberAndClassification = () => {
    return (
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.tableColValue]}>
            <Text style={styles.tableCell}>
              {getCaseClassificationLabel(committeeCaseClassifications, caseDetails.laborCommitteeClassification)}
            </Text>
          </View>

          <View style={[styles.tableCol, styles.tableColHeader]}>
            <Text style={styles.tableCell}>{t(['Laborer', 'CommitteeClassification'])}</Text>
          </View>

          <View style={[styles.tableCol, styles.tableColValue]}>
            <Text style={styles.tableCell}>
              {getCaseClassificationLabel(registrarCaseClassifications, caseDetails.registrarClassification)}
            </Text>
          </View>

          <View style={[styles.tableCol, styles.tableColHeader]}>
            <Text style={styles.tableCell}>{t(['Laborer', 'RegistrarClassification'])}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.tableColValueSpacious]}>
            <Text style={styles.tableCell}>{caseDetails.caseNumber}</Text>
          </View>

          <View style={[styles.tableCol, styles.tableColHeader]}>
            <Text style={styles.tableCell}>{t(['Laborer', 'CaseNo'])}</Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Document title={`Case summary - Case No.${caseDetails.caseNumber}`}>
      <Page style={styles.body}>
        <View style={styles.pageHeader}>
          <Text style={styles.pageTitle}>{t(['Case', 'DetailsHeader'])}</Text>
          <Image source={MusanedLogo} style={styles.pageImage} />
        </View>

        {renderCaseNumberAndClassification()}

        <Text style={styles.sectionHeader}>{t(['Common', 'LaborerInfo'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerNumber}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'IqamaBorderNo'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerName}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'LaborerName'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerPassportNumber}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'PassportNo'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>
                {getNationalityLabelByName(nationalities, caseDetails.laborerNationality)}
              </Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Nationality'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{fullFormat(caseDetails.joiningDate)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'JoiningToCenterDate'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>
                {getOccupationLabelByName(laborerOccupations, caseDetails.laborerOccupation)}
              </Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Occupation'])}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionHeader}>{t(['Laborer', 'CaseDetailsEmployerInformation'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'employerId')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'CaseDetailsEmployerId'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              {employerNameSequence.map((sequence, index) => (
                <Text key={index} style={styles.tableCell}>
                  {sequence}
                </Text>
              ))}
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'EmployerName'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValueSpacious]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'employerMobileNumber')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'MobileNo'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValueSpacious]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'employerAddress')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Address'])}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.sectionHeader}>{t(['Laborer', 'CaseDetailsProInformation'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'proInformation.laborerProId')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'ProNo'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              {proInformationNameSequence.map((sequence, index) => (
                <Text key={index} style={styles.tableCell}>
                  {sequence}
                </Text>
              ))}
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Laborer', 'ProName'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'proInformation.mobileNumber')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'MobileNo'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'proInformation.email')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Email'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValueSpacious]}>
              <Text style={styles.tableCell}>{getValue(caseDetails, 'proInformation.address')}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'Address'])}</Text>
            </View>
          </View>
        </View>
      </Page>

      <Page style={styles.body}>
        <View style={styles.pageHeader} fixed>
          <Text style={styles.pageTitle}>{t(['Assessment', 'StartAssesment'])}</Text>
          <Image source={MusanedLogo} style={styles.pageImage} />
        </View>

        {renderCaseNumberAndClassification()}

        <Text style={styles.sectionHeader}>{t(['Assessment', 'LaborerSectionName'])}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getMartialStatus(caseAssessment.married)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Assessment', 'MaritalStatus'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseDetails.laborerName}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'LaborerName'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseAssessment.workDurationInMonths}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{flipParentheses(t(['Assessment', 'WorkDurationLabel']))}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{caseAssessment.cityName}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Common', 'LaborerCity'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValueSpacious]}>
              {workDescriptionSequence.map((sequence, index) => (
                <Text key={index} style={styles.tableCell}>
                  {sequence}
                </Text>
              ))}
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Assessment', 'WorkDescription'])}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getOfficialDocument(caseAssessment.officialDocument)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Assessment', 'LaborerOfficialDocuments'])}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColValue]}>
              <Text style={styles.tableCell}>{getWorkedFor(caseAssessment.workedFor)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader]}>
              <Text style={styles.tableCell}>{t(['Assessment', 'LaborerWorkedFor'])}</Text>
            </View>
          </View>

          {caseAssessment.documentType && (
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableColValueSpacious]}>
                <Text style={styles.tableCell}>{getDocumentType(caseAssessment.documentType)}</Text>
              </View>

              <View style={[styles.tableCol, styles.tableColHeader]}>
                <Text style={styles.tableCell}>{t(['Assessment', 'DocumentType'])}</Text>
              </View>
            </View>
          )}

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableColValue, { width: '50%' }]}>
              <Text style={styles.tableCell}>{getPersonalBelongings(caseAssessment.personalBelongings)}</Text>
            </View>

            <View style={[styles.tableCol, styles.tableColHeader, { width: '50%' }]}>
              <Text style={styles.tableCell}>{t(['Assessment', 'LaborerPersonalBelongings'])}</Text>
            </View>
          </View>
        </View>

        <View wrap={false}>
          <Text style={styles.sectionHeader}>{t(['Assessment', 'WorkReatedSectionName'])}</Text>
          <View style={styles.table}>
            {Array.from({ length: 12 }).map((_, index) => {
              const assessmentQuestion = tBasic(`Assessment:WorkQ${index + 1}`);
              const assessmentQuestionSequence = createSequence(assessmentQuestion, '50/50') || [];
              const assessmentQuestionAnswer = getAssessmentAnswer(getAssessmentQuestion(index + 1));

              return (
                <View style={styles.tableRow} key={index} wrap={false}>
                  <View style={[styles.tableCol, styles.tableColValue, { width: '50%' }]}>
                    <Text style={styles.tableCell}>{assessmentQuestionAnswer}</Text>
                  </View>

                  <View style={[styles.tableCol, styles.tableColHeader, { width: '50%' }]}>
                    {assessmentQuestionSequence.map((sequence, idx) => (
                      <Text key={idx} style={styles.tableCell}>
                        {sequence}
                      </Text>
                    ))}
                  </View>
                </View>
              );
            })}
          </View>
        </View>

        <View>
          <Text style={styles.sectionHeader}>{t(['Assessment', 'DisputeSectionName'])}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableColValueSpacious, { width: '60%' }]}>
                <Text style={styles.tableCell}>{getEntranceReason(caseAssessment.entranceReason)}</Text>
              </View>

              <View style={[styles.tableCol, styles.tableColHeader, { width: '40%' }]}>
                <Text style={styles.tableCell}>{t(['Assessment', 'DisputeEntranceReason'])}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableColValueSpacious, { width: '60%' }]}>
                {disputeDescriptionSequence.map((sequence, index) => (
                  <Text key={index} style={styles.tableCell}>
                    {sequence}
                  </Text>
                ))}
              </View>

              <View style={[styles.tableCol, styles.tableColHeader, { width: '40%' }]}>
                <Text style={styles.tableCell}>{t(['Assessment', 'DisputeDisputesDescription'])}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableColValueSpacious, { width: '60%' }]}>
                {laborerTemporalResidenceSequence.map((sequence, index) => (
                  <Text key={index} style={styles.tableCell}>
                    {sequence}
                  </Text>
                ))}
              </View>

              <View style={[styles.tableCol, styles.tableColHeader, { width: '40%' }]}>
                <Text style={styles.tableCell}>{t(['Assessment', 'DisputeLaborerTempResidence'])}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableColValueSpacious, { width: '60%' }]}>
                {socialWorkerRecommendationSequence.map((sequence, index) => (
                  <Text key={index} style={styles.tableCell}>
                    {sequence}
                  </Text>
                ))}
              </View>

              <View style={[styles.tableCol, styles.tableColHeader, { width: '40%' }]}>
                <Text style={styles.tableCell}>{t(['Assessment', 'DisputeSocialWorkerRecommendation'])}</Text>
              </View>
            </View>
          </View>
        </View>

        <Text style={styles.sectionHeader}>{t(['Assessment', 'Result'])}</Text>
        {violations.pairs.length ? (
          <View style={styles.table}>
            {violations.pairs.map(violation => {
              const [mainViolation, subViolation] = violation;
              const subViolationSequence = createSequence(tBasic(`Assessment:${subViolation}`), '40/60') || [];

              return (
                <View style={styles.tableRow} key={mainViolation + subViolation}>
                  <View style={[styles.tableCol, styles.tableColValue, { width: '60%' }]}>
                    {subViolationSequence.map((sequence, index) => (
                      <Text key={index} style={styles.tableCell}>
                        {sequence}
                      </Text>
                    ))}
                  </View>

                  <View style={[styles.tableCol, styles.tableColHeader, { width: '40%' }]}>
                    <Text style={styles.tableCell}>{tBasic(`Assessment:${mainViolation}`)}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        ) : (
          <Text style={[{ margin: 12 }]}>{t(['Assessment', 'NoResults'])}</Text>
        )}
      </Page>
    </Document>
  );
};

import { makeStyles, Theme, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(7),
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    backgroundColor: theme.palette.secondary.main,
    border: 'transparent',
    overflowX: 'hidden',
  },
  drawerOpen: {
    zIndex: theme.zIndex.drawer,
    width: 250,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: theme.spacing(7),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerList: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
  },
  drawerListItem: {
    height: 55,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  drawerItemText: {
    marginLeft: 15,
    marginRight: 15,
  },
  drawerItemActive: {
    backgroundColor: theme.palette.action.selected,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
  },
  menuButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    padding: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 20,
    color: theme.palette.primary.contrastText,
  },
  backdrop: {
    backgroundColor: fade(theme.palette.secondary.main, 0.45),
  },
}));

import { from, of, Observable } from 'rxjs';
import { map, switchMap, filter, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { replace } from 'connected-react-router';
import { Location, Action as HistoryAction } from 'history';
import { omit } from 'lodash';
import { parse, stringify } from 'query-string';
import { apiClient } from 'root';
import { routes } from 'const';
import { ofType, Action, RootState } from 'services/store';
import { i18nClient } from 'services/translation-client';
import { LaborerCasesQuery } from 'modules/laborer-case';
import { EwaCenterAction } from 'modules/ewa-center';
import { UserRole } from 'modules/user';
import { isUUID } from 'modules/shared';
import { SharedAction, SharedActionType } from './actions';
import { toNationalityResponseDto } from './dto';
import {
  RegistrarClassificationResponse,
  LaborCommitteeClassificationResponse,
  ReferralTypeResponse,
  HostingTypeResponse,
  NationalityResponse,
  AuthorityTypeResponse,
  ApplicationLocationState,
  OccupationResponse,
} from './types';

export const inspectLocationQueryParamsEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(SharedActionType.InspectLocationQueryParams),
    withLatestFrom(state$),
    filter(([_, { router, ewaCenter }]) => {
      const queryParams = parse(router.location.search, { parseNumbers: true });
      const isLaborerCasesView = router.location.pathname.startsWith(routes.laborerCases.main.path);
      const isEwaCenterSelectedByMLSD = Boolean(ewaCenter.selectedEwaCenterId);

      return isLaborerCasesView && 'ewaCenterId' in queryParams && !isEwaCenterSelectedByMLSD;
    }),
    map(([_, { router, user }]) => {
      const queryParams: LaborerCasesQuery = parse(router.location.search, { parseNumbers: true });
      const userDetailsData = user.userDetails.data;

      if (
        userDetailsData &&
        userDetailsData.userRole === UserRole.MLSD &&
        queryParams.ewaCenterId &&
        !isUUID(queryParams.ewaCenterId)
      ) {
        return replace(routes.ewaCenter.selection.path);
      }

      if (userDetailsData && userDetailsData.userRole !== UserRole.MLSD && queryParams.ewaCenterId) {
        return replace({
          pathname: router.location.pathname,
          state: router.location.state,
          search: stringify({ ...omit(queryParams, 'ewaCenterId') }),
        });
      }

      return EwaCenterAction.selectEwaCenter(queryParams.ewaCenterId!, true);
    })
  );

type LocationListenerPayload = {
  location: Location<ApplicationLocationState>;
  action: HistoryAction;
};
/**
 * Save current location on route change
 * Remove current location on back/forward button clicked or safe redirect called
 */
const startLocationObserverEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.StartLocationObserver),
    tap(({ payload: { history } }) =>
      SharedAction.saveCurrentPathname(history.location.pathname + history.location.search)
    ),
    switchMap(({ payload: { history } }) =>
      new Observable<LocationListenerPayload>(observer => {
        history.listen((location, action) => observer.next({ location, action }));
      }).pipe(
        map(({ location, action }) => {
          const path = location.pathname + location.search;
          const { state } = location;

          if (action === 'POP' || (state && state.isSafe)) {
            return SharedAction.removeCurrentPathname();
          }

          return SharedAction.saveCurrentPathname(path);
        })
      )
    )
  );

const fetchRegistrarCaseClassificationsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchRegistrarCaseClassifications),
    switchMap(() =>
      from(apiClient.get<RegistrarClassificationResponse[]>('/classifications/registrar')).pipe(
        map(({ data }) => SharedAction.fetchRegistrarCaseClassificationsSuccess(data)),
        catchError(() =>
          of(
            SharedAction.fetchRegistrarCaseClassificationsFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

const fetchLaborCommitteeCaseClassificationsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchCommitteeCaseClassifications),
    switchMap(() =>
      from(apiClient.get<LaborCommitteeClassificationResponse[]>('/classifications/laborercommittee')).pipe(
        map(({ data }) => SharedAction.fetchLaborCommitteCaseClassificationsSuccess(data)),
        catchError(() =>
          of(
            SharedAction.fetchLaborCommitteCaseClassificationsFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

const fetchReferralTypesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchReferralTypes),
    switchMap(() =>
      from(apiClient.get<ReferralTypeResponse[]>('/referrals')).pipe(
        map(({ data }) => SharedAction.fetchReferralTypesSuccess(data)),
        catchError(() =>
          of(
            SharedAction.fetchReferralTypesFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

const fetchHostingTypesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchHostingTypes),
    switchMap(() =>
      from(apiClient.get<HostingTypeResponse[]>('/hostingtypes')).pipe(
        map(({ data }) => SharedAction.fetchHostingTypesSuccess(data)),
        catchError(() =>
          of(
            SharedAction.fetchHostingTypesFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

const fetchNationalitiesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchNationalities),
    switchMap(() =>
      from(apiClient.get<NationalityResponse[]>('/nationalities')).pipe(
        map(({ data }) => SharedAction.fetchNationalitiesSuccess(data.map(toNationalityResponseDto))),
        catchError(() =>
          of(
            SharedAction.fetchNationalitiesFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

const fetchAuthorityTypesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchAuthorityTypes),
    switchMap(() =>
      from(apiClient.get<AuthorityTypeResponse[]>('/laborercases/authorities')).pipe(
        map(({ data }) => SharedAction.fetchAuthorityTypesSuccess(data)),
        catchError(() =>
          of(
            SharedAction.fetchAuthorityTypesFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const fetchLaborerOccupationsEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(SharedActionType.FetchLaborerOccupations),
    switchMap(() =>
      from(apiClient.get<OccupationResponse[]>('/occupations')).pipe(
        map(({ data }) => SharedAction.fetchLaborerOccupationsSuccess(data)),
        catchError(() =>
          of(
            SharedAction.fetchLaborerOccupationsFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const sharedEpics = combineEpics(
  startLocationObserverEpic,
  inspectLocationQueryParamsEpic,
  fetchRegistrarCaseClassificationsEpic,
  fetchLaborCommitteeCaseClassificationsEpic,
  fetchReferralTypesEpic,
  fetchHostingTypesEpic,
  fetchNationalitiesEpic,
  fetchAuthorityTypesEpic,
  fetchLaborerOccupationsEpic
);

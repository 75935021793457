import { RootState } from 'services/store';

export const selectLocationList = (store: RootState): RootState['shared']['locationList'] => {
  return store.shared.locationList;
};

export const selectNotificationQueue = (store: RootState): RootState['shared']['notificationQueue'] => {
  return store.shared.notificationQueue;
};

export const selectRegistrarCaseClassifications = (
  store: RootState
): RootState['shared']['caseClassifications']['registrar'] => {
  return store.shared.caseClassifications.registrar;
};

export const selectCommitteCaseClassifications = (
  store: RootState
): RootState['shared']['caseClassifications']['laborCommitte'] => {
  return store.shared.caseClassifications.laborCommitte;
};

export const selectReferralTypes = (store: RootState): RootState['shared']['referralTypes'] => {
  return store.shared.referralTypes;
};

export const selectHostingTypes = (store: RootState): RootState['shared']['hostingTypes'] => {
  return store.shared.hostingTypes;
};

export const selectNationalities = (store: RootState): RootState['shared']['nationalities'] => {
  return store.shared.nationalities;
};

export const selectAuthorityTypes = (store: RootState): RootState['shared']['authorityTypes'] => {
  return store.shared.authorityTypes;
};

export const selectLaborerOccupations = (store: RootState): RootState['shared']['occupations'] => {
  return store.shared.occupations;
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '135px',
    height: '36px',
    padding: 0,
    fontSize: '14px',
  },
  sizeSmall: {
    width: '68px',
  },
  sizeLarge: {
    width: 'auto',
    padding: `0 ${theme.spacing(1)}px`,
    textTransform: 'none',
  },
}));

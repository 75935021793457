import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.appBar,
    position: 'fixed',
    right: 0,
    backgroundColor: '#fafafa',
  },
  reserveLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

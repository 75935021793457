import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { isNotProduction } from 'config';
import { Autocomplete, AutocompleteOption, AutocompleteProps } from 'components/common';

type ForbiddenProps =
  | 'value'
  | 'onChange'
  | 'onBlur'
  | 'defaultValue'
  | 'size'
  | 'label'
  | 'error'
  | 'helperText'
  | 'children';
type FormAutocompleteProps<T extends AutocompleteOption> = Omit<AutocompleteProps<T>, ForbiddenProps> & {
  valueKey: keyof T;
  name: string;
  label: string;
};

export const FormAutocomplete = <T extends AutocompleteOption>({
  options,
  labelKey,
  valueKey,
  name,
  ...props
}: FormAutocompleteProps<T>) => {
  const { errors, control } = useFormContext();
  const error = get(errors, name, null);
  const errorMessage = get(error, 'message', '');

  if (isNotProduction() && !control) {
    console.error(
      'FormAutocomplete: this component must be descendant of `FormProvider` in order to use form context.'
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ name: fieldName, value, onChange, onBlur }) => (
        <Autocomplete
          name={fieldName}
          value={options.find(option => option[valueKey] === value) || null}
          onChange={(_, option) => onChange(get(option, valueKey, null))}
          onBlur={onBlur}
          options={options}
          labelKey={labelKey}
          error={Boolean(error)}
          helperText={errorMessage}
          {...props}
        />
      )}
    />
  );
};

import React from 'react';
import cn from 'classnames';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { useStyles } from './ListItem.styles';

type ListItemProps = PaperProps & {
  header?: boolean;
  badge?: React.ReactNode;
};

export const ListItem: React.FC<ListItemProps> = ({ children, header, badge, className, ...paperProps }) => {
  const styles = useStyles();

  return (
    <Paper className={cn(className, styles.card, { [styles.header]: header })} {...paperProps}>
      {badge && <div className={styles.badge}>{badge}</div>}
      {children}
    </Paper>
  );
};

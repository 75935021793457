import { i18nClient } from 'services';

export const maritalStatusOptions = [
  {
    value: 'false',
    label: i18nClient.t('Assessment:Single'),
  },
  {
    value: 'true',
    label: i18nClient.t('Assessment:Married'),
  },
];

export const entranceReasonOptions = [
  {
    value: 'EMPLOYER_RETURN',
    label: i18nClient.t('Assessment:DisputeEntranceReasonR1'),
  },
  {
    value: 'WORKER_RETURN',
    label: i18nClient.t('Assessment:DisputeEntranceReasonR2'),
  },
];

export const workQuestions = [
  {
    name: 'question1',
    label: i18nClient.t('Assessment:WorkQ1'),
  },
  {
    name: 'question2',
    label: i18nClient.t('Assessment:WorkQ2'),
  },
  {
    name: 'question3',
    label: i18nClient.t('Assessment:WorkQ3'),
  },
  {
    name: 'question4',
    label: i18nClient.t('Assessment:WorkQ4'),
  },
  {
    name: 'question5',
    label: i18nClient.t('Assessment:WorkQ5'),
  },
  {
    name: 'question6',
    label: i18nClient.t('Assessment:WorkQ6'),
  },
  {
    name: 'question7',
    label: i18nClient.t('Assessment:WorkQ7'),
  },
  {
    name: 'question8',
    label: i18nClient.t('Assessment:WorkQ8'),
  },
  {
    name: 'question9',
    label: i18nClient.t('Assessment:WorkQ9'),
  },
  {
    name: 'question10',
    label: i18nClient.t('Assessment:WorkQ10'),
  },
  {
    name: 'question11',
    label: i18nClient.t('Assessment:WorkQ11'),
  },
  {
    name: 'question12',
    label: i18nClient.t('Assessment:WorkQ12'),
  },
];

export const workOptions = [
  {
    value: 'true',
    label: i18nClient.t('Yes'),
  },
  {
    value: 'false',
    label: i18nClient.t('No'),
  },
];

export const workedForOptions = [
  {
    value: 'PRIMARY_BUSINESS_OWNER',
    label: i18nClient.t('Assessment:LaborerWorkedFor1'),
  },
  {
    value: 'TRANSPORT_SERVICES',
    label: i18nClient.t('Assessment:LaborerWorkedFor2'),
  },
];

export const officialDocumentsOptions = [
  {
    value: 'YES',
    label: i18nClient.t('Yes'),
  },
  {
    value: 'NO',
    label: i18nClient.t('No'),
  },
  {
    value: 'USER_IS_CONVINCED',
    label: i18nClient.t('Assessment:UserIsConvinced'),
  },
];

export const documentTypeOptions = [
  {
    value: 'PASSPORT',
    label: i18nClient.t('Assessment:Passport'),
  },
  {
    value: 'RESIDENCE',
    label: i18nClient.t('Assessment:Residence'),
  },
  {
    value: 'DOCUMENT',
    label: i18nClient.t('Assessment:Document'),
  },
  {
    value: 'RESIDENT_PERSONALITY_REPORT',
    label: i18nClient.t('Assessment:ResidentPersonalityReport'),
  },
];

export const personalBelongingsOptions = [
  {
    value: 'true',
    label: i18nClient.t('Yes'),
  },
  {
    value: 'false',
    label: i18nClient.t('No'),
  },
];

import React, { useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@material-ui/core';
import { useTranslation } from 'services';
import { isCaseFinancialClaimsType, LaborCommitteeClassificationResponse } from 'modules/shared';
import { FormSection, FormRow } from 'components/common';
import { FormTextField, FormSelect } from 'components/form-components';
import { caseClassificationFormSchema, CaseClassificationFormData } from './validation';
import { useStyles } from './CaseClassificationForm.styles';

type CaseClassificationFormProps = {
  committeeClassifications: LaborCommitteeClassificationResponse[];
  onSubmit: SubmitHandler<CaseClassificationFormData>;
};

export const CaseClassificationForm: React.FC<CaseClassificationFormProps> = ({
  committeeClassifications,
  onSubmit,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const formMethods = useForm<CaseClassificationFormData>({
    mode: 'onTouched',
    resolver: yupResolver(caseClassificationFormSchema()),
  });
  const { formState, trigger, handleSubmit } = formMethods;

  const laborCommitteeClassificationValue = formMethods.watch('laborCommitteeClassification');

  useEffect(() => {
    if (formState.isDirty) {
      trigger('laborCommitteeClassification');
    }
  }, [laborCommitteeClassificationValue, formState.isDirty]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormSection title={t(['Laborer', 'CaseClassificationFormSection'])}>
          <FormRow>
            <FormSelect
              name="laborCommitteeClassification"
              label={t(['Laborer', 'CommitteeClassification'])}
              options={committeeClassifications}
              labelKey="arabicName"
              valueKey="name"
            />

            {isCaseFinancialClaimsType(laborCommitteeClassificationValue) && (
              <FormTextField className={styles.formField} name="claimAmount" label={t(['Case', 'ClaimAmountLabel'])} />
            )}
          </FormRow>
        </FormSection>

        <FormSection>
          <Button color="primary" type="submit" variant="contained">
            {t(['Common', 'Submit'])}
          </Button>
        </FormSection>
      </form>
    </FormProvider>
  );
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '28px',
  },
  content: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  toggler: {
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    outline: 0,
  },
  arrow: {
    transition: 'transform 330ms ease-out',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    transition: 'height 330ms ease-out',
  },
}));

import { apiClient } from 'root';

export const getReportFile = async () => {
  const { data } = await apiClient.get<Blob>(`/statistics/ondemand/users`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
  return data;
};

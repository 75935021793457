import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isStatusPending } from 'services/store';
import { RequestConfig } from 'services/api-client';
import { Query } from 'modules/http';
import { UserAction } from '../actions';
import {
  selectUsersList,
  selectUserDetails,
  selectPostUserStatus,
  selectInviteUserStatus,
  selectUpdateUserStatus,
  selectUserIdDetails,
} from '../selectors';
import { CreateBasicUserRequest, ExtendedUser, EditUserPrivilege } from '../types';

export const useUsersManagement = () => {
  const dispatch = useDispatch();

  const usersList = useSelector(selectUsersList);
  const userDetails = useSelector(selectUserDetails);
  const postUserStatus = useSelector(selectPostUserStatus);
  const updateUserStatus = useSelector(selectUpdateUserStatus);
  const inviteUserStatus = useSelector(selectInviteUserStatus);
  const userIdDetails = useSelector(selectUserIdDetails);

  const isLoading =
    isStatusPending(usersList.status) ||
    isStatusPending(userDetails.status) ||
    isStatusPending(postUserStatus) ||
    isStatusPending(updateUserStatus) ||
    isStatusPending(inviteUserStatus);

  const fetchUsers = useCallback(
    (query?: Query) => {
      dispatch(UserAction.fetchUsers(query));
    },
    [dispatch]
  );

  const fetchLoggedUserDetails = useCallback(
    (requestOptions?: RequestConfig) => {
      dispatch(UserAction.fetchLoggedUserDetails(requestOptions));
    },
    [dispatch]
  );

  const fetchLoggedUserDetailsStartPooling = useCallback(() => {
    dispatch(UserAction.fetchLoggedUserDetailsStartPooling());
  }, [dispatch]);

  const createUser = useCallback(
    (payload: CreateBasicUserRequest) => {
      dispatch(UserAction.postUser(payload));
    },
    [dispatch]
  );

  const editUser = useCallback(
    (data: ExtendedUser) => {
      dispatch(UserAction.updateUser(data));
    },
    [dispatch]
  );

  const createUserInvitation = useCallback(
    (userId: ExtendedUser['id']) => {
      dispatch(UserAction.inviteUser(userId));
    },
    [dispatch]
  );

  const fetchRoles = useCallback(() => {
    dispatch(UserAction.fetchRoles());
  }, [dispatch]);

  const fetchPrivileges = useCallback(() => {
    dispatch(UserAction.fetchPrivileges());
  }, [dispatch]);

  const fetchUser = useCallback(
    (userId: string) => {
      dispatch(UserAction.fetchUser(userId));
    },
    [dispatch]
  );

  const resetUserDetails = useCallback(() => {
    dispatch(UserAction.resetUserDetails());
  }, [dispatch]);

  const updateUserPrivilege = useCallback(
    (id: string, privileges: EditUserPrivilege) => {
      dispatch(UserAction.updateUserPrivilege(id, privileges));
    },
    [dispatch]
  );

  const deleteUser = useCallback(
    (userId: string) => {
      dispatch(UserAction.deleteUser(userId));
    },
    [dispatch]
  );

  const fetchUserPoints = useCallback(() => {
    dispatch(UserAction.fetchUserPoints());
  }, [dispatch]);

  return {
    isLoading,

    users: usersList.data,
    usersCount: usersList.count,
    loggedUser: userDetails.data,
    userIdDetails: userIdDetails.data,

    fetchUsers,
    fetchLoggedUserDetails,
    fetchLoggedUserDetailsStartPooling,
    createUser,
    editUser,
    createUserInvitation,
    fetchRoles,
    fetchPrivileges,
    fetchUser,
    updateUserPrivilege,
    resetUserDetails,
    deleteUser,
    fetchUserPoints,
  };
};

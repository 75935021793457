export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  PendingLogin = 'PENDING_LOGIN',
}

export enum UserRole {
  Admin = 'ADMIN',
  Registrar = 'REGISTRAR',
  LaborCommittee = 'LABOR_COMMITTEE',
  SocialWorker = 'SOCIAL_WORKER',
  MLSD = 'MLSD',
  EwaCenterManager = 'EWA_CENTER_MANAGER',
  none = '',
}

export enum UserPrivilege {
  REGISTRAR_VIEW_AND_MANAGE = 'REGISTRAR_VIEW_AND_MANAGE',
  REGISTRAR_VIEW_AND_EXPORT_REPORT = 'REGISTRAR_VIEW_AND_EXPORT_REPORT',
}

export type BasicUser = {
  firstName: string;
  lastName: string;
  email: string;
  ewaCenterId: string | null | undefined;
  userRole: UserRole;
  privileges?: string[] | null | undefined;
};

export type ExtendedUser = BasicUser & {
  id: string;
  status: UserStatus;
  ewaCenterName: string | null;
  invitationCount: number;
};

export type Role = {
  id: number;
  name: UserRole;
  arabicName: string;
  engilshName: string;
};

export type Privilege = {
  id: number;
  role: string;
  code: UserPrivilege;
  name: string;
  arabicName: string;
};

export type CreateBasicUserRequest = Omit<BasicUser, 'ewaCenterId'> & {
  ewaCenterId?: BasicUser['ewaCenterId'];
};
export type CreateExtendedUserUserRequest = Omit<ExtendedUser, 'ewaCenterId'> & {
  ewaCenterId: BasicUser['ewaCenterId'];
};

export type UserDetailsResponse = ExtendedUser;

export type EditUserPrivilege = {
  privileges?: string[];
};

export type UserPoints = {
  points?: number;
};

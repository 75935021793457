import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Level } from './level';

type BadgePropTypes = {
  level?: Level;
  className?: string;
};

const useStyles = makeStyles(t => ({
  badge: {
    borderRadius: '4px',
    padding: '0 10px',
  },
  [Level.DEFAULT]: {
    color: t.palette.primary.main,
    backgroundColor: '#e1e8ed',
  },
  [Level.INFO]: {
    color: t.palette.primary.contrastText,
    backgroundColor: t.palette.primary.main,
  },
  [Level.SUCCESS]: {
    color: t.palette.primary.contrastText,
    backgroundColor: '#79c91d',
  },
  [Level.DANGER]: {
    color: t.palette.primary.contrastText,
    backgroundColor: '#ef545d',
  },
}));

export const Badge: React.FC<BadgePropTypes> = ({ children, level = Level.DEFAULT, className }) => {
  const styles = useStyles();

  return <div className={cn(className, styles.badge, styles[level])}>{children}</div>;
};

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '& + &': {
      marginTop: theme.spacing(2),
    },
    fontSize: '18px',
  },
  title: {
    color: theme.palette.secondary.contrastText,
  },
}));

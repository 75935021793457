import { from, of, race } from 'rxjs';
import { switchMap, mergeMap, map, mapTo, catchError, filter, take, withLatestFrom } from 'rxjs/operators';
import { Epic, combineEpics } from 'redux-observable';
import { push } from 'connected-react-router';
import { apiClient, webSocketClient } from 'root';
import { ofType, Action, RootState } from 'services/store';
import { SharedAction } from 'modules/shared';
import { i18nClient } from 'services/translation-client';
import { selectUserId, hasUserRoles, UserRole } from 'modules/user';
import { normalizeQueryParams, Paginated } from 'modules/http';
import { routes } from 'const';
import { LaborerAction, LaborerActionType } from './actions';
import { LaborerCaseResponse, EditLaborerCaseRequest, ReservationEvent } from './types';
import { preparePostLaborerCaseErrorMessage, isReopenWindowAvailable } from './helpers';

export const fetchLaborerCaseEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.FetchLaborerCase),
    switchMap(({ payload }) =>
      from(apiClient.get<LaborerCaseResponse>(`/laborercases/${payload.laborerCaseId}`)).pipe(
        map(({ data }) => LaborerAction.fetchLaborerCaseSuccess(data)),
        catchError(() =>
          of(
            LaborerAction.fetchLaborerCaseFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const fetchLaborerCasesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.FetchLaborerCases),
    switchMap(({ payload }) =>
      from(
        apiClient.get<Paginated<LaborerCaseResponse>>('/laborercases', {
          params: normalizeQueryParams(payload.query),
        })
      ).pipe(
        map(({ data }) => LaborerAction.fetchLaborerCasesSuccess(data)),
        catchError(() =>
          of(
            LaborerAction.fetchLaborerCasesFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const fetchFilteredLaborerCasesEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.FetchLaborerFilteredCases),
    switchMap(({ payload }) =>
      from(
        apiClient.get<Paginated<LaborerCaseResponse>>('/laborercases/search', {
          params: normalizeQueryParams(payload.query),
        })
      ).pipe(
        map(({ data }) => LaborerAction.fetchLaborerCasesSuccess(data)),
        catchError(() =>
          of(
            LaborerAction.fetchLaborerCasesFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const postLaborerCaseEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.PostLaborerCase),
    switchMap(({ payload }) =>
      from(apiClient.post('/laborercases', { data: payload })).pipe(
        map(() => LaborerAction.postLaborerCaseSuccess()),
        catchError(error =>
          of(
            LaborerAction.postLaborerCaseFailure(),
            SharedAction.notifyUser(i18nClient.t(preparePostLaborerCaseErrorMessage(error)), 'error')
          )
        )
      )
    )
  );

export const postLaborerCaseSuccessEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.PostLaborerCaseSuccess),
    mergeMap(() =>
      of(SharedAction.notifyUser(i18nClient.t('Common:GeneralSendSuccessMessage')), push(routes.laborerCases.main.path))
    )
  );

export const updateLaborerCaseEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.UpdateLaborerCase),
    switchMap(({ payload }) => {
      const { data, successMessageTranslationKey, blockRedirect, shouldRefetch } = payload;

      return from(
        apiClient.put<EditLaborerCaseRequest>('/laborercases', { data })
      ).pipe(
        mergeMap(() =>
          of(
            LaborerAction.updateLaborerCaseSuccess(blockRedirect, shouldRefetch, data.id),
            SharedAction.notifyUser(i18nClient.t(successMessageTranslationKey))
          )
        ),
        catchError(() =>
          of(
            LaborerAction.updateLaborerCaseFailure(),
            SharedAction.notifyUser(i18nClient.t('GeneralErrorMessage'), 'error')
          )
        )
      );
    })
  );

export const updateLaborerCaseSuccessRedirectEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.UpdateLaborerCaseSuccess),
    filter(({ payload }) => !payload.blockRedirect),
    mapTo(push(routes.laborerCases.main.path))
  );

export const updateLaborerCaseSuccessRefetchEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.UpdateLaborerCaseSuccess),
    filter(({ payload }) => payload.shouldRefetch),
    map(({ payload }) => LaborerAction.fetchLaborerCase(payload.laborerCaseId))
  );

export const connectToReservationSocketEpic: Epic<Action> = actions$ =>
  actions$.pipe(
    ofType(LaborerActionType.ConnectToReservationSocket),
    mergeMap(() =>
      of(webSocketClient.connect('/ws/reservations')).pipe(
        map(() => LaborerAction.connectToReservationSocketSuccess()),
        catchError(() =>
          of(
            LaborerAction.connectToReservationSocketFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const reserveLaborerCaseEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(LaborerActionType.ReserveLaborerCase),
    withLatestFrom(state$),
    filter(([_, state]) => !!selectUserId(state)),
    switchMap(([{ payload }, state]) =>
      from(
        webSocketClient.send<ReservationEvent>({
          data: { laborerCaseId: payload.laborerCaseId, userId: selectUserId(state)!, eventType: 'RESERVE' },
          options: {
            retryLimit: 3,
            retryDelay: 1500,
          },
        })
      ).pipe(
        mergeMap(() =>
          race(
            actions$.pipe(
              ofType(LaborerActionType.ReserveLaborerCaseSuccess),
              take(1),
              mergeMap(() => of(SharedAction.notifyUser(i18nClient.t('Case:Reservation.SuccessMessage'))))
            ),
            actions$.pipe(
              ofType(LaborerActionType.ReserveLaborerCaseFailure),
              take(1),
              mergeMap(() =>
                of(
                  SharedAction.notifyUser(i18nClient.t('Case:Reservation.IsAlreadyReserved'), 'error'),
                  push(routes.laborerCases.main.path)
                )
              )
            )
          )
        ),
        catchError(() =>
          of(
            LaborerAction.reserveLaborerCaseFailure(),
            SharedAction.notifyUser(i18nClient.t('Case:Reservation.FailureMessage'), 'error'),
            push(routes.laborerCases.main.path)
          )
        )
      )
    )
  );

export const releaseLaborerCaseEpic: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(LaborerActionType.ReleaseLaborerCase),
    withLatestFrom(state$),
    filter(([_, state]) => !!selectUserId(state)),
    switchMap(([{ payload }, state]) =>
      of(
        webSocketClient.send<ReservationEvent>({
          data: { laborerCaseId: payload.laborerCaseId, eventType: 'RELEASE', releasedByUserId: selectUserId(state) },
          options: {
            retryLimit: 3,
            retryDelay: 1500,
          },
        })
      ).pipe(
        map(() => LaborerAction.releaseLaborerCaseSuccess()),
        catchError(() =>
          // TODO: should be also handled after receiving ws error messages
          of(
            LaborerAction.releaseLaborerCaseFailure(),
            SharedAction.notifyUser(i18nClient.t('Common:GeneralErrorMessage'), 'error')
          )
        )
      )
    )
  );

export const reserveCaseAgain: Epic<Action, Action, RootState> = (actions$, state$) =>
  actions$.pipe(
    ofType(LaborerActionType.ReserveLaborerCaseAgain),
    switchMap(({ payload }) =>
      from(apiClient.get<LaborerCaseResponse>(`/laborercases/${payload.laborerCaseId}`)).pipe(
        withLatestFrom(state$),
        mergeMap(([{ data: laborerCase }, store]) => {
          const hasRoles = hasUserRoles(store);
          const currentUserId = selectUserId(store);
          const isReservationFree = laborerCase && laborerCase.reservedByUserId === null && currentUserId;

          if (isReservationFree) {
            const hasPendingClearanceStatus =
              hasRoles([UserRole.Registrar]) && laborerCase.status === 'PENDING_CLEARANCE';
            const hasPendingSWReviewStatus =
              hasRoles([UserRole.SocialWorker]) && laborerCase.status === 'PENDING_SW_REVIEW';
            const hasClosedStatus =
              hasRoles([UserRole.Registrar]) &&
              laborerCase.status === 'CLOSED' &&
              !laborerCase.successorExists &&
              laborerCase.laborerCaseClosingDetails &&
              isReopenWindowAvailable(laborerCase.laborerCaseClosingDetails.exitDateTime);

            if (routes.laborerCases.close.isValid(store.router.location.pathname)) {
              if (hasPendingClearanceStatus) {
                return of(LaborerAction.reserveLaborerCase(laborerCase.id));
              }
            }

            if (routes.laborerCases.assessment.isValid(store.router.location.pathname)) {
              if (hasPendingSWReviewStatus) {
                return of(LaborerAction.reserveLaborerCase(laborerCase.id));
              }
            }

            if (routes.laborerCases.reopen.isValid(store.router.location.pathname)) {
              if (hasClosedStatus) {
                return of(LaborerAction.reserveLaborerCase(laborerCase.id));
              }
            }

            if (routes.laborerCases.details.isValid(store.router.location.pathname)) {
              if (hasPendingClearanceStatus || hasPendingSWReviewStatus || hasClosedStatus) {
                return of(LaborerAction.reserveLaborerCase(laborerCase.id));
              }
            }
          }

          return of(
            SharedAction.notifyUser(i18nClient.t('Case:Reservation.IsAlreadyReserved'), 'error'),
            push(routes.laborerCases.main.path)
          );
        })
      )
    )
  );

export const laborerCaseEpics = combineEpics(
  fetchLaborerCaseEpic,
  fetchLaborerCasesEpic,
  fetchFilteredLaborerCasesEpic,
  postLaborerCaseEpic,
  postLaborerCaseSuccessEpic,
  updateLaborerCaseEpic,
  updateLaborerCaseSuccessRedirectEpic,
  updateLaborerCaseSuccessRefetchEpic,
  connectToReservationSocketEpic,
  reserveLaborerCaseEpic,
  releaseLaborerCaseEpic,
  reserveCaseAgain
);

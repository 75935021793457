import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { get } from 'lodash';
import { isNotProduction } from 'config';
import {
  FormControl,
  FormControlProps as InternalFormControlProps,
  Select,
  SelectOption,
  SelectProps,
} from 'components/common';

type ForbiddenProps = 'value' | 'onChange' | 'onBlur' | 'defaultValue' | 'children';
type FormSelectProps<T extends SelectOption> = Omit<SelectProps<T>, ForbiddenProps> & {
  name: string;
  label?: string;
  FormControlProps?: InternalFormControlProps;
};

export const FormSelect = <T extends SelectOption>({ name, label, FormControlProps, ...props }: FormSelectProps<T>) => {
  const { errors, control } = useFormContext();
  const error = get(errors, name, null);
  const errorMessage = get(error, 'message', '');

  if (isNotProduction() && !control) {
    console.error('FormSelect: this component must be descendant of `FormProvider` in order to use form context.');
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ name: fieldName, value, onChange, onBlur }) => (
        <FormControl error={Boolean(error)} label={label} helperText={errorMessage} {...FormControlProps}>
          <Select name={fieldName} value={value} onChange={onChange} onBlur={onBlur} {...props} />
        </FormControl>
      )}
    />
  );
};
